<template>
  <div id="human_base">
    <!-- 公共人才资源界面 -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
#human_base {
  width: 100%;
  height: 100%;
}
</style>
