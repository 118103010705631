import Vue from 'vue'
/**
 * 商机的二级菜单数据
 */
export function getMenuData() {
    return [{
            title: "我的商机",
            icon: "el-icon-s-help",
            path: "1",
            flag: 1,
            children: [{
                    title: "我的资源",
                    path: "my-resources",
                    active: "myResources",
                    flag: 1,
                },
                {
                    title: "我的商机",
                    path: "my-thread",
                    active: "myThread",
                    flag: 1,
                },
                {
                    title: "共享商机",
                    path: "thread-share",
                    active: "threadShare",
                    flag: 1,
                },
                {
                    title: "全部商机",
                    path: "all-thread",
                    active: "allThread",
                    flag: 1,
                },
                {
                    title: "微信待加",
                    path: "weixin-resources",
                    active: "weixinResources",
                    flag: 1,
                },
                {
                    title: "线索分配",
                    path: "thread-allocation",
                    active: "threadAllocation",
                    flag: 1,
                },
            ],
        },
        {
            title: "出访",
            icon: "el-icon-s-cooperation",
            path: "2",
            flag: 1,
            children: [{
                    title: "出访记录",
                    path: "thread-visit",
                    active: "threadVisit",
                    flag: 1,
                },
            ],
        },
        {
            title: "公海",
            icon: "el-icon-s-cooperation",
            path: "2",
            flag: 1,
            children: [{
                    title: "已签约客户",
                    path: "client-thread",
                    active: "clientThread",
                    flag: 1,
                },
                {
                    title: "商机池",
                    path: "threads",
                    active: "threadPool",
                    flag: 1,
                },
            ],
        },
        {
            title: "回收站",
            icon: "el-icon-delete-solid",
            path: "threads-recyle",
            active: "threadRecycle",
            children: [],
            flag: 1,
        },
        {
            title: "设置",
            icon: "el-icon-s-tools",
            path: "thread-setting",
            active: "threadSetting",
            children: [],
            flag: 1,
        },
    ]
}

/**
 * 获取商机中的抽屉数据格式
 * @param {*} title 当前商机名称
 * @param {*} authorityData 权限数据
 * @param {*} isHead 是否是机构负责人
 * @param {*} options 所有下拉框数组
 * 
 * options:{
 * allUser:[], // 所有用户
 * areaList:[], // 所有片区
 * sourceList:[], // 客户所有来源
 * addedValueTaxTypeList:[], // 增值税类型
 * }
 * 
 * {
 * label:"",
 * value:""
 * }
 */
export function myThreadDrawerData(title, authorityData, isHead, options) {

    
    let myThreadDrawerData = getDrawerData(title, isHead, options)

    // 拼接数据显示权限
    myThreadDrawerData = Vue.prototype.$initDrawerAuthorityData.initDrawerAuthorityData(myThreadDrawerData, authorityData, isHead)

    return myThreadDrawerData
}

export function visitDrawerData(modelListLine) {
    console.log('modelListLine', modelListLine)
    // return
    let myThreadDrawerData = {
        title: "出访记录",
        flag: false,
        settingOperList: [{
            text: "置为无效",
            active: "export",
            key: "invalid"
        },  ],
        buttonList: [{
                text: "导出",
                icon: "el-icon-edit-outline",
                active: "exportDetails",
                key: "exportDetails"
            },
        ],
        data: {
            title: '出访记录',
            titleEdit: false,
            isDisable: false, // 是否只读
            modelList: [{
                    line: modelListLine
                },
            ],
        },
    }

    return myThreadDrawerData
}

export function threadVisitMenuData() {
    return [
        {
            prop: "contactPerson",
            title: "联系人",
            width: "110"
        }, 
        {
            prop: "contactNumber",
            title: "手机号",
            width: "110"
        }, 
        {
            prop: "visitArea",
            title: "出访地区",
            width: "100"
        }, 
        {
            prop: "visitAddress",
            title: "详细地址",
          
        }, 
        {
            prop: "visitTimeStart",
            title: "出访时间",
            width: "155"
        }, 
        {
            prop: "duration",
            title: "出访时长",
            width: "120"
        }, 
        {
            prop: "visitReason",
            title: "出访事由",
            width: "120"
        }, 
        {
            prop: "registeredUserName",
            title: "提交人",
            width: "120"
        }, 
        {
            prop: "accompanyUserNames",
            title: "陪访人",
            width: "150"
        }, 
        {
            prop: "gmtCreate",
            title: "提交时间",
            width: "150"
        }, 
        {
            prop: "status",
            title: "完成情况",
            width: "100",
            fixed: "right",
            icon: true
        }, 

    ]
}

function getDrawerData(title, isHead, options) {
    // console.log("🚀 ~ getDrawerData ~ options:", options.allUser)
    let userData = JSON.parse(sessionStorage.getItem("userData"))
    let userList = [{
        label: userData.userName,
        value: userData.userId
    }]

    let myThreadDrawerData = {
        title: "销售商机",
        flag: false,
        hasSetting: true,
        status: "已成交", // 当前商机状态
        settingOperList: [{
            text: "置为无效",
            active: "zhiweiwuxiao",
            key: "invalid"
        }, {
            text: "释放至公海",
            active: "shifang",
            key: "openSea"
        }, {
            text: "删除",
            active: "delete_thread",
            key: "delete"
        }, {
            text: "彻底删除",
            active: "delete_thread",
            key: "shiftDelete"
        }, {
            text: "恢复商机",
            active: "recover",
            key: "restore"
        }, {
            text: "操作记录",
            active: "operating_record",
            key: "cancel"
        }, ],
        buttonList: [{
                text: "跟进",
                icon: "el-icon-edit-outline",
                active: "genjin",
                key: "followUp"
            },
            {
                text: "转成交",
                icon: "el-icon-user",
                active: "zhuanchengjiao",
                key: "turnover"
            },
            {
                text: "还原",
                icon: "el-icon-circle-plus-outline",
                active: "recover",
                key: "reduction"
            },
            {
                text: "分配",
                icon: "el-icon-circle-plus-outline",
                active: "allocation",
                key: "distribution"
            },
            {
                text: "共享商机",
                icon: "el-icon-circle-plus-outline",
                active: "convertToShare",
                key: "convertToShare"
            },
            // {
            //     text: "领取",
            //     icon: "el-icon-circle-plus-outline",
            //     active: "to_receive",
            //     key: "reduction"
            // },

        ],
        data: {
            title: title,
            titleEdit: true,
            isDisable: false, // 是否只读
            modelList: [{
                    line: [{
                            horizontal: true,
                            isShow: true, // 是否显示
                            isDisable: false, // 是否只读
                            title: "跟进联系人",
                            key: "followName",
                            type: "select",
                            value: "",
                            options: options.allUser ? options.allUser : [],
                        },
                        {
                            horizontal: true,
                            isShow: false,
                            isDisable: false,
                            title: "商机状态",
                            key: "threadStatus",
                            flag: "0", // 当前字段是否在其他字段中:0不在,1在
                            type: "text",
                            value: "跟进中",
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "跟进阶段",
                            key: "followStage",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "text",
                            value: "",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "片区",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "select",
                            key: "zone",
                            value: "",
                            options: options.areaList ? options.areaList : [],
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            marked: true,
                            title: "客户来源",
                            key: "customerSource",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "select",
                            value: "",
                            options: options.sourceList ? options.sourceList : [],
                        }, {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "商机创建人",
                            key: "createName",
                            flag: "0", // 当前字段是否在其他字段中:0不在,1在
                            type: "text",
                            value: "",
                        },
                    ],
                },
                {
                    line: [{
                        horizontal: true,
                        isShow: true,
                        isDisable: false,
                        title: "商机提交人",
                        key: "submitName",
                        flag: "0", // 当前字段是否在其他字段中:0不在,1在
                        type: "select",
                        value: "",
                        options: isHead ? (options.allUser ? options.allUser : []) : userList, // 判断是否是机构负责人  不是就只能选自己
                    }, ]
                },
                {
                    line: [{
                        horizontal: true,
                        isShow: true,
                        isDisable: false,
                        title: "共享用户",
                        key: "sharedUserId",
                        flag: "0", // 当前字段是否在其他字段中:0不在,1在
                        type: "selects-multiple",
                        value: "",
                        options: options.allUser ? options.allUser : [],
                    }, ]
                },
                {
                    line: [{
                        horizontal: true,
                        isShow: true,
                        isDisable: false,
                        marked: true,
                        title: "",
                        flag: "1", // 当前字段是否在其他字段中:0不在,1在
                        type: "linkman",
                        key: "contact", // 联系人
                        value: [],
                    }, ]
                },
                {
                    line: [{
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "增值税类型",
                            key: "addedValueTaxType",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "select",
                            value: "",
                            options: options.addedValueTaxTypeList ? options.addedValueTaxTypeList : [],
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "创建日期",
                            key: "createTime",
                            flag: "0", // 当前字段是否在其他字段中:0不在,1在
                            type: "time",
                            value: "",
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "代账到期时间",
                            key: "accountDueTime",
                            type: "time-month",
                            value: "",
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "地区",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "area",
                            key: "area",
                            value: [],
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "详细地址",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "input",
                            key: "address",
                            value: "跟进中",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "企业税号",
                            type: "input-but",
                            key: "enterpriseNumber",
                            value: "",
                            buts: [{
                                text: "工商信息",
                                active: "get_companyInfo"
                            }]
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "联系电话",
                            key: "tel",
                            type: "input",
                            value: "",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "开户行",
                            key: "bankName",
                            type: "input",
                            value: "",
                        },
                        {
                            horizontal: false,
                            isShow: true,
                            isDisable: false,
                            title: "银行账号",
                            key: "bankAccount",
                            type: "input",
                            value: "",
                        },
                    ],
                },
                {
                    line: [{
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "意向产品",
                            marked: true,
                            key: "intentionalProduct",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "tags",
                            value: [],
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            title: "备注",
                            flag: "1", // 当前字段是否在其他字段中:0不在,1在
                            type: "remark",
                            value: "180****8888",
                            key: "area",
                        },
                    ],
                },
                {
                    line: [{
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "跟进记录",
                            type: "text",
                            value: "",
                        },
                        {
                            horizontal: true,
                            isShow: true,
                            isDisable: false,
                            title: "",
                            flag: "0", // 当前字段是否在其他字段中:0不在,1在
                            type: "record", // 跟进记录
                            key: "followUpRecords",
                            value: [],
                        },
                    ],
                },
            ],
        },
    }

    return myThreadDrawerData
}

/**
 * 获取商机中的抽屉数据格式
 * @param {*} title 当前商机名称
 * @param {*} authorityData 权限数据
 * @param {*} isHead 是否是机构负责人
 * @param {*} options 所有下拉框数组
 * 
 * options:{
 * allUser:[], // 所有用户
 * areaList:[], // 所有片区
 * sourceList:[], // 客户所有来源
 * addedValueTaxTypeList:[], // 增值税类型
 * }
 * 
 * {
 * label:"",
 * value:""
 * }
 */
export function threadShareDrawerData(title, authorityData, isHead, options, flag) {
    let threadDrawerData = getDrawerDataByThreadShare(title, options, flag )

    // 拼接数据显示权限
    threadDrawerData = Vue.prototype.$initDrawerAuthorityData.initDrawerAuthorityData(threadDrawerData, authorityData, isHead)

    return threadDrawerData
}

// 根据商机共享获取抽屉数据
function getDrawerDataByThreadShare(title, options, flag) {
    let threadDrawerData = {
        data: {
            modelList: []
        }
    }
    switch (flag) {
        case '共享商机':
            threadDrawerData = {
                title: "销售商机",
                flag: false,
                hasSetting: true,
                status: "已成交", // 当前商机状态
                settingOperList: [ {
                    text: "操作记录",
                    active: "operating_record",
                    key: "cancel"
                }, ],
                buttonList: [],
                data: {
                    title: title,
                    titleEdit: false,
                    isDisable: true, // 是否只读
                    modelList: [{
                            line: [{
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "法人姓名",
                                    key: "corporateName",
                                    type: "input",
                                    value: "",
                                },
                                {
                                    horizontal: true,
                                    isShow: true,
                                    isDisable: false,
                                    title: "决策人",
                                    key: "decisionMaker",
                                    flag: "1", // 当前字段是否在其他字段中:0不在,1在
                                    type: "input",
                                    value: "",
                                },
                                {
                                    horizontal: false,
                                    isShow: true,
                                    isDisable: false,
                                    title: "电话号码",
                                    flag: "1", // 当前字段是否在其他字段中:0不在,1在
                                    type: "input",
                                    key: "phoneNumber",
                                    value: "",
                                },
                                {
                                    horizontal: true,
                                    isShow: true,
                                    isDisable: false,
                                    title: "代账情况",
                                    key: "accountOnBehalf",
                                    flag: "1", // 当前字段是否在其他字段中:0不在,1在
                                    type: "select",
                                    value: "",
                                    options: options.accountOnBehalfList ? options.accountOnBehalfList : [],
                                },
                                {
                                    horizontal: true,
                                    isShow: true,
                                    isDisable: false,
                                    title: "代账到期时间",
                                    key: "accountDueTime",
                                    flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                    type: "text",
                                    value: "",
                                },
                                {
                                    horizontal: true,
                                    isShow: true,
                                    isDisable: false,
                                    title: "商机状态",
                                    key: "status",
                                    flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                    type: "radio",
                                    value: "",
                                    options: [{
                                        label: "0",
                                        text: "跟进中",
                                    }, 
                                    {
                                        label: "1",
                                        text: "已成交",
                                    },
                                    {
                                        label: "2",
                                        text: "无效",
                                    },
                                ],
                                },
                            ],
                        },
                        {
                            line: [{
                                horizontal: false,
                                isShow: true,
                                isDisable: false,
                                title: "意向等级",
                                flag: "1", // 当前字段是否在其他字段中:0不在,1在
                                type: "select",
                                key: "intentionLevel",
                                value: "",
                                options: options.intentionLevelList ? options.intentionLevelList : [],
                            },]
                        },
                        {
                            line: [
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "共享商机类型",
                                key: "type",
                                flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                type: "select",
                                value: "",
                                options: options.threadShareTypeList ? options.threadShareTypeList : [],
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "经营情况",
                                key: "businessSituation",
                                flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                type: "input",
                                value: "",
                            }, {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "公司注册地址",
                                key: "registeredAddress",
                                flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                type: "input",
                                value: "",
                            }, {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "实际办公地址",
                                key: "officeAddress",
                                flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                type: "input",
                                value: "",
                            }, {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "年营收规模",
                                key: "annualRevenueScale",
                                flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                type: "input",
                                value: "",
                            },{
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "专职会计人数",
                                key: "quantityOfAccountant",
                                flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                type: "input",
                                value: "",
                            },{
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "园区返税",
                                key: "parkRebateFlag",
                                flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                type: "radio",
                                value: "",
                                options: [{
                                    label: "0",
                                    text: "是",
                                }, {
                                    label: "1",
                                    text: "否",
                                }, ],
                            },{
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "合规意识",
                                key: "complianceAwareness",
                                flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                type: "input",
                                value: "",
                            },{
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "资质数量",
                                key: "quantityOfQualification",
                                flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                type: "input",
                                value: "",
                            },{
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "目前代账费",
                                key: "escrowFee",
                                flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                type: "input",
                                value: "",
                            },{
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "关联代账客户名称",
                                key: "associatedAccountClientName",
                                flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                type: "input",
                                value: "",
                            },{
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "关联未代账名称",
                                key: "associateAnUnaccountedName",
                                flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                type: "input",
                                value: "",
                            },
                            {
                                horizontal: true,
                                isShow: true,
                                isDisable: false,
                                title: "客户问题及诉求说明",
                                key: "problemsAndDemands",
                                flag: "0", // 当前字段是否在其他字段中:0不在,1在
                                type: "textarea",
                                value: "",
                            },]
                        },
                    ],
                }
            }
            break;
        case "线索分配":
            threadDrawerData = {
                title: "线索详情",
                flag: false,
                hasSetting: true,
                status: "", // 当前商机状态
                settingOperList: [],
                buttonList: [],
                data: {
                    title: title,
                    titleEdit: false,
                    isDisable: true, // 是否只读
                    modelList: [{
                            line: [{
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "线索名称",
                                    key: "threadName",
                                    type: "text",
                                    value:'',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "联系人",
                                    key: "contactPerson",
                                    type: "text",
                                    value: '',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "电话",
                                    key: "phone",
                                    type: "text",
                                    value: "",
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "其他账号",
                                    key: "otherAccount",
                                    type: "text",
                                    value: "",
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "所属行业",
                                    key: "industry",
                                    type: "text",
                                    value: '',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "分配时间",
                                    key: "allocation",
                                    type: "text",
                                    value: "",
                                },
                               
                                {
                                    horizontal: true,
                                    isShow: true,
                                    isDisable: false,
                                    isSelect: false,
                                    tempValue: "",
                                    title: "分配人",
                                    key: "role",
                                    type: "tags-select-obj",
                                    value: [],
                                    options: options.allUser ? options.allUser.map((item) => {
                                        return {
                                          id: item.value,
                                          name: item.label,
                                        };
                                      }) : [],
                                }, 
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "备注",
                                    key: "remark",
                                    type: "text",
                                    value: "",
                                },
                            ],
                        },
                        {
                            line: [
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "线索意向",
                                    key: "status",
                                    type: "select",
                                    value:'',
                                    options: [
                                        {
                                            label: "新线索",
                                            value: '新线索'
                                        },
                                        {
                                            label: "有意向",
                                            value: '有意向'
                                        },
                                        {
                                            label: "无意向",
                                            value: '无意向'
                                        },
                                        {
                                            label: "已成交",
                                            value: '已成交'
                                        }
                                    ]
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "是否有效",
                                    key: "validOrNot",
                                    type: "select",
                                    value:'',
                                    options: [
                                        {
                                            label: "有效",
                                            value: '有效'
                                        },
                                        {
                                            label: "无效",
                                            value: '无效'
                                        },
                                    ]
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "客户需求",
                                    key: "demand",
                                    type: "select",
                                    value:'',
                                    options: options.demandList
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "意向等级",
                                    key: "intentionLevel",
                                    type: "select",
                                    value:'',
                                    options: options.intentionLevelList
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "成交人",
                                    key: "dealUserId",
                                    type: "select",
                                    value:'',
                                    options: options.allUser
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "成交产品",
                                    key: "dealProduct",
                                    type: "selects-multiple",
                                    value:'',
                                    options: options.dealProductList
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "成交金额",
                                    key: "dealAmount",
                                    type: "input",
                                    value:'',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "线索成本",
                                    key: "cost",
                                    type: "input",
                                    value:'',
                                },
                            ],
                        },
                        {
                            line: [{
                                horizontal: false, 
                                isShow: true,
                                isDisable: false,
                                title: "", // 项目记录
                                type: "projectRecord",
                                value: "", 
                                key: "itemRecord"
                            }],
                        }
                    ],
                },
            }
            break;
        default:
            break;
    }
    console.log('threadDrawerDatathreadDrawerData', threadDrawerData)
    return threadDrawerData
}

export function threadAllocationDrawerData(title, authorityData, isHead, options) {
    let threadDrawerData = getDrawerDataByThreadAllocation(title, isHead, options)

    // 拼接数据显示权限
    threadDrawerData = Vue.prototype.$initDrawerAuthorityData.initDrawerAuthorityData(threadDrawerData, authorityData, isHead)

    return threadDrawerData
}


export function returnDrawerKeys() {
    let keys = []
   
    let drawerData = getDrawerData("", false, {
        allUser: [], // 所有用户
        areaList: [], // 所有片区
        sourceList: [], // 客户所有来源
        addedValueTaxTypeList: [], // 增值税类型
    }).data.modelList
    for (let index = 0; index < drawerData.length; index++) {
        let element = drawerData[index].line;
        for (let i = 0; i < element.length; i++) {
            let ele = element[i];
            if (ele.key) {
                let dd = {
                    name: ele.text,
                    key: ele.key,
                    authority: "1",
                    sequence: 1,
                    id: ""
                }
                keys.push(JSON.parse(JSON.stringify(dd)))
            }
        }
    }
    return keys
}

export function returnDrawerKeysByThreadShare(model, flag) {
    let keys = []
    let drawerData = getDrawerDataByThreadShare("", Vue.prototype.$tagData.getDrawerOptionsList(model), flag, {}).data.modelList
  
    for (let index = 0; index < drawerData.length; index++) {
        let element = drawerData[index].line;
        for (let i = 0; i < element.length; i++) {
            let ele = element[i];
            if (ele.key) {
                let dd = {
                    name: ele.text,
                    key: ele.key,
                    authority: "1",
                    sequence: 1,
                    id: ""
                }
                keys.push(JSON.parse(JSON.stringify(dd)))
            }
        }

    }
    console.log('keys', keys)
    return keys
}

export function returnDrawerKeysByThreadAllocation() {
    let keys = []
    let drawerData = getDrawerDataByThreadAllocation("", false, {
        allUser: [], // 所有用户
        areaList: [], // 所有片区
        sourceList: [], // 客户所有来源
        addedValueTaxTypeList: [], // 增值税类型
    }).data.modelList
    for (let index = 0; index < drawerData.length; index++) {
        let element = drawerData[index].line;
        for (let i = 0; i < element.length; i++) {
            let ele = element[i];
            if (ele.key) {
                let dd = {
                    name: ele.text,
                    key: ele.key,
                    authority: "1",
                    sequence: 1,
                    id: ""
                }
                keys.push(JSON.parse(JSON.stringify(dd)))
            }
        }

    }
    console.log('keys', keys)
    return keys
}

/**
 * 
 * @param {*} arrayList 
 * @param {*} employeeList 
 * @param {*} info 
 * @returns 
 */
export function ThreadShareDialogData(arrayList, employeeList, info) {
    let data = [
        {
            title: ' 共享用户',
            key: 'shareUserIds',
            type: 'select-multiple',
            options: employeeList,
            value: []
        },
        {
            title: ' 法人姓名',
            key: 'corporateName',
            type: 'input',
            value: ''
        },
        {
            title: '状态',
            key: 'status', // '0' - 跟进中；'1' - 已成交
            type: 'radio',
            options: [
                { value: '0', text: '跟进中' },
                { value: '1', text: '已成交' }
            ],
            value: '0'
        },
        {
            title: '意向等级',
            key: 'intentionLevel',
            type: 'select',
            options: arrayList.intentionLevelList,
            value: ''
        },
        {
            title: '决策人',
            key: 'decisionMaker',
            type: 'input',
            value: info.decisionMaker ? info.decisionMaker : ''
        },
        {
            title: '电话号码',
            key: 'phoneNumber',
            type: 'input',
            value: info.phoneNumber ? info.phoneNumber : ''
        },
        {
            title: '经营情况',
            key: 'businessSituation',
            type: 'input',
            value: ''
        },
        {
            title: '共享商机类型',
            key: 'type',
            type: 'select',
            options: arrayList.threadShareTypeList,
            value: ''
        },
        {
            title: '代账情况',
            key: 'accountOnBehalf',
            type: 'select',
            options: arrayList.accountOnBehalfList,
            value: ''
        },
        // {
        //     title: '代账到期时间',
        //     key: 'accountDueTime',
        //     type: 'date',
        //     value: '',
        //     format: 'yyyy-MM',
        //     date_type: 'month'
        // },
        {
            title: '公司注册地址',
            key: 'registeredAddress',
            type: 'input',
            value: '',
        },
        {
            title: '实际办公地址',
            key: 'officeAddress',
            type: 'input',
            value: ''
        },
        {
            title: '年营收规模',
            key: 'annualRevenueScale',
            type: 'input',
            value: ''
        },
        {
            title: '专职会计人数',
            key: 'quantityOfAccountant',
            type: 'input',
            value: ''
        },
        {
            title: '园区返税',
            key: 'parkRebateFlag',
            type: 'radio',
            options: [
                { value: '0', text: '是' },
                { value: '1', text: '否' }
            ],
            value: '1'
        },
        {
            title: '合规意识',
            key: 'complianceAwareness',
            type: 'input',
            value: ''
        },
        {
            title: '支付能力',
            key: 'abilityToPay',
            type: 'input',
            value: ''
        },
        {
            title: '资质数量',
            key: 'quantityOfQualification',
            type: 'input',
            value: ''
        },
        {
            title: '目前代账费',
            key: 'escrowFee',
            type: 'input',
            value: ''
        },
        {
            title: '关联代账客户名称',
            key: 'associatedAccountClientName',
            type: 'input',
            value: ''
        },
        {
            title: '关联未代账名称',
            key: 'associateAnUnaccountedName',
            type: 'input',
            value: ''
        },
        {
            title: '客户存在问题机诉求说明',
            key: 'problemsAndDemands',
            type: 'textarea',
            value: ''
        },
    ]
    return data
}

/**
 * 获取界面筛选数据
 * 
 */
export function threadAllocationSearchData(options) {
    let data = [
        {
            title: "创建时间",
            type: "times",
            flag: "0",
            hasSearch: false,
            key: "createTime",
            value: "",
        },
        {
            title: "所属行业",
            type: "select",
            flag: "0",
            hasSearch: false,
            key: "industry",
            search_value: "",
            value: [
                // { text: "张三", is_active: false },
            ],
            options: options.industryList,
        },
        {
            title: "客户需求",
            type: "select",
            flag: "0",
            hasSearch: false,
            key: "demand",
            search_value: "",
            value: [
                // { text: "张三", is_active: false },
            ],
            options: options.demandList,
        },
        {
            title: "线索来源",
            type: "cascader",
            flag: "0",
            hasSearch: false,
            key: "source",
            search_value: "",
            value: [
                // { text: "张三", is_active: false },
            ],
            options: options.leadSourceList,
        },
        {
            title: "是否有效",
            type: "select",
            flag: "0",
            hasSearch: false,
            key: "validOrNot",
            search_value: "",
            value: [
                // { text: "张三", is_active: false },
            ],
            options: [
                { text: "有效", value: "有效" },
                { text: "无效", value: "无效" },
            ],
        },
        {
            title: "线索创建人",
            type: "select",
            flag: "0",
            hasSearch: false,
            key: "createUser",
            search_value: "",
            value: [
                // { text: "张三", is_active: false },
            ],
            options: options.allEmployeeList,
        },
        {
            title: "线索分配人",
            type: "select",
            flag: "0",
            hasSearch: false,
            key: "assignee",
            search_value: "",
            value: [
                // { text: "张三", is_active: false },
            ],
            options: options.allEmployeeList,
        },
        
    ]
    return data
  }