<template>
  <div id="myDispatch">
    <!-- 我的派工 -->
    <div class="myDispatch-screen">
      <div class="myDispatch-screen-line">
        <div class="myDispatch-screen-search">
          <el-input
            v-model="searchData.input"
            placeholder="请输入客户全称"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <!-- 不带权限 -->
        <div class="myDispatch-screen-search">
          <el-select
            placeholder="请选择执行人"
            v-model="searchData.userId"
            @change="initTableData"
            filterable
            clearable
          >
            <el-option
              v-for="item in searchData.allUserList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <!-- 带权限 -->
        <div class="myDispatch-screen-search">
          <el-select
            placeholder="请选择派工人"
            v-model="searchData.launcher"
            @change="selectInitTableData2"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="myDispatch-screen-search">
          <el-cascader
            v-model="searchData.location"
            placeholder="请选择办事地点"
            :options="list"
            collapse-tags
            :props="{
              multiple: true,
              value: 'id',
              label: 'name',
              emitPath: false,
            }"
            @change="initTableData"
            clearable
          ></el-cascader>
        </div>
        <div class="myDispatch-screen-search">
          <el-date-picker
            v-model="searchData.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
        <div class="myDispatch-screen-active"></div>
        <div
          class="myDispatch-screen-but"
          @click="handleClick('', 0)"
          v-showFunction="{
            active: 'customDispatch',
            array: allFunctionButtons,
          }"
        >
          自定义派工
        </div>
      </div>
    </div>

    <div class="table-content">
      <div class="myDispatch-table-card">
        <div class="myDispatch-table-card-tabs">
          <div
            :class="{
              'myDispatch-table-card-tab': true,
              'is-active': tabIndex == 0,
            }"
            @click="changeTab('0')"
          >
            全部派工
          </div>
          <div
            :class="{
              'myDispatch-table-card-tab': true,
              'is-active': tabIndex == 1,
            }"
            @click="changeTab('1')"
          >
            历史派工
          </div>
          <div
            :class="{
              'myDispatch-table-card-tab2': true,
              'is-active2': tabIndex == 3,
            }"
            @click="changeTab('3')"
          >
            今天
          </div>
          <div
            :class="{
              'myDispatch-table-card-tab2': true,
              'is-active2': tabIndex == 4,
            }"
            @click="changeTab('4')"
          >
            明天
          </div>
          <div
            :class="{
              'myDispatch-table-card-tab2': true,
              'is-active2': tabIndex == 5,
            }"
            @click="changeTab('5')"
          >
            后天
          </div>
          <div
            :class="{
              'myDispatch-table-card-tab2': true,
              'is-active2': tabIndex == 6,
            }"
            @click="changeTab('6')"
          >
            三天内
          </div>
          <div
            :class="{
              'myDispatch-table-card-tab2': true,
              'is-active2': tabIndex == 7,
            }"
            @click="changeTab('7')"
          >
            七天内
          </div>
          <div
            :class="{
              'myDispatch-table-card-tab2': true,
              'is-active2': tabIndex == 2,
            }"
            @click="changeTab('2')"
          >
            已过期
          </div>
        </div>
        <div class="myDispatch-content">
          <el-table
            :data="tableData"
            highlight-current-row
            :row-style="{ height: '60px' }"
            style="width: 100%"
            height="98%"
            v-loading="loading"
            @row-click="rowClick"
          >
            <el-table-column type="index" width="40" fixed> </el-table-column>
            <el-table-column
              prop="clientName"
              label="公司名称"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="clientNum" label="客户编号">
            </el-table-column>
            <el-table-column prop="name" label="派工内容">
              <template slot-scope="scope">
                <i v-if="scope.row.isUrgent" class="iconfont icon-jiaji"></i>
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column prop="launchTime" label="派工时间">
            </el-table-column>
            <el-table-column prop="" label="待办时间" width="200">
              <template slot-scope="scope">
                {{ scope.row.expectTime + " - " + scope.row.endTime }}
              </template>
            </el-table-column>
            <el-table-column prop="locationName" label="办事地点">
              <template slot-scope="scope">
                <span
                  @click.stop="handleClick(scope.row, 2)"
                  style="color: #40a3ff; cursor: pointer;"
                  >{{ scope.row.locationName }}</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="launchName" label="派工人">
            </el-table-column>
            <el-table-column prop="executorName" label="执行人">
              <template slot-scope="scope">
                {{ scope.row.executorName.join(", ") }}
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="mini"
                  plain
                  v-if="tabIndex !== '1' && tabIndex !== '2'"
                  v-showFunction="{
                    active: 'cancel',
                    array: allFunctionButtons,
                  }"
                  @click.stop="handleClick(scope.row, 1)"
                  >撤销</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="myDispatch-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @buttonOper="drawerOper"
        @addFollowRecord="followRecord"
        @handleAvatarSuccess="handleAvatarSuccess"
      ></my_dialog>
    </div>

    <!-- 右边抽屉 -->
    <div id="my-drawer" v-if="contractDrawerData.flag">
      <my_drawer
        :drawerData="contractDrawerData"
        :allFunctionButtons="allFunctionButtons2"
        :model="'contractWork'"
        @show="showDrawer"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @editStep="editStep"
        @addStep="addStep"
        @doneStep="doneStep"
        @deleteStep="deleteStep"
        @detailStep="detailStep"
        @workOrder="workOrder"
        @saveDrawerData="updateWorkContractByID"
        @updateDrawerTitle="updateDrawerTitle"
        @drawerOper="drawerOper"
        @addTags="addTags"
        @tagsHandleClose="tagsHandleClose"
        @linkNow="linkNow"
        @addProjectRecord="addProjectRecord"
      ></my_drawer>
    </div>
  </div>
</template>

<script>
import my_dialog from "@/components/dialog.vue";
import my_drawer from "@/components/my-drawer.vue";
import { waitDealLocation } from "@/minixs.js";
import * as contractDrawData from "@/assets/js/contractDraw.js";
import * as allContractData from "@/views/contract/js/contractData.js";
export default {
  components: {
    my_dialog,
    my_drawer,
  },
  data() {
    return {
      loading: false,
      baseInfo: {},
      tableData: [],
      searchData: {
        input: "",
        location: [],
        time: [],
        status: 0,
        tabFlag: null,
        userList: [],
        userIdList: [],
        executorList: [],
        userId: "",
        launcher: "",
        launchList: [],
        launchIdList: [],
        clientList: [],
        allUserIdList: [],
        allUserList: [],
      },
      defaultSort: { prop: "", order: "" },
      type: "all",
      allFunctionButtons: this.$featureData.getAllFunctionButtons("myDispatch"),
      allFunctionButtons2: this.$featureData.getAllFunctionButtons(
        "contractWork"
      ),
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      whetherAgencyHead: false,
      tabIndex: 0,
      contractDrawerData: contractDrawData.contractWorkDrawerData("", [], []),
      contractDrawerOptions: this.$tagData.getDrawerOptionsList("contractWork"), // 抽屉中的下拉框配置项
      tempContract: { id: "", name: "" }, // 临时合同
      ttempClient: {},
      allEmployeeList: [],
      list: [],
      tempDispatch: {},
    };
  },
  mixins: [waitDealLocation],
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "myDispatch",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeListForDownBox();
        this.getClientList();
        this.getEmployeeList();
      });
    },
    /**
     * 获取客户名称列表
     */
    getClientList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/client/getAllOptions",
        requestData,
        (data) => {
          this.clientList = data;
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.launcher = this.baseInfo.userId;
          this.selectInitTableData2(this.baseInfo.userId);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有人员列表
     */
    getEmployeeListForDownBox() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.allEmployeeList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.allEmployeeList.push(dd);
            this.searchData.allUserIdList.push(element.userId);
            this.searchData.allUserList.push(dd);
          });
          // this.searchData.allUserList.splice(0, 0, {
          //   value: "all",
          //   label: "全部人员",
          // });
        }
      );
    },

    /**
     * 初始化数据
     */
    initTableData() {
      let requestData = {
        clientNameAndNum: this.searchData.input, // 客户名称和客户编号
        type: this.tabIndex, // 0-未完成 1-已完成
        userId: this.baseInfo.userId,
        executor: this.searchData.userId, // 执行人
        launchIdList: this.searchData.launchList, // 派工人
        locationIds: this.searchData.location, // 办事地点
        time: this.searchData.time, // 待办时间
        status: this.searchData.status, // 状态
        tabFlag: this.searchData.tabFlag, // tab栏查询标识
        companyId: this.baseInfo.company.companyId, // 公司id
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request(
        "post",
        "/waitDeal/pageToMyDispatch",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.pageData.total = data.total;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    handleClick(data, flag) {
      let requestData = {};
      switch (flag) {
        case 0:
          this.dialogData.width = "600px";
          this.dialogData.title = "自定义派工";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "sure_dispatch",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          requestData = {};
          this.dialogData.data = [
            {
              title: "公司名称",
              type: "select",
              value: "",
              options: this.clientList,
            },
            {
              title: "派工内容",
              type: "input",
              value: "",
            },
            {
              title: "办事地点",
              type: "cascader",
              locationId: "",
              placeholder: "请选择办事地点",
              options: this.list,
              props: {
                multiple: false,
                value: "id",
                label: "name",
                emitPath: false,
              },
              collapse_tags: true,
            },
            {
              title: "执行人",
              type: "select-object",
              value: "",
              options: this.allEmployeeList,
            },
            {
              title: "待办时间",
              type: "times",
              value: [],
              pickerOptions: [],
              value_format: "yyyy-MM-dd",
            },
            {
              title: "加急",
              type: "radio",
              value: 0,
              options: [
                {
                  label: "是",
                  value: 1,
                },
                {
                  label: "否",
                  value: 0,
                },
              ],
            },
            {
              title: "备注",
              type: "textarea",
              value: "",
            },
          ];
          this.dialogData.flag = true;
          break;
        case 1:
          let ids = [];
          ids.push(data.id);
          requestData = {
            ids, // 派工id
          };
          this.$confirm("确定要撤销该派工吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/waitDeal/delBatch",
                requestData,
                (data) => {
                  this.$message.success("撤销成功!");
                  this.initTableData();
                },
                (errormessage) => {}
              );
            })
            .catch(() => {});
          break;
        case 2:
          this.dialogData.title = "地址详情";
          this.dialogData.buttonArray = [];
          this.dialogData.data = [
            {
              title: "地址详情",
              type: "textarea_black",
              value: data.locationDetail,
              rows: 5,
              disabled: true,
            },
          ];
          this.dialogData.flag = true;
        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "sure_dispatch":
          // 添加自定义派工
          let executor = [];
          this.dialogData.data[3].value.forEach((item) => {
            executor.push(item.value);
          });
          requestData = {
            companyId: this.baseInfo.company.companyId,
            launch: this.baseInfo.userId, // 发起人 id
            clientId: this.dialogData.data[0].value, // 客户id
            name: this.dialogData.data[1].value, // 派工内容
            executor: JSON.stringify(executor), // 执行人 id 集合
            operation: this.baseInfo.userId, // 操作人，当前登录用户 id
            type: "9", // 待办类型，目前业务传 "9" 即可
            locationId: this.dialogData.data[2].locationId, // 办事地点
            expectAndEndTime: this.dialogData.data[4].value, // 待办时间
            isUrgent: this.dialogData.data[5].value, // 是否加急 0 - 不加急 1 - 加急
            remark: this.dialogData.data[6].value, // 备注
          };
          if (!requestData.name) {
            this.$message.error("派工内容必填!");
            return;
          }
          if (requestData.expectAndEndTime.length == 0) {
            this.$message.error("待办时间必填!");
            return;
          }
          this.$request(
            "post",
            "/waitDeal/add",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                duration: 1000,
                type: "success",
              });
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "提交自定义派工!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.dialogData.flag = false;
                  this.initTableData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_dispatch2":
          // 添加合同派工
          let executor2 = [];
          this.dialogData.data[3].value.forEach((item) => {
            executor2.push(item.value);
          });
          requestData = {
            companyId: this.baseInfo.company.companyId,
            launch: this.baseInfo.userId, // 发起人 id
            clientId: this.dialogData.data[0].value, // 客户id
            name: this.dialogData.data[1].value, // 派工内容
            executor: JSON.stringify(executor2), // 执行人 id 集合
            operation: this.baseInfo.userId, // 操作人，当前登录用户 id
            type: "9", // 待办类型，目前业务传 "9" 即可
            locationId: this.dialogData.data[2].locationId, // 办事地点
            expectAndEndTime: this.dialogData.data[4].value, // 待办时间
            isUrgent: this.dialogData.data[5].value, // 是否加急 0 - 不加急 1 - 加急
            remark: this.dialogData.data[6].value, // 备注
            contractId: this.tempDispatch.contractId, // 关联的合同 id
          };
          if (!requestData.name) {
            this.$message.error("派工内容必填!");
            return;
          }
          if (requestData.expectAndEndTime.length == 0) {
            this.$message.error("待办时间必填!");
            return;
          }
          this.$request(
            "post",
            "/waitDeal/add",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                duration: 1000,
                type: "success",
              });
              let requestData2 = {
                contractId: this.tempDispatch.contractId, // 合同 id
                index: this.tempStep.index, // 第几个步骤
                status: "dispatch", // 修改成什么状态：yes、no、done、dispatch
              };
              this.$request(
                "post",
                "/contract/updateStepStatus",
                requestData2,
                (data) => {
                  this.$operationRecords.saveOperationRecords(
                    this.tempContract.id,
                    "提交合同派工!",
                    this.baseInfo,
                    "contract",
                    this,
                    (data2) => {
                      this.dialogData.flag = false;
                      this.initTableData();
                      this.showContractInfoByID();
                    }
                  );
                },
                (messageerror) => {
                  this.$message.error(errorMessage);
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "work_submit":
          // 完成步骤
          requestData = {
            contractId: this.tempContract.id, // 合同ID
            info: {
              stepId: this.tempStep.data.id, // 步骤id
              stepName: this.dialogData.data[0].value, // 步骤名称
              time: this.dialogData.data[1].value, // 完成时间
              annex: this.dialogData.data[2].value.value, // 附件 url
              push: this.dialogData.data[3].value, // 是否推送，1-微信公众号推送 2- 发送短信 0-不推送
              remark: this.dialogData.data[4].value, // 备注
              operatorId: this.baseInfo.userId, // 操作人 id
            },
          };
          if (this.whetherAgencyHead) {
            // 机构负责人 跳过校验
          } else {
            let flag = this.tempStep.data.users.some((item) => {
              if (item.value == this.baseInfo.userId) {
                return true;
              }
            });
            if (!flag) {
              this.$message.error("只有当前执行人可以完成此次派工步骤!");
              return;
            }
          }
          this.$request(
            "post",
            "/contract/executeStep",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                duration: 1000,
                type: "success",
              });
              // this.contractDrawerData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "提交派工!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.dialogData.flag = false;
                  this.showContractInfoByID();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "add_step":
          // 确认插入步骤
          console.log("确认插入步骤", this.dialogData.data);
          requestData = {
            contractId: this.tempContract.id, // 合同 id
            step: {
              des: this.dialogData.data[1].value, // 步骤说明
              role: {},
              money: "",
              name: this.dialogData.data[0].value, // 步骤名称
              active: "yes", // 是否已完成
              users: this.dialogData.data[2].value, // 执行人
            },
            index: this.tempStep.index, // 该步骤的索引
          };
          this.$request(
            "post",
            "/contract/addStep",
            requestData,
            (data) => {
              this.$message.success("插入成功!");
              this.dialogData.flag = false;
              this.showContractInfoByID();
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "插入步骤!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {}
              );
            },
            (err) => {
              this.$message.error(err);
            }
          );
          this.dialogData.flag = false;
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    /**
     * 表格单行点击事件
     */
    rowClick(row, column, event) {
      this.tempDispatch = row;
      if (row.contractId) {
        if (!this.contractDrawerData.flag) {
          //防止重复点击
          this.tempContract.id = row.contractId;
          this.tempContract.name = row.clientName;
          this.ttempClient = row;
          this.tempClientName = row.clientName;
          this.showContractInfoByID();
          this.contractDrawerData.flag = true;
          console.log("打开抽屉:", this.contractDrawerData);
        } else {
          this.closeUpdateTableDrawer();
        }
      }
    },
    showContractInfoByID() {
      // 1.拿到合同的按钮权限
      this.getModelFeatureAuthority();
      setTimeout(() => {
        this.$featureData.getAttributeData(
          "contractWork",
          false,
          allContractData.returnDrawerKeys("合同派工"),
          (authorityData) => {
            console.log("4444", authorityData);
            this.contractDrawerAuthorityData = authorityData;
            // 2.拿对应属性的权限
            this.$authority.getModelFeatureAuthority(
              this,
              "contractWork",
              authorityData,
              (authorityList) => {
                this.contractDrawerDataAuthorityList = authorityList;
                // this.showContractInfoByID();
                let requestData = {
                  contractId: this.tempContract.id,
                  userId: this.baseInfo.userId,
                  companyId: this.baseInfo.company.companyId,
                  authorityData: this.$initDrawerAuthorityData.getKeysByAuthorityList(
                    this.contractDrawerAuthorityData
                  ),
                };
                this.$request(
                  "post",
                  "/contract/getWorkContractByID",
                  requestData,
                  (data) => {
                    data.data.itemRecord = {
                      title: "项目记录",
                      list: data.data.itemRecord,
                    };
                    // 3.将权限数据对应到界面
                    let butText = {};
                    this.contractDrawerData = allContractData.contractInfoDrawerData(
                      this.tempClientName,
                      this.contractDrawerDataAuthorityList,
                      this.whetherAgencyHead, // 是否是机构负责人
                      this.contractDrawerOptions,
                      "合同派工",
                      butText
                    );
                    // this.tempSign = data.data.sign;
                    this.contractDrawerData = this.$initDrawerAuthorityData.initDrawerData(
                      this.contractDrawerData,
                      data.data
                    );
                    this.contractDrawerData.status = data.submitFlag
                      ? "可提交"
                      : "不可提交";
                    allContractData.initData(
                      data,
                      this.contractDrawerData,
                      this.allFunctionButtons,
                      this.whetherAgencyHead
                    );
                    console.log("打开抽屉:", this.contractDrawerData);

                    this.contractDrawerData.flag = true;
                  },
                  (errorMessage) => {
                    this.$message.error(errorMessage);
                  }
                );
                // this.contractDrawerData.flag = true;
              }
            );
          }
        );
      }, 0);
    },
    /**
     * 编辑步骤
     */
    editStep(data) {
      if (
        this.contractDrawerData.data.modelList[1].line[0].isShow &&
        !this.contractDrawerData.data.modelList[1].line[0].isDisable
      ) {
        this.tempStep = JSON.parse(JSON.stringify(data));
        this.updateWorkContractByID("");
      } else {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
      }
    },
    /**
     * 步骤插入
     */
    addStep(data) {
      if (
        this.contractDrawerData.data.modelList[1].line[0].isShow &&
        !this.contractDrawerData.data.modelList[1].line[0].isDisable
      ) {
        console.log("插入步骤", data);
        this.tempStep = JSON.parse(JSON.stringify(data));
        this.dialogData.title = "插入步骤";
        this.dialogData.buttonArray = [
          {
            text: "确认",
            active: "add_step",
          },
          {
            text: "取消",
            active: "cancel",
          },
        ];
        this.dialogData.data = [
          {
            title: "步骤名称",
            type: "input",
            placeholder: "请输入步骤名称",
            value: "",
          },
          {
            title: "步骤描述",
            type: "input",
            placeholder: "请输入步骤描述",
            value: "",
          },
          // {
          //   title: "执行角色",
          //   type: "select-search-url",
          //   placeholder: "请选择执行角色",
          //   value: this.tempStep.data.role,
          //   options: this.roleList,
          // },
          {
            title: "执行员工",
            type: "select-object",
            placeholder: "请选择执行员工",
            value: "",
            options: this.allEmployeeList,
          },
        ];

        this.dialogData.flag = true;
      } else {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
      }
    },
    /**
     * 步骤完成
     */
    doneStep(data) {
      if (
        this.contractDrawerData.data.modelList[1].line[0].isShow &&
        !this.contractDrawerData.data.modelList[1].line[0].isDisable
      ) {
        this.tempStep = JSON.parse(JSON.stringify(data));
        console.log("111", this.tempStep);
        this.dialogData.title = "项目步骤说明";
        this.dialogData.buttonArray = [
          {
            text: "确认",
            active: "work_submit",
          },
          {
            text: "取消",
            active: "cancel",
          },
        ];
        this.dialogData.data = [
          {
            title: "步骤名称",
            type: "text",
            value: this.tempStep.data.name,
          },
          {
            title: "完成时间",
            type: "text",
            value: this.$formatTime(new Date(), "YYYY-MM-DD HH:mm:ss"),
          },
          {
            title: "上传附件",
            type: "upload",
            value: {
              value: "",
              action: this.$fileBaseurl + "/uploadImageByMultipartFile",
              headers: {
                token: "95931b371eb64f4a9c862983ca8a5d15",
              },
              uploadParameter: {
                systemName: "finance",
                title: "合同派工附件",
              },
            },
          },
          {
            title: "推送给客户",
            type: "radio",
            value: "",
            options: [
              {
                value: 1,
                label: "微信小程序推送",
              },
              {
                value: 2,
                label: "发送短信消息",
              },
            ],
          },

          {
            title: "完成备注",
            type: "textarea",
            value: "",
          },
        ];

        this.dialogData.flag = true;
      } else {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
      }
    },
    /**
     * 步骤删除
     */
    deleteStep(data) {
      console.log("deleteStep", data);
      let requestData = {
        contractId: this.tempContract.id, // 合同 id
        index: data.index, // 要删除的步骤的索引
      };
      if (
        this.contractDrawerData.data.modelList[1].line[0].isShow &&
        !this.contractDrawerData.data.modelList[1].line[0].isDisable
      ) {
        console.log(
          "this.contractDrawerData.data",
          this.contractDrawerData.data
        );
        this.$confirm("确认要删除该步骤吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$request(
              "post",
              "/contract/delStep",
              requestData,
              (data) => {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });

                this.$operationRecords.saveOperationRecords(
                  this.tempContract.id,
                  "删除步骤!",
                  this.baseInfo,
                  "contract",
                  this,
                  (data2) => {
                    this.showContractInfoByID();
                  }
                );
              },
              (errormessage) => {
                this.$message.error(errormessage);
              }
            );
          })
          .catch(() => {});
      } else {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
      }
    },
    /**
     * 步骤详情
     */
    detailStep(data) {
      this.tempStep = JSON.parse(JSON.stringify(data));
      let requestData = {
        contractId: this.tempContract.id, // 合同id
        stepId: this.tempStep.data.id, // 第几个步骤
      };
      this.$request(
        "post",
        "/contract/getStepDoneInfo",
        requestData,
        (data) => {
          this.dialogData.width = "500px";
          this.dialogData.title = "项目步骤说明";
          this.dialogData.data = [
            {
              title: "步骤名称",
              type: "text",
              value: data.stepName,
            },
            {
              title: "完成人",
              type: "text",
              value: data.operatorName,
            },
            {
              title: "完成时间",
              type: "text",
              value: data.time,
            },
            {
              title: "附件",
              type: "show_img",
              value: data.annex,
            },
            {
              title: "推送给客户",
              type: "text",
              value:
                data.push == 0
                  ? "未推送"
                  : data.push == 1
                  ? "推送到微信公众号"
                  : "发送到短信",
            },
            {
              title: "完成备注",
              type: "text",
              value: data.remark,
            },
          ];
          this.dialogData.buttonArray = [];
          this.dialogData.flag = true;
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    /**
     * 派工
     */
    workOrder(data) {
      if (
        this.contractDrawerData.data.modelList[1].line[0].isShow &&
        !this.contractDrawerData.data.modelList[1].line[0].isDisable
      ) {
        this.tempStep = JSON.parse(JSON.stringify(data));

        this.dialogData.title = "派工";
        this.dialogData.buttonArray = [
          {
            text: "确认",
            active: "sure_dispatch2",
          },
          {
            text: "取消",
            active: "cancel",
          },
        ];
        this.dialogData.data = [
          {
            title: "公司名称",
            type: "select",
            value: this.ttempClient.clientId,
            options: this.clientList,
          },
          {
            title: "派工内容",
            type: "input",
            value: "",
          },

          {
            title: "办事地点",
            type: "cascader",
            locationId: "",
            placeholder: "请选择办事地点",
            options: this.list,
            props: {
              multiple: false,
              value: "id",
              label: "name",
              emitPath: false,
            },
            collapse_tags: true,
          },
          {
            title: "执行人",
            type: "select-object",
            value: "",
            options: this.allEmployeeList,
          },
          {
            title: "待办时间",
            type: "times",
            value: [],
            pickerOptions: [],
            value_format: "yyyy-MM-dd",
          },
          {
            title: "加急",
            type: "radio",
            value: 0,
            options: [
              {
                label: "是",
                value: 1,
              },
              {
                label: "否",
                value: 0,
              },
            ],
          },
          {
            title: "备注",
            type: "textarea",
            value: "",
          },
        ];

        this.dialogData.flag = true;
      } else {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
      }
    },
    addProjectRecord(textarea) {
      let requestData = {
        contractId: this.tempContract.id,
        clientId: this.ttempClient.clientId,
        userId: this.baseInfo.userId,
        content: textarea,
      };
      if (requestData.content == "") {
        return this.$message.error("请输入项目记录内容");
      }
      this.$request(
        "post",
        "/contractProjectRecords/add",
        requestData,
        (data) => {
          this.$operationRecords.saveOperationRecords(
            this.tempContract.id,
            "添加项目记录!",
            this.baseInfo,
            "contract",
            this,
            (data2) => {
              this.$message.success("项目记录添加成功!");
              // this.initTableData();
              this.showContractInfoByID();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 右边抽屉父子组件连锁事件
     */
    showDrawer(value) {
      this.contractDrawerData = value;
    },
    /**
     * tab切换事件
     */
    changeTab(index) {
      this.tabIndex = index;
      if (index == 0 || index == 1) {
        this.searchData.tabFlag = null;
        this.searchData.status = index;
      } else {
        this.searchData.status = 0;
        index == 2
          ? // 过期
            (this.searchData.tabFlag = -1)
          : index == 3
          ? // 今天
            (this.searchData.tabFlag = 0)
          : index == 4
          ? // 明天
            (this.searchData.tabFlag = 1)
          : index == 5
          ? // 后天
            (this.searchData.tabFlag = 2)
          : index == 6
          ? // 三天内
            (this.searchData.tabFlag = 3)
          : // 七天内
            (this.searchData.tabFlag = 7);
      }
      this.initTableData();
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.initTableData();
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    // /**
    //  * 员工选择
    //  */
    // selectInitTableData(val) {
    //   if (val == "all") {
    //     this.searchData.executorList = this.searchData.userIdList;
    //   } else {
    //     this.searchData.executorList = [val];
    //   }
    //   if (this.searchData.launchList.length == 0) {
    //     return;
    //   } else {
    //     this.initTableData();
    //   }
    // },
    /**
     * 员工选择
     */

    selectInitTableData2(val) {
      if (val == "all") {
        this.searchData.launchList = this.searchData.userIdList;
      } else {
        this.searchData.launchList = [val];
      }
      this.initTableData();
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.contractDrawerData.flag = false;
      // this.initTableData();
      // this.updateWorkContractByID();
    },
    /**
     * 确认编辑合同派工内容
     */
    updateWorkContractByID(drawerTitle) {
      let requestData = {};
      if (this.contractDrawerData.title == "合同派工") {
        requestData = {
          contractId: this.tempContract.id,
          data: contractDrawData.formatContractWorkDrawerData(
            this.contractDrawerData.data.modelList
          ),
        };
        console.log("requestData.data", requestData.data);
        requestData.data[8].key = "projectStep";
        this.$request(
          "post",
          "/contract/updateWorkContractByID",
          requestData,
          (data) => {
            this.$message({
              message: "操作成功!",
              duration: 1000,
              type: "success",
            });
            this.$operationRecords.saveOperationRecords(
              this.tempContract.id,
              "派工基本信息修改!",
              this.baseInfo,
              "contract",
              this,
              (data2) => {
                this.showContractInfoByID();
                this.initTableData();
              }
            );
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }
      if (this.contractDrawerData.title == "客户") {
        requestData = {
          clientId: this.tempClient.value,
          companyId: this.baseInfo.company.companyId,
          data: [],
        };
        switch (this.contractDrawerData.cardIndex) {
          case 0:
            // 更新客户信息
            requestData.data = clientInfoData.formatClientInfoDrawerData(
              this.contractDrawerData.data.modelList
            );
            this.$request(
              "post",
              "/client/updateClient",
              requestData,
              (data) => {
                // this.saveOperationRecords(
                //   "客户【" + this.tempClientName + "】信息修改!",
                //   true
                // );
                this.$message({
                  message: "修改成功!",
                  type: "success",
                  duration: 1000,
                });
                this.$operationRecords.saveOperationRecords(
                  this.tempClient.value,
                  "修改" + drawerTitle + "信息!",
                  this.baseInfo,
                  "client",
                  this,
                  (data2) => {
                    this.initTableData();
                  }
                );
                // this.initTableData();
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
            break;
          case 1:
            // 更新客户联系人
            this.updateClientContactByID();
            break;

          default:
            break;
        }
      }
    },
    /**
     * 抽屉中tag移除事件
     * data(tag,i,j):tag->移除的tag,i为第几个model,j为第几line
     */
    tagsHandleClose(data) {
      this.contractDrawerData.data.modelList[data.i].line[data.j].value.splice(
        this.contractDrawerData.data.modelList[data.i].line[
          data.j
        ].value.indexOf(data.tag),
        1
      );
      this.updateWorkContractByID("角色信息");
    },
    addTags(data) {
      if (this.contractDrawerData.title == "合同") {
        this.tempTags = this.contractDrawerData.data.modelList[data.i].line[
          data.j
        ].value;
        let v = "2";
        this.tempTags.push(v);
        this.updateWorkContractByID("合同信息");
      }
      if (this.contractDrawerData.title == "客户") {
        if (
          this.contractDrawerData.data.modelList[data.i].line[data.j].isSelect
        ) {
          let value = this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].tempValue;
          if (
            value &&
            this.contractDrawerData.data.modelList[data.i].line[
              data.j
            ].value.indexOf(value) < 0
          ) {
            // 排除重复选项
            this.contractDrawerData.data.modelList[data.i].line[
              data.j
            ].value.push(value);
            this.updateWorkContractByID("角色信息");
          }
          this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].isSelect = false;
          this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].tempValue = "";
        } else {
          this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].isSelect = true;
        }
      }
    },
    linkNow(dd) {
      console.log("llllll", dd);
      this.tempContract.id = dd.item.id;
      this.showContractDetailsById();
    },
    /**
     * 确定添加跟进记录
     */
    followRecord(recordData) {
      console.log("跟进记录:", recordData);
      let requestData = {
        clientId: this.ttempClient.clientId, // 商机ID
        threadId: "", // 商机ID
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId, // 操作人ID
        content: recordData.content, // 当前操作
        time: recordData.time, // 下次跟进时间
        stage: "",
      };
      if (requestData.content == "") {
        this.$message.error("跟进内容不能为空!");
        return;
      }
      this.$request(
        "post",
        "/followRecord/addFollowRecord",
        requestData,
        (data) => {
          this.$message({
            message: "跟进记录添加成功!",
            type: "success",
            duration: 1000,
          });
          this.$operationRecords.saveOperationRecords(
            this.ttempClient.clientId,
            "添加跟进记录:" + recordData.content + "!",
            this.baseInfo,
            "client",
            this,
            (data2) => {
              this.showGenjin(this.ttempClient);
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 附件上传
     */
    handleAvatarSuccess(data) {
      this.dialogData.data[2].value.value = data.result.path;
    },
    /**
     * 修改客户抽屉公司名称
     */
    updateDrawerTitle(title) {
      console.log("contractDrawerData", this.contractDrawerData);
      let requestData = {
        clientId: this.ttempClient.clientId, // 客户id
        companyId: this.baseInfo.company.companyId, // 公司 id
        clientName: this.contractDrawerData.data.title, // 修改后的客户名称
      };
      this.$request(
        "post",
        "/client/updateClientNameForContract",
        requestData,
        (data) => {
          this.$message.success("修改成功!");
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
    this.isCharge();
    this.waitDealLocation();
  },
};
</script>

<style lang="less" scoped>
#myDispatch {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .myDispatch-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .myDispatch-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .myDispatch-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .myDispatch-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .myDispatch-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .myDispatch-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        &:hover {
          background-color: #abbed3;
        }
      }

      .myDispatch-screen-but {
        width: 140px;
        min-width: 140px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;

        &:hover {
          cursor: pointer;
          background-color: #2c7db3;
        }
      }
      .myDispatch-screen-but-boder {
        width: 50px;
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .myDispatch-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .myDispatch-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .myDispatch-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .myDispatch-table-card-tab {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .myDispatch-table-card-tab2 {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }

        .myDispatch-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .myDispatch-table-card-tab2:hover {
          cursor: pointer;
          color: #67c23a;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .is-active2 {
          color: #67c23a;
          border-bottom: 2px solid #67c23a;
        }
        .myDispatch-table-card-active {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: @contentFontSize;
          .textArea {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: @activeColor;
            cursor: pointer;
            &:hover {
              text-decoration: underline; // 下划线
            }
          }
          .numArea {
            height: 100%;
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: @activeColor;
          }
        }
        .myDispatch-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .myDispatch-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
      }
      .myDispatch-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
