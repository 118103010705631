<template>
  <div id="wechat-client" v-if="displayInterface">
    <!-- 企业微信中的企业信息 -->
    <div class="wechat-client-title">
      <div class="wechat-client-title-content">
        <div class="wechat-client-title-content-line">
          {{ client.name }}
          <i class="el-icon-notebook-2 icon"></i>
        </div>
        <div
          class="wechat-client-title-content-line"
          style="font-size: 12px; color: #8f9396"
        >
          {{ client.clientNum }}
        </div>
      </div>
      <div class="wechat-client-title-phone">
        <i class="el-icon-phone"></i>
      </div>
    </div>

    <div class="wechat-client-tab">
      <el-tabs v-model="activeName" @tab-click="handleClick" stretch>
        <el-tab-pane label="首页" name="index">
          <wechatIndex
            ref="wechatIndexRef"
            :client="client"
            :baseInfo="baseInfo"
          ></wechatIndex>
        </el-tab-pane>
        <el-tab-pane label="合同" name="contract">
          <wechatContract ref="wechatContractRef"></wechatContract>
        </el-tab-pane>
        <el-tab-pane label="云盘" name="cloud">云盘</el-tab-pane>
        <el-tab-pane label="发票" name="invoice">
          发票
          <!-- <div class="wechat-client-content-invoice" v-if="invoice.flag">
            <InvoiceIncomeDdialog
              :wechatInvoiceIncomeData="invoice"
              @makeOutAnInvoice="makeOutAnInvoice"
              @show="showWechatInvoiceIncomeData"
              @cancellationMakeOutAnInvoice="cancellationMakeOutAnInvoice"
            ></InvoiceIncomeDdialog>
          </div> -->
          <!-- <div class="wechat-client-content-invoice" v-else>
            <InvoiceInfo
              :invoiceInfoData="invoiceInfoData"
              @copy="testCopy"
              @makeOutAnInvoice="makeOutAnInvoiceOpen"
            ></InvoiceInfo>
          
          </div> -->
        </el-tab-pane>
        <el-tab-pane label="社保" name="social">社保</el-tab-pane>
        <el-tab-pane label="派工" name="dispatch">派工</el-tab-pane>
        <el-tab-pane label="知识" name="knowledge">知识</el-tab-pane>
        <el-tab-pane label="待办" name="backlog">
          待办
          <!-- <WechatWaite :wechatWaiteData="waite"></WechatWaite> -->
        </el-tab-pane>
      </el-tabs>
    </div>
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @choseProductTipOne="choseProductTipOne"
      ></my_dialog>
    </div>
  </div>
  <div id="wechat-client" class="backgr" v-else>
    <img
      :src="require('../assets/img/company_logo.png')"
      alt=""
      style="width: 140px; height: 40px"
    />
  </div>
</template>

<script>
import wechatIndex from "./wechat-components/wechat-index.vue";
import wechatContract from "./wechat-components/wechat-contract.vue";
import InvoiceIncomeDdialog from "../components/wechat-invoice-income.vue";
import InvoiceInfo from "../components/invoice-info.vue";
import WechatContract from "../components/wechat-contract.vue";
import WechatAddContract from "../components/wechat-add-contract.vue";
import WechatWaite from "../components/wechat-waite.vue";
import my_dialog from "../components/dialog.vue";
export default {
  components: {
    InvoiceIncomeDdialog,
    WechatContract,
    WechatAddContract,
    my_dialog,
    WechatWaite,
    InvoiceInfo,
    wechatIndex,
    wechatContract,
  },
  data() {
    return {
      displayInterface: true,
      userId: "", // 当前外部联系人的userId
      chatId: "", // 当前外部联系人的userId
      baseInfo: {
        userId: "",
        // userId: "fc2477a8b74c4184a9270a22cf40b0f0",
        company: { companyId: "" },
        wechatId: "",
      },
      activeName: "index",
      client: {
        id: "",
        // id: "0aecb0c585504c84a700b2156753c74c",
        name: "",
        clientNum: "",
        index: {},
        client: {},
      },
      tempClient: {},
      invoice: {
        flag: false, // 是否开票
        id: "", // id为空时,即为新增发票
        clientId: "", // 客户id
        companyId: "", // 公司id
        title: "安徽增值税电子普通发票", // 发票标题
        type: "0", // 发票类型
        allMoney: {
          // 价税合计
          num: "0.00", // 小写
          text: "零元整", // 大写
        },
        buyerName: "", // 购买方名称
        buyerTaxpayerNum: "", // 购买方纳税人识别号
        buyerAddress: "", // 购买方地址
        buyerTel: "", // 购买方电话
        buyerBankName: "", // 购买方开户行
        buyerBankAccount: "", // 购买方银行账号
        takerTel: "", // 收票人手机
        takerEmail: "", // 收票人邮箱
        itemName: "", // 主开票项目名称 可默认为开票项目列表的第一个项目名称
        casherName: {}, // 收款人名称
        reviewerName: {}, //复核人名称
        drawerName: {}, //开票人名称
        includeTaxFlag: true, // 含税标示  false：不含税，true：含税，默认为 true 含税
        itemList: [
          // 开票项目列表
          {
            flag: false,
            goodsType: "", // 项目类别
            goodsName: "", // 项目名称  等价于itemName
            taxClassificationCode: "", // 对应税收分类编码
            specificationModel: "", // 对应规格型号
            meteringUnit: "", // 单位
            quantity: "1", // 数量
            unitPrice: "0.00", // 单价
            invoiceAmount: "0.00", // 金额
            taxRateValue: "0.01", // 税率
            taxRateAmount: "0", // 税额 (税额 = 含税金额/(1+税率)*税率)

            discountAmount: "", // 折扣金额
            discountTaxRateAmount: "", // 折扣税额

            deductionAmount: "", // 差额开票抵扣金额
            preferentialPolicyFlag: "", // 优惠政策标识
            zeroTaxFlag: "", // 零税率标识
            vatSpecialManage: "", // 增值税特殊管理
          },
        ],
        remark: "", // 备注

        employeeList: [],
      },
      contract: {
        id: "",
        flag: true,
        contractTableData: [],
        wechatAddContractData: {},
      },
      waite: {
        userId: "",
        companyId: "",
      },
      dialogData: {
        title: "新增合同",
        flag: false,
        width: "80%",
        buttonArray: [
          {
            text: "取消",
            active: "cancel",
          },
        ],
        data: [],
      },
      productList: [],
      serviceItemList: [],
      agentData: {},
      invoiceInfoData: {
        clientId: "",
        data: [
          {
            name: "纸质普通发票",
            num: "123", // 已开票
            money: "222", // 金额
            remain: "22", // 剩余张数
            limitMoney: "22222", //  限额
          },
          {
            name: "纸质专用发票",
            num: "11111", // 已开票
            money: "1111", // 金额
            remain: "11", // 剩余张数
            limitMoney: "111", //  限额
          },
          {
            name: "电子纸质发票",
            num: "212121", // 已开票
            money: "1222121", // 金额
            remain: "122211", // 剩余张数
            limitMoney: "2112212", //  限额
          },
          {
            name: "电子专用发票",
            num: "3333", // 已开票
            money: "33", // 金额
            remain: "333", // 剩余张数
            limitMoney: "3333", //  限额
          },
        ],
      },
      wechatBaseInfo: {},
    };
  },
  methods: {
    getWechatUserInfo() {
      let code = this.GetQueryString("code", location.search);
      let requestData = {
        code: code,
      };
      this.$request(
        "post",
        "/wechat/getWechatUserInfo",
        requestData,
        (data) => {
          this.baseInfo.userId = data.userId;
          this.baseInfo.wechatId = data.wechatId;
          this.baseInfo.company.companyId = data.companyId;

          sessionStorage.setItem("userId", data.userId);
          sessionStorage.setItem("wechatId", data.wechatId);
          sessionStorage.setItem("companyId", data.companyId);
          this.wechatBaseInfo.userId = data.userId;
          this.getClientInfoByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    getClientInfoByUserId() {
      let groupId = sessionStorage.getItem("chatId");
      this.$request(
        "post",
        "/wechat/getClientInfoByGroupId",
        { groupId },
        (clientData) => {
          console.log(
            "clientDataclientDataclientData",
            JSON.parse(JSON.stringify(clientData))
          );
          this.tempClient = JSON.parse(JSON.stringify(clientData)); // 拿到当前客户基本信息
          this.client.name = clientData.clientName;
          this.client.clientNum = clientData.clientNum;
          this.client.id = clientData.id;

          this.$refs.wechatIndexRef.initData(
            this.client.id,
            this.baseInfo.userId
          );
        },
        (errorMessage) => {
          that.$message.error(errorMessage);
        }
      );
    },

    /**
     * 获取公司的所有在职员工列表
     */
    getEmployeeList() {
      this.invoice.employeeList = [];
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/isCharge",
        requestData,
        (data) => {
          data.forEach((element) => {
            let dd = {
              id: element.userId,
              userName: element.userName,
            };
            this.invoice.employeeList.push(dd);
            this.getAllServeType();
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有合同模板/产品列表
     */
    getAllServeType() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/serveType/getAllServeType",
        requestData,
        (data) => {
          this.productList = data;
          this.getServicesItem();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有服务项列表
     */
    getServicesItem() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        status: "1", // 0为停用;1为启用;2为全部
        name: "", // 模糊查询
        type: "", // 服务项类别ID 获取全部数据时,该项为空
      };
      this.$request(
        "post",
        "/servicesItem/getServicesItem",
        requestData,
        (data) => {
          this.serviceItemList = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 首页点击开票按钮
     */
    kaipiao() {
      this.activeName = "invoice";
    },
    /**
     * 确认开票
     */
    makeOutAnInvoice() {
      let requestData = {
        userId: this.userId, // 客户的微信ID
        groupId: this.chatId,
        value: this.invoice,
      };
      this.$request(
        "post",
        "/wechat/makeOutAnInvoice",
        requestData,
        (data) => {
          this.invoice.flag = false;
          this.$message({
            message: "开票成功!",
            type: "success",
            duration: 1000,
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 取消开票
     */
    cancellationMakeOutAnInvoice() {
      // this.activeName = "index";
      this.invoice.flag = false;
    },
    /**
     * 标签页切换
     */
    handleClick(tab, e) {
      console.log("标签切换:", tab.label);
      switch (tab.label) {
        case "首页":
          break;
        case "合同":
          console.log("合同合同合同合同");
          this.$refs.wechatContractRef.initData(
            this.client.id,
            this.baseInfo.userId
          );
        case "待办":
          // this.getAgent();
          wx.invoke("getCurExternalChat", {}, function(res) {
            console.log("获取客户群成功!", res);
            if (res.err_msg == "getCurExternalChat:ok") {
              // that.client.chatId = res.chatId; //返回当前客户外部联系人userId
              if (res.chatId) {
                console.log("群id", JSON.parse(JSON.stringify(res)));
                // resolve({ msg: res.chatId, flag: 1 });
              }
            } else {
              //错误处理
              // alert("成功人员用户id错误");
              console.log("群fail", JSON.parse(JSON.stringify(res)));
            }
          });
          break;
          break;
        default:
          break;
      }
    },
    /**
     * 获取发票具体信息的信息
     */
    getInvoiceInfoByWechat() {
      let requestData = {
        clientId: this.tempClient.id,
        year: "2021",
        quarter: "第一季度",
      };
      this.$request(
        "post",
        "/wechat/getInvoiceInfoByWechat",
        requestData,
        (data) => {
          this.invoiceInfoData.clientId = this.tempClient.id;
          this.invoiceInfoData.data[0].num = data.commonTicketPaperNum;
          this.invoiceInfoData.data[0].money = data.commonTicketPaperMoney;
          this.invoiceInfoData.data[0].remain = data.commonTicketPaperRemainNum;
          this.invoiceInfoData.data[0].limitMoney =
            data.commonTicketPaperLimitMoney;

          this.invoiceInfoData.data[1].num = data.specialTicketPaperNum;
          this.invoiceInfoData.data[1].money = data.specialTicketPaperMoney;
          this.invoiceInfoData.data[1].remain =
            data.specialTicketPaperRemainNum;
          this.invoiceInfoData.data[1].limitMoney =
            data.specialTicketPaperLimitMoney;

          this.invoiceInfoData.data[2].num = data.commonTicketElectronicNum;
          this.invoiceInfoData.data[2].money = data.commonTicketElectronicMoney;
          this.invoiceInfoData.data[2].remain =
            data.commonTicketElectronicRemainNum;
          this.invoiceInfoData.data[2].limitMoney =
            data.commonTicketElectronicLimitMoney;

          this.invoiceInfoData.data[3].num = data.specialTicketElectronicNum;
          this.invoiceInfoData.data[3].money =
            data.specialTicketElectronicMoney;
          this.invoiceInfoData.data[3].remain =
            data.specialTicketElectronicRemainNum;
          this.invoiceInfoData.data[3].limitMoney =
            data.specialTicketElectronicLimitMoney;
          console.log("开票数据:客户ID", this.invoiceInfoData);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取该客户的所有签订合同
     */
    getAllContractByClientId() {
      let requestData = {
        clientId: this.invoice.clientId,
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/contract/getAllContractByClientId",
        requestData,
        (data) => {
          this.contract.contractTableData = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取待办事项
     */
    getAllWaiteByClientId() {
      let requestData = {
        type: "all",
        userId: this.baseInfo.userId,
        flag: this.waite.flag,
        companyId: this.baseInfo.company.companyId,
        currentPage: 1, // 当前页数  20210903 加
        pageSize: 500, // 每页条数  20210903 加
      };
      this.$request(
        "post",
        "/waitDeal/getAllWaitDealByUserId",
        requestData,
        (data) => {
          this.waite.tableData = data.tableData;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 显示合同详情数据
     */
    showContractDetails(id) {
      this.contract.id = id;
      let requestData = {
        contractId: id,
      };
      this.$request(
        "post",
        "/wechat/showContractDetailsById",
        requestData,
        (data) => {
          this.contract.wechatAddContractData = JSON.parse(
            JSON.stringify(data)
          );
          this.contract.wechatAddContractData.serviceItem = [];
          let item_data = [
            {
              horizontal: false,
              isShow: true,
              isDisable: false,
              title: "服务名称",
              type: "select",
              value: "会计部",
              options: [],
            },
            {
              horizontal: false,
              isShow: true,
              isDisable: false,
              title: "付费周期",
              type: "select",
              value: "每年",
              options: [
                {
                  label: "每年",
                  value: "每年",
                  img: "",
                },
                {
                  label: "每月",
                  value: "每月",
                  img: "",
                },
                {
                  label: "每季度",
                  value: "每季度",
                  img: "",
                },
              ],
            },
            {
              horizontal: false,
              isShow: true,
              isDisable: true,
              title: "催费时间",
              type: "收费提醒选择",
              radio: "提前",
              beforeDay: "",
              lateDay: "",
              value: "",
            },
            {
              horizontal: false,
              isShow: true,
              isDisable: false,
              title: "优惠",
              type: "discounts",
              value: {},
            },
            {
              horizontal: false,
              isShow: true,
              isDisable: false,
              title: "首次催费时间",
              type: "time",
              value: "",
            },
            {
              horizontal: false,
              isShow: true,
              isDisable: false,
              title: "服务总价",
              type: "input",
              value: "180****8888",
            },
            {
              horizontal: false,
              isShow: true,
              isDisable: false,
              title: "服务时间",
              type: "timeToTime",
              value: [],
            },
          ];
          data.serviceItem.forEach((el) => {
            this.$set(el, "flag", false);
            item_data.forEach((it, ii) => {
              // 界面详情数据的每一行数据
              it.isShow = el.item[ii].isShow; // 详情每一行显示权限
              it.isDisable = el.item[ii].isDisable; // 详情每一行显示权限
              if (it.type == "收费提醒选择") {
                it.radio =
                  el.item[ii].value.label == "" ? "0" : el.item[ii].value.label;
                it.value = el.item[ii].value.value;
              } else if (it.type == "timeToTime") {
                it.value = el.item[ii].value;
              } else if (it.type == "discounts") {
                // 优惠
                it.value = el.item[ii].value;
                it.value.totalPrice = el.tableData.data[0].price;
              } else {
                it.value = el.item[ii].value;
              }
            });
            item_data[0].value = el.tableData.name;
            let dd = {
              flag: false,
              tableData: JSON.parse(JSON.stringify(el.tableData)),
              item: JSON.parse(JSON.stringify(item_data)),
            };
            this.contract.wechatAddContractData.serviceItem.push(dd);
          });
          this.contract.flag = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 添加合同
     * 1. 显示选择合同模板数据
     * 2. 选择具体合同模板
     * 3. 隐藏合同列表界面,显示合同详情界面
     */
    addContract() {
      // this.contract.flag = !this.contract.flag;
      this.dialogData.width = "50%";
      this.dialogData.title = "选择产品";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "取消",
        active: "cancel",
      });
      this.dialogData.data = [
        {
          type: "new-contract",
          title: "",
          value: {
            clientName: this.tempClient.clientName,
            // clientName: "",
          },
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 新增合同时,选择服务类型
     */
    choseProductTipOne(data) {
      if (this.dialogData.title == "选择产品") {
        let requestData = {
          userId: this.baseInfo.userId,
          companyId: this.baseInfo.company.companyId,
          productId: data.id, // 合同模板ID
          clientId: this.invoice.clientId, // 客户ID
          isRenew: false,
          oldContractId: "", // 原合同ID
          expenses: "0", // 原合同费用(续签才有,可能为空)
        };
        this.$request(
          "post",
          "/contract/addContract",
          requestData,
          (data) => {
            this.dialogData.flag = false;
            this.$message({
              message: "合同创建成功!",
              duration: 1000,
              type: "success",
            });
            this.showContractDetails(data.contractId);
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }
    },
    /**
     * 添加合同时的操作
     */
    WechatAddContractOper(active) {
      let that = this;
      switch (active) {
        case "submit":
          // 提交合同
          this.saveContractData().then(function(resolveData) {
            that.submitContract();
          });
          break;
        case "save":
          // 保存合同
          this.saveContractData().then(function(resolveData) {
            that.$message({
              message: "保存成功!",
              type: "success",
              duration: 1000,
            });
            that.contract.flag = true;
            that.getAllContractByClientId();
          });
          break;
        case "cancel":
          // 取消编辑
          this.contract.flag = true;
          break;

        default:
          break;
      }
    },
    /**
     * 保存合同
     */
    saveContractData() {
      let that = this;
      let p = new Promise(function(resolve, reject) {
        let requestData = that.contract.wechatAddContractData;
        that.$request(
          "post",
          "/wechat/saveContractDetails",
          requestData,
          (data) => {
            resolve(true);
          },
          (errorMessage) => {
            reject(errorMessage);
            that.$message.error(errorMessage);
          }
        );
      });
      return p;
    },
    /**
     * 提交合同
     */
    submitContract() {
      let requestData = {
        contractId: this.contract.wechatAddContractData.id,
      };
      this.$request(
        "post",
        "/wechat/submitContractDetails",
        requestData,
        (data) => {
          this.$message({
            message: "提交成功!",
            type: "success",
            duration: 1000,
          });
          this.contract.flag = true;
          that.getAllContractByClientId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * dialog操作事件
     */
    drawerOper(active) {
      switch (active) {
        case "cancel":
          // 取消
          this.colseDialog();
          break;

        default:
          break;
      }
    },
    /**
     * 企业微信开票的父子组件连锁事件
     */
    showWechatInvoiceIncomeData(data) {
      this.invoice = data;
    },
    /**
     * 收费/合同的父子组件连锁事件
     */
    showContractData(data) {
      this.contract = data;
    },
    /**
     * dialog关闭
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 复制信息
     */
    testCopy() {
      let that = this;
      setTimeout(() => {
        //设置延迟执行
        this.$request(
          "post",
          "/getAgentConfig",
          { url: this.$baseUrl + "/" },
          (data) => {
            console.log("PC后台连接数据", data);
            wx.agentConfig({
              corpid: data.corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
              agentid: data.agentId, // 必填，企业微信的应用id （e.g. 1000247）
              timestamp: data.timestamp, // 必填，生成签名的时间戳
              nonceStr: data.nonceStr, // 必填，生成签名的随机串
              signature: data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
              jsApiList: ["sendChatMessage"], //必填，传入需要使用的接口名称
              success: function(res) {
                // 回调
                console.log("success", res);
                let text =
                  "客户名称:" +
                  that.client.client.companyName +
                  ";\n税号:" +
                  that.client.client.tax +
                  ";\n开户行:" +
                  that.client.client.bank +
                  ";\n开户行账号:" +
                  that.client.client.bankAccount +
                  ";\n地址:" +
                  that.client.client.address +
                  ";\n电话:" +
                  that.client.client.phone;
                wx.invoke(
                  "sendChatMessage",
                  {
                    msgtype: "text", //消息类型，必填
                    enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
                    text: {
                      content: text, //文本内容
                    },
                  },
                  function(res) {
                    if (res.err_msg == "sendChatMessage:ok") {
                      console.log("发送成功!", res);
                    }
                  }
                );
              },
              fail: function(res) {
                //直接重新获取所有信息
                console.log("fail", res);
              },
            });
          },
          (errorMessage) => {
            that.$message.error(errorMessage);
          }
        );
      }, 500);
    },
    /**
     * 开票
     */
    makeOutAnInvoiceOpen() {
      let requestData = {
        clientId: this.invoice.clientId,
      };
      this.$request(
        "post",
        "/wechat/judgmentAuthorityMakeOutAnInvoice",
        requestData,
        (data) => {
          if (data == "Y") {
            //  可以开票
            this.invoice.flag = true;
          } else {
            this.invoice.flag = true;
            this.$message.error(
              "该客户暂时不可进行开票操作,请查看客户是否是开票客户!"
            );
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    GetQueryString(name, str) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = str.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
      var context = "";
      if (r != null) context = r[2];
      reg = null;
      r = null;
      return context == null || context == "" || context == "undefined"
        ? ""
        : context;
    },
    getAgent() {
      let that = this;
      that.$request(
        "post",
        "/getAgentConfig",
        { url: "https://crm.hfjunling.com/" },
        (data) => {
          console.log("PC后台连接数据", data);
          wx.checkJsApi({
            jsApiList: ["getCurExternalChat"], // 需要检测的JS接口列表
            success: function(res) {
              // console.log("res", JSON.parse(JSON.stringify(res)));
              // 以键值对的形式返回，可用的api值true，不可用为false
              // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
              let jsApiList = [];
              if (!res.checkResult.getCurExternalChat) {
                jsApiList.push("getCurExternalChat");
              }
              if (jsApiList.length > 0) {
                wx.agentConfig({
                  corpid: data.corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
                  agentid: data.agentId, // 必填，企业微信的应用id （e.g. 1000247）
                  timestamp: data.timestamp, // 必填，生成签名的时间戳
                  nonceStr: data.nonceStr, // 必填，生成签名的随机串
                  signature: data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
                  jsApiList: ["getCurExternalChat"], //必填，传入需要使用的接口名称
                  success: function(res2) {
                    // 回调
                    console.log("agentConfig----success", res2);
                    wx.invoke("getCurExternalChat", {}, function(res3) {
                      console.log("获取客户群成功!", res3);
                      if (res3.err_msg == "getCurExternalChat:ok") {
                        if (res3.chatId) {
                          sessionStorage.setItem("chatId", res3.chatId);
                          that.wechatBaseInfo.chatId = res3.chatId;
                          let url =
                            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww83f0f3b64e9ca3f5&redirect_uri=https://crm.hfjunling.com/#/wechat-client&response_type=code&scope=SCOPE&state=STATE#wechat_redirect";
                          window.location.href = url;
                        }
                      } else {
                        //错误处理
                        // alert("成功人员用户id错误");
                        console.log("群fail", res3);
                      }
                    });
                  },
                  fail: function(res4) {
                    //直接重新获取所有信息
                    console.log("agentConfig----fail", res4);
                  },
                });
              } else {
              }
            },
          });
        },
        (errorMessage) => {
          that.$message.error(errorMessage);
        }
      );
    },
  },
  mounted() {
    // console.log("CODE:", location.href);
    // console.log("CODE:", location.search);
    // console.log("CODE:", this.GetQueryString("code", location.search));

    this.wechatBaseInfo.chatId = sessionStorage.getItem("chatId");
    if (!this.wechatBaseInfo.chatId) {
      // 无 chatId 则获取当前外部群 id
      this.displayInterface = false;
      this.getAgent();
      // this.displayInterface = true;
      // this.$refs.wechatIndexRef.initData(this.client.id, this.baseInfo.userId);
    } else {
      this.displayInterface = true;
      this.wechatBaseInfo.userId = sessionStorage.getItem("userId");
      if (!this.wechatBaseInfo.userId) {
        this.getWechatUserInfo(); // 获取当前外部联系人userId
      } else {
        this.getClientInfoByUserId();
      }
    }
  },
};
</script>

<style lang="less" scoped>
#wechat-client {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .wechat-client-title {
    width: 100%;
    height: 50px;
    display: flex;
    margin-top: 10px;
    border-bottom: 1px solid #d1d3d8;
    .wechat-client-title-content {
      flex: 1;
      width: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      padding: 0 10px;
      box-sizing: border-box;
      .wechat-client-title-content-line {
        width: 100%;
        height: 0;
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        .icon {
          font-size: 16px;
          color: #8f9396;
          margin-left: 5px;
        }
      }
    }
    .wechat-client-title-phone {
      width: 40px;
      height: 100%;
      display: flex;
      justify-content: center;
      color: #ff874b;
      font-size: 16px;
      padding-top: 5px;
      box-sizing: border-box;
      i {
        border: 1px solid #d1d3d8;
        width: 22px;
        height: 22px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .wechat-client-tab {
    width: 100%;
    height: 0;
    flex: 1;
    margin-top: 10px;
    box-sizing: border-box;
    overflow-y: auto;
    /deep/ .el-tabs__header {
      margin: 0;
    }
    &::-webkit-scrollbar {
      width: 1px !important;
    }
    .wechat-client-content-index,
    .wechat-client-content-client {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .wechat-client-content-index-line {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #d1d3d8;
        box-sizing: border-box;
        margin-top: 10px;
        padding: 0 5px;
        .wechat-client-content-index-line-top {
          width: 100%;
          height: 20px;
          display: flex;
          align-items: center;
          font-size: 13px;
          .wechat-client-content-index-line-title {
            width: auto;
            height: 100%;
            padding: 0 5px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            &::before {
              content: "";
              width: 2px;
              height: 90%;
              margin-right: 5px;
              background-image: linear-gradient(#ff874b57, #ff874b);
            }
          }
          .wechat-client-content-index-line-time {
            width: 180px;
            height: 30px;
            margin-right: 5px;
          }
          .wechat-client-content-index-line-but {
            width: 70px;
            height: 25px;
            margin-right: 5px;
            border: 1px solid #ff874b;
            color: #ff874b;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
          }
        }
        .wechat-client-content-index-line-value {
          width: 100%;
          height: auto;
          display: flex;
          flex-wrap: wrap;
          font-size: 11px;
          margin: 5px;
          margin-top: 10px;
          .tip {
            width: auto;
            height: 20px;
            padding: 3px 8px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #5f5f5f25;
            margin: 3px;
          }
          .tip-border {
            border: 1px solid #333;
            background: none;
          }
          .text {
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            span {
              color: #ff874b;
              margin-left: 5px;
            }
          }
          .table {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            .line {
              width: 98%;
              height: 30px;
              border-bottom: 1px solid #d1d3d8;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 11px;
              div {
                flex: 1;
                width: 0;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .head {
              background-color: #d1d3d84f;
            }
          }
        }
      }
      .wechat-client-content-client-cad {
        width: 85%;
        height: auto;
        border: 1px solid #d1d3d8;
        border-radius: 5px;
        margin: 3px;
        padding: 3px;
        box-sizing: border-box;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        display: flex;
        flex-direction: column;
        .cad-line {
          width: 100%;
          height: 40px;
          display: flex;
          font-size: 13px;
          align-items: center;
          .cad-line-title {
            width: 30%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 5px;
            box-sizing: border-box;
          }
          .cad-line-value {
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    /deep/ .el-tabs--card {
      height: calc(100vh - 110px);
    }
    /deep/ .el-tab-pane {
      height: calc(100vh - 110px);
      overflow-y: auto;
    }
  }
  .active {
    flex: 1;
  }
}
.backgr {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
