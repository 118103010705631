import Vue from 'vue'
/**
 * 获取当前模块的表头菜单数据
 */
export function getTableMenu(active) {
    let menu = []
    switch (active) {
        case "humanMine":
            // 我的人才
        case "humanBase":
            // 人才库
            menu = [
                { name: "姓名", key: "name", authority: '1' },
                { name: "联系电话", key: "phone", authority: '1' },
                { name: "地区", key: "address", authority: '1' },
                { name: "证书及专业", key: "certificateTYype", authority: '1' },
                { name: "身份证", key: "idCard", authority: '1' },
                { name: "最新联系时间", key: "latestContactTime", authority: '1' },
                { name: "是否聘用", key: "isEngage", authority: '1' },
            ]
            break;
        default:
            break;
    }
    return menu
}

/**
 * 获取工资单表头菜单数据
 */
export function getHumanPayrollSettingMenuData() {
    let menu = [
        {
            label: '职位',
            prop: 'position'
        },
        {
            label: '月份',
            prop: 'date'
        },
        {
            label: '基本工资',
            prop: 'basicPay',
            remark: 'basicPayRemark'
        },
        {
            label: '岗位工资',
            prop: 'postWage',
            remark: 'postWageRemark'
        },
        {
            label: '绩效工资',
            prop: 'meritPay',
            remark: 'meritPayRemark'
        },
        {
            label: '交付提成',
            prop: 'deliveryCommission',
            remark: 'deliveryCommission'
        },
        {
            label: '业务提成',
            prop: 'businessCommission',
            remark: 'businessCommissionRemark'
        },
        {
            label: '业务提成 (增减) ',
            prop: 'businessCommissionIncOrDec',
            remark: 'businessCommissionIncOrDecRemark'
        },
        {
            label: '奖金',
            prop: 'bonus',
            remark: 'bonusRemark'
        },
        {
            label: '津贴补助',
            prop: 'subsidy',
            remark: 'subsidyRemark'
        },
        {
            label: '考勤扣款',
            prop: 'attendanceDeduction',
            remark: 'attendanceDeductionRemark'
        },
        {
            label: '个税',
            prop: 'individualIncomeTax',
            remark: 'individualIncomeTaxRemark'
        },
        {
            label: '五险代扣合计',
            prop: 'socialSecurityWithholding',
            remark: 'socialSecurityWithholdingRemark'
        },
        {
            label: '累计工资收入',
            prop: 'wageIncome',
            remark: 'wageIncomeRemark'
        },
        {
            label: '扣除总计',
            prop: 'deductTotal',
            remark: 'deductTotalRemark'
        },
        {
            label: '实发工资',
            prop: 'netPayroll',
            remark: 'netPayrollRemark'
        },
        {
            label: '扣分',
            prop: 'deductionOfPoints',
            remark: 'deductionOfPointsRemark'
        },
        {
            label: '所在公司',
            prop: 'company',
            remark: ''
        },
        {
            label: '备注',
            prop: 'remark',
            remark: ''
        },
    ]
    return menu
}

export function humanDrawerData(title, authorityData, isHead, options, flag) {
    let threadDrawerData = getDrawerData(title, options, flag )

    // 拼接数据显示权限
    threadDrawerData = Vue.prototype.$initDrawerAuthorityData.initDrawerAuthorityData(threadDrawerData, authorityData, isHead)

    return threadDrawerData
}

function getDrawerData(title, options, flag) {
    let humanDrawerData = {
        data: {
            modelList: []
        }
    }
    switch (flag) {
        case "人才信息":
            humanDrawerData = {
                title: "人才信息",
                flag: false,
                CardList: [{
                        text: "人才信息",
                        active: "info",
                        key: "",
                    }, {
                        text: "需求",
                        active: "requirements",
                    },
                    {
                        text: "附件",
                        active: "attachment",
                    },
                ],
                cardIndex: 0,
                buttonList: [{
                    text: "跟进",
                    icon: "el-icon-edit-outline",
                    active: "genjin",
                    key: "followUp"
                }, ],
                hasSetting: true,
                settingOperList: [{
                    text: "删除人才",
                    active: "delete_client",
                    key: "delete"
                }, {
                    text: "申请客户流失",
                    active: "run_off",
                    key: "lossOf"
                }, {
                    text: "添加关联客户",
                    authority: 1,
                    active: "adding_associated_customer",
                    key: "associat"
                }, {
                    text: "操作记录",
                    active: "operating_record",
                    key: "cancel"
                }, ],
                data: {}
            }
            break;
        case "简历库":
            console.log('简历库简历库简历库简历库简历库')
            humanDrawerData = {
                title: '简历信息',
                flag: false,
                hasSetting: true,
                status: "", // 当前商机状态
                settingOperList: [],
                buttonList: [],
                data: {
                    title: title,
                    titleEdit: false,
                    isDisable: true, // 是否只读
                    modelList: [{
                            line: [{
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "姓名",
                                    key: "name",
                                    type: "text",
                                    value:'',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "创建日期",
                                    key: "gmtCreate",
                                    type: "text",
                                    value:'',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "面试官",
                                    key: "interviewerName",
                                    type: "text",
                                    value:'',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "面试时间",
                                    key: "interviewTime",
                                    type: "text",
                                    value:'',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "待入职时间",
                                    key: "entryDate",
                                    type: "text",
                                    value:'',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "应聘部门",
                                    key: "departmentAppliedName",
                                    type: "text",
                                    value: '',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "应聘职位",
                                    key: "positionApplied",
                                    type: "text",
                                    value: "",
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "简历来源",
                                    key: "talentSource",
                                    type: "text",
                                    value: "",
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "入库原因",
                                    key: "reasonForStorage",
                                    type: "text",
                                    value: '',
                                },        
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "手机号",
                                    key: "phone",
                                    type: "text",
                                    value: '',
                                },     
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "微信号",
                                    key: "wechat",
                                    type: "text",
                                    value: '',
                                },             
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "在职状态",
                                    key: "inductionStatus",
                                    type: "text",
                                    value: '',
                                },               
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "备注",
                                    key: "remark",
                                    type: "text",
                                    value: "",
                                },
                            ],  
                        },
                        {
                            line: [       
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "",
                                    key: "interviewRecord",
                                    type: "interviewRecord",
                                    value: "",
                                }, 
                            ],  
                        },
                        {
                            line: [       
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "",
                                    key: "workExperience",
                                    type: "interviewCompanyRecord",
                                    value: "",
                                },
                            ],  
                        },
                    ],
                },
            }
            break;
        case "员工信息":
            humanDrawerData = {
                title: '员工信息',
                flag: false,
                hasSetting: true,
                status: "", // 当前商机状态
                settingOperList: [],
                buttonList: [],
                data: {
                    title: title,
                    titleEdit: false,
                    isDisable: true, // 是否只读
                    modelList: [{
                            line: [{
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "姓名",
                                    key: "name",
                                    type: "text",
                                    value:'',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "性别",
                                    key: "sex",
                                    type: "text",
                                    value:'',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "年龄",
                                    key: "age",
                                    type: "text",
                                    value:'',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "学历",
                                    key: "qualification",
                                    type: "text",
                                    value:'',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "出生日期",
                                    key: "birthday",
                                    type: "text",
                                    value:'',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "手机号",
                                    key: "phone",
                                    type: "text",
                                    value:'',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "身份证号",
                                    key: "idCard",
                                    type: "text",
                                    value:'',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "银行卡号",
                                    key: "bankCard",
                                    type: "text",
                                    value:'',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "户籍所在地",
                                    key: "domicileL",
                                    type: "text",
                                    value:'',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "现居地址",
                                    key: "address",
                                    type: "text",
                                    value:'',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "紧急联系人1",
                                    key: "firstEmergencyContact",
                                    type: "text",
                                    value:'',
                                },
                                {
                                    horizontal: true,
                                    isShow: true, // 是否显示
                                    isDisable: false, // 是否只读
                                    title: "紧急联系人2",
                                    key: "secondEmergencyContact",
                                    type: "text",
                                    value:'',
                                },
                            ],  
                        },
                        {
                            line: [
                            {
                                horizontal: true,
                                isShow: true, // 是否显示
                                isDisable: false, // 是否只读
                                title: "入职时间",
                                key: "entryDate",
                                type: "text",
                                value:'',
                            },
                            {
                                horizontal: true,
                                isShow: true, // 是否显示
                                isDisable: false, // 是否只读
                                title: "部门",
                                key: "activeDepartmentName",
                                type: "text",
                                value:'',
                            },
                            {
                                horizontal: true,
                                isShow: true, // 是否显示
                                isDisable: false, // 是否只读
                                title: "岗位",
                                key: "activePost",
                                type: "text",
                                value:'',
                            },
                            {
                                horizontal: true,
                                isShow: true, // 是否显示
                                isDisable: false, // 是否只读
                                title: "薪资",
                                key: "salary",
                                type: "text",
                                value:'',
                            },
                            {
                                horizontal: true,
                                isShow: true, // 是否显示
                                isDisable: false, // 是否只读
                                title: "合同主体",
                                key: "subjectOfContract",
                                type: "text",
                                value:'',
                            },
                            {
                                horizontal: true,
                                isShow: true, // 是否显示
                                isDisable: false, // 是否只读
                                title: "合同到期时间",
                                key: "contractExpirationDate",
                                type: "text",
                                value:'',
                            },
                            {
                                horizontal: true,
                                isShow: true, // 是否显示
                                isDisable: false, // 是否只读
                                title: "社保有效期",
                                key: "socialSecurityPurchase",
                                type: "text",
                                value:'',
                            },
                        ],
                        }
                    ],
                },
            }
            break;    
        default:
            break;
    }
    return humanDrawerData
}

export function returnDrawerKeys(model, flag) {
    let keys = []
    let drawerData = getDrawerData("", Vue.prototype.$tagData.getDrawerOptionsList(model), flag, {}).data.modelList
    for (let index = 0; index < drawerData.length; index++) {
        let element = drawerData[index].line;
        for (let i = 0; i < element.length; i++) {
            let ele = element[i];
            if (ele.key) {
                let dd = {
                    name: ele.text,
                    key: ele.key,
                    authority: "1",
                    sequence: 1,
                    id: ""
                }
                keys.push(JSON.parse(JSON.stringify(dd)))
            }
        }

    }
    console.log('keys', keys)
    return keys
}

/**
 * 获取新增, 编辑dialog数据
 * @employeeList 人员列表 带权限
 * @data 表格当前行数据
 */
export function getHumanDialogData(employeeList, data) {
    let dialogData = {
        id: data.id,
        employeeList,
        name: data.userId,
        date: data.date,
        remark: data.remark,
        rowData: [
            {
                name: '基本工资',
                data: data.basicPay, // 基本工资
                remark: data.basicPayRemark
            },
            {
                name: '岗位工资',
                data: data.postWage, // 岗位工资
                remark: data.postWageRemark
            },
            {
                name: '绩效工资',
                data: data.meritPay, // 绩效工资
                remark: data.meritPayRemark
            },
            {
                name: '业务提成 (增减) ',
                data: data.businessCommissionIncOrDec, // 业务提成
                remark: data.businessCommissionIncOrDecRemark
            },
            {
                name: '奖金',
                data: data.bonus, // 奖金
                remark: data.bonusRemark
            },
            {
                name: '津贴补助',
                data: data.subsidy, // 津贴补助
                remark: data.subsidyRemark
            },
            {
                name: '考勤扣款',
                data: data.attendanceDeduction, // 考勤扣款
                remark: data.attendanceDeductionRemark
            },
            {
                name: '个税',
                data: data.individualIncomeTax, // 个税
                remark: data.individualIncomeTaxRemark
            },
            {
                name: '五险代扣合计',
                data: data.socialSecurityWithholding, // 五险代扣合计
                remark: data.socialSecurityWithholdingRemark
            },
            {
                name: '累计工资收入',
                data: data.wageIncome, // 累计工资收入
                remark: data.wageIncomeRemark
            },
            {
                name: '扣除总计',
                data: data.deductTotal, // 扣除总计
                remark: data.deductTotalRemark
            },
            {
                name: '实发工资',
                data: data.netPayroll, // 实发工资
                remark: data.netPayrollRemark
            },
            {
                name: '扣分',
                data: data.deductionOfPoints, // 扣分
                remark: data.deductionOfPointsRemark
            },
            {
                name: '所在公司',
                data: data.company, // 所在公司
                remark: ""
            },
        ]
    }
    return dialogData
}

/**
 * 获取新增, 编辑 请求数据
 */
export function getRequestData(data) {
    console.log('data', data)
    let requestData = {}
    data.forEach((item) => {
        if (item.name == "基本工资") {
          requestData.basicPay = item.data;
          requestData.basicPayRemark = item.remark;

        } else if (item.name == "岗位工资") {
          requestData.postWage = item.data;
          requestData.postWageRemark = item.remark;

        } else if (item.name == "绩效工资") {
          requestData.meritPay = item.data;
          requestData.meritPayRemark = item.remark;

        } else if (item.name == "业务提成 (增减) ") {
          requestData.businessCommissionIncOrDec = item.data;
          requestData.businessCommissionIncOrDecRemark = item.remark;

        } else if (item.name == "奖金") {
          requestData.bonus = item.data;
          requestData.bonusRemark = item.remark;

        } else if (item.name == "津贴补助") {
          requestData.subsidy = item.data;
          requestData.subsidyRemark = item.remark;

        } else if (item.name == "考勤扣款") {
          requestData.attendanceDeduction = item.data;
          requestData.attendanceDeductionRemark = item.remark;
        } 
        else if (item.name == "个税") {
          requestData.individualIncomeTax = item.data;
          requestData.individualIncomeTaxRemark = item.remark;
  
        }else if (item.name == "五险代扣合计") {
          requestData.socialSecurityWithholding = item.data;
          requestData.socialSecurityWithholdingRemark = item.remark;

        } else if (item.name == "累计工资收入") {
          requestData.wageIncomeRemark = item.remark;
        } else if (item.name == "扣除总计") {
          requestData.deductTotalRemark = item.remark;
        } else if (item.name == "实发工资") {
          requestData.netPayrollRemark = item.remark;
          
        } else if (item.name == "扣分") {
          requestData.deductionOfPoints = item.data;
          requestData.deductionOfPointsRemark = item.remark;
        }
         else if (item.name == "所在公司") {
          requestData.company = item.data;
        }
      });
    return requestData
}

/**
 * 导入税务详情数据
 */
export function importHumanDialogData(this_) {
    return [
        {
            title: '选择月份',
            type: "time_month",
            value: ''
        },
        {
            type: "improt_resources",
            value: {
                url: "",
                text: "",
                action: this_.$fileBaseurl + "/uploadImageByMultipartFile",
                uploadParameter: {
                    systemName: "finance",
                    title: "导入工资单",
                },
                headers: {
                    token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                modelType: "payrollMode"
            },
        },
 ]
}

/**
 * 获取业绩提成表的菜单数据
 */
export function getHumanPerformanceMenuData() {
    return [{
            prop: "userName",
            title: "姓名",
            width: "120"
        }, {
            prop: "clientName",
            title: "公司名称",
            width: ""
        }, {
            prop: "serveItem",
            title: "服务项目",
            width: "120"
        }, {
            prop: "money",
            title: "签约金额",
            width: ""
        }, {
            prop: "cost",
            title: "成本",
            width: ""
        }, {
            prop: "paymentCollect",
            title: "收款金额",
            width: ""
        }, {
            prop: "amountsOw",
            title: "未回款额",
            width: ""
        }, {
            prop: "profits",
            title: "毛利润",
            width: ""
        }, {
            prop: "royaltyRate",
            title: "提成比例",
            width: ""
        }, {
            prop: "royalty",
            title: "提成",
            width: ""
        },
    ]
}

/**
 * 获取业绩提成表的菜单数据 详情
 */
export function getHumanDispatchMenuData() {
    return [{
            prop: "completeUserName",
            updateKey: 'completeUserId',
            title: "执行人",
            width: "140"
        }, {
            prop: "clientName",
            title: "客户名称",
            width: ""
        }, {
            prop: "serviceItem",
            title: "服务项",
            width: "110"
        }, {
            prop: "productName",
            title: "服务产品",
            width: "130"
        }, {
            prop: "stepName",
            title: "完成步骤",
            width: "200"
        }, {
            prop: "currentStepTime",
            title: "完成步骤时间",
            width: "150"
        }, {
            prop: "commission",
            title: "步骤提成",
            width: ""
        }, {
            prop: "status",
            title: "派工完成情况",
            width: ""
        }
    ]
}

/**
 * 获取业绩提成表的菜单数据 统计
 */
export function getHumanDispatchMenuData2() {
    return [{
            prop: "completeUserName",
            title: "姓名",
            width: ""
        }, {
            prop: "totalCommission",
            title: "提成合计",
            width: ""
        }, {
            prop: "royaltyRate",
            title: "提成比率",
            width: ""
        }, {
            prop: "totalRoyalty",
            title: "提成金额",
            width: ""
        }
    ]
}

/**
 * 获取交付提成表的菜单数据 详情
 */
export function getHumanContractMenuData() {
    return [{
            prop: "clientName",
            title: "客户名称",
            width: "",
            fixed: true
        }, {
            prop: "contractNumber",
            title: "合同编号",
            width: "130",
            fixed: true
        }, {
            prop: "chargeUpStatus",
            title: "记账状态",
            width: "130",
        }, {
            prop: "checkStatus",
            title: "审核状态",
            width: "130",
        }, {
            prop: "receiptsStatus",
            title: "收款状态",
            width: "130",
        }, {
            prop: "amount",
            title: "合同金额",
            width: "130",
        }, {
            prop: "contractCycle",
            title: "合同周期",
            width: "130",
        }, {
            prop: "contractAverageRoyalty",
            title: "合同月均提成",
            width: "130",
        }, {
            prop: "chargePerson",
            title: "收费负责人",
            width: "150"
        }, {
            prop: "chargePersonRatio",
            title: "收费负责人提成比例",
            width: "170",
            type: "input",
            icon: true
        }, {
            prop: "chargePersonRoyalty",
            title: "收费负责人提成",
            width: "150",
        }, {
            prop: "serviceConsultant",
            title: "服务顾问",
            width: "150"
        }, {
            prop: "serviceConsultantRatio",
            title: "服务顾问提成比例",
            width: "150",
            type: "input",
            icon: true
        }, {
            prop: "serviceConsultantRoyalty",
            title: "服务顾问提成",
            width: "150",
        }, {
            prop: "sponsorAccounting",
            title: "主办会计",
            width: "150"
        }, {
            prop: "sponsorAccountingRatio",
            title: "主办会计提成比例",
            width: "150",
            type: "input",
            icon: true
        }, {
            prop: "sponsorAccountingRoyalty",
            title: "主办会计提成",
            width: "150",
        }, {
            prop: "accountManager",
            title: "客户经理",
            width: "150"
        }, {
            prop: "accountManagerRatio",
            title: "客户经理提成比例",
            width: "150",
            type: "input",
            icon: true
        }, {
            prop: "accountManagerRoyalty",
            title: "客户经理提成",
            width: "150",
        }, {
            prop: "divisionHead",
            title: "分部负责人",
            width: "150"
        }, {
            prop: "divisionHeadRatio",
            title: "分部负责人提成比例",
            width: "170",
            type: "input",
            icon: true
        }, {
            prop: "divisionHeadRoyalty",
            title: "分部负责人提成",
            width: "150",
        }
    ]
}

/**
 * 获取交付提成表的菜单数据 统计
 */
export function getHumanContractMenuData2() {
    return [{
            prop: "userName",
            title: "姓名",
            width: ""
        }, {
            prop: "royalty",
            title: "总提成金额",
            width: ""
        }
    ]
}

// 简历库抽屉数据模板
export function getDrawerDataByHumanResume(title, authorityData, isHead, options) {
    // 定义线索分配抽屉数据
    let humanResumeDrawerData =  {}
  
}

// 员工信息抽屉数据模板
export function getDrawerDataByHumanEmployee(title, authorityData, isHead, options) {

    // 定义线索分配抽屉数据
    let humanResumeDrawerData = {}

  

    return humanResumeDrawerData
}