<template>
  <div id="interview-record">
    <div class="interview-record-title">
      <div>{{ interviewRecordData.title }}</div>
      <div>
        <i
          class="el-icon-circle-plus-outline icon"
          @click="componentsOperation('')"
        ></i>
      </div>
    </div>
    <div
      class="interview-record-list"
      v-if="interviewRecordData.list.length > 0"
    >
      <div
        class="interview-record-item"
        v-for="(item, index) in interviewRecordData.list"
        :key="index"
      >
        <el-descriptions class="margin-top" title="" :column="3" border>
          >
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              面试官
            </template>
            {{ item.interviewerName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-time"></i>
              面试时间
            </template>
            {{ item.interviewTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              操作
            </template>
            <i class="el-icon-edit icon" @click="componentsOperation(item)"></i>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-tickets"></i>
              备注
            </template>
            {{ item.interviewRemark }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div class="interview-record-list" v-else>
      <div class="noMore">暂无数据</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    interviewRecordData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    componentsOperation(item) {
      this.$emit("componentsOperation", {
        value: item,
        model: "interviewRecord",
      });
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
#interview-record {
  width: 100%;
  .interview-record-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    font-size: 16px;
    padding: 0 15px;
    box-sizing: border-box;
    border-bottom: 1px solid #d1d3d8;
  }
  .interview-record-list {
    width: 97%;
    padding: 10px;
    .interview-record-item {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .noMore {
      color: #9ba1a7;
    }
  }
  .icon {
    font-size: 16px;
    cursor: pointer;
    &:hover {
      color: #409eff;
    }
  }
}
</style>
