<template>
  <div id="task-info">
    <!-- 日程列表 -->
    <div class="task-info-screen">
      <div class="task-info-screen-line">
        <div class="task-info-screen-search">
          <el-input
            v-model="searchData.title"
            placeholder="请输入日程标题"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="task-info-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="resetPage"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="task-info-screen-search">
          <el-select
            v-model="searchData.userId"
            placeholder="请选择客户负责人"
            @change="selectInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="task-info-screen-search">
          <el-date-picker
            v-model="searchData.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :value-format="'yyyy-MM-dd HH:mm:ss'"
            @change="resetPage"
          >
          </el-date-picker>
        </div>
        <div class="task-info-screen-active"></div>
        <el-button
          type="primary"
          size="small"
          @click="handleClick('', 0)"
          style="margin-right: 10px"
          v-showFunction="{
            active: 'add',
            array: allFunctionButtons,
          }"
          >新增日程</el-button
        >
      </div>
      <div
        class="task-info-screen-line"
        v-if="searchData.screenList.length > 0"
      >
        <div class="title">筛选条件:</div>
        <el-tag
          v-for="(tag, index) in searchData.screenList"
          :key="index"
          closable
          size="small"
          @close="closeScreen(index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>
    <div class="table-content">
      <div class="task-info-table-card">
        <div class="task-info-table-card-tabs">
          <div
            :class="{
              'task-info-table-card-tab': true,
              'is-active': tabindex == '1',
            }"
            @click="changeTab('1')"
          >
            未开始
          </div>
          <div
            :class="{
              'task-info-table-card-tab': true,
              'is-active': tabindex == '2',
            }"
            @click="changeTab('2')"
          >
            进行中
          </div>
          <div
            :class="{
              'task-info-table-card-tab': true,
              'is-active': tabindex == '3',
            }"
            @click="changeTab('3')"
          >
            已结束
          </div>
          <div
            :class="{
              'task-info-table-card-tab': true,
              'is-active': tabindex == '4',
            }"
            @click="changeTab('4')"
          >
            已逾期
          </div>
        </div>
        <div class="task-info-content">
          <el-table
            :data="tableData"
            highlight-current-row
            :row-style="{ height: '60px' }"
            style="width: 100%"
            height="98%"
            v-loading="loading"
          >
            <el-table-column type="index" width="40" fixed> </el-table-column>
            <el-table-column
              prop="title"
              label="日程名称"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span
                  class="tableHighLight"
                  @click="handleClick(scope.row, 1)"
                  >{{ scope.row.title }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="time"
              label="日程时间"
              width="300"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span
                  >{{ scope.row.startTime }} -- {{ scope.row.endTime }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="repeatRule"
              label="日程重复"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="content"
              label="日程内容"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="statusName"
              label="状态"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="completionRate"
              label="完成率"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="操作" width="350" fixed="right">
              <template slot-scope="scope">
                <el-button
                  v-if="
                    scope.row.statusName == '未开始' ||
                      scope.row.statusName == '进行中'
                  "
                  type="primary"
                  size="mini"
                  plainZ
                  icon="el-icon-edit-outline"
                  @click="handleClick(scope.row, 2)"
                  v-showFunction="{
                    active: 'edit',
                    array: allFunctionButtons,
                  }"
                  >编辑</el-button
                >
                <el-button
                  v-if="scope.row.statusName == '进行中'"
                  type="success"
                  size="mini"
                  plain
                  icon="el-icon-check"
                  @click="handleClick(scope.row, 3)"
                  v-showFunction="{
                    active: 'end',
                    array: allFunctionButtons,
                  }"
                  >完结</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  plain
                  icon="el-icon-delete"
                  @click="handleClick(scope.row, 4)"
                  v-showFunction="{
                    active: 'delete',
                    array: allFunctionButtons,
                  }"
                  >删除</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  plain
                  icon="el-icon-edit-outline"
                  @click="handleClick(scope.row, 5)"
                  v-showFunction="{
                    active: 'look',
                    array: allFunctionButtons,
                  }"
                  >查看</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="task-info-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as expressageData from "@/views/expressage/js/expressage.js";
import my_dialog from "@/components/dialog.vue";
import popo_search from "@/components/popo-search.vue";

export default {
  components: {
    popo_search,
    my_dialog,
  },
  data() {
    return {
      loading: false,
      baseInfo: {},
      tabindex: "1",
      tableData: [],
      searchData: {
        status: "",
        userId: "",
        title: "",
        time: [],
        department: "",
        tempUserId: [],
        userList: [],
        allUserList: [],
        screenList: [],
        departTreeData: [],
      },
      defaultSort: { prop: "", order: "" },
      popoData: {
        popoVisible: false,
        list: expressageData.expressageInfoSearchData(),
      },
      arrayList: {},
      allFunctionButtons: this.$featureData.getAllFunctionButtons("taskInfo"),
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      tempAllEmpolyeeList: [],
      tempData: {},
      whetherAgencyHead: false,
      allRoleList: [],
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "taskInfo",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeListForDownBox();
      });
    },
    /**
     * 获取所有人员列表
     */
    getEmployeeListForDownBox() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.searchData.allUserList = data;
          this.getDepartmentTreeByUserId();
        }
      );
    },

    /**
     * 获取所有下属列表 包括自己
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getBusinessAuthorityList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有业务角色
     */
    getBusinessAuthorityList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/role/getBusinessAuthorityList",
        requestData,
        (data) => {
          this.allRoleList = [];
          data.forEach((element) => {
            let dd = {
              label: element.authorityName,
              value: element.authorityId,
            };
            this.allRoleList.push(dd);
          });
          this.getEmployeeListByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeListByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = JSON.parse(JSON.stringify(data));
          this.tempAllEmpolyeeList = [];
          this.searchData.userList.forEach((element) => {
            this.tempAllEmpolyeeList.push(element.userId);
          });
          this.searchData.userList.splice(0, 0, {
            userId: "all",
            userName: "全部",
          });
          this.searchData.userId = this.baseInfo.userId;
          this.selectInitTableData(this.baseInfo.userId);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        title: this.searchData.title, //
        time: this.searchData.time, // 筛选项 日程时间
        status: this.tabindex, // 筛选项 日程状态
        userIds: this.searchData.tempUserId,
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
        pageSize: this.pageData.pageSize, // 每页条数
        currentPage: this.pageData.currentPage, // 当前页数
      };
      this.$request(
        "post",
        "/schedule/page",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.tableData.forEach((item) => {
            switch (item.status) {
              case "1":
                item.statusName = "未完成";
                break;
              case "2":
                item.statusName = "进行中";
                break;
              case "3":
                item.statusName = "已结束";
                break;
              case "4":
                item.statusName = "已逾期";
                break;
              default:
                break;
            }
          });
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    changeTab(flag) {
      this.tabindex = flag;
      this.resetPage();
    },
    /**
     * 界面操作
     */
    handleClick(data, flag) {
      this.tempData = data;
      let requestData = {};
      switch (flag) {
        case 0:
          this.dialogData.width = "600px";
          this.dialogData.title = "新增日程";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_add",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "input",
              title: "日程名称",
              value: "",
              required: true,
            },
            {
              type: "filters",
              title: "参与人",
              value: [],
              required: true,
              data: {
                playersType: "1", // 参与者类型（'1'-人员；'2'-角色）
                visibleUsers: [], // 具体可见用户集合（JSON字符串），如果参与者类型是 '1' 则该字段必填
                businessRole: "", // 选择的业务角色
                clientCondition: [], // 客户范围的筛选条件信息（JSON字符串）
                visibleUsersList: this.searchData.allUserList, // 下拉选项 可选人员列表
                businessRoleList: this.allRoleList, // 下拉选项 可选业务角色列表
              },
            },
            {
              type: "times",
              title: "日程时间",
              value: [],
              required: true,
              pickerOptions: {
                disabledDate(time) {
                  return time.getTime() < Date.now() - 8.64e7;
                },
              },
            },
            {
              type: "select",
              title: "日程重复",
              value: "不重复",
              options: [
                {
                  label: "不重复",
                  value: "不重复",
                },
                {
                  label: "每月重复",
                  value: "每月",
                },
              ],
              required: true,
            },
            {
              type: "textarea",
              title: "日程内容",
              value: "",
              placeholder: "请输入日程内容",
            },
          ];
          this.dialogData.flag = true;
          break;
        case 1:
          this.dialogData.width = "1300px";
          this.dialogData.title = "日程详情";
          this.dialogData.buttonArray = [];
          this.dialogData.data = [
            {
              type: "table_payroll",
              data: {
                model: "task",
                searchData: [
                  {
                    type: "input",
                    key: "clientName",
                  },
                  {
                    type: "department",
                    key: "department",
                    options: this.searchData.departTreeData,
                  },
                  {
                    type: "employeeList",
                    key: "tempUserId",
                    options: this.searchData.userList,
                  },
                  {
                    type: "select",
                    key: "status",
                    options: [
                      {
                        label: "未完成",
                        value: "未完成",
                      },
                      {
                        label: "已完成",
                        value: "已完成",
                      },
                    ],
                  },
                ],
                requestData: {
                  scheduleId: this.tempData.id, // 日程 id
                  clientName: "", // 客户名称
                  department: "", // 部门 id
                  tempUserId: this.baseInfo.userId,
                  status: "", // 日程状态
                  userIds: [this.baseInfo.userId], // 人员 id 集合
                },
                optionsList: {
                  tempAllEmpolyeeList: this.tempAllEmpolyeeList,
                },
                url: "/schedule/getExecutor",
                tableMenuData: [
                  {
                    prop: "userName",
                    title: "姓名",
                  },
                  {
                    prop: "clientName",
                    title: "客户名称",
                  },
                  {
                    prop: "status",
                    title: "是否完成",
                  },
                  {
                    prop: "operation",
                    title: "操作",
                    width: "200px",
                    list: [
                      {
                        name: "完成",
                        active: "complete",
                        type: "success",
                        plain: true,
                        size: "mini",
                        icon: "el-icon-check",
                      },
                    ],
                  },
                ],
              },
            },
          ];
          console.log("dialog数据", this.dialogData);
          this.dialogData.flag = true;
          break;
        case 2:
          // 编辑
          console.log("this.tempData", this.tempData);
          this.dialogData.width = "600px";
          this.dialogData.title = "编辑日程";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_edit",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "input",
              title: "日程名称",
              value: this.tempData.title,
              required: true,
            },
            {
              type: "filters",
              title: "参与人",
              value: [],
              required: true,
              data: {
                playersType: this.tempData.playersType, // 参与者类型（'1'-人员；'2'-角色）
                visibleUsers: JSON.parse(this.tempData.visibleUsers), // 具体可见用户集合（JSON字符串），如果参与者类型是 '1' 则该字段必填
                businessRole: this.tempData.businessRole, // 选择的业务角色
                clientCondition: JSON.parse(this.tempData.clientCondition), // 客户范围的筛选条件信息（JSON字符串）
                visibleUsersList: this.searchData.allUserList, // 下拉选项 可选人员列表
                businessRoleList: this.allRoleList, // 下拉选项 可选业务角色列表
              },
            },
            {
              type: "times",
              title: "日程时间",
              value: [this.tempData.startTime, this.tempData.endTime],
              required: true,
            },
            {
              type: "select",
              title: "日程重复",
              value: "不重复",
              options: [
                {
                  label: "不重复",
                  value: "不重复",
                },
                {
                  label: "每月重复",
                  value: "每月",
                },
              ],
              required: true,
            },
            {
              type: "textarea",
              title: "日程内容",
              value: this.tempData.content,
              placeholder: "请输入日程内容",
            },
          ];
          this.dialogData.flag = true;
          break;
        case 3:
          // 完结
          this.$confirm("此操作将完结该日程, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                id: this.tempData.id,
                status: "3", // 状态（'1' - 未开始；'2' - 进行中；'3' - 已结束；'4' - 已逾期）
              };
              this.$request(
                "post",
                "/schedule/update",
                requestData,
                (data) => {
                  this.$message.success("完结成功!");
                  this.initTableData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case 4:
          // 删除
          this.$confirm("此操作将删除该日程, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "get",
                "/schedule/delete?id=" + this.tempData.id,
                {},
                (data) => {
                  this.$message.success("删除成功!");
                  this.initTableData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case 5:
          this.dialogData.width = "1300px";
          this.dialogData.title = "完成详情";
          this.dialogData.buttonArray = [];
          this.dialogData.data = [
            {
              type: "table_payroll",
              data: {
                model: "task",
                searchData: [
                  {
                    type: "department",
                    key: "department",
                    options: this.searchData.departTreeData,
                  },
                  {
                    type: "employeeList",
                    key: "tempUserId",
                    options: this.searchData.userList,
                  },
                ],
                requestData: {
                  scheduleId: this.tempData.id, // 日程 id
                  department: "", // 部门 id
                  tempUserId: "all",
                  userIds: this.tempAllEmpolyeeList, // 人员 id 集合
                },
                optionsList: {
                  tempAllEmpolyeeList: this.tempAllEmpolyeeList,
                },
                url: "/schedule/getCompletionRate",
                tableMenuData: [
                  {
                    prop: "userName",
                    title: "姓名",
                  },

                  {
                    prop: "completionRate",
                    title: "完成率",
                  },
                ],
              },
            },
          ];
          console.log("dialog数据", this.dialogData);
          this.dialogData.flag = true;
          break;
        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "sure_add":
          requestData = {
            title: this.dialogData.data[0].value, // 日程名称
            content: this.dialogData.data[4].value, // 任务内容
            time: this.dialogData.data[2].value, // 日程时间
            repeatRule: this.dialogData.data[3].value, // 重复规则（不重复、每月）
            playersType: this.dialogData.data[1].data.playersType, // 参与者类型（'1'-人员；'2'-角色）
            visibleUsers: this.dialogData.data[1].data.visibleUsers, // 具体可见用户集合（JSON字符串），如果参与者类型是 '1' 则该字段必填
            businessRole: this.dialogData.data[1].data.businessRole, // 选择的业务角色
            clientCondition: this.dialogData.data[1].data.clientCondition, // 客户范围的筛选条件信息（JSON字符串）
          };
          if (!requestData.title) {
            return this.$message.error("日程名称不可为空!");
          }
          if (requestData.time.length == 0) {
            return this.$message.error("日程时间不可为空!");
          }
          if (requestData.playersType == "1") {
            if (requestData.visibleUsers.length == 0) {
              return this.$message.error("可见用户不可为空!");
            }
          } else {
            if (requestData.businessRole.length == 0) {
              return this.$message.error("业务角色不可为空!");
            }
          }
          requestData.visibleUsers = JSON.stringify(requestData.visibleUsers);
          requestData.clientCondition = JSON.stringify(
            requestData.clientCondition
          );
          this.$request(
            "post",
            "/schedule/add",
            requestData,
            (data) => {
              this.$message.success("添加成功!");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_edit":
          requestData = {
            id: this.tempData.id, // 日程id
            title: this.dialogData.data[0].value, // 日程名称
            content: this.dialogData.data[4].value, // 任务内容
            time: this.dialogData.data[2].value, // 日程时间
            repeatRule: this.dialogData.data[3].value, // 重复规则（不重复、每月）
            playersType: this.dialogData.data[1].data.playersType, // 参与者类型（'1'-人员；'2'-角色）
            visibleUsers: this.dialogData.data[1].data.visibleUsers, // 具体可见用户集合（JSON字符串），如果参与者类型是 '1' 则该字段必填
            businessRole: this.dialogData.data[1].data.businessRole, // 选择的业务角色
            clientCondition: this.dialogData.data[1].data.clientCondition, // 客户范围的筛选条件信息（JSON字符串）
          };
          if (!requestData.title) {
            return this.$message.error("日程名称不可为空!");
          }
          if (requestData.time.length == 0) {
            return this.$message.error("日程时间不可为空!");
          }
          if (requestData.playersType == "1") {
            if (requestData.visibleUsers.length == 0) {
              return this.$message.error("可见用户不可为空!");
            }
          } else {
            if (requestData.businessRole.length == 0) {
              return this.$message.error("业务角色不可为空!");
            }
          }
          requestData.visibleUsers = JSON.stringify(requestData.visibleUsers);
          requestData.clientCondition = JSON.stringify(
            requestData.clientCondition
          );
          this.$request(
            "post",
            "/schedule/update",
            requestData,
            (data) => {
              this.$message.success("编辑成功!");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },

    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
      this.searchData.screenList = [];
    },
    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      // console.log("所有数组数据:", this.arrayList);
      this.popoData.list[1].options = []; // 下单员工
      this.popoData.list[1].options = this.searchData.allUserList.map(
        (item, index) => {
          return { label: item.userName, value: item.userId };
        }
      );
      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选弹出框确认事件
     */
    popoMakeSure() {
      this.searchData.screenList = this.$systemBaseData.remakesureSearchData(
        this.popoData.list
      );
      this.popoData.popoVisible = false;
      this.initTableData();
    },
    /**
     * 关闭筛选标签事件
     */
    closeScreen(index) {
      this.searchData.screenList.splice(index, 1);
      this.initTableData();
    },
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.tempAllEmpolyeeList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.resetPage();
    },
    resetPage() {
      this.pageData.currentPage = 1;
      this.initTableData();
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#task-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .task-info-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .task-info-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .task-info-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .task-info-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .task-info-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .task-info-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        &:hover {
          background-color: #abbed3;
        }
      }
      .task-info-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        &:hover {
          cursor: pointer;
          background-color: @activeColor2;
        }
      }

      .task-info-screen-but-boder {
        width: 50px;
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .task-info-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .task-info-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .task-info-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .task-info-table-card-tab {
          width: 130px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .task-info-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .task-info-table-card-active {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: @contentFontSize;
          .textArea {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .numArea {
            height: 100%;
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: @activeColor;
          }
        }
        .task-info-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .task-info-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        .tableHighLight {
          color: @activeColor;
          cursor: pointer;
        }
      }
      .task-info-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
