<template>
  <div id="client_connect">
    <!-- 工作交接 -->
    <div class="screen">
      <div class="screen-line">
        <div class="client_connect-screen-fiftrate">
          <el-popover
            placement="bottom-start"
            width="250"
            trigger="manual"
            :visible-arrow="false"
            v-model="popoData.popoVisible"
          >
            <div class="popover-search">
              <popo_search
                :list="popoData.list"
                @cancel="popoCanael"
                @reset="popoReset"
                @makeSure="popoMakeSure"
              ></popo_search>
            </div>
            <div slot="reference" @click="popoverOpen">
              筛选 <i class="el-icon-s-operation"></i>
            </div>
          </el-popover>
        </div>
        <div class="client_connect-screen-search">
          <el-input
            placeholder="请输入客户全称/编号/税号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="client_connect-screen-checked">
          <el-checkbox v-model="searchData.checked" @change="changeChecked"
            >全选</el-checkbox
          >
        </div>
        <div class="client_connect-screen-checked">
          <el-checkbox
            v-model="searchData.hasLeave"
            :true-label="'1'"
            :false-label="'0'"
            @change="initTableData"
            >包含已流失</el-checkbox
          >
        </div>
        <div class="client_connect-screen-active"></div>
        <div
          class="client_connect-screen-but-select"
          @click="handleClick({}, 0)"
          v-showFunction="{ active: 'assign', array: allFunctionButtons }"
        >
          批量分配
        </div>
      </div>
      <div class="screen-line" v-if="searchData.screenList.length > 0">
        <div class="title">筛选条件:</div>
        <el-tag
          v-for="(tag, index) in searchData.screenList"
          :key="index"
          closable
          size="small"
          @close="closeScreen(index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>
    <div class="table-content">
      <div class="client_connect-content">
        <div class="client_connect-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            @row-click="rowClick"
            style="width: 100%"
            height="98%"
            @selection-change="handleSelectionChange"
            :row-style="rowStyle"
            v-loading="loading"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column type="index" min-width="55" fixed>
            </el-table-column>
            <el-table-column
              label="客户全称"
              property="clientName"
              min-width="170"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div class="hri">
                  <i
                    class="el-icon-notebook-2"
                    @click.stop="showGenjin(scope.row)"
                  ></i
                  >{{ scope.row.clientName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="客户编号" property="clientNum">
            </el-table-column>
            <el-table-column
              v-for="(item, index) in roleList"
              :key="index"
              :label="item.name"
              min-width="100"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div
                  v-for="(ddd, dd) in scope.row.roleList[index].users"
                  :key="dd"
                >
                  <div v-if="dd == 0">{{ ddd.name }}</div>
                  <div v-else>, {{ ddd.name }}</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="client_connect-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "@/components/dialog.vue";
import popo_search from "@/components/popo-search.vue";
import * as clientInfoData from "@/assets/js/clientInfoData.js";
import * as contractDrawData from "@/assets/js/contractDraw.js";
import * as allClientData from "./js/clientData.js";
export default {
  components: {
    my_dialog,
    popo_search,
  },
  data() {
    return {
      rowStyle: this.$rowStyle1,
      baseInfo: {},
      loading: true,
      whetherAgencyHead: false,
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      searchData: {
        input: "",
        hasLeave: "0",
        checked: false, // 是否包含流失
        screenButton_add: 1, // 新增客户
        screenButton_improt: 1, // 导入
        screenButton_export: 1, // 导出
        screenList: [],
      },
      popoData: {
        popoVisible: false,
        list: clientInfoData.clientInfoSearchData({}, {}, []),
      },
      tableButtons: [
        {
          text: "批量分配",
          active: "",
          flag: 1,
        },
      ],
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      multipleSelection: [],

      tabMenu: [],
      roleList: [],
      tableData: [],
      employeeList: [],

      buttonAuth: [],
      productList: [], // 所有合同模板/产品数据
      departmentList: [], // 所有部门
      tempThreadId: "", // 临时存放商机ID
      serviceItemList: [],
      tempServices: [],
      productTreeList: [],
      tempClientName: "", // 新建客户的临时名称
      tempClientId: "", // 临时客户ID
      allRoleList: [],

      tipsData: {
        clientTipsList: [], // 客户标签
        pianquList: [], // 片区标签
        clientLevelList: [], // 客户等级
        sourceList: [], // 客户来源
      },
      tempClient: {
        id: "客户ID",
        clientNum: "客户编号",
        clientName: "客户全称",
        roleList: [
          {
            name: "客户经理",
            users: ["张三", "李四"],
          },
          {
            name: "服务顾问",
            users: ["张三", "李四"],
          },
        ],
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "clientConnect"
      ),
      clientDrawerOptions: this.$tagData.getDrawerOptionsList("clientConnect"), // 抽屉中的下拉框配置项
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "clientConnect",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
          this.getServeTypeAndItem();
        }
      );
    },
    /**
     * 获取产品树
     */
    getServeTypeAndItem() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/servicesItem/getServeTypeAndItem",
        requestData,
        (data) => {
          this.productTreeList = data;
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeList();
      });
    },

    /**
     * 获取所有员工列表
     */
    getEmployeeList() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.employeeList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.employeeList.push(dd);
          });
          this.getBusinessAuthorityList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 获取所有业务角色
     */
    getBusinessAuthorityList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/role/getBusinessAuthorityList",
        requestData,
        (data) => {
          this.allRoleList = [];
          data.forEach((element) => {
            let dd = {
              label: element.authorityName,
              value: element.authorityId,
            };
            this.allRoleList.push(dd);
          });
          this.initMenuData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格表头数组
     */
    initMenuData() {
      this.roleList = [];
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/client/getClientRoleList",
        requestData,
        (data) => {
          this.roleList = data;
          this.$tagData.getTagList(
            this.clientDrawerOptions,
            [
              "合约主体",
              "收费定额",
              "片区",
              "客户来源",
              "增值税类型",
              "客户等级",
              "客户标签",
              "资质类型",
              "流失原因",
            ],
            this.baseInfo.company.companyId,
            this
          );
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.loading = true;
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        search: this.searchData.input,
        screenList: this.searchData.screenList,
        hasLeave: this.searchData.hasLeave, // 是否包含已流失, 0:不包含, 1:包含
      };
      this.$request(
        "post",
        "/client/getAllClientRolesByUserId",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = data.tableData;
          this.$nextTick(() => {
            this.$refs.singleTable.doLayout();
          });
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    changeChecked(val) {
      // if (val) {
      //   this.multipleSelection = this.tableData;
      // }
      this.$refs.singleTable.toggleAllSelection();
      // this.initTableData();
    },
    /**
     * 表格行点击事件
     */
    rowClick(row, column, event) {
      this.tempClient = row;
      this.dialogData.title = "分配-" + row.clientName;
      this.dialogData.width = "50%";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "确定",
        active: "make_sure_one",
      });
      this.dialogData.buttonArray.push({
        text: "取消",
        active: "cancel",
      });
      let employeeList = [];
      this.employeeList.forEach((element) => {
        let ddd = {
          id: element.value,
          name: element.label,
        };
        employeeList.push(ddd);
      });
      this.dialogData.data = clientInfoData.clientConnectDialogData(
        this.tempClient.roleList,
        employeeList
      );
      // console.log("lllllllllllllllll", this.dialogData, this.employeeList);
      this.dialogData.flag = true;
    },
    handleClick(data, flag) {
      switch (flag) {
        case 0:
          // 批量分配
          if (this.multipleSelection.length == 0) {
            this.$message.error("请先勾选客户,再进行分配!");
          } else {
            this.dialogData.title = "批量分配";
            this.dialogData.width = "50%";
            this.dialogData.buttonArray = [];
            this.dialogData.buttonArray.push({
              text: "确定",
              active: "make_sure",
            });
            this.dialogData.buttonArray.push({
              text: "取消",
              active: "cancel",
            });
            let employeeList = [];
            this.employeeList.forEach((element) => {
              let ddd = {
                id: element.value,
                name: element.label,
              };
              employeeList.push(ddd);
            });
            this.dialogData.data = clientInfoData.clientConnectDialogData(
              this.roleList,
              employeeList
            );
            this.dialogData.flag = true;
          }
          break;

        default:
          break;
      }
    },
    /**
     * 弹出款操作事件
     */
    drawerOper(active) {
      switch (active) {
        case "make_sure_one":
          this.allocationClientRoleList([this.tempClient]);
          break;
        case "make_sure":
          this.allocationClientRoleList(this.multipleSelection);

          break;
        case "cancel":
          // 关闭
          this.initMenuData();
          this.colseDialog();
          break;

        default:
          break;
      }
    },

    /**
     * 查看跟进记录
     */
    showGenjin(clientData) {
      let requestData = {
        clientId: clientData.id,
        threadId: "",
      };
      this.$request(
        "post",
        "/followRecord/showFollowRecord",
        requestData,
        (data) => {
          this.dialogData.title = "跟进记录-" + clientData.clientName;
          this.dialogData.buttonArray = [];
          this.dialogData.data = [
            {
              type: "genjinjilu",
              value: {
                hasContent: false,
                content: "",
                time: "",
                list: data,
                hasTarget: false,
                target: "",
                hasPhase: false,
                phase: "",
              },
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 批量分配客户
     * array : 所选客户组
     */
    allocationClientRoleList(array) {
      let clients = [];
      array.forEach((element) => {
        clients.push(element.id);
      });
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        clients: clients, // 客户ID
        roleList: clientInfoData.formatClientConnectDialogData(
          this.dialogData.data
        ), // 业务角色列表
        search: this.searchData.input,
        flag: this.searchData.checked ? "1" : "0",
        list: this.$systemBaseData.makesureSearchData(this.popoData.list),
      };
      this.$request(
        "post",
        "/client/allocationClientRoleList",
        requestData,
        (data) => {
          this.$message({
            message: "操作成功!",
            duration: 1000,
            type: "success",
          });
          this.initMenuData();
          this.colseDialog();
          this.searchData.checked = false;
          // array.forEach((element) => {
          //   this.$operationRecords.saveOperationRecords(
          //     element.id,
          //     "客户分配!",
          //     this.baseInfo,
          //     "client",
          //     this,
          //     (data2) => {
          //     }
          //   );
          // });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.dialogData.data = [];
    },
    /**
     * 多选框事件
     */
    handleSelectionChange(val) {
      console.log("val", val);
      this.multipleSelection = val;
    },
    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      let roleList = this.allRoleList;
      let companyData = {
        employeeList: this.employeeList, // 员工列表
        departmentList: this.departmentList, // 部门列表
      };
      let tipsData = {
        clientTipsList: this.clientDrawerOptions.tipList, // 客户标签
        pianquList: this.clientDrawerOptions.areaList, // 片区标签
        clientLevelList: this.clientDrawerOptions.levelList, // 客户等级
        customerSourceList: this.clientDrawerOptions.sourceList, // 客户来源
        lossReasonList: this.clientDrawerOptions.lossReasonList, // 流失原因
      };
      console.log("this.productTreeList", this.productTreeList);
      this.popoData.list = clientInfoData.clientInfoSearchData(
        companyData,
        tipsData,
        roleList,
        this.productTreeList
      );
      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选框确认事件
     */
    popoMakeSure() {
      this.searchData.screenList = this.$systemBaseData.remakesureSearchData(
        this.popoData.list
      );
      let flag = true;
      this.searchData.screenList.forEach((element) => {
        if (
          element.type == "phone" &&
          !this.$phoneCheck.mobilePhoneNumberCheck(element.value)
        ) {
          // 做手机号格式校验
          flag = false;
        }
      });
      if (!flag) {
        this.$message({
          message: "联系人手机号格式不正确,请确认填入正确的手机号!",
          type: "error",
          duration: 1000,
        });
      } else {
        this.popoData.popoVisible = false;
        this.initTableData();
      }
    },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
      this.searchData.screenList = [];
    },
    /**
     * 关闭筛选标签事件
     */
    closeScreen(index) {
      this.searchData.screenList.splice(index, 1);
      this.initTableData();
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
  },
  mounted() {
    this.loading = true;
    this.isCharge();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#client_connect {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .client_connect-screen-fiftrate {
    width: 65px;
    min-width: 65px;
    height: 30px;
    border: 1px solid @boderColor;
    box-sizing: border-box;
    color: @boderActiveColor;
    font-size: @contentFontSize;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: 0.3s;
    i {
      margin: 0 4px;
    }
  }
  .client_connect-screen-fiftrate:hover {
    cursor: pointer;
    border-color: @boderActiveColor;
  }
  .client_connect-screen-search {
    width: 210px;
    min-width: 210px;
    height: 30px;
  }
  .client_connect-screen-checked {
    width: 140px;
    height: 30px;
    min-width: 140px;
    font-size: @contentFontSize;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .client_connect-screen-active {
    flex: 1;
  }
  .client_connect-screen-but-select {
    width: 140px;
    min-width: 160px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
    cursor: pointer;
    .client_connect-screen-but-select_but {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
    }
    .client_connect-screen-but-select_but:hover {
      background-color: @activeColor2;
    }
    .client_connect-screen-but-select_select {
      width: 30px;
      height: 100%;
      border-left: 1px solid @activeColor3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .client_connect-screen-but {
    width: 100px;
    min-width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .client_connect-screen-but:hover {
    cursor: pointer;
    background-color: @activeColor2;
  }
  .client_connect-screen-but-boder {
    width: 50px;
    min-width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid @activeColor;
    font-size: @contentFontSize;
    background-color: @activeBackground;
    color: @activeColor;
    margin: 0 5px;
    margin-right: 10px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .client_connect-screen-but-boder:hover {
    cursor: pointer;
    background-color: @activeBackground2;
  }
  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .client_connect-content {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .client_connect-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .client_connect-table-card-tab {
          width: auto;
          min-width: 100px;
          height: 100%;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .client_connect-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .client_connect-table-card-active {
          flex: 1;
        }
        .client_connect-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .client_connect-table-card-content {
        flex: 1;
        width: 98%;
      }
      .client_connect-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }

  .hri {
    display: flex;
    i {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
}
</style>
