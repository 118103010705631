<template>
  <div id="interview-company-record">
    <div class="interview-company-record-title">
      <div>工作经历</div>
      <div>
        <i
          class="el-icon-circle-plus-outline icon"
          @click="componentsOperation('')"
        ></i>
      </div>
    </div>
    <div
      class="interview-company-record-list"
      v-if="interviewCompanyRecordData.length > 0"
    >
      <el-timeline>
        <el-timeline-item
          v-for="(activity, index) in interviewCompanyRecordData"
          :key="index"
        >
          <div class="timeline">
            {{ activity.company }} {{ activity.position }}
          </div>
          <div class="timeline">{{ activity.content }}</div>
          <div class="timeline time">
            {{ activity.time[0] }}-{{ activity.time[1] }}
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>
    <div class="interview-company-record-list" v-else>
      <div class="noMore">暂无数据</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    interviewCompanyRecordData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      reverse: true,
    };
  },
  methods: {
    componentsOperation(item) {
      this.$emit("componentsOperation", {
        value: item,
        model: "interviewCompanyRecord",
      });
    },
  },
  created() {
    console.log("interviewCompanyRecordData", this.interviewCompanyRecordData);
  },
};
</script>

<style lang="less" scoped>
#interview-company-record {
  width: 100%;
  .interview-company-record-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    font-size: 16px;
    padding: 0 15px;
    box-sizing: border-box;
    border-bottom: 1px solid #d1d3d8;
  }
  .interview-company-record-list {
    width: 97%;
    padding: 10px;
    .timeline {
      margin: 5px 0;
    }
    .time {
      color: #9ba1a7;
    }
    .noMore {
      color: #9ba1a7;
    }
  }
  .icon {
    font-size: 16px;
    cursor: pointer;
    &:hover {
      color: #409eff;
    }
  }
  /deep/ .el-timeline-item__wrapper {
    width: 80%;
    text-align: left;
    font-size: 13px;
  }
}
</style>
