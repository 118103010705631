<template>
  <div id="wechat-contract">
    <div class="wechat-contract-line">
      <div class="wechat-contract-line-value">
        <el-table :data="tableData" :loading="loading">
          <el-table-column
            prop="contractNumber"
            label="合同编号"
            width="130px"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span style="color: #409EFF">{{ scope.row.contractNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="services"
            label="服务项"
            width="100px"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.services"
                :key="index"
                class="tip"
                :style="{ color: item.color, borderColor: item.color }"
              >
                {{ item.text }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="合同状态"
            width="100px"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="money"
            label="合同金额"
            width="100px"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="auditedIncome"
            label="已收款"
            width="100px"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="accruedAssets"
            label="未收款"
            width="100px"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="signTime"
            label="签订时间"
            width="130px"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="startToEnd"
            label="合同期限"
            width="200px"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      client: {},
      baseInfo: {},
      loading: false,
      tableData: [],
    };
  },
  methods: {
    getAllContractByClientId() {
      this.loading = true;
      let requestData = {
        clientId: this.client.id,
        companyId: "4a8add03df734b3bba7bcbb9f488f50f",
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/contract/getAllContractByClientId",
        requestData,
        (data) => {
          this.loading = false;
          this.tableData = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    initData(clientId, userId) {
      console.log("clientId", clientId);
      console.log("userId", userId);
      this.client.id = clientId;
      this.baseInfo.userId = userId;
      this.getAllContractByClientId();
    },
  },
};
</script>

<style lang="less" scoped>
#wechat-contract {
  width: 100%;
  min-height: 100%;
  padding: 16px 12px;
  box-sizing: border-box;
  background: #f4f6f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  .wechat-contract-line {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 1px 4px 0 hsla(0, 0%, 60%, 0.3);
    padding: 16px 12px;
    border-radius: 6px;
    margin-bottom: 12px;
    .wechat-contract-line-top {
      width: 100%;
      height: 20px;
      display: flex;
      align-items: center;
      font-size: 13px;
      .wechat-contract-line-title {
        width: auto;
        height: 100%;
        padding: 0 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
          content: "";
          width: 2px;
          height: 70%;
          margin-right: 5px;
          background-image: linear-gradient(#40a3ff, #40a3ff);
        }
      }
      .wechat-contract-line-time {
        width: 180px;
        height: 30px;
      }
      .wechat-contract-line-but {
        width: 70px;
        height: 25px;
        margin-right: 5px;
        border: 1px solid #ff874b;
        color: #ff874b;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
      }
      .active {
        flex: 1;
      }
    }
    .wechat-contract-line-value {
      width: 100%;
      height: auto;
      font-size: 11px;
      margin: 5px;
      margin-top: 10px;
      .tax-cost {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .tax-cost-item {
          width: 50%;
          display: flex;
          padding: 3px 0;
          box-sizing: border-box;
          .tax-cost-item-title {
            padding-right: 5px;
            box-sizing: border-box;
          }

          &:nth-child(2n) {
            border-left: 1px solid #c0c0c0;
            padding-left: 10px;
            box-sizing: border-box;
          }
        }
      }
      .tip {
        height: 30px;
        width: auto;
        padding: 2px 5px;
        margin: 0 3px;
        border: 1px solid #333;
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .tip-border {
        border: 1px solid #333;
        background: none;
      }
      .text {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        span {
          color: #ff874b;
          margin-left: 5px;
        }
      }
      .table {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        .line {
          width: 98%;
          height: 30px;
          border-bottom: 1px solid #d1d3d8;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 11px;
          div {
            flex: 1;
            width: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .head {
          background-color: #d1d3d84f;
        }
      }
      .none {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #999;
      }
      .more {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #999;
      }
    }
  }
}
</style>
