<template>
  <div id="task-info">
    <!-- 日程列表 -->
    <div class="task-info-screen">
      <div class="task-info-screen-line">
        <div class="task-info-screen-search">
          <div class="date">
            <i class="el-icon-arrow-left" @click="changeDate('prevMonth')"></i>
            {{ searchData.currentYear }}年{{ searchData.currentMonth + 1 }}月
            <i class="el-icon-arrow-right" @click="changeDate('nextMonth')"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="table-content">
      <div class="task-info-table-card">
        <div class="task-info-content">
          <table class="task-info-table">
            <thead>
              <tr>
                <th>周一</th>
                <th>周二</th>
                <th>周三</th>
                <th>周四</th>
                <th>周五</th>
                <th>周六</th>
                <th>周日</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="task-calendar-day">
                    <div class="task-calendar-day-value">1</div>
                    <div class="task-calendar-day-content">
                      <div class="row-item" @click="showDetail">
                        <div class="row-item-inner">
                          <div class="row-item-inner-title">
                            <i class="el-icon-discover"></i>
                            <span>发送【个税变更核对】</span>
                            <span>0%</span>
                          </div>
                        </div>
                      </div>
                      <div class="row-item">
                        <div class="row-item-inner">
                          <div class="row-item-inner-title">
                            <i class="el-icon-discover"></i>
                            <span>发送【个税变更核对】</span>
                            <span>0%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
              </tr>
              <tr>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
              </tr>
              <tr>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
              </tr>
              <tr>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
              </tr>
              <tr>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
              </tr>
              <tr>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as expressageData from "@/views/expressage/js/expressage.js";
import my_dialog from "@/components/dialog.vue";
import popo_search from "@/components/popo-search.vue";

export default {
  components: {
    popo_search,
    my_dialog,
  },
  data() {
    return {
      value: new Date(),
      loading: false,
      baseInfo: {},
      tabindex: "1",
      tableData: [],
      searchData: {
        currentYear: new Date().getFullYear(),
        currentMonth: new Date().getMonth(),
        selectedDate: null,
        department: "",
        tempUserId: [],
        userList: [],
        allUserList: [],
        screenList: [],
        departTreeData: [],
      },
      defaultSort: { prop: "", order: "" },
      popoData: {
        popoVisible: false,
        list: expressageData.expressageInfoSearchData(),
      },
      arrayList: {},
      allFunctionButtons: this.$featureData.getAllFunctionButtons("taskInfo"),
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      tempAllEmpolyeeList: [],
      tempData: {},
      whetherAgencyHead: false,
      allRoleList: [],
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "taskInfo",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeListForDownBox();
      });
    },
    /**
     * 获取所有人员列表
     */
    getEmployeeListForDownBox() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.searchData.allUserList = data;
          this.getDepartmentTreeByUserId();
        }
      );
    },

    /**
     * 获取所有下属列表 包括自己
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getBusinessAuthorityList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有业务角色
     */
    getBusinessAuthorityList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/role/getBusinessAuthorityList",
        requestData,
        (data) => {
          this.allRoleList = [];
          data.forEach((element) => {
            let dd = {
              label: element.authorityName,
              value: element.authorityId,
            };
            this.allRoleList.push(dd);
          });
          this.getEmployeeListByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeListByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = JSON.parse(JSON.stringify(data));
          this.tempAllEmpolyeeList = [];
          this.searchData.userList.forEach((element) => {
            this.tempAllEmpolyeeList.push(element.userId);
          });
          this.searchData.userList.splice(0, 0, {
            userId: "all",
            userName: "全部",
          });
          this.searchData.userId = this.baseInfo.userId;
          this.selectInitTableData(this.baseInfo.userId);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {},
    changeDate(flag) {
      switch (flag) {
        case "prevMonth":
          if (this.searchData.currentMonth === 0) {
            this.searchData.currentYear--;
            this.searchData.currentMonth = 11;
          } else {
            this.searchData.currentMonth--;
          }
          break;
        case "nextMonth":
          if (this.searchData.currentMonth === 11) {
            this.searchData.currentYear++;
            this.searchData.currentMonth = 0;
          } else {
            this.searchData.currentMonth++;
          }
          break;
        default:
          break;
      }
    },
    /**
     * 界面操作
     */
    handleClick(data, flag) {
      this.tempData = data;
      let requestData = {};
      switch (flag) {
        case 0:
          break;
        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    /**
     * 关闭筛选标签事件
     */
    closeScreen(index) {
      this.searchData.screenList.splice(index, 1);
      this.initTableData();
    },
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.tempAllEmpolyeeList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.resetPage();
    },
    resetPage() {
      this.pageData.currentPage = 1;
      this.initTableData();
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#task-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .task-info-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .task-info-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .task-info-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .task-info-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .task-info-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        .date {
          display: flex;
          align-items: center;
          i {
            cursor: pointer;
          }
        }
      }
      .task-info-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        &:hover {
          background-color: #abbed3;
        }
      }
      .task-info-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        &:hover {
          cursor: pointer;
          background-color: @activeColor2;
        }
      }

      .task-info-screen-but-boder {
        width: 50px;
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .task-info-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .task-info-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .task-info-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .task-info-table-card-tab {
          width: 130px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .task-info-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .task-info-table-card-active {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: @contentFontSize;
          .textArea {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .numArea {
            height: 100%;
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: @activeColor;
          }
        }
        .task-info-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .task-info-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        .tableHighLight {
          color: @activeColor;
          cursor: pointer;
        }
        .task-info-table {
          width: 100%;
          height: 100%;
          border-collapse: collapse;
          table-layout: fixed;
          font-size: 12px;
          .task-calendar-day {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            .task-calendar-day-value {
              display: flex;
              justify-content: flex-end;
            }
            .task-calendar-day-content {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              .row-item {
                width: 100%;
                height: 20px;
                line-height: 20px;
                font-size: 12px;
                position: relative;
                margin-bottom: 5px;
                .row-item-inner {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 650px;
                  background: #f2f7ff;
                  border-radius: 3px;
                  cursor: default;
                  overflow: hidden;
                  padding: 0 10px;
                  text-align: left;
                  text-overflow: ellipsis;
                  .row-item-inner-title {
                    i {
                      font-size: 14px;
                      margin-right: 5px;
                    }
                  }
                }
                .rows-item-focused {
                  color: #fff;
                  background: #40a3ff;
                }
              }
            }
          }
          thead th {
            background: #e9ebf0;
            border: 1px solid #d9d9d9;
            height: auto;
            line-height: 18px;
            padding: 5px 12px 5px 0;
            text-align: right;
          }
          tbody td {
            height: 16%;
            width: 14%;
            min-width: 150px;
            min-height: 100px;
            padding: 5px 10px 5px 0;
            box-sizing: border-box;
            border-bottom: 1px solid #d9d9d9;
            border-right: 1px solid #d9d9d9;
          }
        }
      }
      .task-info-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
