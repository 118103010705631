<template>
  <div id="contract-work">
    <!-- 合同派工 -->
    <div class="contract-work-screen">
      <div class="contract-work-screen-search">
        <el-input
          placeholder="请输入客户名称"
          v-model="searchData.input"
          @keyup.enter.native="initTableData"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click.native.stop="initTableData"
          ></el-button>
        </el-input>
      </div>
      <div class="contract-work-screen-search">
        <el-cascader
          v-model="searchData.select_value"
          :options="professionList"
          placeholder="请选择业务类型"
          @change="initTableData"
          collapse-tags
          :props="{
            multiple: true,
            label: 'name',
            value: 'id',
            emitPath: false,
            children: 'child',
          }"
          clearable
        ></el-cascader>
      </div>
      <div class="contract-work-screen-search">
        <el-select
          placeholder="请选择提交人"
          v-model="searchData.submitter"
          @change="selectInitTableData"
          filterable
        >
          <el-option
            v-for="item in searchData.userList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="contract-work-screen-search">
        <el-date-picker
          v-model="searchData.time"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择任务开始时间"
          @change="initTableData"
        >
        </el-date-picker>
      </div>
      <div class="contract-work-screen-search">
        <el-cascader
          v-model="searchData.currentStepName"
          :options="allStep"
          placeholder="请选择派工步骤"
          @change="initTableData"
          collapse-tags
          :props="{
            label: 'name',
            value: 'id',
            emitPath: false,
            children: 'child',
          }"
          clearable
          filterable
        ></el-cascader>
      </div>
      <div class="contract-work-screen-active"></div>
    </div>

    <div class="contract-work-table">
      <div class="contract-work-table-card">
        <div class="contract-work-table-card-tabs">
          <div
            :class="{
              'contract-work-table-card-tab': true,
              'is-active': tabindex == '0',
            }"
            @click="changeTab('0')"
          >
            我的任务
          </div>
          <div
            :class="{
              'contract-work-table-card-tab': true,
              'is-active': tabindex == '1',
            }"
            @click="changeTab('1')"
          >
            我的派工
          </div>
          <div
            :class="{
              'contract-work-table-card-tab': true,
              'is-active': tabindex == '2',
            }"
            @click="changeTab('2')"
          >
            历史任务
          </div>
          <div
            :class="{
              'contract-work-table-card-tab': true,
              'is-active': tabindex == '3',
            }"
            @click="changeTab('3')"
          >
            历史派工
          </div>
          <!-- <div class="contract-table-card-edit">
            <i class="el-icon-edit-outline" @click="updateTableMenu"></i>
          </div> -->
        </div>
        <div class="contract-work-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            @row-click="rowClick"
            style="width: 100%"
            height="98%"
            :row-style="rowStyle"
            v-loading="loading"
            @sort-change="sortChange"
          >
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <template v-for="(item, index) in tabMenu"
              ><el-table-column
                :key="index"
                :property="item.key"
                v-if="item.authority == 1"
                :fixed="index == 0"
                :label="item.name"
                :min-width="item.key == 'stepExecute' ? 150 : 100"
                :width="item.width"
                show-overflow-tooltip
                :sortable="
                  item.key == 'lastStepDoneTime' || item.key == 'orderTime'
                    ? 'custom'
                    : false
                "
              >
                <template slot-scope="scope">
                  <div v-if="item.key == 'clientName'" class="hri">
                    <i
                      class="el-icon-notebook-2"
                      @click.stop="showGenjin(scope.row)"
                    ></i>
                    {{ scope.row[item.key] }}
                  </div>
                  <div v-else-if="item.key == 'progress'" style="width: 130px">
                    <el-progress
                      :percentage="scope.row[item.key]"
                      :color="customColors"
                    ></el-progress>
                  </div>
                  <div v-else-if="item.key == 'services'" class="hri">
                    <!-- 服务项 -->
                    <div
                      v-for="(ddd, dd) in scope.row[item.key]"
                      :key="dd"
                      class="table-tip"
                      :style="{
                        borderColor: ddd.color,
                        color: ddd.color,
                      }"
                    >
                      <el-tooltip :content="ddd.tip" placement="top">
                        <div>{{ ddd.text }}</div>
                      </el-tooltip>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      item.key == 'serviceConsultant' || item.key == 'charge'
                    "
                    class="hri"
                  >
                    <!-- 服务顾问 -->
                    <div v-for="(ddd, dd) in scope.row[item.key]" :key="dd">
                      <span v-if="dd == 0">
                        {{ ddd }}
                      </span>
                      <span v-else>,{{ ddd }}</span>
                    </div>
                  </div>
                  <div v-else-if="item.key == 'signDepartment'" class="hri">
                    <!-- 签单人部门 -->
                    <div v-for="(ddd, dd) in scope.row[item.key]" :key="dd">
                      <span v-if="dd == 0">
                        {{ ddd.name }}
                      </span>
                      <span v-else>,{{ ddd.name }}</span>
                    </div>
                  </div>
                  <div v-else-if="item.key == 'stepExecute'" class="hri">
                    <!-- 当前步骤执行人 -->
                    <div v-for="(ddd, dd) in scope.row[item.key]" :key="dd">
                      <span v-if="dd == 0">
                        {{ ddd.label }}
                      </span>
                      <span v-else>,{{ ddd.label }}</span>
                    </div>
                  </div>
                  <div v-else-if="item.key == 'autoRenew'">
                    <!-- 自动续签 -->
                    <span v-if="scope.row[item.key] == '1'">是</span>
                    <span v-if="scope.row[item.key] == '0'">否</span>
                  </div>
                  <div v-else>
                    {{ scope.row[item.key] }}
                  </div>
                </template>
              </el-table-column></template
            >
          </el-table>
        </div>
        <div class="contract-work-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @choseProductTipOne="choseProductTipOne"
        @buttonOper="drawerOper"
        @addFollowRecord="followRecord"
        @handleAvatarSuccess="handleAvatarSuccess"
      ></my_dialog>
    </div>

    <!-- 右边抽屉 -->
    <div id="my-drawer" v-if="contractDrawerData.flag">
      <my_drawer
        :drawerData="contractDrawerData"
        :allFunctionButtons="allFunctionButtons"
        :model="'contractWork'"
        @show="showDrawer"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @editStep="editStep"
        @addStep="addStep"
        @doneStep="doneStep"
        @deleteStep="deleteStep"
        @detailStep="detailStep"
        @workOrder="workOrder"
        @saveDrawerData="updateWorkContractByID"
        @updateDrawerTitle="updateDrawerTitle"
        @drawerOper="drawerOper"
        @lineOpers="lineOper"
        @addTags="addTags"
        @tagsHandleClose="tagsHandleClose"
        @linkNow="linkNow"
        @linkNowFile="linkNowFile"
        @addExpend="addExpend"
        @showExpendDetails="showExpendDetails"
        @linkClitent="showClientByID"
        @showRelevanceClient="showRelevanceClient"
        @deleteRelevanceClient="deleteRelevanceClient"
        @addRelevanceClient="addRelevanceClient"
        @addProjectRecord="addProjectRecord"
      ></my_drawer>
    </div>
  </div>
</template>

<script>
import drager from "@/components/drager.vue";
import my_drawer from "@/components/my-drawer.vue";
import my_dialog from "@/components/dialog.vue";
import popo_search from "@/components/popo-search.vue";
import * as contractDrawData from "@/assets/js/contractDraw.js";
import * as clientInfoData from "@/assets/js/clientInfoData.js";
import * as allContractData from "@/views/contract/js/contractData.js";
import * as allClientData from "../client/js/clientData.js";
import { waitDealLocation } from "@/minixs.js";
export default {
  components: {
    drager,
    my_drawer,
    popo_search,
    my_dialog,
  },
  data() {
    return {
      rowStyle: this.$rowStyle,
      baseInfo: {},
      loading: true,
      tabindex: "0",
      whetherAgencyHead: false,
      searchData: {
        select_value: [],
        options: [],
        input: "",
        submitter: "",
        tempUserId: [],
        userIdList: [], // 全部员工id
        userList: [],
        submitter: "",
        time: "",
        currentStepName: "",
        lossReasonOptions: [],
      },
      buttonAuth: {
        add: 1,
      },
      tableData: [],
      //   tableData:[],
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tabMenu: contractDrawData.getContractWorkTableMenuData(),

      contractDrawerData: contractDrawData.contractWorkDrawerData("", [], []),
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      tempContract: { id: "", name: "" }, // 临时合同
      tempClient: {},
      buttonAuth: [],
      productList: [], // 所有合同模板/产品数据
      professionList: [], // 所有合同执行模板
      serviceItemList: [], // 所有服务项数据
      employeeList: [], // 所有员工
      allEmployeeList: [], // 所有在职员工
      departmentList: [], // 所有员工
      clientList: [], // 所有客户
      contractSettingButtonList: [],
      tipsData: {
        clientTipsList: [], // 客户标签
        pianquList: [], // 片区标签
        clientLevelList: [], // 客户等级
        sourceList: [], // 客户来源
      },
      defaultSort: { prop: "orderTime", order: "descending" },
      roleList: [], // 业务角色
      usersList: [], // 员工列表
      accountList: [], // 收款账号
      tempStep: {
        // 临时步骤存储
        data: {},
        index: 0,
      },
      ttempClient: {},
      tempClientName: "",

      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "contractWork"
      ),
      contractDrawerOptions: this.$tagData.getDrawerOptionsList("contractWork"), // 抽屉中的下拉框配置项
      contractDrawerAuthorityData: [],
      contractDrawerDataAuthorityList: [],

      clientDrawerOptions: this.$tagData.getDrawerOptionsList("clientInfo"), // 客户抽屉中的下拉框配置项
      clientDrawerAuthorityData: [],
      clientDrawerDataAuthorityList: [],
      list: [],
      clientListOption: [],
      serviceTypeList: [],
      customColors: [
        { color: "#c3efab", percentage: 25 },
        { color: "#a8d093", percentage: 50 },
        { color: "#8bc76d", percentage: 75 },
        { color: "#7cc756", percentage: 99 },
        { color: "#67C23A", percentage: 100 },
      ],
      allStep: [],
    };
  },
  mixins: [waitDealLocation],
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "contractWork",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    // 获取客户的按钮权限
    getClientModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "clientInfo",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getClientList();
        this.getTagList();
        this.initMenu();
      });
    },
    /**
     * 获取客户名称列表
     */
    getClientList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/client/getAllOptions",
        requestData,
        (data) => {
          this.clientListOption = data;
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    initMenu() {
      // this.$featureData.getAttributeData(
      //   "contractWork",
      //   true,
      //   [],
      //   (attributeData) => {
      //     console.log("合同派工菜单:", attributeData);
      //     this.$menuData.getModelMenuAndAuthority(
      //       "contractWork",
      //       attributeData,
      //       this,
      //       (data) => {
      //         this.tabMenu = data;
      //         console.log(this.tabMenu);

      this.getBusinessAuthorityList();
      console.log("查看当前menu", this.tabMenu);
      this.$tagData.getTagList(
        this.contractDrawerOptions,
        ["客户标签", "片区", "客户来源", "客户等级", "增值税类型"],

        this.baseInfo.company.companyId,
        this
      );
      this.$tagData.getTagList(
        this.clientDrawerOptions,
        ["片区", "客户来源", "增值税类型", "客户等级", "客户标签"],
        this.baseInfo.company.companyId,
        this
      );
    },
    // 获取当前所需标签
    getTagList() {
      let requestData = {
        type: ["流失原因"],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          console.log("当前获取到的标签组:", data);
          for (let index = 0; index < data.length; index++) {
            const element = data[index];

            if (element.type == "流失原因") {
              this.searchData.lossReasonOptions = element.value;
            }
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有业务角色
     */
    getBusinessAuthorityList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/role/getBusinessAuthorityList",
        requestData,
        (data) => {
          this.roleList = [];
          data.forEach((element) => {
            let dd = {
              label: element.authorityName,
              value: element.authorityId,
            };
            this.roleList.push(dd);
          });
          this.getEmployeeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有员工列表
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.employeeList = [];
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
            this.employeeList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            label: "全部人员",
            value: "all",
          });
          this.searchData.submitter = this.baseInfo.userId;
          this.selectInitTableData(this.baseInfo.userId);
          this.getEmployeeListForDownBox();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有在职员工
     */
    getEmployeeListForDownBox() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.allEmployeeList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.allEmployeeList.push(dd);
          });
          this.getDepartmentList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有部门列表
     */
    getDepartmentList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/department/getDepartmentList",
        requestData,
        (data) => {
          this.departmentList = [];
          data.forEach((element) => {
            let dd = {
              label: element.departmentName,
              value: element.departmentId,
            };
            this.departmentList.push(dd);
          });
          this.getAllProductList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有合同模板/产品列表
     */
    getAllProductList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        name: "", // 服务产品名称 模糊查询
        type: "",
        currentPage: 1, // 当前页数
        pageSize: 1000, // 每页条数
      };
      this.$request(
        "post",
        "/product/getAllProduct",
        requestData,
        (data) => {
          this.productList = data.tableData;
          this.queryStep();
          this.getServicesItem();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    queryStep() {
      this.$request(
        "get",
        "/profession/queryStep?companyId=" + this.baseInfo.company.companyId,
        {},
        (data) => {
          this.allStep = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有服务项列表
     */

    getServicesItem() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        status: "1",
        name: "", // 服务产品名称 模糊查询
        type: "",
      };

      this.$request(
        "post",
        "/servicesItem/getServicesItem",
        requestData,
        (data) => {
          this.serviceItemList = data;
          this.getAllClientByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有客户列表
     */
    getAllClientByUserId() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        type: "全部客户", // 与业务角色对应(对应关系暂定 20210618)
        hasLeave: "0", // 是否包含流失,"0"为否,"1"为是
        currentPage: 1, // 当前页数
        pageSize: 1000, // 每页条数
        search: "",
      };
      this.$request(
        "post",
        "/client/getAllClientByCompany",
        requestData,
        (data) => {
          // this.clientList = data.tableData;
          this.clientList = [];
          data.forEach((element) => {
            let dd = {
              id: element.clientId,
              clientName: element.clientName,
            };
            this.clientList.push(dd);
          });
          this.getProfessionList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有合同执行列表
     */
    getProfessionList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        departmentId: "all",
      };
      this.$request(
        "post",
        "/profession/getProfessionList",
        requestData,
        (data) => {
          this.professionList = data;
          this.searchData.options = [];
          data.forEach((element) => {
            let dd = {
              label: element.name,
              value: element.id,
            };
            this.searchData.options.push(dd);
          });
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        userId: this.baseInfo.userId,
        userIdList: this.searchData.tempUserId, // 当前筛选人
        time: this.searchData.time, // 当前筛选人
        companyId: this.baseInfo.company.companyId, // 当前公司ID
        flag: this.tabindex,
        search: this.searchData.select_value, // 业务类型
        currentStepName: this.searchData.currentStepName, // 筛选项 当前步骤
        clientName: this.searchData.input,
        sortProp: this.defaultSort.prop, // 手动排序项
        sortOrder: this.defaultSort.order, // 手动排序规则 ascending descending ""
        pageSize: this.pageData.pageSize, // 每页条数
        currentPage: this.pageData.currentPage, // 当前页数
      };
      this.$request(
        "post",
        "/contract/getAllWorkContract",
        requestData,
        (data) => {
          this.pageData.total = data.total; // 获取总条目数
          this.tableData = data.tableData;
          this.$nextTick(() => {
            this.$refs.singleTable.doLayout();
          });
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    handleClick(data, flag) {},

    /**
     * 卡片切换事件
     */
    changeTab(flag) {
      if (this.loading == false) {
        this.tabindex = flag;
        this.initTableData();
      }
    },
    /**
     * 表格单行点击事件
     */
    rowClick(row, column, event) {
      if (!this.contractDrawerData.flag) {
        //防止重复点击
        this.tempContract.id = row.id;
        this.tempContract.name = row.clientName;
        this.ttempClient = row;
        this.tempClientName = row.clientName;
        this.showContractInfoByID();
        console.log("打开抽屉:", this.contractDrawerData);
      } else {
        this.closeUpdateTableDrawer();
      }
    },
    showContractInfoByID() {
      // 1.拿到合同的按钮权限
      this.getModelFeatureAuthority();
      setTimeout(() => {
        this.$featureData.getAttributeData(
          "contractWork",
          false,
          allContractData.returnDrawerKeys("合同派工"),
          (authorityData) => {
            console.log("4444", authorityData);
            this.contractDrawerAuthorityData = authorityData;
            // 2.拿对应属性的权限
            this.$authority.getModelFeatureAuthority(
              this,
              "contractWork",
              authorityData,
              (authorityList) => {
                this.contractDrawerDataAuthorityList = authorityList;
                // this.showContractInfoByID();
                let requestData = {
                  contractId: this.tempContract.id,
                  userId: this.baseInfo.userId,
                  companyId: this.baseInfo.company.companyId,
                  authorityData: this.$initDrawerAuthorityData.getKeysByAuthorityList(
                    this.contractDrawerAuthorityData
                  ),
                };
                this.$request(
                  "post",
                  "/contract/getWorkContractByID",
                  requestData,
                  (data) => {
                    data.data.itemRecord = {
                      title: "项目记录",
                      list: data.data.itemRecord,
                    };
                    let requestData2 = {
                      companyId: this.baseInfo.company.companyId,
                      contractId: this.tempContract.id,
                    };
                    // 获取具体服务项
                    this.$request(
                      "post",
                      "/profession/getAllProfessionByServiceType",
                      requestData2,
                      (data2) => {
                        this.serviceTypeList = data2.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        });
                        // 3.将权限数据对应到界面
                        let butText = {};
                        this.contractDrawerData = allContractData.contractInfoDrawerData(
                          data.data.clientName,
                          this.contractDrawerDataAuthorityList,
                          this.whetherAgencyHead, // 是否是机构负责人
                          this.contractDrawerOptions,
                          "合同派工",
                          butText,
                          this.serviceTypeList
                        );
                        console.log(
                          "contractDrawerDataAuthorityList",
                          this.contractDrawerDataAuthorityList
                        );
                        // this.tempSign = data.data.sign;
                        this.contractDrawerData = this.$initDrawerAuthorityData.initDrawerData(
                          this.contractDrawerData,
                          data.data
                        );
                        this.contractDrawerData.status = data.submitFlag
                          ? "可提交"
                          : "不可提交";
                        allContractData.initData(
                          data,
                          this.contractDrawerData,
                          this.allFunctionButtons,
                          this.whetherAgencyHead
                        );

                        this.contractDrawerData.flag = true;
                      },
                      (errorMessage) => {
                        this.$message.error(errorMessage);
                      }
                    );
                  },
                  (errorMessage) => {
                    this.$message.error(errorMessage);
                  }
                );
                // this.contractDrawerData.flag = true;
              }
            );
          }
        );
      }, 0);
    },
    /**
     * 点击客户
     */
    showClient(row) {
      console.log("打开客户抽屉", row);
      this.tempContract.id = row.id;
      this.tempContract.name = row.clientName;
      this.tempClient.value = row.clientId;
      this.ttempClient = row;
      this.showClientByID();
    },
    /**
     * 根据客户ID,查看客户信息
     */
    showClientByID() {},
    /**
     * 查看客户的关联客户
     */
    showRelevanceClient() {
      let requestData = {
        userId: this.baseInfo.userId,
        clientId: this.tempClient.value,
      };
      this.$request(
        "post",
        "/client/getAllRelevanceClientListByClientId",
        requestData,
        (data) => {
          this.contractDrawerData.data.gridData = data;
          this.contractDrawerData.data.customer = data.length;
        }
      );
    },
    /**
     * 手动解除关联客户
     */
    deleteRelevanceClient(id) {
      console.log("手动解除关联客户");
      let requestData = {
        clientId: this.tempClient.value,
        relevanceClientId: id,
      };
      this.$request(
        "post",
        "/client/removeRelevanceClientByClientId",
        requestData,
        (data) => {
          this.$message({
            message: "关联客户解除成功!",
            type: "success",
          });
          this.showRelevanceClient();
        }
      );
    },
    /**
     * 手动添加关联客户
     */
    addRelevanceClient() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        clientId: this.tempClient.value,
      };
      this.$request(
        "post",
        "/client/getNoAssociationClient",
        requestData,
        (data) => {
          let options = [];
          data.forEach((element) => {
            let dd = {
              label: element.clientName,
              value: element.clientId,
            };
            options.push(dd);
          });
          this.dialogData.title = "手动添加关联客户";
          this.dialogData.width = "40%";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push(
            {
              text: "确定",
              active: "makesure_relevance",
            },
            {
              text: "取消",
              active: "cancel",
            }
          );
          this.dialogData.data = [
            {
              type: "select-object-collapse",
              title: "",
              value: [],
              options: options,
              placeholder: "选择客户",
            },
          ];
          this.dialogData.flag = true;
        }
      );
    },
    /**
     * 确定手动关联客户
     */
    makesureRelevance() {
      let list = [];
      this.dialogData.data[0].value.forEach((element) => {
        list.push(element.value);
      });
      let requestData = {
        clientId: this.tempClient.value, // 当前客户
        relevanceClient: list, // 要关联的客户数组
      };
      this.$request(
        "post",
        "/client/setRelevanceClientByClientId",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.$message({
            message: "手动关联客户成功!",
            type: "success",
          });
          this.showRelevanceClient();
        }
      );
    },
    /**
     * 根据客户ID,查看客户联系人
     */
    showClientContactDataByID() {},
    /**
     * 显示合同列表
     */
    getAllContractByClientId() {},
    /**
     * 显示支出详情
     */
    showExpendDetails(data) {
      console.log("显示支出详情:", data, this.tempContract.name);
      let dd = {
        client: this.tempContract.name,
        contractNum: this.tempContract.name,
        contractId: this.tempContract.id,
        expendStatus: data.expendStatus,
        expendNum: data.expendNum,
        time: data.time,
        expendType: data.expendType,
        user: data.user,
        title: data.title,
        file: data.file, // 附件
        services: data.services,
      };
      this.dialogData.title = "支出";
      this.dialogData.width = "40%";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "取消",
        active: "cancel_expend",
      });
      this.dialogData.data = [
        {
          type: "expend_details",
          title: "",
          value: dd,
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 右边抽屉父子组件连锁事件
     */
    showDrawer(value) {
      this.contractDrawerData = value;
    },
    /**
     * 编辑步骤
     */
    editStep(data) {
      if (
        this.contractDrawerData.data.modelList[1].line[0].isShow &&
        !this.contractDrawerData.data.modelList[1].line[0].isDisable
      ) {
        this.tempStep = JSON.parse(JSON.stringify(data));
        this.updateWorkContractByID("");
      } else {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
      }
    },
    /**
     * 步骤插入
     */
    addStep(data) {
      if (
        this.contractDrawerData.data.modelList[1].line[0].isShow &&
        !this.contractDrawerData.data.modelList[1].line[0].isDisable
      ) {
        console.log("插入步骤", data);
        this.tempStep = JSON.parse(JSON.stringify(data));
        this.dialogData.title = "插入步骤";
        this.dialogData.data = [
          {
            title: "步骤名称",
            type: "input",
            placeholder: "请输入步骤名称",
            value: "",
          },
          {
            title: "步骤描述",
            type: "input",
            placeholder: "请输入步骤描述",
            value: "",
          },
          // {
          //   title: "执行角色",
          //   type: "select-search-url",
          //   placeholder: "请选择执行角色",
          //   value: this.tempStep.data.role,
          //   options: this.roleList,
          // },
          {
            title: "执行员工",
            type: "select-object",
            placeholder: "请选择执行员工",
            value: "",
            options: this.allEmployeeList,
          },
        ];
        this.dialogData.buttonArray = [
          {
            text: "确认",
            active: "add_step",
          },
          {
            text: "取消",
            active: "cancel",
          },
        ];
        this.dialogData.flag = true;
      } else {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
      }
    },
    /**
     * 步骤完成
     */
    doneStep(data) {
      if (
        this.contractDrawerData.data.modelList[1].line[0].isShow &&
        !this.contractDrawerData.data.modelList[1].line[0].isDisable
      ) {
        this.tempStep = JSON.parse(JSON.stringify(data));
        console.log("111", this.tempStep);
        this.dialogData.title = "项目步骤说明";
        this.dialogData.buttonArray = [
          {
            text: "确认",
            active: "work_submit",
          },
          {
            text: "取消",
            active: "cancel",
          },
        ];
        this.dialogData.data = [
          {
            title: "步骤名称",
            type: "text",
            value: this.tempStep.data.name,
          },
          {
            title: "完成时间",
            type: "text",
            value: this.$formatTime(new Date(), "YYYY-MM-DD HH:mm:ss"),
          },
          {
            title: "上传附件",
            type: "upload",
            value: {
              value: "",
              action: this.$fileBaseurl + "/uploadImageByMultipartFile",
              headers: {
                token: "95931b371eb64f4a9c862983ca8a5d15",
              },
              uploadParameter: {
                systemName: "finance",
                title: "合同派工附件",
              },
            },
          },
          {
            title: "推送给客户",
            type: "radio",
            value: "",
            options: [
              {
                value: 1,
                label: "微信小程序推送",
              },
              {
                value: 2,
                label: "发送短信消息",
              },
            ],
          },
          {
            title: "完成备注",
            type: "textarea",
            value: "",
          },
        ];

        this.dialogData.flag = true;
      } else {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
      }
    },
    /**
     * 步骤删除
     */
    deleteStep(data) {
      console.log("deleteStep", data);
      let requestData = {
        contractId: this.ttempClient.id, // 合同 id
        index: data.index, // 要删除的步骤的索引
      };
      if (
        this.contractDrawerData.data.modelList[1].line[0].isShow &&
        !this.contractDrawerData.data.modelList[1].line[0].isDisable
      ) {
        console.log(
          "this.contractDrawerData.data",
          this.contractDrawerData.data
        );
        this.$confirm("确认要删除该步骤吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$request(
              "post",
              "/contract/delStep",
              requestData,
              (data) => {
                this.$operationRecords.saveOperationRecords(
                  this.tempContract.id,
                  "删除步骤!",
                  this.baseInfo,
                  "contract",
                  this,
                  (data2) => {
                    this.$message.success("删除成功!");
                    this.initTableData();
                    this.showContractInfoByID();
                  }
                );
              },
              (errormessage) => {
                this.$message.error(errormessage);
              }
            );
          })
          .catch(() => {});
      } else {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
      }
    },
    /**
     * 步骤详情
     */
    detailStep(data) {
      this.tempStep = JSON.parse(JSON.stringify(data));
      let requestData = {
        contractId: this.tempContract.id, // 合同id
        stepId: this.tempStep.data.id, // 第几个步骤
      };
      this.$request(
        "post",
        "/contract/getStepDoneInfo",
        requestData,
        (data) => {
          this.dialogData.width = "500px";
          this.dialogData.title = "项目步骤说明";
          this.dialogData.data = [
            {
              title: "步骤名称",
              type: "text",
              value: data.stepName,
            },
            {
              title: "完成人",
              type: "text",
              value: data.operatorName,
            },
            {
              title: "完成时间",
              type: "text",
              value: data.time,
            },
            {
              title: "附件",
              type: "show_img",
              value: data.annex,
            },
            {
              title: "推送给客户",
              type: "text",
              value:
                data.push == 0
                  ? "未推送"
                  : data.push == 1
                  ? "推送到微信公众号"
                  : "发送到短信",
            },
            {
              title: "完成备注",
              type: "text",
              value: data.remark,
            },
          ];
          this.dialogData.buttonArray = [];
          this.dialogData.flag = true;
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    /**
     * 派工
     */
    workOrder(data) {
      if (
        this.contractDrawerData.data.modelList[1].line[0].isShow &&
        !this.contractDrawerData.data.modelList[1].line[0].isDisable
      ) {
        this.tempStep = JSON.parse(JSON.stringify(data));
        console.log("tempStep", this.tempStep);

        this.dialogData.title = "派工";
        this.dialogData.buttonArray = [
          {
            text: "确认",
            active: "sure_dispatch",
          },
          {
            text: "取消",
            active: "cancel",
          },
        ];
        this.dialogData.data = [
          {
            title: "公司名称",
            type: "select",
            value: this.ttempClient.clientId,
            options: this.clientListOption,
          },
          {
            title: "派工内容",
            type: "input",
            value: "",
          },
          {
            title: "办事地点",
            type: "cascader",
            locationId: "",
            placeholder: "请选择办事地点",
            options: this.list,
            props: {
              multiple: false,
              value: "id",
              label: "name",
              emitPath: false,
            },
            collapse_tags: true,
          },
          {
            title: "执行人",
            type: "select-object",
            value: "",
            options: this.allEmployeeList,
          },
          {
            title: "待办时间",
            type: "times",
            value: [],
            pickerOptions: [],
            value_format: "yyyy-MM-dd",
          },
          {
            title: "加急",
            type: "radio",
            value: 0,
            options: [
              {
                label: "是",
                value: 1,
              },
              {
                label: "否",
                value: 0,
              },
            ],
          },
          {
            title: "备注",
            type: "textarea",
            value: "",
          },
        ];

        this.dialogData.flag = true;
      } else {
        this.$message.error(
          this.$errorMessage.getErrorMessageInfo("authority")
        );
      }
    },
    addProjectRecord(textarea) {
      let requestData = {
        contractId: this.tempContract.id,
        clientId: this.ttempClient.clientId,
        userId: this.baseInfo.userId,
        content: textarea,
      };
      if (requestData.content == "") {
        return this.$message.error("请输入项目记录内容");
      }
      this.$request(
        "post",
        "/contractProjectRecords/add",
        requestData,
        (data) => {
          this.$operationRecords.saveOperationRecords(
            this.tempContract.id,
            "添加项目记录!",
            this.baseInfo,
            "contract",
            this,
            (data2) => {
              this.$message.success("项目记录添加成功!");
              // this.initTableData();
              this.showContractInfoByID();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    handleAvatarSuccess(data) {
      this.dialogData.data[2].value.value = data.result.path;
    },
    /**
     * 弹出框或抽屉操作事件
     */
    drawerOper(active) {
      let dd = {};
      let requestData = {};
      switch (active) {
        case "sure_dispatch":
          // 添加自定义派工
          let executor = [];
          this.dialogData.data[3].value.forEach((item) => {
            executor.push(item.value);
          });
          requestData = {
            companyId: this.baseInfo.company.companyId,
            launch: this.baseInfo.userId, // 发起人 id
            clientId: this.dialogData.data[0].value, // 公司ID
            name: this.dialogData.data[1].value, // 派工内容
            executor: JSON.stringify(executor), // 执行人 id 集合
            operation: this.baseInfo.userId, // 操作人，当前登录用户 id
            type: "9", // 待办类型，目前业务传 "9" 即可
            locationId: this.dialogData.data[2].locationId, // 办事地点
            expectAndEndTime: this.dialogData.data[4].value, // 待办时间
            isUrgent: this.dialogData.data[5].value, // 是否加急 0 - 不加急 1 - 加急
            remark: this.dialogData.data[6].value, // 备注
            contractId: this.tempContract.id, // 关联的合同id
          };
          if (!requestData.name) {
            this.$message.error("派工内容必填!");
            return;
          }
          if (requestData.expectAndEndTime.length == 0) {
            this.$message.error("待办时间必填!");
            return;
          }
          this.$request(
            "post",
            "/waitDeal/add",
            requestData,
            (data) => {
              let requestData2 = {
                contractId: this.tempContract.id, // 合同 id
                index: this.tempStep.index, // 第几个步骤
                status: "dispatch", // 修改成什么状态：yes、no、done、dispatch
              };
              this.$request(
                "post",
                "/contract/updateStepStatus",
                requestData2,
                (data) => {
                  this.$operationRecords.saveOperationRecords(
                    this.tempContract.id,
                    "提交自定义派工!",
                    this.baseInfo,
                    "contract",
                    this,
                    (data2) => {
                      this.$message.success("派工成功!");
                      this.dialogData.flag = false;
                      this.initTableData();
                      this.showContractInfoByID();
                    }
                  );
                },
                (messageerror) => {
                  this.$message.error(errorMessage);
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "work_submit":
          requestData = {
            contractId: this.tempContract.id, // 合同ID
            info: {
              stepId: this.tempStep.data.id, // 步骤id
              stepName: this.dialogData.data[0].value, // 步骤名称
              time: this.dialogData.data[1].value, // 完成时间
              annex: this.dialogData.data[2].value.value, // 附件 url
              push: this.dialogData.data[3].value, // 是否推送给客户，1-微信公众号推送 2- 发送短信 0-不推送
              remark: this.dialogData.data[4].value, // 备注
              operatorId: this.baseInfo.userId, // 操作人 id
            },
          };
          if (this.whetherAgencyHead) {
            // 机构负责人 跳过校验
          } else {
            let flag = this.tempStep.data.users.some((item) => {
              if (item.value == this.baseInfo.userId) {
                return true;
              }
            });
            if (!flag) {
              this.$message.error("只有当前执行人可以完成此次派工步骤!");
              return;
            }
          }

          this.$request(
            "post",
            "/contract/executeStep",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                duration: 1000,
                type: "success",
              });
              // this.contractDrawerData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "提交派工!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.dialogData.flag = false;
                  this.initTableData();
                  this.showContractInfoByID();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "add_step":
          // 确认插入步骤
          console.log("确认插入步骤", this.dialogData.data);
          requestData = {
            contractId: this.ttempClient.id, // 合同 id
            step: {
              des: this.dialogData.data[1].value, // 步骤说明
              role: {},
              money: "",
              name: this.dialogData.data[0].value, // 步骤名称
              active: "no", // 是否已完成
              users: this.dialogData.data[2].value, // 执行人
            },
            index: this.tempStep.index, // 该步骤的索引
          };
          this.$request(
            "post",
            "/contract/addStep",
            requestData,
            (data) => {
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "插入步骤!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.$message.success("插入成功!");
                  this.dialogData.flag = false;
                  this.initTableData();
                  this.showContractInfoByID();
                }
              );
            },
            (err) => {
              this.$message.error(err);
            }
          );
          this.dialogData.flag = false;
          break;

        case "editClientName":
          // 编辑客户名称
          this.dialogData.title = "修改客户名称";
          this.dialogData.width = "30%";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "make_sure_update_client_name",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [
            {
              type: "text",
              title: "客户原名称",
              value: this.ttempClient.clientName,
            },
            {
              type: "input",
              title: "客户名称",
              value: "",
            },
          ];
          this.dialogData.flag = true;
          break;
        case "make_sure_update_client_name":
          // 确定编辑客户名
          requestData = {
            clientId: this.ttempClient.clientId,
            clientName: this.dialogData.data[1].value,
            companyId: this.baseInfo.company.companyId,
          };
          this.$request(
            "post",
            "/client/updateClientNameForContract",
            requestData,
            (data) => {
              this.$message.success("客户名称修改成功!");
              this.dialogData.flag = false;
              this.showContractInfoByID();
              this.initTableData();
            },
            (err) => {
              this.$message.error(err);
            }
          );
          break;
        case "role_authority":
          // 编辑客户角色权限
          requestData = {
            clientId: this.ttempClient.clientId,
          };
          this.$request(
            "post",
            "/client/getOneClientRole",
            requestData,
            (data) => {
              this.dialogData.title = "客户角色";
              this.dialogData.width = "50%";
              this.dialogData.buttonArray = [];
              this.dialogData.buttonArray.push({
                text: "确定",
                active: "make_sure_role_authority",
              });
              this.dialogData.buttonArray.push({
                text: "取消",
                active: "cancel",
              });
              let employeeList = [];
              this.allEmployeeList.forEach((element) => {
                let dd = {
                  id: element.value,
                  name: element.label,
                };
                employeeList.push(dd);
              });
              this.dialogData.data = allContractData.clientConnectDialogData(
                data,
                employeeList
              );
              console.log(
                "lllllllllllllllll",
                this.dialogData,
                this.employeeList
              );
              this.dialogData.flag = true;
            }
          );

          break;
        case "make_sure_role_authority":
          // 确定修改客户业务角色
          requestData = {
            clientId: this.ttempClient.clientId,
            role: allContractData.formateClientConnectDialogData(
              this.dialogData.data
            ),
          };
          this.$request(
            "post",
            "/client/updateOneClientRole",
            requestData,
            (data) => {
              this.$message.success("客户业务角色修改成功!");
              this.dialogData.flag = false;
            }
          );
          break;
        case "create-contract":
          // 新建合同
          this.dialogData.title = "选择产品";
          this.dialogData.width = "50%";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [
            {
              type: "new-contract",
              title: "",
              value: { clientName: this.tempClient.label },
            },
          ];
          this.dialogData.flag = true;
          break;
        case "huikuan":
          // 收款记录
          this.getReceiptRecord();
          break;
        case "shoufei":
          // 收费情况
          this.getCharge();
          break;
        case "zhichu":
          // 合同支出
          this.getExpendByContractId();
          break;
        case "fujian":
          // 合同附件
          this.getAttachmentByContractId();
          break;
        case "add_huikuan":
          // 添加回款
          console.log("该合同所有服务项:", this.tempServices);
          let options = [];
          let accountList = [];
          this.tempServices.forEach((element) => {
            let ll = {
              label: element.name,
              value: element.name,
            };
            options.push(ll);
          });
          this.accountList.forEach((element) => {
            let accountList1 = "";
            element.accountList.forEach((account) => {
              accountList1 =
                accountList1 == ""
                  ? account.account
                  : accountList1 + "," + account.account;
            });

            let dd = {
              label: element.owner,
              value: accountList1, // 拿到该收款商户对应的收款账号组
            };
            accountList.push(dd);
          });
          this.dialogData.title = "添加回款信息";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push(
            {
              text: "确定",
              active: "sure_huikuan",
            },
            {
              text: "取消",
              active: "cancel",
            }
          );
          this.dialogData.data = [
            {
              type: "input_disable",
              title: "客户名称",
              value: this.tempContract.name,
            },
            {
              type: "input",
              title: "回款金额",
              value: "",
            },
            {
              type: "select",
              title: "回款服务项",
              value: "",
              options: options,
            },
            {
              type: "select",
              title: "收款账户",
              value: "",
              options: accountList,
            },
            {
              type: "input",
              title: "交易单号",
              value: "",
            },
            {
              type: "radio",
              title: "上传位置",
              value: "0",
              options: [
                {
                  label: "本地",
                  value: "0",
                },
                {
                  label: "云盘",
                  value: "1",
                },
              ],
            },
            {
              type: "upload",
              title: "付款凭证",
              value: {
                value: "", // 回款单附件
                action: this.$fileBaseurl + "/uploadImageByMultipartFile",
                // action: "http://114.55.138.13:8089/uploadImageByMultipartFile",
                headers: {
                  token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                  systemName: "finance",
                  title: "付款凭证附件",
                },
              },
            },
          ];
          this.dialogData.flag = true;

          break;
        case "sure_huikuan":
          // 确认添加回款信息
          requestData = {
            contractId: this.tempContract.id, // 合同ID
            addTime: this.$formatTime(new Date(), "YYYY-MM-DD HH:mm:ss"), // 回款时间
            money: this.dialogData.data[1].value, // 回款金额
            service: this.dialogData.data[2].value, // 回款服务项
            account: this.dialogData.data[3].value, // 回款账号
            receivedId: this.dialogData.data[4].value, // 回款账号
            file: this.dialogData.data[6].value.value, // 回款单附件
            userId: this.baseInfo.userId, // 当前操作人
            status: "待审核", // 回款状态
            companyId: this.baseInfo.company.companyId,
          };

          if (requestData.account == "") {
            this.$message({
              message: "回款账号不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.money == "") {
            this.$message({
              message: "回款金额不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.receivedId == "") {
            this.$message({
              message: "回款单号不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.service == "") {
            this.$message({
              message: "回款服务项不能为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (requestData.file == "") {
            this.$message({
              message: "请上传付款凭证!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          console.log("确认添加回款记录:", requestData);
          this.$request(
            "post",
            "/contract/chargeContract",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "添加回款!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.showContractInfoByID();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "submit":
          // 合同提交
          this.$confirm("确定合同提交?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.submitContract();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消提交",
                duration: 1000,
              });
            });

          break;
        case "add_serves":
          // 添加服务项
          dd = {
            flag: true,
            tableData: {},
            item: [
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "服务名称",
                type: "select",
                value: "",
                options: this.serviceItemList,
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "付费周期",
                type: "select",
                value: "每年",
                options: [
                  {
                    label: "每年",
                    value: "每年",
                    img: "",
                  },
                  {
                    label: "每月",
                    value: "每月",
                    img: "",
                  },
                  {
                    label: "每季度",
                    value: "每季度",
                    img: "",
                  },
                ],
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: true,
                title: "催费时间",
                type: "收费提醒选择",
                radio: "提前",
                beforeDay: "",
                lateDay: "",
                value: "",
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "优惠",
                type: "discounts",
                value: {
                  discount: "",
                  flag: "",
                  mitigate: "",
                  totalPrice: "",
                },
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "首次催费时间",
                type: "time",
                value: "",
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "服务总价",
                type: "input",
                value: "",
              },
              {
                horizontal: false,
                isShow: true,
                isDisable: false,
                title: "服务时间",
                type: "timeToTime",
                value: [
                  {
                    discounts: "",
                    money: "",
                    price: "",
                    time1: "2021-07",
                    time2: "2022-07",
                  },
                ],
              },
            ],
          };
          this.contractDrawerData.data.modelList[2].line[0].value.push(dd);
          break;
        case "add_free_serves":
          // 添加赠送的服务项
          if (!this.contractDrawerData.data.modelList[3].line[0].isDisable) {
            dd = {
              isEdit: true, // 是否是编辑状态
              flag: true, // 是否是新增
              serviceItem: {}, //服务项
              serviceTime: "", // 服务时间
            };
            this.contractDrawerData.data.modelList[3].line[0].value.push(dd);
          } else {
            this.$message({
              message: "当前合同状态不可编辑!",
              duration: 1000,
              type: "error",
            });
          }

          break;
        case "expend_submit":
          // 合同支出提交
          requestData = {
            contractId: this.tempContract.id, // 合同ID
            expendNum: this.dialogData.data[0].value.expendNum, // 支出编号
            expendType: this.dialogData.data[0].value.expendType, // 支出类型
            expendStatus: "完成", // 支出状态  待提交  待审核  完成  审核不通过
            time: this.$formatTime(new Date(), "YYYY-MM-DD HH:mm:ss"), // 确认支出时间  当状态为完成时自动填充
            title: this.dialogData.data[0].value.title, //收款账户
            file: this.dialogData.data[0].value.file, // 附件
            services: this.dialogData.data[0].value.services,
            user: this.baseInfo.userId,
          };
          if (requestData.title == "") {
            this.$message.error("支出标题不能为空!");
            return;
          }
          if (requestData.file == "") {
            this.$message.error("支出附件不能为空!");
            return;
          }
          if (requestData.services[0].expendMoney == "") {
            this.$message.error("支出金额不能为空!");
            return;
          }
          this.$request(
            "post",
            "/contract/updateExpend",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "添加支出!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.getExpendByContractId();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "expend_echeck":
          // 合同支出审核
          requestData = {
            contractId: this.tempContract.id, // 合同ID
            expendNum: this.dialogData.data[0].value.expendNum, // 支出编号
            expendType: this.dialogData.data[0].value.expendType, // 支出类型
            expendStatus: "完成", // 支出状态  待提交  待审核  完成  审核不通过
            time: this.dialogData.data[0].value.time, // 确认支出时间  当状态为完成时自动填充
            receiptAccount: this.dialogData.data[0].value.receiptAccount, //收款账户
            receiptName: this.dialogData.data[0].value.receiptName, // 收款人姓名
            blank: this.dialogData.data[0].value.blank, // 开户银行  如徽商银行等
            des: this.dialogData.data[0].value.des, // 支出备注
            file: this.dialogData.data[0].value.file, // 附件
            services: this.dialogData.data[0].value.services,
          };
          this.$request(
            "post",
            "/contract/updateExpend",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "合同支出审核!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.getExpendByContractId();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "delete_contract":
          // 删除合同
          this.$request(
            "post",
            "/contract/beforeDelContract",
            { contractId: this.tempContract.id },
            (data) => {
              if (data.flag) {
                // 正常删除  当前合同没有收款记录
                this.deleteContract();
              } else {
                // 有收款记录,提示删除
                this.$confirm("该合同已有收款记录,是否继续删除?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(() => {
                    this.deleteContract();
                  })
                  .catch(() => {});
              }
            }
          );
          break;
        /**
         * 客户抽屉操作
         */
        case "info":
          if (this.contractDrawerData.title == "客户") {
            this.showClientByID();
          }
          if (this.contractDrawerData.title == "合同") {
            this.showContractDetailsById();
          }
          break;
        case "end_the_manual":
          // 手动结束合同
          this.$confirm("该合同未到期或未全部回款, 是否继续结束?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/contract/setContractFinish",
                { contractId: this.tempContract.id },
                (data) => {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                    duration: 1000,
                  });
                  this.contractDrawerData.flag = false;
                  this.initTableData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                  this.dialogData.flag = false;
                }
              );
            })
            .catch(() => {});
          break;
        case "hetong":
          this.getAllContractByClientId();
          break;
        case "lianxiren":
          this.showClientContactDataByID();
          break;

        case "newContact":
          let temp = {
            line: [
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "姓名",
                type: "input-but",
                value: "",
                buts: [
                  {
                    text: "公开",
                    active: "gongkai",
                  },
                  {
                    text: "删除",
                    active: "shanchu",
                  },
                ],
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "职位",
                type: "select",
                value: "老板",
                options: [
                  {
                    label: "老板",
                    value: "老板",
                  },
                  {
                    label: "法人",
                    value: "法人",
                  },
                  {
                    label: "股东",
                    value: "股东",
                  },
                  {
                    label: "会计",
                    value: "会计",
                  },
                  {
                    label: "其他",
                    value: "其他",
                  },
                ],
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "电话",
                type: "input",
                value: "",
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "手机",
                type: "input",
                value: "",
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "邮件",
                type: "input",
                value: "",
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "备注",
                type: "input",
                value: "",
              },
            ],
          };
          this.contractDrawerData.data.modelList.push(temp);
          break;
        case "create_contract":
          // 新建合同
          this.drawerOper("create-contract");
          break;
        case "cancel_expend":
          this.dialogData.flag = false;
          this.getExpendByContractId();
          break;
        case "delete_client":
          // 删除客户
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_delete_client",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "删除,同时删除其对应商机",
            value: this.tempClientName,
          });
          this.dialogData.flag = true;
          break;
        case "makesure_delete_client":
          // 确定删除
          requestData = {
            clientId: this.tempClient.value,
            status: 0,
            userId: this.baseInfo.userId,
            companyId: this.baseInfo.company.companyId,
          };
          this.$request(
            "post",
            "/client/deleteClientStatus",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.contractDrawerData.flag = false;
              this.$message({
                message: "客户删除成功,删除的客户可在客户回收站中还原!",
                duration: 1000,
                type: "success",
              });

              this.$operationRecords.saveOperationRecords(
                this.tempClient.value,
                "删除客户!",
                this.baseInfo,
                "client",
                this,
                (data2) => {
                  this.initTableData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "run_off":
          // 申请代账流失
          this.toApplyForCustomerChurn();
          break;
        case "confirm_that_client_loss":
          // 确定客户流失
          dd = {
            typeName: this.dialogData.data[0].value.label, // 服务类型名称
            id: this.dialogData.data[0].value.value, // 服务类型ID
          };
          requestData = {
            clientId: this.tempClient.value,
            serviceType: dd,
            lossReason: this.dialogData.data[1].value,
            userId: this.baseInfo.userId,
            companyId: this.baseInfo.company.companyId,
          };
          this.$request(
            "post",
            "/client/clientChurnByClientId",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.contractDrawerData.flag = false;
              this.$message({
                message: "成功申请客户流失!",
                duration: 1000,
                type: "success",
              });

              this.$operationRecords.saveOperationRecords(
                this.tempClient.value,
                "申请客户流失!",
                this.baseInfo,
                "client",
                this,
                (data2) => {
                  this.initTableData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;

        case "pause_serve":
          // 暂停代账服务

          break;
        case "zhuanweifeidaizhang":
          // 转为非代账
          break;
        case "get_companyInfo":
          // 获取工商信息
          this.getCompanyInfo();
          break;
        case "genjin":
          // 添加跟进记录
          this.showGenjin(this.ttempClient);
          break;

        case "makesure_relevance":
          // 确定手动添加关联客户
          this.makesureRelevance();
          break;
        case "adding_associated_customer":
          // 手动添加关联客户
          this.addRelevanceClient();
          break;
        case "operating_record":
          // 查看客户的操作记录
          this.$operationRecords.showOperationRecords(
            this.tempClient.value,
            "client",
            this
          );
          break;
        case "operating_record_contract":
          // 查看合同的操作记录
          this.$operationRecords.showOperationRecords(
            this.tempContract.id,
            "contract",
            this
          );
          break;
        case "cancel":
          // 取消
          this.colseDialog();
          break;

        default:
          break;
      }
    },
    /**
     * 合同删除
     */
    deleteContract() {
      let requestData = {
        contractId: this.tempContract.id,
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$confirm("此操作将永久删除该合同, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request(
            "post",
            "/contract/deleteContract",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "删除成功",
                duration: 1000,
              });
              this.contractDrawerData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "合同删除!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.initTableData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /**
     * 获取收款记录
     */
    getReceiptRecord() {
      console.log("获取回款记录");
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getReceiptRecord",
        requestData,
        (data) => {
          this.contractDrawerData = contractDrawData.contractReturnedMoneyDrawerData(
            this.tempContract.name,
            data,
            this.contractSettingButtonList
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 查看具体附件内容
     */
    linkNowFile(data) {
      if (data.item.fileType == "img") {
        // 附件是图片
        this.dialogData.title = "查看收款附件";
        this.dialogData.width = "30%";
        this.dialogData.data = [
          {
            type: "show_img",
            value: data.item.file,
            title: "",
          },
        ];
        this.dialogData.buttonArray = [];
        this.dialogData.flag = true;
      } else if (data.item.file != "") {
        // 附件是文件
        // window.open(data.item.file, "_blank");

        if (this.$keyType.keyType(data.item.file) == "Object") {
          window.open(data.item.file.path, "_blank");
        } else if (this.$keyType.keyType(data.item.file) == "String") {
          window.open(data.item.file, "_blank");
        } else {
          this.$message.error("附件格式错误,请联系管理员!");
        }
      }
    },
    /**
     * 获取收费情况
     */
    getCharge() {
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getCharge",
        requestData,
        (data) => {
          this.contractDrawerData = contractDrawData.contractShouFeiDrawerData(
            this.tempContract.name,
            data,
            this.contractSettingButtonList
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取合同支出情况
     */
    getExpendByContractId() {
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getExpendByContractId",
        requestData,
        (data) => {
          this.contractDrawerData = contractDrawData.contractExpendDrawerData(
            this.tempContract.name,
            // contractDrawData.getContractExpendData()
            data,
            this.buttonAuth
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 添加支出
     */
    addExpend() {
      let options = [
        {
          id: "",
          name: "刻章",
          flag: false,
        },
        {
          name: "地址费",
          flag: false,
        },
        {
          name: "材料费",
          flag: false,
        },
        {
          name: "外包成本",
          flag: false,
        },
        {
          name: "其他成本",
          flag: false,
        },
        {
          name: "业务招待费",
          flag: false,
        },
      ];
      this.dialogData.title = "新建支出";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "取消",
        active: "cancel",
      });
      this.dialogData.data = [
        {
          type: "text",
          title: "",
          value: "请选择一个支出类型",
        },
        {
          type: "select_array_one",
          title: "",
          value: options,
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 获取合同附件数据
     */
    getAttachmentByContractId() {
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getAttachmentByContractId",
        requestData,
        (data) => {
          this.contractDrawerData = contractDrawData.contractEttachmentDrawerData(
            this.tempContract.name,
            data,
            this.buttonAuth,
            this
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 合同提交
     */
    submitContract() {
      if (this.contractDrawerData.data.modelList[0].line[5].value == "") {
        this.$message({
          message: "合同成本不能为空!",
          duration: 1000,
          type: "error",
        });
        return;
      }
      let requestData = {
        userId: this.baseInfo.userId,
        id: this.tempContract.id,
        // professionId: data.id, // 执行流程IDID
      };
      this.$request(
        "post",
        "/contract/submitContract",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.contractDrawerData.flag = false;
          this.$message({
            message: "合同提交成功!",
            duration: 1000,
            type: "success",
          });
          this.$operationRecords.saveOperationRecords(
            this.tempContract.id,
            "提交合同!",
            this.baseInfo,
            "contract",
            this,
            (data2) => {
              this.initTableData();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    linkNow(dd) {
      console.log("llllll", dd);
      this.tempContract.id = dd.item.id;
      this.showContractDetailsById();
    },

    /**
     * 客户
     */
    /**
     * 申请客户流失
     */
    toApplyForCustomerChurn() {
      let requestData = {
        clientId: this.tempClient.value,
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/client/getAllServiceTypeListByClientId",
        requestData,
        (data) => {
          this.dialogData.title = "选择流失类型";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push(
            {
              text: "确定",
              active: "confirm_that_client_loss",
            },
            {
              text: "取消",
              active: "cancel",
            }
          );

          let options = [];
          data.forEach((element) => {
            let dd = {
              label: element.typeName,
              value: element.id,
            };
            options.push(dd);
          });
          let options2 = [];
          this.searchData.lossReasonOptions.forEach((element) => {
            let dd = {
              label: element.name,
              value: element.value,
            };
            options2.push(dd);
          });
          this.dialogData.data = [
            {
              title: "选择流失类型",
              type: "select-obj",
              value: { label: "全部", value: "all" },
              options: options,
            },
            {
              title: "流失原因",
              type: "select",
              value: "",
              options: options2,
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 添加跟进记录
     */
    addFollowRecord() {
      let requestData = {
        clientId: this.tempClient.value, // 商机ID
        userId: this.baseInfo.userId, // 操作人ID
        content: this.dialogData.data[0].value, // 当前操作
        time: this.dialogData.data[1].value, // 下次跟进时间
      };
      // if (requestData.time == "") {
      //   this.$message.error("下次跟进时间不能为空!");
      //   return;
      // }
      this.$request(
        "post",
        "/followRecord/addFollowRecord",
        requestData,
        (data) => {
          this.$message({
            message: "跟进记录添加成功!",
            type: "success",
            duration: 1000,
          });
          this.dialogData.flag = false;
          this.$operationRecords.saveOperationRecords(
            this.tempClient.value,
            "添加跟进记录!",
            this.baseInfo,
            "client",
            this,
            (data2) => {}
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取工商信息
     * 根据企业全称调用企查查接口,获取企业税号、增值税类型、行业分类、所在地址、详细地址
     */
    getCompanyInfo() {
      this.$request(
        "get",
        "/qicc/search?keyword=" +
          this.contractDrawerData.data.modelList[2].line[0].value,
        {},
        (data) => {
          this.contractDrawerData.data.modelList[2].line[0].value =
            data.name == ""
              ? this.contractDrawerData.data.modelList[2].line[0].value
              : data.name;
          this.contractDrawerData.data.modelList[2].line[1].value =
            data.taxNum == ""
              ? this.contractDrawerData.data.modelList[2].line[1].value
              : data.taxNum;
          this.contractDrawerData.data.modelList[2].line[5].value =
            data.address == ""
              ? []
              : this.$addressAutomaticallyMatch.addressAutomaticallyMatch(
                  data.address
                );

          if (
            this.contractDrawerData.data.modelList[2].line[5].value == null ||
            this.contractDrawerData.data.modelList[2].line[5].value.length < 3
          ) {
            this.contractDrawerData.data.modelList[2].line[5].value = [];
          }
          this.contractDrawerData.data.modelList[2].line[6].value =
            data.address == ""
              ? this.contractDrawerData.data.modelList[2].line[6].value
              : data.address;
          this.updateWorkContractByID("工商信息");
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 更新/修改/添加客户联系人
     */
    updateClientContactByID() {
      if (
        !clientInfoData.formatClientContactDrawerData(
          this.contractDrawerData.data.modelList
        ).flag
      ) {
        this.$message({
          message: "联系人手机号格式不正确,请确认填入正确的手机号!",
          type: "error",
          duration: 1000,
        });
        // this.getMyThreadDetailsByThreadId();
        return;
      }
      let requestData = {
        clientId: this.tempClient.value,
        data: clientInfoData.formatClientContactDrawerData(
          this.contractDrawerData.data.modelList
        ).list,
      };
      this.$request(
        "post",
        "/client/updateClientContactByID",
        requestData,
        (data) => {
          // this.saveOperationRecords(
          //   "客户【" + this.tempClientName + "】修改客户联系人!",
          //   true
          // );
          this.$message({
            message: "客户联系人操作成功!",
            type: "success",
            duration: 1000,
          });
          this.$operationRecords.saveOperationRecords(
            this.tempClient.value,
            "更新客户联系人基本信息!",
            this.baseInfo,
            "client",
            this,
            (data2) => {
              this.showContractInfoByID();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 抽屉中删除当前联系人
     */
    lineOper(data) {
      switch (data.active) {
        case "gongkai":
          break;
        case "shanchu":
          this.$confirm("此操作将永久删除该联系人, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.contractDrawerData.data.modelList.splice(data.i, 1);
              this.updateClientContactByID();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
        default:
          break;
      }
    },

    /**
     * 客户结束
     */

    /**
     * 抽屉中tag移除事件
     * data(tag,i,j):tag->移除的tag,i为第几个model,j为第几line
     */
    tagsHandleClose(data) {
      this.contractDrawerData.data.modelList[data.i].line[data.j].value.splice(
        this.contractDrawerData.data.modelList[data.i].line[
          data.j
        ].value.indexOf(data.tag),
        1
      );
      this.updateWorkContractByID("角色信息");
    },
    addTags(data) {
      if (this.contractDrawerData.title == "合同") {
        this.tempTags = this.contractDrawerData.data.modelList[data.i].line[
          data.j
        ].value;
        let v = "2";
        this.tempTags.push(v);
        this.updateWorkContractByID("合同信息");
      }
      if (this.contractDrawerData.title == "客户") {
        if (
          this.contractDrawerData.data.modelList[data.i].line[data.j].isSelect
        ) {
          let value = this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].tempValue;
          if (
            value &&
            this.contractDrawerData.data.modelList[data.i].line[
              data.j
            ].value.indexOf(value) < 0
          ) {
            // 排除重复选项
            this.contractDrawerData.data.modelList[data.i].line[
              data.j
            ].value.push(value);
            this.updateWorkContractByID("角色信息");
          }
          this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].isSelect = false;
          this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].tempValue = "";
        } else {
          this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].isSelect = true;
        }
      }
    },
    choseProductTipOne(data) {
      // 新建合同
      if (this.dialogData.title == "选择产品") {
        let requestData = {
          userId: this.baseInfo.userId,
          companyId: this.baseInfo.company.companyId,
          productId: data.id, // 合同模板ID
          clientId: this.tempClient.value, // 客户ID
          isRenew: this.isRenew,
          oldContractId: this.isRenew ? this.tempContract.id : "", // 原合同ID
          expenses: "0", // 原合同费用(续签才有,可能为空)
        };
        this.$request(
          "post",
          "/contract/addContract",
          requestData,
          (data) => {
            this.dialogData.flag = false;
            this.contractDrawerData.flag = false;
            this.tempContract.id = data.contractId;
            this.$message({
              message: "合同创建成功!",
              duration: 1000,
              type: "success",
            });
            // this.initTableData();
            this.showContractInfoByID();
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }

      // 新建支出
      if (this.dialogData.title == "新建支出") {
        let dd = {
          client: this.tempClient.label,
          contractNum: this.tempContract.name,
          contractId: this.tempContract.id,
          expendStatus: "待提交",
          expendNum: "",
          time: "",
          expendType: data.name,
          user: this.baseInfo.userId,
          title: "",
          file: "", // 附件
          services: this.tempServices,
        };
        this.$request(
          "post",
          "/contract/addExpend",
          dd,
          (data) => {
            this.$message({
              message: "支出添加成功!",
              type: "success",
              duration: 1000,
            });
            this.dialogData.flag = false;
            this.getExpendByContractId();
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }
    },
    /**
     * 确认编辑合同派工内容
     */
    updateWorkContractByID(drawerTitle) {
      let requestData = {};
      if (this.contractDrawerData.title == "合同派工") {
        requestData = {
          contractId: this.tempContract.id,
          clientName: this.contractDrawerData.data.title, // 修改后的客户名称
          data: contractDrawData.formatContractWorkDrawerData(
            this.contractDrawerData.data.modelList
          ),
        };
        if (drawerTitle == "业务类型名称") {
          this.updateProfession();
          return;
        }
        console.log("requestData.data", requestData.data);
        this.$request(
          "post",
          "/contract/updateWorkContractByID",
          requestData,
          (data) => {
            this.$message({
              message: "操作成功!",
              duration: 1000,
              type: "success",
            });
            this.$operationRecords.saveOperationRecords(
              this.tempContract.id,
              "派工基本信息修改!",
              this.baseInfo,
              "contract",
              this,
              (data2) => {
                this.showContractInfoByID();
                this.initTableData();
              }
            );
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }
      if (this.contractDrawerData.title == "客户") {
        requestData = {
          clientId: this.tempClient.value,
          companyId: this.baseInfo.company.companyId,
          data: [],
        };
        switch (this.contractDrawerData.cardIndex) {
          case 0:
            // 更新客户信息
            requestData.data = clientInfoData.formatClientInfoDrawerData(
              this.contractDrawerData.data.modelList
            );
            this.$request(
              "post",
              "/client/updateClient",
              requestData,
              (data) => {
                // this.saveOperationRecords(
                //   "客户【" + this.tempClientName + "】信息修改!",
                //   true
                // );
                this.$message({
                  message: "修改成功!",
                  type: "success",
                  duration: 1000,
                });
                this.$operationRecords.saveOperationRecords(
                  this.tempClient.value,
                  "修改" + drawerTitle + "信息!",
                  this.baseInfo,
                  "client",
                  this,
                  (data2) => {
                    this.initTableData();
                  }
                );
                // this.initTableData();
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
            break;
          case 1:
            // 更新客户联系人
            this.updateClientContactByID();
            break;

          default:
            break;
        }
      }
    },
    /**
     * 修改客户抽屉公司名称
     */
    updateDrawerTitle(title) {
      console.log("contractDrawerData", this.contractDrawerData);
      let requestData = {
        clientId: this.ttempClient.clientId, // 客户id
        companyId: this.baseInfo.company.companyId, // 公司 id
        clientName: this.contractDrawerData.data.title, // 修改后的客户名称
      };
      this.$request(
        "post",
        "/client/updateClientNameForContract",
        requestData,
        (data) => {
          this.$message.success("修改成功!");
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    updateProfession() {
      let requestData = {
        contractId: this.tempContract.id, // 合同 id
        professionId: "", // 业务模板 id
      };
      let data = contractDrawData.formatContractWorkDrawerData(
        this.contractDrawerData.data.modelList
      );
      let flag = false;
      data.forEach((item) => {
        if (item.key == "projectStep") {
          flag = item.value.some((item) => item.active == "done");
        }
        if (item.title == "业务类型名称") {
          requestData.professionId = item.value;
        }
      });
      if (flag) {
        this.$message.error("该派工已有完成步骤, 无法修改!");
        this.showContractInfoByID();
        this.initTableData();
        return;
      }
      this.$request(
        "post",
        "/contract/updateProfession",
        requestData,
        (data) => {
          this.$message.success("修改成功!");
          this.showContractInfoByID();
          this.initTableData();
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.contractDrawerData.flag = false;
      // this.initTableData();
      // this.updateWorkContractByID();
    },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      // this.popoData.popoVisible = false;
    },
    /**
     * 表头div拖动方法
     */
    dragging(value) {
      console.log("div拖动", value);
    },
    /**
     * 查看跟进记录
     */
    showGenjin(clientData) {
      this.ttempClient = clientData;
      let requestData = {
        clientId: clientData.clientId,
        threadId: "",
      };
      this.$request(
        "post",
        "/followRecord/showFollowRecord",
        requestData,
        (data) => {
          this.dialogData.title = "跟进记录-" + clientData.clientName;
          this.dialogData.buttonArray = [];
          this.dialogData.data = [
            {
              type: "genjinjilu",
              value: {
                clientId: clientData.id,
                threadId: "",
                hasContent: true,
                content: "",
                time: "",
                list: data,
                hasTarget: false,
                target: "",
                hasPhase: false,
                phase: "",
              },
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 确定添加跟进记录
     */
    followRecord(recordData) {
      console.log("跟进记录:", recordData);
      let requestData = {
        clientId: this.ttempClient.clientId, // 商机ID
        threadId: "", // 商机ID
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId, // 操作人ID
        content: recordData.content, // 当前操作
        time: recordData.time, // 下次跟进时间
        stage: "",
      };
      if (requestData.content == "") {
        this.$message.error("跟进内容不能为空!");
        return;
      }
      this.$request(
        "post",
        "/followRecord/addFollowRecord",
        requestData,
        (data) => {
          this.$message({
            message: "跟进记录添加成功!",
            type: "success",
            duration: 1000,
          });
          this.$operationRecords.saveOperationRecords(
            this.ttempClient.clientId,
            "添加跟进记录:" + recordData.content + "!",
            this.baseInfo,
            "client",
            this,
            (data2) => {
              this.showGenjin(this.ttempClient);
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 表格排序事件
     */
    sortChange(data) {
      this.defaultSort.prop = data.order == null ? "" : data.prop;
      this.defaultSort.order = data.order == null ? "" : data.order;
      this.initTableData();
    },
  },
  mounted() {
    this.loading = true;
    this.isCharge();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.$store.dispatch("getCompantAccountList", this);
    let accountList = JSON.parse(
      JSON.stringify(this.$store.getters.getCompantAccountList)
    );
    this.accountList = accountList;
    this.getModelFeatureAuthority();
    this.waitDealLocation();
  },
};
</script>

<style lang="less" scoped>
#contract-work {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .contract-work-screen {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid @boderColor;
    display: flex;
    align-items: center;
    .contract-work-screen-search {
      width: 210px;
      min-width: 210px;
      height: 30px;
      margin: 0 10px;
    }
    .contract-work-screen-active {
      flex: 1;
      height: 100%;
    }
    .contract-work-screen-but {
      width: 140px;
      min-width: 140px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @activeColor;
      font-size: @contentFontSize;
      color: #fff;
      margin: 0 5px;
      border-radius: 4px;
      transition: 0.5s;

      &:hover {
        cursor: pointer;
        background-color: #2c7db3;
      }
    }
    .contract-work-screen-but-select {
      width: 140px;
      min-width: 160px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @activeColor;
      font-size: @contentFontSize;
      color: #fff;
      margin: 0 5px;
      border-radius: 4px;
      transition: 0.5s;
      cursor: pointer;
      .contract-work-screen-but-select_but {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
      }
      .contract-work-screen-but-select_but:hover {
        background-color: @activeColor2;
      }
      .contract-work-screen-but-select_select {
        width: 30px;
        height: 100%;
        border-left: 1px solid @activeColor3;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .contract-work-screen-but-boder {
      width: 150px;
      min-width: 50px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid @activeColor;
      font-size: @contentFontSize;
      background-color: @activeBackground;
      color: @activeColor;
      margin: 0 5px;
      margin-right: 10px;
      border-radius: 4px;
      transition: 0.5s;
    }
    .contract-work-screen-but-boder:hover {
      cursor: pointer;
      background-color: @activeBackground2;
    }
  }
  .contract-work-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    position: relative;
    .contract-work-table-card-content-tip {
      width: 98%;
      height: 4vh;
      position: absolute;
      top: 2vh;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .contract-work-table-card-active {
        flex: 1;
      }
      .contract-work-table-card-edit {
        width: 30px;
        min-width: 30px;
        margin-right: 10px;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @editColor;
        cursor: pointer;
      }
    }
    .contract-work-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      .contract-work-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        .contract-work-table-card-tab {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .contract-work-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .contract-work-table-card-active {
          flex: 1;
        }
        .contract-work-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .contract-work-table-card-content {
        flex: 1;
        width: 98%;
        height: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: @contentFontSize;
        color: @closeColor;
      }
      .contract-work-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }

  .table-tip {
    height: 30px;
    width: auto;
    padding: 2px 5px;
    margin: 0 3px;
    border: 1px solid #333;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hri {
    display: flex;
    i {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
  .link {
    color: @activeColor;
    transition: 0.3s;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
