<template>
  <div id="popo-search">
    <div class="popo-search-content">
      <div
        class="popo-search-content-line"
        v-for="(item, index) in array"
        :key="index"
      >
        <div class="popo-search-content-line-top">
          <div
            class="popo-search-content-line-top-title"
            v-if="item.type != 'role'"
          >
            {{ item.title }}
          </div>
          <div class="popo-search-content-line-top-title" v-else>
            <el-select
              v-model="item.searchRole"
              filterable
              placeholder="请选择"
              value-key="label"
            >
              <el-option
                v-for="it in item.roleOptions"
                :key="it.label"
                :label="it.label"
                :value="it"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="popo-search-content-line-top-search"
            v-if="item.hasSearch"
          >
            <el-select
              v-model="item.search_value"
              filterable
              placeholder="请选择"
              value-key="label"
              @change="changeSelect(item)"
            >
              <el-option
                v-for="it in item.options"
                :key="it.label"
                :label="it.label"
                :value="it"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="popo-search-content-line-below" v-if="item.type == 'tip'">
          <div
            :class="{ tip: true, is_active: it.is_active }"
            v-for="(it, ii) in item.value"
            :key="ii"
            @click="it.is_active = !it.is_active"
          >
            {{ it.text }}
          </div>
        </div>
        <div
          class="popo-search-content-line-below"
          v-if="item.type == 'select'"
        >
          <el-select filterable v-model="item.value" placeholder="请选择">
            <el-option
              v-for="it in item.options"
              :key="it.value"
              :label="it.label"
              :value="it.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="popo-search-content-line-below" v-if="item.type == 'tree'">
          <el-cascader
            v-model="item.value"
            placeholder="请选择"
            :options="item.options"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id', label: 'name' }"
            filterable
          ></el-cascader>
        </div>
        <div
          class="popo-search-content-line-below"
          v-if="item.type == 'cascader'"
        >
          <el-cascader
            v-model="item.value"
            placeholder="请选择"
            :options="item.options"
            collapse-tags
            :props="{
              multiple: true,
              label: 'name',
              value: 'id',
              emitPath: false,
            }"
            clearable
          ></el-cascader>
        </div>
        <div
          class="popo-search-content-line-below"
          v-if="item.type == 'input' || item.type == 'phone'"
        >
          <el-input
            v-model="item.value"
            :placeholder="'请输入' + item.title"
          ></el-input>
        </div>
        <div class="popo-search-content-line-below" v-if="item.type == 'radio'">
          <el-radio-group v-model="item.value">
            <el-radio label="Y">是</el-radio>
            <el-radio label="N">否</el-radio>
          </el-radio-group>
        </div>
        <div class="popo-search-content-line-below" v-if="item.type == 'times'">
          <el-date-picker
            v-model="item.value"
            type="daterange"
            range-separator="到"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="popo-search-but">
      <div class="active"></div>
      <div class="but" @click="cancel">取消</div>
      <div class="but" @click="reset">重置</div>
      <div class="but" @click="makeSure">确定</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      array: this.list,
    };
  },
  props: {
    list: {
      type: Array,
      default: function() {
        return [
          {
            title: "来源",
            type: "select",
            hasSearch: false,
            value: "",
            options: [{ label: "来源一", value: "来源一" }],
          },
        ];
      },
    },
  },
  watch: {
    list() {
      //父组件值变化->>子组件也变化
      this.array = this.list;
    },
    array(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    reset() {
      this.$emit("reset");
    },
    makeSure() {
      this.$emit("makeSure");
    },
    changeSelect(item) {
      if (
        !item.value.some((it) => {
          if (it.id == item.search_value.value) {
            return true;
          }
        })
      ) {
        let dd = {
          text: item.search_value.label,
          is_active: true,
          id: item.search_value.value,
        };
        item.value.push(dd);
      }
    },
  },
  mounted() {
    console.log("当前数据组:", this.array);
  },
};
</script>

<style lang="less" scoped>
#popo-search {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .popo-search-content {
    flex: 1;
    max-height: 750px;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
      width: 1px;
    }
    .popo-search-content-line {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: auto;
      border-bottom: 1px solid @boderColor;
      margin-top: 5px;
      .popo-search-content-line-top {
        width: 100%;
        height: 30px;
        display: flex;
        .popo-search-content-line-top-title {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-left: 5px;
          font-size: 12px;
        }
        .popo-search-content-line-top-search {
          flex: 1;
          height: 100%;
        }
      }
      .popo-search-content-line-below {
        width: 100%;
        height: auto;
        min-height: 40px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        font-size: 12px;

        .tip {
          width: auto;
          height: 25px;
          padding: 1px 5px;
          transition: 0.3s;
          margin: 1px 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border: 1px solid transparent;
        }
        .is_active {
          background-color: @activeBackground;
          border: 1px dashed @activeColor;
          // box-sizing: border-box;
          color: @activeColor;
        }
        /deep/ .el-cascader__tags {
          flex-wrap: nowrap;
        }
      }
    }
    .popo-search-content-line:nth-last-child(1) {
      border: none;
    }
  }
  .popo-search-but {
    width: 100%;
    height: 40px;
    border-top: 1px solid @boderColor;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .active {
      flex: 1;
    }
    .but {
      width: 50px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      background-color: @activeColor;
      border-radius: 5px;
      margin: 0 5px;
      color: #fff;
      transition: 0.3s;
    }
    .but:hover {
      cursor: pointer;
      background-color: @activeColor3;
    }
  }
}
</style>
