<template>
  <!-- 线索分配 -->
  <div id="thread-allocation">
    <div class="thread-allocation-screen">
      <div class="thread-allocation-screen-line">
        <div class="thread-allocation-screen-fiftrate" v-if="statisticsFlag">
          <el-popover
            placement="bottom-start"
            width="250"
            trigger="manual"
            :visible-arrow="false"
            v-model="popoData.popoVisible"
          >
            <div class="popover-search">
              <popo_search
                :list="popoData.list"
                @cancel="popoCanael"
                @reset="popoReset"
                @makeSure="popoMakeSure"
              ></popo_search>
            </div>
            <div slot="reference" @click="popoverOpen">
              筛选 <i class="el-icon-s-operation"></i>
            </div>
          </el-popover>
        </div>
        <div class="thread-allocation-screen-search">
          <el-input
            placeholder="线索名称/联系人/手机号"
            v-model="searchData.input"
            @keyup.enter.native="resetPage"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="resetPage"
            ></el-button>
          </el-input>
        </div>
        <div class="thread-allocation-screen-search">
          <el-select
            v-model="searchData.intentionLevel"
            placeholder="请选择意向等级"
            @change="resetPage"
            clearable
          >
            <el-option
              v-for="it in tagList.intentionLevelList"
              :key="it.value"
              :value="it.value"
              :label="it.label"
            >
            </el-option>
          </el-select>
        </div>
        <div class="thread-allocation-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="resetPage"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="thread-allocation-screen-search">
          <el-select
            v-model="searchData.userId"
            placeholder="请选择商机跟进人"
            @change="selectInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="thread-allocation-screen-active"></div>
        <div class="thread-allocation-screen-search">
          <el-input placeholder="请输入被叫号码" v-model="searchData.phone">
            <el-button
              slot="append"
              icon="el-icon-phone-outline phone"
              @click.native.stop="callNumber(searchData.phone)"
            ></el-button>
          </el-input>
        </div>
        <el-button
          type="primary"
          plain
          size="small"
          @click="handleClick({}, 0)"
          v-showFunction="{
            active: 'add',
            array: allFunctionButtons,
          }"
          >添加线索</el-button
        >
      </div>
      <div
        class="thread-allocation-screen-line"
        v-if="searchData.screenList.length > 0"
      >
        <div class="title">筛选条件:</div>
        <el-tag
          v-for="(tag, index) in searchData.screenList"
          :key="index"
          closable
          size="small"
          @close="closeScreen(index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
      <!-- <div class="thread-allocation-screen-line" v-if="statisticsFlag">
        <div class="thread-allocation-screen-search">
          <el-date-picker
            v-model="searchData.createTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :value-format="'yyyy-MM-dd'"
            @change="resetPage"
          >
          </el-date-picker>
        </div>
        <div class="thread-allocation-screen-search">
          <el-select
            v-model="searchData.industry"
            placeholder="请选择所属行业"
            @change="resetPage"
            filterable
            clearable
          >
            <el-option
              v-for="item in tagList.industryList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            >
            </el-option>
          </el-select>
        </div>
        <div class="thread-allocation-screen-search">
          <el-select
            v-model="searchData.demand"
            placeholder="请选择客户需求"
            @change="resetPage"
            filterable
            clearable
          >
            <el-option
              v-for="item in tagList.demandList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            >
            </el-option>
          </el-select>
        </div>
        <div class="thread-allocation-screen-search">
          <el-cascader
            v-model="searchData.source"
            placeholder="请选线索来源"
            :options="tagList.leadSourceList"
            collapse-tags
            :props="{
              multiple: true,
              value: 'id',
              emitPath: false,
            }"
            @change="resetPage"
            clearable
            filterable
          ></el-cascader>
        </div>
        <div class="thread-allocation-screen-search" v-if="tabIndex == '统计'">
          <el-select
            v-model="searchData.createUser"
            placeholder="请选择线索创建人"
            @change="selectInitTableData"
            filterable
            clearable
          >
            <el-option
              v-for="item in allEmployeeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="thread-allocation-screen-search">
          <el-select
            v-model="searchData.assignee"
            placeholder="请选择线索分配人"
            @change="selectInitTableData"
            filterable
            clearable
          >
            <el-option
              v-for="item in allEmployeeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div> -->
    </div>
    <div class="thread-allocation-table">
      <div class="thread-allocation-table-card">
        <div class="thread-allocation-content">
          <div class="thread-allocation-table-card-tabs">
            <div
              :class="{
                'thread-allocation-table-card-tab': true,
                'is-active': tabIndex == '新线索',
              }"
              @click="changeTab('新线索')"
            >
              新线索
            </div>
            <div
              :class="{
                'thread-allocation-table-card-tab': true,
                'is-active': tabIndex == '有意向',
              }"
              @click="changeTab('有意向')"
            >
              有意向
            </div>
            <div
              :class="{
                'thread-allocation-table-card-tab': true,
                'is-active': tabIndex == '无意向',
              }"
              @click="changeTab('无意向')"
            >
              无意向
            </div>
            <div
              :class="{
                'thread-allocation-table-card-tab': true,
                'is-active': tabIndex == '已成交',
              }"
              @click="changeTab('已成交')"
            >
              已成交
            </div>
            <div
              :class="{
                'thread-allocation-table-card-tab': true,
                'is-active': tabIndex == '',
              }"
              @click="changeTab('')"
            >
              全部
            </div>
            <div
              v-if="statisticsFlag"
              :class="{
                'thread-allocation-table-card-tab': true,
                'is-active': tabIndex == '统计',
              }"
              @click="changeTab('统计')"
            >
              统计
            </div>
            <div class="thread-allocation-table-card-active"></div>
          </div>
          <el-table
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            v-loading="loading"
            @row-click="rowClick"
            @sort-change="sortChange"
            :row-style="{ height: '60px' }"
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column type="index" width="60" fixed> </el-table-column>
            <el-table-column
              prop="threadName"
              label="线索名称"
              show-overflow-tooltip
              width="200"
              fixed
            >
              <template slot-scope="scope">
                <div style="display: flex; align-items: center">
                  <i
                    v-showFunction="{
                      active: 'edit',
                      array: allFunctionButtons,
                    }"
                    style="color: #409eff; cursor: pointer; margin-right: 5px;"
                    class="el-icon-edit-outline"
                    @click.stop="handleClick(scope.row, 3)"
                  ></i>
                  <span>{{ scope.row.threadName }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="createUserName"
              label="创建人"
              width="150"
              fixed
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.createUser"
                  @change="changeItem($event, scope.row, 'createUser')"
                  :disabled="editCreateUserFlag"
                >
                  <el-option
                    v-for="it in allEmployeeList"
                    :key="it.value"
                    :value="it.value"
                    :label="it.label"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop="createUserName"
              label="分配人"
              width="150"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>{{
                  scope.row.statusList.map((it) => it.name).join(", ")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="contactPerson" label="联系人" width="100">
            </el-table-column>
            <el-table-column prop="phone" label="手机" width="130">
            </el-table-column>
            <el-table-column prop="otherAccount" label="其他账号" width="130">
            </el-table-column>
            <el-table-column
              prop="sourceName"
              label="线索来源"
              show-overflow-tooltip
              width="130"
            >
              <template slot-scope="scope">
                <span v-if="tabIndex !== '统计'">{{
                  scope.row.sourceName.split("/")[0]
                }}</span>
                <span v-else>{{ scope.row.sourceName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="industry"
              label="所属行业"
              show-overflow-tooltip
              width="130"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.industry"
                  @change="changeItem($event, scope.row, 'industry')"
                >
                  <el-option
                    v-for="it in tagList.industryList"
                    :key="it.value"
                    :value="it.value"
                    :label="it.label"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="150">
            </el-table-column>
            <el-table-column prop="allocation" label="分配时间" width="150">
            </el-table-column>
            <el-table-column prop="status" label="线索意向" width="130">
            </el-table-column>
            <el-table-column prop="validOrNot" label="是否有效" width="130">
            </el-table-column>
            <el-table-column prop="demand" label="客户需求" width="130">
            </el-table-column>
            <el-table-column prop="intentionLevel" label="意向等级" width="130">
            </el-table-column>
            <el-table-column prop="dealProduct" label="成交产品" width="130">
            </el-table-column>
            <el-table-column prop="dealAmount" label="成交金额" width="130">
            </el-table-column>
            <el-table-column prop="cost" label="线索成本" width="130">
            </el-table-column>
            <el-table-column prop="attachment" label="附件" width="130">
              <template slot-scope="scope">
                <img
                  v-if="scope.row.attachment"
                  v-viewer="{ movable: false }"
                  @click.stop
                  class="table-image"
                  :src="scope.row.attachment"
                  alt=""
                  style="margin-right: 5px"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="companyName"
              label="公司名称"
              show-overflow-tooltip
              width="200"
            >
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="100">
              <template slot-scope="scope">
                <el-button
                  @click.stop="handleClick(scope.row, 1)"
                  type="primary"
                  size="mini"
                  icon="el-icon-edit-outline"
                  plain
                  v-showFunction="{
                    active: 'allocation',
                    array: allFunctionButtons,
                  }"
                  >分配</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="thread-allocation-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
            :page-sizes="[50, 100, 300, 500, 1000]"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :clientName="dialogData.clientName"
        :dialogStyle="dialogData.dialogStyle"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @deleteItem="deleteItem"
        :allFunctionButtons="allFunctionButtons"
        @handleAvatarSuccess="handleAvatarSuccess"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
    <!-- 右边抽屉 -->
    <div id="my-drawer" v-if="threadDrawerData.flag">
      <my_drawer
        :drawerData="threadDrawerData"
        :allFunctionButtons="allFunctionButtons"
        :model="'threadAllocation'"
        @show="showDrawer"
        @saveDrawerData="saveDrawerData"
        @drawerOper="drawerOper"
        @addProjectRecord="addProjectRecord"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @addTags="addTags"
        @tagsHandleClose="tagsHandleClose"
      ></my_drawer>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import my_dialog from "@/components/dialog.vue";
import my_drawer from "@/components/my-drawer.vue";
import * as threadData from "./js/threadData.js";
import * as clientInfoData from "@/assets/js/clientInfoData.js";
import { callNumber } from "@/minixs.js";
import popo_search from "@/components/popo-search.vue";

export default {
  components: {
    my_dialog,
    my_drawer,
    popo_search,
  },
  data() {
    return {
      tabIndex: "新线索",
      loading: false,
      statisticsFlag: false,
      editCreateUserFlag: true,
      baseInfo: {},
      tableData: [],
      searchData: {
        input: "",
        phone: "",
        intentionLevel: "",
        createTime: [],
        industry: "",
        demand: "",
        source: [],
        userId: "",
        userIdList: [],
        tempUserId: [],
        address: "",
        department: [],
        createUser: "",
        assignee: "",
        screenList: [],
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "threadAllocation"
      ),
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 50, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      threadDrawerData: threadData.threadShareDrawerData(
        "线索分配",
        [],
        false,
        {},
        "线索分配"
      ),
      whetherAgencyHead: false,
      defaultSort: { prop: "gmt_create", order: "descending" },
      tagList: {
        industryList: [], // 所属行业
        demandList: [], // 客户需求
        intentionLevelList: [], // 意向等级
        dealProductList: [], // 成交产品
        leadSourceList: [], // 线索来源
      },
      tempData: {},
      popoData: {
        popoVisible: false,
        list: threadData.threadAllocationSearchData({}),
      },
      allEmployeeList: [],
      drawerAuthorityData: [],
    };
  },
  mixins: [callNumber],
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "threadAllocation",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.allFunctionButtons.forEach((item) => {
            if (item.key == "statistics" && item.authority == "1") {
              this.statisticsFlag = true;
            }
            if (item.key == "editCreateUser" && item.authority == "1") {
              this.editCreateUserFlag = false;
            }
          });
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getDepartmentTreeByUserId();
      });
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getTagList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取客户片区
     */
    getTagList() {
      let requestData = {
        type: ["所属行业", "客户需求", "意向等级", "成交产品"],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            switch (element.type) {
              case "所属行业":
                this.tagList.industryList = element.value.map((item) => {
                  return {
                    label: item.name,
                    value: item.value,
                  };
                });
                break;
              case "客户需求":
                this.tagList.demandList = element.value.map((item) => {
                  return {
                    label: item.name,
                    value: item.value,
                  };
                });
                break;
              case "意向等级":
                this.tagList.intentionLevelList = element.value.map((item) => {
                  return {
                    label: item.name,
                    value: item.value,
                  };
                });
                break;
              case "成交产品":
                this.tagList.dealProductList = element.value.map((item) => {
                  return {
                    label: item.name,
                    value: item.value,
                  };
                });
                break;
              default:
                break;
            }
          }
          console.log("tagList", this.tagList);
          this.getEmployeeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = this.baseInfo.userId;
          this.getEmployeeListForDownBox();
          this.selectInitTableData(this.baseInfo.userId);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有在职员工
     */
    getEmployeeListForDownBox() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.allEmployeeList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.allEmployeeList.push(dd);
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      // this.tableData = [];
      this.loading = true;
      let url = "";
      if (this.tabIndex == "统计") {
        url = "/threadAllocation/pageNotAuth";
      } else {
        url = "/threadAllocation/page";
      }
      let requestData = {
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "", // 部门 id
        userIdList: this.searchData.tempUserId, // 员工 id 集合
        userId: this.baseInfo.userId, // 当前登录用户
        input: this.searchData.input, // 线索名称/联系人/手机号
        status: this.tabIndex, // 有效、无效、已成交
        intentionLevel: this.searchData.intentionLevel, // 筛选项 意向等级
        createTime: this.searchData.createTime, // 筛选项 创建时间
        industry: this.searchData.industry, // 筛选项 所属行业
        demand: this.searchData.demand, // 筛选项 客户需求
        source: this.searchData.source, // 筛选项 线索来源
        createUser: this.searchData.createUser, // 筛选项 线索创建人
        assignee: this.searchData.assignee, // 筛选项 线索分配人
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        screenList: this.searchData.screenList, // 其余筛选条件
      };
      this.$request(
        "post",
        url,
        requestData,
        (data) => {
          this.tableData = data.records;
          this.tableData.forEach((item) => {
            if (item.dealProduct) {
              item.dealProductVal = item.dealProduct;
              item.dealProduct = item.dealProduct.split(",");
            }
            item.statusList = [];
            item.demandList = [];
            item.intentionLevelList = [];
            item.dealProductList = [];
            item.threadAllocationUsers.forEach((it) => {
              if (it.userId !== item.createUser) {
                item.statusList.push({
                  id: it.id,
                  name: it.userName,
                  value: it.userId,
                  status: it.status,
                }); // 线索状态
              }
              item.demandList.push({
                label: it.userName,
                value: it.demand,
              }); // 客户需求
              item.intentionLevelList.push({
                label: it.userName,
                value: it.intentionLevel,
              }); // 意向等级
              item.dealProductList.push({
                label: it.userName,
                value: it.dealProduct,
              }); // 成交产品
            });
          });
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 界面操作
     */
    handleClick(data, flag) {
      this.tempData = data;
      let requestData = {};
      let options = [];
      switch (flag) {
        case 0:
          // 新增
          this.dialogData.width = "700px";
          this.dialogData.title = "添加线索";
          this.dialogData.dialogStyle = {
            flexDirection: "row",
            flexWrap: "wrap",
          };
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_add",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              width: "45%",
              type: "input",
              title: "公司名称",
              key: "companyName",
              value: "",
            },
            {
              width: "45%",
              type: "input",
              title: "线索名称",
              key: "threadName",
              value: "",
            },
            {
              width: "45%",
              type: "time",
              title: "创建时间",
              key: "createTime",
              value: this.$formatTime(new Date(), "YYYY-MM-DD HH:mm:ss"),
            },
            {
              width: "45%",
              type: "input",
              title: "联系人",
              key: "contactPerson",
              value: "",
            },
            {
              width: "45%",
              type: "input",
              title: "手机号",
              key: "phone",
              value: "",
            },
            {
              width: "45%",
              type: "input",
              title: "其他账号",
              key: "otherAccount",
              value: "",
            },
            {
              width: "45%",
              type: "cascader-tree",
              title: "线索来源",
              key: "source",
              options: this.tagList.leadSourceList,
              props: { value: "id", emitPath: false },
              value: "",
            },
            {
              width: "45%",
              type: "select",
              title: "所属行业",
              key: "industry",
              value: "",
              options: this.tagList.industryList,
            },
            {
              width: "45%",
              type: "select",
              title: "客户需求",
              key: "demand",
              value: "",
              options: this.tagList.demandList,
            },
            {
              width: "45%",
              type: "input",
              title: "备注",
              key: "remark",
              value: "",
            },
            {
              width: "45%",
              title: "上传附件",
              key: "attachment",
              type: "upload_img_png",
              value: {
                value: "",
                action: this.$fileBaseurl + "/uploadImageByMultipartFile",
                headers: {
                  token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                  systemName: "finance",
                  title: "添加线索附件",
                },
              },
            },
          ];
          this.dialogData.flag = true;
          break;
        case 1:
          // 分配
          this.dialogData.width = "600px";
          this.dialogData.title = "分配线索";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_allocation",
            },
            { text: "取消", active: "cancel" },
          ];
          this.$request(
            "get",
            "/threadAllocationUser/getAssignedUser?threadAllocationId=" +
              this.tempData.id,
            {},
            (data) => {
              let options = [];
              this.allEmployeeList.forEach((item) => {
                item.disabled = false;
                if (data.includes(item.value)) {
                  item.disabled = true;
                  console.log("item", item);
                }
                options.push({
                  label: item.label,
                  value: item.value,
                  disabled: item.disabled,
                });
              });
              this.dialogData.data = [
                {
                  title: "选择分配人员",
                  type: "select",
                  value: [],
                  options,
                },
              ];
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );

          break;
        case 2:
          // 查看附件
          window.open(this.tempData.attachment, "_blank");
          break;
        case 3:
          this.dialogData.width = "700px";
          this.dialogData.title = "编辑线索";
          this.dialogData.dialogStyle = {
            flexDirection: "row",
            flexWrap: "wrap",
          };
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_edit",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              width: "45%",
              type: "input",
              title: "公司名称",
              key: "companyName",
              value: this.tempData.companyName,
            },
            {
              width: "45%",
              type: "input",
              title: "线索名称",
              key: "threadName",
              value: this.tempData.threadName,
            },
            {
              width: "45%",
              type: "time",
              title: "创建时间",
              key: "createTime",
              value: this.tempData.createTime,
            },
            {
              width: "45%",
              type: "input",
              title: "联系人",
              key: "contactPerson",
              value: this.tempData.contactPerson,
            },
            {
              width: "45%",
              type: "input",
              title: "手机号",
              key: "phone",
              value: this.tempData.phone,
            },
            {
              width: "45%",
              type: "input",
              title: "其他账号",
              key: "otherAccount",
              value: this.tempData.otherAccount,
            },
            {
              width: "45%",
              type: "cascader-tree",
              title: "线索来源",
              key: "source",
              options: this.tagList.leadSourceList,
              props: { value: "id", emitPath: false },
              value: this.tempData.source,
            },
            {
              width: "45%",
              type: "select",
              title: "所属行业",
              key: "industry",
              value: this.tempData.industry,
              options: this.tagList.industryList,
            },
            {
              width: "45%",
              type: "select",
              title: "客户需求",
              key: "demand",
              value: this.tempData.demand,
              options: this.tagList.demandList,
            },
            {
              width: "45%",
              type: "input",
              title: "备注",
              key: "remark",
              value: this.tempData.remark,
            },
            {
              width: "45%",
              title: "上传附件",
              key: "attachment",
              type: "upload_img_png",
              value: {
                value: this.tempData.attachment,
                action: this.$fileBaseurl + "/uploadImageByMultipartFile",
                headers: {
                  token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                  systemName: "finance",
                  title: "编辑线索附件",
                },
              },
            },
          ];
          this.dialogData.flag = true;
          break;
        case 4:
          this.dialogData.width = "700px";
          this.dialogData.title = "编辑创建人";
          this.dialogData.dialogStyle = {
            flexDirection: "row",
            flexWrap: "wrap",
          };
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_edit_create",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          break;
        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "sure_add":
          requestData = {};
          this.dialogData.data.forEach((item) => {
            requestData[item.key] = item.value;
          });
          requestData.attachment = this.dialogData.data[9].value.value; // 附件
          requestData.createUser = this.baseInfo.userId;
          requestData.status = "新线索";
          requestData.phone = requestData.phone.trim();
          this.$request(
            "post",
            "/threadAllocation/add",
            requestData,
            (data) => {
              this.$message.success("添加成功");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_allocation":
          this.addAllocation(this.dialogData.data[0].value);
          break;
        case "sure_edit":
          requestData = {};
          this.dialogData.data.forEach((item) => {
            requestData[item.key] = item.value;
          });
          requestData.id = this.tempData.id;
          requestData.attachment = this.dialogData.data[9].value.value; // 附件
          this.$request(
            "post",
            "/threadAllocation/update",
            requestData,
            (data) => {
              this.$message.success("编辑成功!");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    addAllocation(value) {
      let requestData = {
        threadAllocationId: this.tempData.id, // 线索 id
        assignedUserId: this.baseInfo.userId, // 分配用户 id（操作用户）
        userId: value, // 被分配用户 id
        demand: this.tempData.demand, // 客户需求
        intentionLevel: this.tempData.intentionLevel, // 意向等级（A、B、C、D）
      };
      if (!requestData.userId) {
        this.$message.error("请选择分配用户!");
        return;
      }
      this.$request(
        "post",
        "/threadAllocationUser/add",
        requestData,
        (data) => {
          this.$message.success("分配成功");
          this.dialogData.flag = false;
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    deleteAllocation(value) {
      let requestData = [value];
      this.$request(
        "post",
        "/threadAllocationUser/deleteBatch",
        requestData,
        (data) => {
          this.$message.success("删除成功");
          this.dialogData.flag = false;
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    getTreeList() {
      let requestData = {
        groupName: "线索来源", // 组名称
        flag: false, // 组名称
      };
      this.$request(
        "post",
        "/tagMultilevel/getTreeList",
        requestData,
        (data) => {
          this.tagList.leadSourceList = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 编辑银行授权状态 和 流水状态
     */
    changeItem(value, row, prop) {
      let requestData = {
        id: row.id, // id
        userId: this.baseInfo.userId, // 操作用户 id
      };
      requestData[prop] = value;

      if (prop === "dealProduct") {
        requestData[prop] = value.join(",");
      }
      this.$request(
        "post",
        "/threadAllocation/update",
        requestData,
        (data) => {
          this.$message.success("编辑成功!");
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    handleAvatarSuccess(data) {
      console.log("data", data);
      this.dialogData.data[9].value.value = data.result.path;
    },
    deleteItem(index) {
      console.log("index", index);
      this.dialogData.data[9].value.value = "";
    },
    /**
     * tab切换事件
     */
    changeTab(index) {
      this.tabIndex = index;

      this.initTableData();
    },
    /**
     * 表格单行点击事件
     */
    rowClick(row, column, event) {
      this.tempData = row;
      if (!this.popoData.popoVisible) {
        if (!this.threadDrawerData.flag) {
          //防止重复点击
          this.$featureData.getAttributeData(
            "threadAllocation",
            false,
            threadData.returnDrawerKeysByThreadShare(
              "threadAllocation",
              "线索分配"
            ),
            (authorityData) => {
              this.drawerAuthorityData = authorityData;
              // 2.拿对应属性的权限
              this.$authority.getModelFeatureAuthority(
                this,
                "threadAllocation",
                authorityData,
                (authorityList) => {
                  this.$request(
                    "get",
                    "/threadAllocationRecords/list?threadAllocationId=" +
                      this.tempData.id,
                    {},
                    (data) => {
                      this.tempData.itemRecord = {
                        title: "线索记录",
                        list: data,
                      };
                      // 3.将权限数据对应到界
                      this.threadDrawerData = threadData.threadShareDrawerData(
                        this.tempData.companyName,
                        authorityList,
                        this.whetherAgencyHead, // 是否是机构负责人
                        {
                          allUser: this.allEmployeeList,
                          industryList: this.tagList.industryList, // 所属行业
                          demandList: this.tagList.demandList, // 客户需求
                          intentionLevelList: this.tagList.intentionLevelList, // 意向等级
                          dealProductList: this.tagList.dealProductList, // 成交产品
                          leadSourceList: this.tagList.leadSourceList, // 线索来源
                        },
                        "线索分配"
                      );
                      this.threadDrawerData = this.$initDrawerAuthorityData.initDrawerData(
                        this.threadDrawerData,
                        this.tempData
                      );
                      this.threadDrawerData.data.modelList[0].line[6].value = this.tempData.statusList;
                      this.threadDrawerData.flag = true;
                    },
                    (errorMessage) => {
                      this.$message.error(errorMessage);
                    }
                  );
                  // 3.将权限数据对应到界面
                }
              );
            }
          );
        } else {
          this.closeUpdateTableDrawer();
        }
      } else {
        this.popoCanael();
      }
    },

    addProjectRecord(textarea) {
      let requestData = {
        threadAllocationId: this.tempData.id,
        userId: this.baseInfo.userId,
        content: textarea,
      };
      if (requestData.content == "") {
        return this.$message.error("请输入项目记录内容");
      }
      this.$request(
        "post",
        "/threadAllocationRecords/add",
        requestData,
        (data) => {
          this.$operationRecords.saveOperationRecords(
            this.tempData.id,
            "添加项目记录!",
            this.baseInfo,
            "thread",
            this,
            (data2) => {
              this.$message.success("项目记录添加成功!");

              this.rowClick(this.tempData);
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 右边抽屉父子组件连锁事件
     */
    showDrawer(value) {
      this.threadDrawerData = value;
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.threadDrawerData.flag = false;
    },
    saveDrawerData(drawerTitle) {
      let data = clientInfoData
        .formatClientInfoDrawerData(this.threadDrawerData.data.modelList)
        .find((item) => item.title == drawerTitle);
      this.changeItem(data.value, this.tempData, data.key);
    },
    /**
     * 添加标签事件
     */
    addTags(data) {
      // if (
      //   this.clientInfoDrawerData.data.modelList[data.i].line[data.j].type ==
      //   "tags-select-obj"
      // ) {
      if (this.threadDrawerData.data.modelList[data.i].line[data.j].isSelect) {
        let value = this.threadDrawerData.data.modelList[data.i].line[data.j]
          .tempValue;

        if (
          value &&
          this.threadDrawerData.data.modelList[data.i].line[
            data.j
          ].value.indexOf(value) < 0
        ) {
          // 排除重复选项
          this.threadDrawerData.data.modelList[data.i].line[data.j].value.push(
            value
          );
          this.addAllocation(value.id);
        }
        this.threadDrawerData.data.modelList[data.i].line[
          data.j
        ].isSelect = false;
        this.threadDrawerData.data.modelList[data.i].line[data.j].tempValue =
          "";
      } else {
        this.threadDrawerData.data.modelList[data.i].line[
          data.j
        ].isSelect = true;
      }
      // }
    },
    /**-
     * 抽屉中tag移除事件
     * data(tag,i,j):tag->移除的tag,i为第几个model,j为第几line
     */
    tagsHandleClose(data) {
      console.log("data", data);
      this.threadDrawerData.data.modelList[data.i].line[data.j].value.splice(
        this.threadDrawerData.data.modelList[data.i].line[data.j].value.indexOf(
          data.tag
        ),
        1
      );
      this.deleteAllocation(data.tag.id);
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.resetPage();
    },
    /**
     * 表格排序事件
     */
    sortChange(data) {
      this.defaultSort.prop = data.prop ? "gmt_create" : "";
      this.defaultSort.order = data.order == null ? "" : data.order;
      this.initTableData();
    },
    resetPage() {
      this.pageData.currentPage = 1;
      this.initTableData();
    },
    /**
     * 表格底部合计方法
     */
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (column.property === "dealAmount" || column.property === "cost") {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = sums[index].toFixed(2);
          } else {
            sums[index] = "00.00";
          }
        } else {
          sums[index] = "";
        }
      });

      return sums;
    },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
      this.searchData.screenList = [];
    },
    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      // console.log("所有数组数据:", this.arrayList);
      this.popoData.list = threadData.threadAllocationSearchData({
        industryList: this.tagList.industryList, // 所属行业
        demandList: this.tagList.demandList, // 客户需求
        dealProductList: this.tagList.dealProductList, // 成交产品
        leadSourceList: this.tagList.leadSourceList, // 线索来源
        allEmployeeList: this.allEmployeeList, // 所有员工
      });
      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选弹出框确认事件
     */
    popoMakeSure() {
      this.searchData.screenList = this.$systemBaseData.remakesureSearchData(
        this.popoData.list
      );
      this.popoData.popoVisible = false;
      this.initTableData();
    },
    /**
     * 关闭筛选标签事件
     */
    closeScreen(index) {
      this.searchData.screenList.splice(index, 1);
      this.initTableData();
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
    this.getTreeList();
  },
  beforeDestroy() {
    // 在组件销毁前取消防抖，确保callNumber存在
    if (this.callNumber && typeof this.callNumber.cancel === "function") {
      this.callNumber.cancel();
    }
  },
};
</script>

<style lang="less" scoped>
#thread-allocation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .thread-allocation-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .thread-allocation-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      padding-right: 10px;
      box-sizing: border-box;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .thread-allocation-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .thread-allocation-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .thread-allocation-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        /deep/ .el-cascader {
          .el-cascader__tags {
            flex-wrap: nowrap;
          }
        }
      }
      .thread-allocation-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        &:hover {
          background-color: #abbed3;
        }
      }
      .thread-allocation-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .thread-allocation-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .thread-allocation-screen-but-boder {
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
        padding: 0 15px;
      }
      .thread-allocation-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .thread-allocation-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .thread-allocation-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .thread-allocation-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .thread-allocation-table-card-tab {
          width: 120px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .thread-allocation-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
      }
      .thread-allocation-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        /deep/ .el-input__inner {
          border: 0;
          background-color: #fff;
        }
        /deep/ .el-input__suffix {
          display: none;
        }
        /deep/ .el-select__tags {
          flex-wrap: nowrap;
        }
        /deep/ .el-select .el-input.is-disabled .el-input__inner {
          color: #606266;
        }
        .table-image {
          width: 40px;
          height: 40px;
        }
        .phone {
          color: red;
          transform: rotateY(180deg);
          cursor: pointer;
          margin-left: 3px;
        }
      }
      .thread-allocation-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
