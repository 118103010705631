<template>
  <div id="tax-statement">
    <!-- 企业流水 -->
    <div class="tax-statement-screen">
      <div class="tax-statement-screen-line">
        <div class="tax-statement-screen-search">
          <el-input
            v-model="searchData.input"
            placeholder="请输入客户全称"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="tax-statement-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="tax-statement-screen-search">
          <el-select
            v-model="searchData.userId"
            @change="selectInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="tax-statement-screen-search">
          <el-select
            v-model="searchData.companyType"
            placeholder="请选择公司类型"
            @change="initTableData"
            filterable
          >
            <el-option
              v-for="item in companyTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="tax-statement-screen-search">
          <el-date-picker
            v-model="searchData.year"
            type="year"
            placeholder="选择年"
            :clearable="false"
            value-format="yyyy"
            @change="initTableData"
          >
          </el-date-picker>
        </div>
        <div class="tax-statement-screen-active"></div>
        <div
          class="tax-statement-screen-but-boder"
          v-showFunction="{
            active: 'setting',
            array: allFunctionButtons,
          }"
          @click="handleClick('', 0)"
        >
          设置项
        </div>
        <div
          class="tax-statement-screen-but-boder"
          v-if="whetherAgencyHead"
          @click="handleClick('', 1)"
        >
          导出
        </div>
      </div>
      <div class="tax-statement-screen-line">
        <div class="tax-statement-screen-search">
          <el-cascader
            v-model="value"
            :options="options"
            clearable
            placeholder="请选择月份流水状态"
            @change="initTableData"
          ></el-cascader>
        </div>
        <div class="tax-statement-screen-active"></div>
      </div>
    </div>
    <div class="tax-statement">
      <div class="tax-statement-table-card">
        <div class="tax-statement-content">
          <el-table
            :data="tableData"
            :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
            highlight-current-row
            style="width: 100%"
            height="98%"
            border
            v-loading="loading"
            :row-style="{ height: '60px' }"
          >
            <el-table-column type="index" width="60" fixed></el-table-column>
            <el-table-column
              v-for="(item, index) in tableMenu"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              :fixed="item.fixed"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-select
                  v-if="item.type == 'select-bank'"
                  v-model="scope.row[item.prop]"
                  :name="scope.row[item.prop]"
                  :disabled="editFlag"
                  @change="
                    changeItem($event, scope.row, item.prop, scope.$index)
                  "
                >
                  <el-option
                    v-for="it in bankStatus"
                    :key="it.value"
                    :value="it.value"
                    :label="it.label"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-else-if="item.type == 'select-salary'"
                  v-model="scope.row[item.prop]"
                  :name="scope.row[item.prop]"
                  :disabled="editFlag"
                  @change="
                    changeItem($event, scope.row, item.prop, scope.$index)
                  "
                >
                  <el-option
                    v-for="it in salaryStatus"
                    :key="it.value"
                    :value="it.value"
                    :label="it.label"
                  >
                  </el-option>
                </el-select>
                <span v-else>{{ scope.row[item.prop] }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="tax-statement-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            :page-sizes="[20, 50, 100, 200]"
            layout="total,sizes, prev, pager, next, jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :clientName="dialogData.clientName"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as taxData from "@/views/tax/js/tax.js";
import my_dialog from "@/components/dialog.vue";

export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      pictLoading: false,
      tabIndex: 0,
      loading: false,
      baseInfo: {},
      tableData: [],
      searchData: {
        input: "",
        department: "",
        departTreeData: [],
        userId: "",
        userList: [],
        userIdList: [],
        tempUserId: [],
        year: this.$formatTime(new Date(), "YYYY"),
        companyType: "",
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "taxStatement"
      ),
      tableMenu: taxData.taxStatementTableMenu(),
      tableData: [],
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 20, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      tempData: {},
      whetherAgencyHead: false,
      bankStatus: [
        {
          value: "已授权",
          label: "已授权",
        },
        {
          value: "未授权",
          label: "未授权",
        },
      ],
      salaryStatus: [],
      companyTypeList: [
        {
          label: "0申报",
          value: "0申报",
        },
        {
          label: "一般纳税人",
          value: "一般纳税人",
        },
        {
          label: "小规模纳税人",
          value: "小规模纳税人",
        },
      ],
      editFlag: true,
      value: [],
      options: taxData.taxStatementMonthStatus(this),
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "taxStatement",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.editFlag = !this.allFunctionButtons.some(
            (item) => item.key == "edit" && item.authority == "1"
          );
          this.isCharge();
          console.log("当前显示的按钮权限", data);
          console.log("this.allFunctionButtons", this.allFunctionButtons);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.queryByMarkByTaxStatement();
        this.getDepartmentTreeByUserId();
      });
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getEmployeeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });

          this.searchData.userId = this.baseInfo.userId;
          this.selectInitTableData(this.baseInfo.userId);
        },

        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取流水设置项
     */
    queryByMarkByTaxStatement() {
      this.$request(
        "get",
        "/dataDictionary/queryByMark?mark=taxStatement",
        {},
        (data) => {
          this.salaryStatus = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        clientName: this.searchData.input, // 客户简称
        year: this.searchData.year, // 日期
        companyType: this.searchData.companyType, // 供公司类型
        pageSize: this.pageData.pageSize, // 每页条数
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "", // 部门 id
        userIdList: this.searchData.tempUserId, // 员工 id 集合
        currentPage: this.pageData.currentPage, // 当前页数
      };
      if (this.value.length > 0) {
        requestData[this.value[0]] = this.value[1];
      }
      this.$request(
        "post",
        "/businessJournal/page",
        requestData,
        (data) => {
          data.records.forEach((item) => {
            item.host = item.host.join(",");
            item.consultant = item.consultant.join(",");
          });
          this.tableData = data.records;
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 界面操作
     */
    handleClick(data, flag) {
      this.tempData = data;
      let requestData = {};
      switch (flag) {
        case 0:
          // 设置项编辑
          this.$request(
            "get",
            "/dataDictionary/queryByMark?mark=taxStatement",
            requestData,
            (data) => {
              this.dialogData.title = "设置项编辑";
              this.dialogData.width = "30%";
              this.dialogData.buttonArray = [
                {
                  text: "取消",
                  active: "cancel",
                },
              ];
              this.dialogData.data = [
                {
                  title: "",
                  type: "tax-drawer",
                  value: {},
                },
              ];
              this.dialogData.data[0].value = {
                title: "流水配置项",
                list: data,
              };
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case 1:
          // 导出
          requestData = {
            clientName: this.searchData.input, // 客户简称
            year: this.searchData.year, // 日期
            companyType: this.searchData.companyType, // 供公司类型
            pageSize: this.pageData.pageSize, // 每页条数
            department:
              this.searchData.department.length > 0
                ? this.searchData.department[
                    this.searchData.department.length - 1
                  ]
                : "", // 部门 id
            userIdList: this.searchData.tempUserId, // 员工 id 集合
            currentPage: this.pageData.currentPage, // 当前页数
          };
          window.location.href =
            this.$baseurl +
            "/businessJournal/export?data=" +
            JSON.stringify(requestData);
          break;
        default:
          break;
      }
    },

    /**
     * 编辑银行授权状态 和 流水状态
     */
    changeItem(value, row, prop, index) {
      let requestData = {
        clientId: row.clientId, // 客户id
        id: row.id, // id
        year: this.searchData.year, // id
      };
      requestData[prop] = value;
      this.$request(
        "post",
        "/businessJournal/saveOrUpdate",
        requestData,
        (data) => {
          this.$message.success("编辑成功!");
          this.tableData[index].id = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active, data) {
      let requestData = {};
      switch (active) {
        case "add":
          requestData = {
            mark: "taxStatement",
            label: data,
            value: data,
          };
          this.$request(
            "post",
            "/dataDictionary/add",
            requestData,
            (data) => {
              this.$message.success("添加成功!");
              this.handleClick("", 0);
              this.queryByMarkByTaxStatement();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "del":
          requestData = {
            id: this.dialogData.data[0].value.list[data].id,
          };
          this.$request(
            "get",
            `/dataDictionary/delete?id=${requestData.id}`,
            requestData,
            (data) => {
              this.$message.success("删除成功!");
              this.handleClick("", 0);
              this.queryByMarkByTaxStatement();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#tax-statement {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .tax-statement-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .tax-statement-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .tax-statement-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .tax-statement-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .tax-statement-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .tax-statement-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        &:hover {
          background-color: #abbed3;
        }
      }
      .tax-statement-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .tax-statement-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .tax-statement-screen-but-boder {
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
        padding: 0 15px;
      }
      .tax-statement-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .tax-statement {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .tax-statement-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .tax-statement-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .tax-statement-table-card-tab {
          width: 120px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .tax-statement-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
      }
      .tax-statement-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        /deep/ .el-select [name="已授权"] {
          color: #67c23a;
        }
        /deep/ .el-select [name="已收"] {
          color: #67c23a;
        }
        /deep/ .el-select [name="季收"] {
          color: #e6a23c;
        }
        /deep/ .el-input__inner {
          border: 0;
          background-color: #fff;
        }
        /deep/ .el-input__suffix {
          display: none;
        }
        /deep/ .el-table--scrollable-y .el-table__body-wrapper {
          z-index: 1;
        }
      }
      .tax-statement-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
