import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueDND from 'awe-dnd'
import 'default-passive-events'

import {
    encrypt,
    decrypt
} from './utils/sm4'
// import echarts from 'echarts'
import * as echarts from 'echarts';

import myDialog from './components/dialog.vue';
Vue.component("my_dialog7", myDialog)

import newDrager from './components/new-drager.vue';
Vue.component("new_drager", newDrager)


// import dialog_customers from "../src/components/customer"

import VueAreaLinkage from 'vue-area-linkage';
Vue.use(VueAreaLinkage)

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

import "./assets/css/base.css"
import "./assets/font/iconfont.css"

import request from "./utils/request.js"
import requestByFormData from "./utils/requestByFormData.js"
import formatTime from "./utils/formatTime.js"
import uuid from './utils/uuid.js';

import * as systemBaseData from "./assets/js/baseData.js"
import * as authority from "./utils/authority.js"
import * as operationRecords from "./utils/operationRecords.js"
import * as errorMessage from "./utils/errorMessage.js"
import * as cutoutnum from "./utils/cutoutnum.js"
import * as addressAutomaticallyMatch from "./utils/addressAutomaticallyMatch.js"
import money from "./utils/money.js"
import formatMathDecimal from "./utils/formatMathDecimal.js"
import * as phoneCheck from "./utils/mobilePhoneNumberCheck.js"
import * as menuData from "./utils/menu.js"
import * as featureData from "./utils/feature.js"
import * as statusData from "./utils/status.js"
import * as keyType from "./utils/keyType.js"
import * as sortUtil from "./utils/sortUtil.js"
import toLowerCase from "./utils/toLowerCase.js"
import numberReg from "./utils/numberReg.js"
import validateData from "./utils/validateData.js"

// 复制剪切板使用
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard);

import VueQriously from 'vue-qriously'
Vue.use(VueQriously)

// 自定义右键菜单
import Contextmenu from 'vue-contextmenujs';
Vue.use(Contextmenu);

import * as waiteData from "./assets/js/waiteData.js"

// 自定义指令
import show from "./utils/show.js"
Vue.directive("showFunction", show)

import focus from "./utils/focus"
Vue.directive("focus", focus)

Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(Vuex)
Vue.use(VueAxios, axios);
Vue.use(VueDND)

Vue.use(Viewer);
Viewer.setDefaults({
    Options: {
        "inline": true,
        "button": true,
        "navbar": true,
        "title": true,
        "toolbar": true,
        "tooltip": true,
        "movable": true,
        "zoomable": true,
        "rotatable": true,
        "scalable": true,
        "transition": true,
        "fullscreen": true,
        "keyboard": true,
        "url": "data-source"
    }
});

// Vue.use(dialog_customers)

Vue.component

// let taxRateValueList = [{ // 税率
//         value: "0.01",
//         label: "1%",
//     },
//     {
//         label: "3%",
//         value: "0.03",
//     },
//     {
//         label: "6%",
//         value: "0.06",
//     },
//     {
//         label: "9%",
//         value: "0.09",
//     }, {
//         label: "13%",
//         value: "0.13",
//     },
// ]

let taxRateValueList = {
    taxRateValueList1 : [ // 小规模纳税人税率
       
        {
            label: "1%",
            value: 0.01,
        },
        {
            label: "3%",
            value: 0.03,
        },
        {
            label: "5%",
            value: 0.05,
        },
        {
            label: "免税",
            value: 0,
        },
    ],
    taxRateValueList2: [ // 一般纳税人税率 
        {
            label: "3%",
            value: 0.03,
        },
        {
            label: "5%",
            value: 0.05,
        },
        {
            label: "6%",
            value: 0.06,
        },
        {
            label: "9%",
            value: 0.09,
        },
        {
            label: "13%",
            value: 0.13,
        },
        {
            label: "免税",
            value: 0,
        },
    ]
}

let paymentTermList = [{ // 收款方式
        label: "支付宝",
        value: "支付宝",
    },
    {
        label: "微信",
        value: "微信",
    },
    {
        label: "银行转账",
        value: "银行转账",
    },
    {
        label: "其他",
        value: "其他",
    },
]

// 试题类型
let examinationTypeList = [
    {
      label: "单选题",
      value: "单选题",
    },
    {
      label: "多选题",
      value: "多选题",
    },
    {
      label: "判断题",
      value: "判断题",
    },
  ]

Vue.prototype.$echarts = echarts
Vue.prototype.$request = request
Vue.prototype.$requestByFormData = requestByFormData
Vue.prototype.$operationRecords = operationRecords
Vue.prototype.$formatTime = formatTime
Vue.prototype.$formatMathDecimal = formatMathDecimal
Vue.prototype.$phoneCheck = phoneCheck
Vue.prototype.$uuid = uuid
Vue.prototype.$systemBaseData = systemBaseData
Vue.prototype.$authority = authority
Vue.prototype.$errorMessage = errorMessage
Vue.prototype.$money = money // 小写转大写
Vue.prototype.$cutOutNum = cutoutnum // 保留两位小数点
Vue.prototype.$taxRateValueList = taxRateValueList // 税率
Vue.prototype.$waiteData = waiteData // 待办事项
Vue.prototype.$paymentTermList = paymentTermList // 收款方式
Vue.prototype.$addressAutomaticallyMatch = addressAutomaticallyMatch // 自动匹配省市区
Vue.prototype.$featureData = featureData // 界面的所有按钮
Vue.prototype.$menuData = menuData // 菜单的处理js
Vue.prototype.$statusData = statusData
Vue.prototype.$keyType = keyType
Vue.prototype.$sortUtil = sortUtil // 对象数组排序 
Vue.prototype.$toLowerCase = toLowerCase // 对象数组排序 
Vue.prototype.$numberReg = numberReg // 对象数组排序 
Vue.prototype.$validateData = validateData // 字段校验 
Vue.prototype.$examinationTypeList = examinationTypeList // 试题类型 

// 创建事件总线   就相当于创建了一个新的vue实例
const bus = new Vue()
// 把bus挂载到了Vue的原型上, 保证所有的组件都能通过 this.$bus访问到事件总线
Vue.prototype.$bus = bus




import * as initDrawerAuthorityData from "./utils/initDrawerAuthorityData.js"
Vue.prototype.$initDrawerAuthorityData = initDrawerAuthorityData // 初始化抽屉权限数据

import * as tagData from "./utils/tag.js"
Vue.prototype.$tagData = tagData // 初始化标签数据

Vue.prototype.$rowStyle = { // 表格行高 有切换条的
    height: "65px",
}

Vue.prototype.$rowStyle1 = { // 表格行高  无切换条的
    height: "71.5px", 
}

Vue.prototype.$webSocket = process.env.VUE_APP_API_WEBSOCKET // 朱鑫亚 
Vue.prototype.$baseurl = process.env.VUE_APP_API_URL // 朱鑫亚
Vue.prototype.$fileBaseurl = process.env.VUE_APP_API_URL

Vue.prototype.$baseimgurl = 'https://crm.hfjunling.com/20210514/14:42:46_tuxiang_1620974566155.png'
Vue.prototype.$baseLogo = 'https://crm.hfjunling.com/20210907/16-49-59_company_logo_1631004599291.png'

// // 添加请求拦截器，在请求头中加token
// axios.interceptors.request.use(
//     config => {
//         if (window.sessionStorage.getItem('token')) {
//             config.headers.token = window.sessionStorage.getItem('token')
//         }
//         return config;
//     },
//     error => {
//         return Promise.reject(error);
//     });

// // 添加请求拦截器，对请求数据进行加密 
// axios.interceptors.request.use( 
//     async config => {
//         //数据加密
//         config.data = encrypt(config.data)
//         return config;
//     },
//     error => {
//         return Promise.reject(error);
//     }
// );

// // 对后台获取的数据进行解密
// axios.interceptors.response.use(
//     response => {
//         response.data = JSON.parse(decrypt(response.data))
//         return Promise.resolve(response)
//     },
//     error => {
//         return Promise.reject(error)
//     }
// );

new Vue({
    router,
    store, //store:store 和 router一样，将我们创建的Vuex实例挂载到这个vue实例中
    render: h => h(App)
}).$mount('#app')