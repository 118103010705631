<template>
  <div id="login">
    <div class="text"></div>
    <div class="content">
      <div class="top">
        <div class="title">欢迎登录</div>
      </div>
      <div class="line">
        <el-input
          v-model="userName"
          placeholder="请输入手机号"
          prefix-icon="el-icon-user"
        ></el-input>
      </div>
      <div class="line">
        <el-input
          v-model="passWord"
          type="password"
          placeholder="请输入密码"
          show-password
          @keyup.enter.native="login"
          prefix-icon="el-icon-lock"
        ></el-input>
      </div>
      <div class="line_button">
        <div class="button" @click="login">登录</div>
      </div>
      <div class="text">
        登录即代表您同意 <span>用户服务协议、隐私政策</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userName: "",
      passWord: "",
      baseInfo: {},
      company: {
        companyId: "",
      },
      companyList: [], // 公司列表
      logo: "",
    };
  },
  methods: {
    login() {
      let requestData = {
        userName: this.userName,
        passWord: this.passWord,
      };
      // this.$message({
      //   message: "系统升级中!",
      //   type: "warning",
      // });
      this.$request(
        "post",
        "/user/login",
        requestData,
        (data) => {
          // sessionStorage.setItem("userData", JSON.stringify(data));
          this.baseInfo = data;
          this.$store.dispatch("setIcon", data.img);

          console.log("设置图像", this.$store.getters.getIcon);
          if (this.baseInfo.userId == "super") {
            this.companyList = [];
            this.company.companyId = "";
            this.$set(this.baseInfo, "company", this.company);
            this.$set(this.baseInfo, "companyList", this.companyList);
            sessionStorage.setItem("userData", JSON.stringify(this.baseInfo));
            this.$router.push("/main");
          } else {
            this.getCompanyList(data.userId);
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 获取公司列表
     */
    getCompanyList(id) {
      let requestData = {
        userId: id,
      };
      this.$request(
        "post",
        "/company/getCompanyList",
        requestData,
        (data) => {
          this.companyList = data;
          this.company =
            this.companyList.length > 0 ? this.companyList[0] : "--";
          this.$set(this.baseInfo, "company", this.company);
          this.$set(this.baseInfo, "companyList", this.companyList);
          sessionStorage.setItem("userData", JSON.stringify(this.baseInfo));
          console.log("公司列表", this.companyList);
          this.logo = this.companyList[0].logo;
          this.$store.dispatch("setCompanyLogo", this.logo);
          this.$message({
            message: "登录成功!",
            type: "success",
            duration: 1000,
          });
          this.$router.push("/main");
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
#login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: url("../assets/img/login.png") no-repeat;
  // background-size: 100% 100%;
  > .text {
    width: 56%;
    height: 65%;
  }
  .content {
    width: 20%;
    min-width: 386px;
    // min-height: 400px;
    height: 60%;
    display: flex;
    margin-bottom: 10%;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border: 1px solid @boderColor;
    border-radius: 10px;
    margin-top: 15%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);

    > .top {
      width: 100%;
      height: 36%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2vw;
      font-weight: bold;
      > .title {
        width: auto;
        height: auto;
        padding: 16px 0;
        border-bottom: 3px solid #3a5dde;
        letter-spacing: 0.3vw;
      }
    }

    > .line {
      width: 90%;
      height: 80px;
      .el-input {
        width: 80%;
        height: 50px;
        border: none;
        outline: none;
        background-color: rgba(255, 255, 255, 0);
        .el-input__inner {
          border: none !important;
          outline: none;
          color: #1780b2;
          background-color: rgba(250, 235, 215, 0);
          border-color: rgba(255, 255, 255, 0);
        }
      }
      .el-input::after {
        content: "";
        background-color: #1780b2;
        display: block;
        height: 1px;
        transition: 0.3s;
      }
      .el-input:hover::after {
        content: "";
        border-color: rgba(255, 255, 255, 0);
        background: linear-gradient(
          to right,
          #041b68,
          #0a2b97,
          #496fec,
          #6e36d6,
          #587aeb,
          #041b68
        );
      }
    }

    > .line_button {
      width: 90%;
      height: 80px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      .button {
        width: 80%;
        height: 40px;
        background-color: #3a5cde;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 4px rgba(58, 92, 222, 0.12),
          0 0 6px rgba(58, 92, 222, 0.04);
        font-size: 15px;
        letter-spacing: 0.2vw;
        transition: 0.3s;
        cursor: pointer;
      }
    }
    > .text {
      width: 100%;
      height: 30px;
      font-size: 13px;
      color: #000;
      margin-top: 30px;
      > span {
        color: @activeColor;
      }
    }
  }
  .el-input__prefix {
    margin-top: 5px;
  }
}
</style>
