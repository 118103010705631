<template>
  <div id="check-reimbursement">
    <!-- 我的审批 -->
    <div class="check-reimbursement-screen">
      <div class="check-reimbursement-screen-line">
        <div class="check-reimbursement-screen-fiftrate">
          <el-popover
            placement="bottom-start"
            width="250"
            trigger="manual"
            :visible-arrow="false"
            v-model="popoData.popoVisible"
          >
            <div class="popover-search">
              <popo_search
                :list="popoData.list"
                @cancel="popoCanael"
                @reset="popoReset"
                @makeSure="popoMakeSure"
              ></popo_search>
            </div>
            <div slot="reference" @click="popoverOpen">
              筛选 <i class="el-icon-s-operation"></i>
            </div>
          </el-popover>
        </div>
        <div class="check-reimbursement-screen-search">
          <el-input
            placeholder="报销编号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="check-reimbursement-screen-search">
          <el-select
            v-model="searchData.userId"
            placeholder="请选择员工"
            @change="seleteInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.userId"
              :label="item.userName == '全部' ? '全部人员' : item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="check-reimbursement-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id', emitPath: false }"
            filterable
          ></el-cascader>
        </div>
        <div class="check-reimbursement-screen-search">
          <el-select
            v-model="searchData.type"
            placeholder="请选择报销类型"
            @change="changeType"
            filterable
            clearable
          >
            <el-option
              v-for="item in searchData.typeOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="check-reimbursement-screen-search">
          <el-select
            v-model="searchData.status"
            placeholder="请选择报销状态"
            @change="initTableData"
            filterable
            clearable
          >
            <el-option
              v-for="item in searchData.statusOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="check-reimbursement-screen-line">
        <div class="check-reimbursement-screen-search">
          <el-input
            placeholder="收款人全称"
            v-model="searchData.payeeName"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="check-reimbursement-screen-search" style="width: 450px">
          <span style="width: 70px; font-size: 14px">金额范围:</span>
          <span class="in">
            <el-input
              placeholder="金额范围"
              v-model.number="searchData.amountAll[0]"
              @keyup.enter.native="initTableData"
              @change="changeAmountAll"
            >
            </el-input>
          </span>
          <span>-</span>
          <span class="in">
            <el-input
              placeholder="金额范围"
              v-model.number="searchData.amountAll[1]"
              @keyup.enter.native="initTableData"
              @change="changeAmountAll"
            >
            </el-input>
          </span>
          <span>元</span>
        </div>
        <div class="check-reimbursement-screen-search">
          <el-checkbox v-model="searchData.check1" @change="check1"
            >立即报销</el-checkbox
          >
          <el-checkbox v-model="searchData.check2" @change="check2"
            >立即付款</el-checkbox
          >
        </div>
        <div class="check-reimbursement-screen-active"></div>
        <div
          class="check-reimbursement-screen-but-select"
          @click="handleClick('', 'approve')"
          v-showFunction="{
            active: 'examine',
            array: allFunctionButtons,
          }"
        >
          批量审批
        </div>
        <div
          class="check-reimbursement-screen-but-select"
          @click="handleClick('', 'payment')"
          v-showFunction="{ active: 'pay', array: allFunctionButtons }"
        >
          批量付款
        </div>
        <div
          class="check-reimbursement-screen-but-boder"
          style="z-index: 9999"
          @click="importThread"
          v-if="whetherAgencyHead"
        >
          导出
        </div>
      </div>
    </div>
    <div class="check-reimbursement-table">
      <div class="check-reimbursement-table-card">
        <div class="check-reimbursement-table-card-tabs">
          <div
            :class="{
              'check-reimbursement-table-card-tab': true,
              'is-active': tabindex == '1',
            }"
            @click="changeTab('1')"
          >
            待审批
          </div>
          <div
            :class="{
              'check-reimbursement-table-card-tab': true,
              'is-active': tabindex == '2',
            }"
            @click="changeTab('2')"
          >
            待付款
          </div>
          <div
            :class="{
              'check-reimbursement-table-card-tab': true,
              'is-active': tabindex == '3',
            }"
            @click="changeTab('3')"
          >
            已完成
          </div>
          <div
            :class="{
              'check-reimbursement-table-card-tab': true,
              'is-active': tabindex == '0',
            }"
            @click="changeTab('0')"
          >
            全部
          </div>
          <div class="check-reimbursement-table-card-active"></div>
          <div class="all-amount">
            总报销金额:<span>{{ amountAll }}</span
            >元
          </div>
        </div>
        <div class="check-reimbursement-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            v-loading="loading"
            @sort-change="sortChange"
            @selection-change="handleSelectionChange"
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column
              type="selection"
              width="35"
              fixed
            ></el-table-column>
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <template v-for="(item, index) in tabMenu"
              ><el-table-column
                :property="item.key"
                :key="index"
                :label="item.name"
                :show-overflow-tooltip="true"
                :sortable="item.key === 'amountAll' ? 'custom' : false"
                :width="item.key === 'amountAll' ? '130' : ''"
              >
                <template slot-scope="scope">
                  <span
                    v-if="scope.row[item.key] != ''"
                    :class="{ 'table-tip': item.key == 'type' ? true : false }"
                    :style="{
                      color: item.key == 'type' ? scope.row.borderColor : '',
                      borderColor: scope.row.borderColor,
                    }"
                    >{{ scope.row[item.key] }}</span
                  >
                  <span v-else>--</span>
                </template>
              </el-table-column>
            </template>
            <!-- <el-table-column label="附件" width="80">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row)"
                  type="text"
                  size="small"
                  >查看</el-button
                >
              </template> 
            </el-table-column>-->
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row, 'showDetail')"
                  type="text"
                  size="small"
                  v-showFunction="{
                    active: 'reimburseInfo',
                    array: allFunctionButtons,
                  }"
                  >查看</el-button
                >
                <!-- <el-button
                  v-if="scope.row.status == '审批中'"
                  @click="handleClick(scope.row, 'approve')"
                  type="text"
                  size="small"
                  v-showFunction="{
                    active: 'examine',
                    array: allFunctionButtons,
                  }"
                  >审批</el-button
                ><el-button
                  v-if="scope.row.status == '待付款'"
                  @click="handleClick(scope.row, 'payment')"
                  type="text"
                  size="small"
                  v-showFunction="{ active: 'pay', array: allFunctionButtons }"
                  >付款</el-button
                > -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="check-reimbursement-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @buttonOper="drawerOper"
        @expendDetails="expendDetails"
        @payerChange="payerChange"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import popo_search from "@/components/popo-search.vue";
import my_dialog from "@/components/dialog.vue";
import * as reimbursementJsData from "./js/reimbursement.js";
export default {
  components: {
    popo_search,
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      loading: true,
      whetherAgencyHead: false, // 是否是机构负责人
      searchData: {
        input: "",
        submitTime: ["", ""], // 提交时间
        examineTime: ["", ""], // 审批时间
        type: "", // 报销类型
        typeOptions: [],
        status: "", // 报销状态
        statusOptions: [
          { name: "审批中", value: "1" },
          { name: "已驳回", value: "2" },
          { name: "已撤销", value: "3" },
          { name: "待付款", value: "4" },
          { name: "已完成", value: "5" },
        ],
        userId: "",
        userList: [],
        tempUserId: [],
        department: "",
        departTreeData: [],
        amountAll: ["100", ""], // 金额范围
        check1: true, // 立即报销 报销金额范围为 100+
        check2: false, // 立即付款 报销类型为付款
        payeeName: "",
        screenList: [],
        payOptions: [],
      },
      tempAllEmpolyeeList: [],
      popoData: {
        popoVisible: false,
        list: reimbursementJsData.myReimbursementSearchData([]),
      },
      allFunctionButtons: [],

      tabindex: "1",
      tabMenu: reimbursementJsData.getMyReimbursementTabMenu(),
      tableData: [],
      amountAll: "0", // 总报销金额
      defaultSort: { prop: "", order: "" },

      dialogData: {
        flag: false,
        width: "45%",
        title: "新建报销",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      employeeList: [], // 所有员工列表
      multipleSelection: [],
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "reimburseExamine"
      ),
      tempData: {},
      expenditureId: "", // 支出id
      editFlag: false,
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "reimburseExamine",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.editFlag = this.allFunctionButtons.some(
            (item) => item.authority == "1" && item.key == "editExpenditure"
          );
          console.log("当前显示的按钮权限", data);
          this.isCharge();
        }
      );
    },
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
      });
    },
    /**
     * 获取所有员工列表
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.employeeList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.employeeList.push(dd);
          });
          this.getDepartmentTreeByUserId();
          // this.drawerOptions.allUser = this.employeeList;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取部门树 包括自己
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getEmployeeListByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeListByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = data;
          console.log(this.searchData);
          this.tempAllEmpolyeeList = [];
          data.forEach((element) => {
            this.tempAllEmpolyeeList.push(element.userId);
          });
          this.searchData.userList.splice(0, 0, {
            userId: "all",
            userName: "全部",
          });
          this.searchData.userId = "all";
          this.seleteInitTableData("all");
          this.getTagList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    // 获取当前所需标签
    getTagList() {
      let requestData = {
        type: ["报销类型", "付款方式"],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          console.log("当前获取到的标签组:", data);
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.type == "报销类型") {
              this.searchData.typeOptions = element.value;
            }
            if (element.type == "付款方式") {
              this.searchData.payOptions = element.value.map((item) => {
                return { label: item.name, value: item.value };
              });
            }
          }
          console.log(this.searchData);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    changeTab(index) {
      this.tabindex = index;
      this.initTableData();
    },
    // 初始化表格数据
    initTableData() {
      this.loading = true;
      let requestData = {
        companyId: this.baseInfo.company.companyId, // 公司id
        userId: this.baseInfo.userId, // 用户id
        amountAll: this.searchData.amountAll, // 金额范围
        submitUser:
          this.searchData.tempUserId.length == 0
            ? this.tempAllEmpolyeeList
            : this.searchData.tempUserId, // 报销提交人
        department: this.searchData.department, // 部门ID
        // submitTime: this.searchData.submitTime, // 提交时间范围
        // examineTime: this.searchData.examineTime, // 审批时间范围
        numbers: this.searchData.input, // 审批编号(不能模糊)
        type: this.searchData.type, // 报销类型
        status: this.searchData.status, // 状态
        payeeName: this.searchData.payeeName, // 收款人
        flag: this.tabindex, // 0全部 1待审批 2待付款 3已完成
        sortProp: this.defaultSort.prop, // 手动排序项
        sortOrder: this.defaultSort.order, // 手动排序规则 ascending descending ""
        screenList: this.searchData.screenList, // 其余筛选条件
        currentPage: this.pageData.currentPage, // 第几页
        pageSize: this.pageData.pageSize, // 每页多少条
      };
      this.$request(
        "post",
        "/reimburse/getMyApproval",
        requestData,
        (data) => {
          this.loading = false;
          this.pageData.total = data.total;
          this.tableData = reimbursementJsData.initTableData(data.tableData);
          this.tableData.forEach((item, index) => {
            if (item.status == "待付款") {
              item.color = "#66B1FF";
            } else if (item.status == "已完成") {
              item.color = "#82c91e";
            } else {
              item.color = "#606266";
            }
          });
          console.log("拿到的数据是", this.tableData);
          this.amountAll = data.totalAmount;
        },
        (errorMessage) => {
          this.loading = false;
          this.$message.error(errorMessage);
        }
      );
    },
    handleClick(item, active) {
      this.tempData = item;
      let res = null;
      let requestData = {
        id: item.id,
        userId: this.baseInfo.userId,
      };
      let data = {
        reimbursemen: [],
        payWay: "", // 付款方式
        des: "", // 审批意见
        file: [], // 付款附件
      };
      switch (active) {
        case "showDetail":
          // 查看详情
          this.$request(
            "post",
            "/reimburse/seeDetails",
            requestData,
            (data) => {
              this.expenditureId = data.detail[0].expenditureId;
              this.dialogData.title = "查看报销";
              this.dialogData.buttonArray = [
                {
                  text: "取消",
                  active: "cancel",
                },
              ];
              if (this.tempData.status == "审批中") {
                this.dialogData.buttonArray.unshift({
                  text: "审批",
                  active: "examine",
                  key: "examine",
                });
              }
              if (this.tempData.status == "待付款") {
                this.dialogData.buttonArray.unshift({
                  text: "付款",
                  active: "payment",
                  key: "pay",
                });
              }
              data.editFlag = this.editFlag;
              this.dialogData.data = [
                {
                  type: "show-reimbursemen",
                  title: "",
                  isEditFlag: false,
                  value: data,
                },
              ];
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.loading = false;
              this.$message.error(errorMessage);
            }
          );
          break;
        case "approve":
          // 批量审批
          if (this.multipleSelection.length == 0) {
            return this.$message.warning("请先选择想要审批的报销!");
          }
          res = this.multipleSelection.every(
            (item) => item.status === "审批中"
          );
          if (!res) {
            return this.$message.warning("选择的报销状态只可以是待审批");
          }
          data.reimbursemen = this.multipleSelection;
          this.dialogData.title = "报销审核";
          this.dialogData.buttonArray = [
            {
              text: "通过",
              active: "approve",
              key: "cancel",
            },
            {
              text: "不通过",
              active: "passApprove",
              key: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "show-reimbursemen-approy",
              title: "",
              flag: false, // 是否为付款
              value: data,
            },
          ];
          this.dialogData.flag = true;
          break;
        case "payment":
          // 批量付款
          if (this.multipleSelection.length == 0) {
            return this.$message.warning("请先选择想要付款的报销!");
          }
          res = this.multipleSelection.every(
            (item) => item.status === "待付款"
          );
          if (!res) {
            return this.$message.warning("选择的报销状态只可以是待付款");
          }
          data.reimbursemen = this.multipleSelection;
          this.dialogData.title = "报销付款";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "makeSurePay",
              key: "cancel",
            },
            {
              text: "取消",
              active: "cancel",
              key: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "show-reimbursemen-approy",
              title: "",
              flag: true, // 是否为付款
              value: data,
            },
          ];
          this.dialogData.flag = true;
          break;

        default:
          break;
      }
    },
    /**
     * 弹出框操作事件
     */
    drawerOper(active) {
      let requestData = {};
      let id = [];
      let reimbursemen = this.dialogData.data[0].value.reimbursemen;
      let data = {
        reimbursemen: [],
        payWay: "", // 付款方式
        des: "", // 审批意见
        file: [], // 付款附件
      };
      switch (active) {
        // 详情审批
        case "examine":
          data.reimbursemen.push({
            id: this.tempData.id,
            numbers: this.tempData.numbers,
          });
          console.log("data.reimbursemen", data.reimbursemen);
          this.dialogData.title = "报销审核";
          this.dialogData.buttonArray = [
            {
              text: "不通过",
              active: "passApprove",
              key: "cancel",
            },
            {
              text: "通过",
              active: "approve",
              key: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "show-reimbursemen-approy",
              title: "",
              flag: false, // 是否为付款
              value: data,
            },
          ];
          this.dialogData.flag = true;
          break;
        // 详情付款
        case "payment":
          data.reimbursemen = [this.tempData];
          this.dialogData.title = "报销付款";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "makeSurePay",
              key: "cancel",
            },
            {
              text: "取消",
              active: "cancel",
              key: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "show-reimbursemen-approy",
              title: "",
              flag: true, // 是否为付款
              value: data,
            },
          ];
          this.dialogData.flag = true;
          break;
        case "passApprove":
          // 不通过
          reimbursemen.forEach((element) => {
            id.push(element.id);
          });
          requestData = {
            id: id,
            status: 1,
            des: this.dialogData.data[0].value.des,
            userId: [this.baseInfo.userId],
          };
          this.$request(
            "post",
            "/reimburse/checkReimburseBatch",
            requestData,
            (data) => {
              this.$message({
                message: "报销已驳回!",
                type: "success",
              });
              this.initTableData();
              this.dialogData.flag = false;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "approve":
          // 通过
          console.log(
            "this.dialogData.data[0].value.reimbursemen",
            this.dialogData.data[0].value.reimbursemen
          );
          this.dialogData.data[0].value.reimbursemen.forEach((element) => {
            id.push(element.id);
          });
          requestData = {
            id: id,
            status: 0,
            des: this.dialogData.data[0].value.des,
            userId: [this.baseInfo.userId],
          };
          this.$request(
            "post",
            "/reimburse/checkReimburseBatch",
            requestData,
            (data) => {
              this.$message({
                message: "审核已通过!",
                type: "success",
              });
              this.initTableData();
              this.dialogData.flag = false;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "makeSurePay":
          // 付款
          this.dialogData.data[0].value.reimbursemen.forEach((element) => {
            id.push(element.id);
          });
          requestData = {
            id: id,
            payWay: this.dialogData.data[0].value.payWay,
            file: this.dialogData.data[0].value.file,
            userId: [this.baseInfo.userId],
          };
          this.$request(
            "post",
            "/reimburse/payReimburse",
            requestData,
            (data) => {
              this.$message({
                message: "付款成功!",
                type: "success",
              });
              this.initTableData();
              this.dialogData.flag = false;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;

        default:
          break;
      }
    },

    /**
     * 导出报销
     */
    importThread() {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请先选择想要导出的报销!");
        return;
      }
      let requestData = "";
      this.multipleSelection.forEach((element) => {
        if (requestData == "") {
          requestData = element.id;
        } else {
          requestData = requestData + "," + element.id;
        }
      });
      console.log("传入参数:", requestData);
      window.location.href =
        this.$baseurl + "/reimburse/exportReimburse?reimburseId=" + requestData;
    },

    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },

    /**
     * 表格排序事件
     */
    sortChange(data) {
      console.log(data);
      this.defaultSort.prop = data.order == null ? "" : data.prop;
      this.defaultSort.order = data.order == null ? "" : data.order;
      this.initTableData();
    },
    seleteInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.tempAllEmpolyeeList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 多选框事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 查看详情
     */
    expendDetails(expenditureId) {
      this.$request(
        "get",
        "/contractExpenditure/getInfoById?id=" + expenditureId,
        {},
        (data) => {
          data.attachments = data.attachments.split(",");
          data.reimburse = {
            status: this.tempData.status,
            color: this.tempData.color,
          };

          this.dialogData.title = "支出";
          this.dialogData.width = "40%";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [
            {
              type: "expend_details",
              title: "",
              value: data,
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    payerChange(payer) {
      let requestData = {
        id: this.expenditureId, // 支出 id
        payer: payer, // 付款单位
      };
      this.$request(
        "post",
        "/contractExpenditure/updateExpenditure",
        requestData,
        (data) => {
          this.$message.success("编辑成功!");
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
      this.searchData.screenList = [];
    },
    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      let tipsData = {
        payOptions: this.searchData.payOptions,
      };
      this.popoData.list = reimbursementJsData.myReimbursementSearchData(
        tipsData
      );
      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选弹出框确认事件
     */
    popoMakeSure() {
      this.searchData.screenList = this.$systemBaseData.remakesureSearchData(
        this.popoData.list
      );
      this.popoData.popoVisible = false;
      this.initTableData();
    },
    // 立即报销
    check1() {
      if (this.searchData.check1) {
        this.searchData.amountAll[0] = "100";
        this.searchData.amountAll[1] = "";
        this.initTableData();
      } else {
        this.searchData.amountAll[0] = "";
        this.searchData.amountAll[1] = "";
        this.initTableData();
      }
    },
    // 立即付款
    check2() {
      if (this.searchData.check2) {
        this.searchData.type = "付款";
        this.initTableData();
      } else {
        this.searchData.type = "";
        this.initTableData();
      }
    },
    changeAmountAll() {
      this.searchData.check1 = false;
    },
    changeType() {
      if (this.searchData.type !== "付款") {
        this.searchData.check2 = false;
      }
      this.initTableData();
    },
    getSummaries(param) {
      const { columns, data } = param;

      const sums = [];
      columns.forEach((column, index) => {
        if (index == 2) {
          sums[index] = "合计";
          return;
        }
        if (index !== 7) {
          sums[index] = "";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            let sum = Number(Number(prev) + Number(curr)).toFixed(2); //toFixed(2)数据项保留两位小数
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = Math.round(sums[index] * 100) / 100;
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.singleTable.doLayout();
    });
  },
  mounted() {
    this.loading = true;
    this.getEmployeeList();
    this.initTableData();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#check-reimbursement {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .check-reimbursement-screen {
    width: 100%;
    height: auto;
    border-bottom: 1px solid @boderColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    .check-reimbursement-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .check-reimbursement-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .check-reimbursement-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .check-reimbursement-screen-search {
        width: 235px;
        min-width: 235px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 0;
        &:nth-child(7n + 1) {
          margin-left: 8px;
          // margin-right: 15px;
        }
        > .in {
          width: 0;
          flex: 1;
          height: 100%;
        }
        span {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 3px;
          box-sizing: border-box;
        }
      }
      .check-reimbursement-screen-checked {
        width: 140px;
        height: 30px;
        min-width: 140px;
        font-size: @contentFontSize;
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .check-reimbursement-screen-active {
        flex: 1;
      }
      .check-reimbursement-screen-but-select {
        z-index: 2;
        // width: 140px;
        // min-width: 160px;
        padding: 0 20px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
        .check-reimbursement-screen-but-select_but {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
        }
        .check-reimbursement-screen-but-select_but:hover {
          background-color: @activeColor2;
        }
        .check-reimbursement-screen-but-select_select {
          width: 30px;
          height: 100%;
          border-left: 1px solid @activeColor3;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .check-reimbursement-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .check-reimbursement-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .check-reimbursement-screen-but-boder {
        width: 50px;
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .check-reimbursement-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .check-reimbursement-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .check-reimbursement-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .check-reimbursement-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .check-reimbursement-table-card-tab {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .check-reimbursement-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .check-reimbursement-table-card-active {
          flex: 1;
        }
        .check-reimbursement-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
        .all-amount {
          width: auto;
          height: 100%;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          > span {
            color: #fc8a2e;
            margin: 0 3px;
          }
        }
      }
      .check-reimbursement-table-card-content {
        flex: 1;
        width: 98%;
        height: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: @contentFontSize;
        color: @closeColor;
      }
      .check-reimbursement-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
  .table-tip {
    height: 30px;
    width: auto;
    padding: 2px 5px;
    margin: 0 3px;
    border: 1px solid #333;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hri {
    display: flex;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      cursor: pointer;
      // color: @activeColor;
    }
  }
}
.popover-search {
  // 筛选的pop弹出框
  max-height: 800px;
}
</style>
