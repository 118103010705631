<template>
  <!-- 简历库 -->
  <div id="human-resume">
    <div class="human-resume-screen">
      <div class="human-resume-screen-line">
        <div class="human-resume-screen-search">
          <el-input
            placeholder="简历人姓名/手机号"
            v-model="searchData.input"
            @keyup.enter.native="resetPage"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="resetPage"
            ></el-button>
          </el-input>
        </div>
        <div class="human-resume-screen-search">
          <el-input
            placeholder="请输入工作经历"
            v-model="searchData.workExperience"
            @keyup.enter.native="resetPage"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="resetPage"
            ></el-button>
          </el-input>
        </div>
        <div class="human-resume-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="resetPage"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="human-resume-screen-search">
          <el-select
            v-model="searchData.userId"
            placeholder="请选择简历创建人"
            @change="selectInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="human-resume-screen-active"></div>
        <el-button
          type="primary"
          plain
          size="small"
          @click="handleClick({}, 0)"
          v-showFunction="{
            active: 'add',
            array: allFunctionButtons,
          }"
          >添加简历</el-button
        >
      </div>
      <div class="human-resume-screen-line">
        <div class="human-resume-screen-search">
          <el-select
            v-model="searchData.positionApplied"
            placeholder="请选择应聘职位"
            @change="resetPage"
            filterable
            clearable
          >
            <el-option
              v-for="item in tagList.positionList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="human-resume-screen-search">
          <el-select
            v-model="searchData.talentSource"
            placeholder="请选择简历来源"
            @change="resetPage"
            filterable
            clearable
          >
            <el-option
              v-for="item in tagList.resumeSourceList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="human-resume-screen-search">
          <el-select
            v-model="searchData.reasonForStorage"
            placeholder="请选择入库原因"
            @change="resetPage"
            filterable
            clearable
          >
            <el-option
              v-for="item in tagList.storageReasonList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="human-resume-screen-active"></div>
      </div>
    </div>
    <div class="human-resume-table">
      <div class="human-resume-table-card">
        <div class="human-resume-content">
          <div class="human-resume-table-card-tabs">
            <div
              :class="{
                'human-resume-table-card-tab': true,
                'is-active': tabIndex == '新简历',
              }"
              @click="changeTab('新简历')"
            >
              新简历
            </div>
            <div
              :class="{
                'human-resume-table-card-tab': true,
                'is-active': tabIndex == '待面试',
              }"
              @click="changeTab('待面试')"
            >
              待面试
            </div>
            <div
              :class="{
                'human-resume-table-card-tab': true,
                'is-active': tabIndex == '待入职',
              }"
              @click="changeTab('待入职')"
            >
              待入职
            </div>
            <div
              :class="{
                'human-resume-table-card-tab': true,
                'is-active': tabIndex == '已入职',
              }"
              @click="changeTab('已入职')"
            >
              已入职
            </div>
            <div
              :class="{
                'human-resume-table-card-tab': true,
                'is-active': tabIndex == '放弃入职',
              }"
              @click="changeTab('放弃入职')"
            >
              放弃入职
            </div>
            <div
              :class="{
                'human-resume-table-card-tab': true,
                'is-active': tabIndex == '无效',
              }"
              @click="changeTab('无效')"
            >
              无效
            </div>
            <div
              :class="{
                'human-resume-table-card-tab': true,
                'is-active': tabIndex == '',
              }"
              @click="changeTab('')"
            >
              全部
            </div>
            <div class="human-resume-table-card-active"></div>
          </div>
          <el-table
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            v-loading="loading"
            @row-click="rowClick"
            @sort-change="sortChange"
            :row-style="{ height: '60px' }"
          >
            <el-table-column type="index" width="40" fixed> </el-table-column>
            <el-table-column
              prop="name"
              label="姓名"
              show-overflow-tooltip
              width="100"
              fixed
            >
              <template slot-scope="scope">
                <div style="display: flex; align-items: center">
                  <i
                    v-showFunction="{
                      active: 'edit',
                      array: allFunctionButtons,
                    }"
                    style="color: #409eff; cursor: pointer; margin-right: 5px;"
                    class="el-icon-edit-outline"
                    @click.stop="handleClick(scope.row, 7)"
                  ></i>
                  <span>{{ scope.row.name }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="createUserName" label="创建人" width="150">
            </el-table-column>
            <el-table-column prop="gmtCreate" label="创建日期" width="150">
            </el-table-column>
            <el-table-column
              prop="interviewerName"
              label="面试官"
              width="150"
              v-if="tabIndex !== '新简历'"
            >
              <template slot-scope="scope">
                {{
                  scope.row.latestTalentInterview
                    ? scope.row.latestTalentInterview.interviewerName
                    : "暂无"
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="interviewTime"
              label="面试时间"
              width="150"
              v-if="tabIndex !== '新简历'"
            >
              <template slot-scope="scope">
                {{
                  scope.row.latestTalentInterview
                    ? scope.row.latestTalentInterview.interviewTime
                    : "暂无"
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="entryDate"
              label="待入职时间"
              width="150"
              v-if="tabIndex !== '新简历' && tabIndex !== '待面试'"
            >
              <template slot-scope="scope">
                {{ scope.row.entryDate }}
              </template>
            </el-table-column>
            <el-table-column
              prop="departmentAppliedName"
              label="应聘部门"
              width="150"
            >
            </el-table-column>
            <el-table-column
              prop="positionApplied"
              label="应聘职位"
              width="150"
            >
            </el-table-column>
            <el-table-column prop="talentSource" label="简历来源" width="150">
            </el-table-column>
            <el-table-column prop="status" label="状态" width="150">
            </el-table-column>
            <el-table-column
              prop="reasonForStorage"
              label="入库原因"
              width="150"
            >
            </el-table-column>
            <el-table-column prop="phone" label="手机号" width="130">
            </el-table-column>
            <el-table-column prop="wechat" label="微信号" width="130">
            </el-table-column>
            <el-table-column
              prop="inductionStatus"
              label="在职状态"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="attachment" label="附件" width="130">
              <template slot-scope="scope">
                <img
                  v-if="scope.row.attachment"
                  v-viewer="{ movable: false }"
                  @click.stop
                  class="table-image"
                  :src="scope.row.attachment"
                  alt=""
                  style="margin-right: 5px"
                />
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="360">
              <template slot-scope="scope">
                <el-button
                  type="success"
                  size="mini"
                  icon="el-icon-s-custom"
                  plain
                  v-showFunction="{
                    active: 'interview',
                    array: allFunctionButtons,
                  }"
                  v-if="
                    scope.row.status === '新简历' ||
                      scope.row.status === '待面试'
                  "
                  @click.stop="handleClick(scope.row, 1)"
                  >面试</el-button
                >
                <el-button
                  type="success"
                  size="mini"
                  icon="el-icon-s-custom"
                  plain
                  v-showFunction="{
                    active: 'interviewResult',
                    array: allFunctionButtons,
                  }"
                  v-if="scope.row.status === '待面试'"
                  @click.stop="handleClick(scope.row, 2)"
                  >面试结果</el-button
                >

                <el-button
                  type="warning"
                  size="mini"
                  icon="el-icon-s-claim"
                  plain
                  v-showFunction="{
                    active: 'processEnrollment',
                    array: allFunctionButtons,
                  }"
                  v-if="scope.row.status === '待入职'"
                  @click.stop="handleClick(scope.row, 4)"
                  >办理入职</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-s-promotion"
                  plain
                  v-showFunction="{
                    active: 'share',
                    array: allFunctionButtons,
                  }"
                  @click.stop="handleClick(scope.row, 3)"
                  >共享</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-circle-close"
                  plain
                  v-showFunction="{
                    active: 'invalidate',
                    array: allFunctionButtons,
                  }"
                  v-if="scope.row.status !== '无效'"
                  @click.stop="handleClick(scope.row, 5)"
                  >置为无效</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="human-resume-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
            :page-sizes="[100, 300, 500, 1000]"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :clientName="dialogData.clientName"
        :dialogStyle="dialogData.dialogStyle"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @deleteItem="deleteItem"
        :allFunctionButtons="allFunctionButtons"
        @handleAvatarSuccess="handleAvatarSuccess"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @changeFlag="changeFlag"
      ></my_dialog>
    </div>
    <!-- 右边抽屉 -->
    <div id="my-drawer" v-if="humanDrawerData.flag">
      <my_drawer
        :drawerData="humanDrawerData"
        :allFunctionButtons="allFunctionButtons"
        :model="'humanResume'"
        @show="showDrawer"
        @saveDrawerData="saveDrawerData"
        @drawerOper="drawerOper"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @addTags="addTags"
        @tagsHandleClose="tagsHandleClose"
        @componentsOperation="componentsOperation"
      ></my_drawer>
    </div>
  </div>
</template>

<script>
import my_dialog from "@/components/dialog.vue";
import my_drawer from "@/components/my-drawer.vue";
import * as humanData from "@/views/human/js/humanData.js";
import * as employeeData from "../../assets/js/employeeData.js";

export default {
  components: {
    my_dialog,
    my_drawer,
  },
  data() {
    return {
      tabIndex: "新简历",
      loading: false,
      baseInfo: {},
      tableData: [],
      searchData: {
        input: "",
        source: [],
        reasonForStorage: "",
        talentSource: "",
        positionApplied: "",
        userId: "",
        userIdList: [],
        tempUserId: [],
        department: [],
        departTreeData: [],
        allDepartTreeData: [],
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "humanResume"
      ),
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 100, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      humanDrawerData: humanData.humanDrawerData(
        "简历详情",
        [],
        false,
        {},
        "简历库"
      ),
      whetherAgencyHead: false,
      defaultSort: { prop: "gmt_create", order: "descending" },
      tagList: {
        positionList: [], // 应聘职位
        resumeSourceList: [], // 简历来源
        storageReasonList: [], // 入库原因
        positionStatusList: [], // 在职状态
      },
      tempData: {},
      interviewData: {},
      popoData: {
        popoVisible: false,
        list: [],
      },
      allEmployeeList: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "humanResume",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getDepartmentTreeByUserId();
      });
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getDepartmentTree();
          this.getTagList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取部门
     */
    getDepartmentTree() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTree",
        requestData,
        (data) => {
          this.searchData.allDepartTreeData = [data];
          this.getTagList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取客户片区
     */
    getTagList() {
      let requestData = {
        type: ["应聘职位", "简历来源", "入库原因", "在职状态"],
        companyId: this.baseInfo.company.companyId,
      };
      const tagMapping = {
        应聘职位: "positionList",
        简历来源: "resumeSourceList",
        入库原因: "storageReasonList",
        在职状态: "positionStatusList",
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          const mapTagItems = (items) =>
            items.map((item) => ({
              label: item.name,
              value: item.value,
            }));

          data.forEach((element) => {
            const tagListKey = tagMapping[element.type];
            if (tagListKey) {
              this.tagList[tagListKey] = mapTagItems(element.value);
            }
          });

          console.log("tagList", this.tagList);
          this.getEmployeeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = this.baseInfo.userId;
          this.getEmployeeListForDownBox();
          this.selectInitTableData(this.baseInfo.userId);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有在职员工
     */
    getEmployeeListForDownBox() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.allEmployeeList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.allEmployeeList.push(dd);
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      // return;
      this.tableData = [];
      this.loading = true;
      let requestData = {
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "", // 部门 id
        userIdList: this.searchData.tempUserId, // 员工 id 集合
        userId: this.baseInfo.userId, // 当前登录用户
        input: this.searchData.input, // 名称/联系人/手机号
        status: this.tabIndex, // 简历状态
        reasonForStorage: this.searchData.reasonForStorage, // 筛选项 入库原因
        positionApplied: this.searchData.positionApplied, // 筛选项 应聘职位
        talentSource: this.searchData.talentSource, // 筛选项 简历来源
        workExperience: this.searchData.workExperience, // 筛选项 工作经历
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request(
        "post",
        "/talentResume/page",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.tableData.forEach((item) => {
            item.workExperience = item.workExperience
              ? JSON.parse(item.workExperience)
              : [];
          });
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 界面操作
     */
    handleClick(data, flag) {
      this.tempData = data;
      let requestData = {};
      switch (flag) {
        case 0:
          // 新增
          this.dialogData.width = "800px";
          this.dialogData.title = "添加简历";
          this.dialogData.dialogStyle = {
            flexDirection: "row",
            flexWrap: "wrap",
          };
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_add",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              width: "45%",
              type: "input",
              title: "姓名",
              key: "name",
              required: true,
              value: "",
            },
            {
              width: "45%",
              type: "cascader-tree",
              title: "应聘部门",
              key: "departmentApplied",
              required: true,
              value: "",
              options: this.searchData.allDepartTreeData,
              props: { value: "id", emitPath: false, checkStrictly: true },
            },
            {
              width: "45%",
              type: "select",
              title: "应聘职位",
              key: "positionApplied",
              required: true,
              value: "",
              options: this.tagList.positionList,
            },
            {
              width: "45%",
              type: "select",
              title: "简历来源",
              key: "talentSource",
              required: true,
              value: "",
              options: this.tagList.resumeSourceList,
            },
            {
              width: "45%",
              type: "select",
              title: "入库原因",
              key: "reasonForStorage",
              required: true,
              value: "",
              options: this.tagList.storageReasonList,
            },
            {
              width: "45%",
              type: "input",
              title: "手机号",
              key: "phone",
              required: true,
              value: "",
            },
            {
              width: "45%",
              type: "input",
              title: "微信号",
              key: "wechat",
              value: "",
            },
            {
              width: "45%",
              type: "select",
              title: "在职状态",
              key: "inductionStatus",
              required: true,
              value: "",
              options: this.tagList.positionStatusList,
            },
            {
              width: "45%",
              type: "textarea",
              title: "备注",
              key: "remark",
              value: "",
              rows: 1,
            },
            {
              width: "45%",
              title: "上传附件",
              key: "attachment",
              type: "upload_img_png",
              value: {
                value: "",
                action: this.$fileBaseurl + "/uploadImageByMultipartFile",
                headers: {
                  token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                  systemName: "finance",
                  title: "简历附件",
                },
              },
            },
          ];
          this.dialogData.flag = true;
          break;
        case 1:
          // 添加面试
          this.dialogData.width = "500px";
          this.dialogData.title = "添加面试信息";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_interview_add",
            },
            { text: "取消", active: "cancel" },
          ];
          this.dialogData.data = [
            {
              type: "select",
              title: "面试官",
              key: "interviewerId",
              value: "",
              options: this.allEmployeeList,
              required: true,
            },
            {
              type: "time",
              title: "面试时间",
              key: "interviewTime",
              value: "",
              pickerOptions: this.pickerOptions,
              required: true,
            },
            {
              type: "textarea",
              title: "面试备注",
              key: "interviewRemark",
              value: "",
              rows: 1,
            },
          ];
          this.dialogData.flag = true;
          break;
        case 2:
          // 面试结果
          this.dialogData.width = "600px";
          this.dialogData.title = "面试结果";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_result",
            },
            { text: "取消", active: "cancel" },
          ];
          this.dialogData.data = [
            {
              title: "面试情况",
              key: "status",
              type: "radio",
              value: "待入职",
              options: [
                {
                  label: "面试通过",
                  value: "待入职",
                },
                {
                  label: "面试不通过",
                  value: "无效",
                },
              ],
            },
            {
              type: "time",
              title: "入职时间",
              key: "entryDate",
              value: "",
              pickerOptions: this.pickerOptions,
            },
          ];
          this.dialogData.flag = true;
          break;
        case 3:
          // 共享简历
          this.dialogData.width = "500px";
          this.dialogData.title = "共享简历";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_share",
            },
            { text: "取消", active: "cancel" },
          ];
          this.dialogData.data = [
            {
              type: "select",
              title: "选择共享人员",
              key: "user",
              multiple: true,
              value: this.tempData.visibleUserIds.split(","),
              options: this.allEmployeeList,
              props: { value: "id", emitPath: false },
            },
          ];
          this.dialogData.flag = true;
          break;
        case 4:
          // 办理入职
          this.dialogData.width = "500px";
          this.dialogData.title = "办理入职";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_entry",
            },
            { text: "取消", active: "cancel" },
          ];
          this.dialogData.data = [
            {
              title: "入职情况",
              type: "radio",
              key: "status",
              value: "已入职",
              options: [
                {
                  label: "确认入职",
                  value: "已入职",
                },
                {
                  label: "面试通过, 放弃入职",
                  value: "放弃入职",
                },
              ],
            },
          ];
          this.dialogData.flag = true;
          break;
        case 5:
          // 置为无效
          requestData = {
            id: this.tempData.id, // 简历 id
            status: "无效",
          };
          this.$confirm("确认要将该简历置为无效吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.updateResume(requestData, "简历置为无效成功");
            })
            .catch(() => {});

          break;
        case 6:
          // 查看附件
          window.open(this.tempData.attachment, "_blank");
          break;
        case 7:
          // 编辑简历
          this.dialogData.width = "800px";
          this.dialogData.title = "编辑简历";
          this.dialogData.dialogStyle = {
            flexDirection: "row",
            flexWrap: "wrap",
          };
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_edit",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              width: "45%",
              type: "input",
              title: "姓名",
              key: "name",
              required: true,
              value: this.tempData.name,
            },
            {
              width: "45%",
              type: "cascader-tree",
              title: "应聘部门",
              key: "departmentApplied",
              required: true,
              value: this.tempData.departmentApplied,
              options: this.searchData.departTreeData,
              props: { value: "id", emitPath: false },
            },
            {
              width: "45%",
              type: "select",
              title: "应聘职位",
              key: "positionApplied",
              required: true,
              value: this.tempData.positionApplied,
              options: this.tagList.positionList,
            },
            {
              width: "45%",
              type: "select",
              title: "简历来源",
              key: "talentSource",
              required: true,
              value: this.tempData.talentSource,
              options: this.tagList.resumeSourceList,
            },
            {
              width: "45%",
              type: "select",
              title: "入库原因",
              key: "reasonForStorage",
              required: true,
              value: this.tempData.reasonForStorage,
              options: this.tagList.storageReasonList,
            },
            {
              width: "45%",
              type: "input",
              title: "手机号",
              key: "phone",
              required: true,
              value: this.tempData.phone,
            },
            {
              width: "45%",
              type: "input",
              title: "微信号",
              key: "wechat",
              value: this.tempData.wechat,
            },
            {
              width: "45%",
              type: "select",
              title: "在职状态",
              key: "inductionStatus",
              required: true,
              value: this.tempData.inductionStatus,
              options: this.tagList.positionStatusList,
            },
            {
              width: "45%",
              type: "input",
              title: "备注",
              key: "remark",
              value: this.tempData.remark,
            },
            {
              width: "45%",
              title: "上传附件",
              key: "attachment",
              type: "upload_img_png",
              value: {
                value: this.tempData.attachment || "",
                action: this.$fileBaseurl + "/uploadImageByMultipartFile",
                headers: {
                  token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                  systemName: "finance",
                  title: "简历附件",
                },
              },
            },
          ];
          this.dialogData.flag = true;
          break;
        default:
          break;
      }
    },
    changeFlag(val) {
      let data = {
        type: "time",
        title: "入职时间",
        key: "entryDate",
        value: "",
      };
      if (val == "待入职") {
        this.dialogData.data.push(data);
      }
      if (val == "无效") {
        this.dialogData.data = this.dialogData.data.filter((item) => {
          return item.key != "entryDate";
        });
      }
    },
    /**
     * 查询所有员工
     */
    getAllEmployee() {
      this.$request(
        "get",
        "/user/getAllEmployee",
        {},
        (data) => {
          this.allEmployeeList = data.data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      let flag = false;
      switch (active) {
        case "sure_add":
          // 确认添加简历
          requestData = {};
          flag = false;
          this.dialogData.data.forEach((item) => {
            requestData[item.key] = item.value;
            if (!requestData[item.key] && item.required) flag = true;
          });
          requestData.attachment = this.dialogData.data[9].value.value; // 附件
          requestData.createUserId = this.baseInfo.userId; // 创建人
          if (flag) return this.$message.error("请将信息填写完整");
          this.$request(
            "post",
            "/talentResume/add",
            requestData,
            (data) => {
              this.$message.success("简历添加成功");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_interview_add":
          // 确认添加面试信息
          requestData = {
            talentResumeId: this.tempData.id, // 人才简历 id
            interviewerId: this.dialogData.data[0].value, // 面试官 id
            interviewTime: this.dialogData.data[1].value, // 面试时间
            interviewRemark: this.dialogData.data[2].value, // 面试备注
          };
          if (!this.dialogData.data[0].value) {
            this.$message.error("面试官不能为空");
            return;
          }
          if (!this.dialogData.data[1].value) {
            this.$message.error("面试时间不能为空");
            return;
          }
          this.$request(
            "post",
            "/talentInterview/add",
            requestData,
            (data) => {
              this.$message.success("添加面试信息成功");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_interview_edit":
          // 确认编辑面试
          requestData = {
            id: this.interviewData.id, // 面试信息 id
            talentResumeId: this.tempData.id, // 人才简历 id
            interviewerId: this.dialogData.data[0].value, // 面试官 id
            interviewTime: this.dialogData.data[1].value, // 面试时间
            interviewRemark: this.dialogData.data[2].value, // 面试备注
          };
          if (!this.dialogData.data[0].value) {
            this.$message.error("面试官不能为空");
            return;
          }
          if (!this.dialogData.data[1].value) {
            this.$message.error("面试时间不能为空");
            return;
          }
          this.$request(
            "post",
            "/talentInterview/update",
            requestData,
            (data) => {
              this.$message.success("编辑面试信息成功");
              this.dialogData.flag = false;
              this.initTableData();
              this.rowClick(this.tempData);
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_add_company_record":
          // 确认添加工作经历
          requestData = {};
          let workExperience = JSON.parse(
            JSON.stringify(this.tempData.workExperience)
          );
          flag = false;
          this.dialogData.data.forEach((item) => {
            requestData[item.key] = item.value;
            if (!requestData[item.key] && item.required) flag = true;
          });
          if (flag) return this.$message.error("请将信息填写完整");
          workExperience.push(requestData);
          workExperience.sort(
            (a, b) => new Date(a.time[0]) - new Date(b.time[0])
          );
          workExperience = JSON.stringify(workExperience);
          this.updateResume(
            { workExperience, id: this.tempData.id },
            "添加工作经历成功"
          );
          // 手动修改当前行数据
          this.tempData.workExperience.push(requestData);
          this.tempData.workExperience.sort(
            (a, b) => new Date(a.time[0]) - new Date(b.time[0])
          );
          this.rowClick(this.tempData);
          break;
        case "sure_share":
          // 确认共享简历
          requestData = {
            id: this.tempData.id, // 简历 id
            visibleUserIds: this.dialogData.data[0].value.join(","), // 共享人 id
          };
          this.updateResume(requestData, "简历共享成功");
          break;
        case "sure_result":
          // 确认面试结果
          requestData = {
            id: this.tempData.id, // 简历 id
            status: this.dialogData.data[0].value, // 简历状态
          };
          if (requestData.status == "待入职") {
            requestData.entryDate = this.dialogData.data[1].value;
            if (!requestData.entryDate)
              return this.$message.error("入职时间不能为空!");
          }
          this.updateResume(requestData, "面试结果确认成功");
          break;
        case "sure_entry":
          // 确认入职情况
          requestData = {
            id: this.tempData.id, // 简历 id
            status: this.dialogData.data[0].value, // 简历状态
          };
          this.$request(
            "post",
            "/talentResume/update",
            requestData,
            (data) => {
              this.$message.success("编辑成功");
              this.dialogData.flag = false;
              this.initTableData();
              if (requestData.status == "已入职") {
                this.dialogData.width = "500px";
                this.dialogData.title = "办理入职";
                this.dialogData.buttonArray = [
                  {
                    text: "确认",
                    active: "sure_entry",
                  },
                  { text: "取消", active: "cancel" },
                ];
                // 新建员工
                this.dialogData.width = "30%";
                this.dialogData.title = "新增员工";
                this.dialogData.buttonArray = [
                  {
                    text: "确认",
                    active: "add_employee",
                  },
                  {
                    text: "取消",
                    active: "cancel",
                  },
                ];
                this.dialogData.data = employeeData.createDialogData(
                  this.searchData.allDepartTreeData
                );
                this.dialogData.flag = true;
              }
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_edit":
          requestData = {};
          flag = false;
          this.dialogData.data.forEach((item) => {
            requestData[item.key] = item.value;
            if (!requestData[item.key] && item.required) flag = true;
          });
          requestData.id = this.tempData.id; // 简历 id
          requestData.attachment = this.dialogData.data[9].value.value; // 附件
          if (flag) return this.$message.error("请将信息填写完整");
          this.updateResume(requestData);
          break;
        case "add_employee":
          // 确定员工入职
          flag = false;
          requestData = {};
          this.dialogData.data.forEach((item) => {
            requestData[item.key] = item.value;
            if (!requestData[item.key] && item.required) flag = true;
          });
          requestData.icon = this.$baseimgurl; // 默认图像
          requestData.psw = "111111"; // 初始密码
          requestData.companyId = this.baseInfo.company.companyId; // 公司ID
          if (flag) return this.$message.error("请将信息填写完整");
          requestData.file = this.dialogData.data[8].value.value; // 合同附件
          if (!requestData.file) {
            return this.$message.error("请将信息填写完整");
          }
          this.$request(
            "post",
            "/user/createEmployee",
            requestData,
            (data) => {
              this.$message.success("员工创建成功");
              this.dialogData.flag = false;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    updateResume(requestData) {
      this.$request(
        "post",
        "/talentResume/update",
        requestData,
        (data) => {
          this.$message.success("编辑成功");
          this.dialogData.flag = false;
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    handleAvatarSuccess(data) {
      this.$message.success("上传成功");
      if (data.result.title == "简历附件") {
        this.dialogData.data[9].value.value = data.result.path;
      } else if (data.result.title == "合同附件") {
        this.dialogData.data[8].value.value = data.result;
      }
    },
    deleteItem(index) {
      console.log("index", index);
      this.dialogData.data[9].value.value = "";
    },
    /**
     * tab切换事件
     */
    changeTab(index) {
      this.tabIndex = index;

      this.initTableData();
    },
    /**
     * 表格单行点击事件
     */
    rowClick(row) {
      if (!this.popoData.popoVisible) {
        this.tempData = row;
        this.$featureData.getAttributeData(
          "humanResume",
          false,
          humanData.returnDrawerKeys("humanResume", "简历库"),
          (authorityData) => {
            this.drawerAuthorityData = authorityData;
            // 2.拿对应属性的权限
            this.$authority.getModelFeatureAuthority(
              this,
              "humanResume",
              authorityData,
              (authorityList) => {
                // 3.将权限数据对应到界面
                let requestData = {
                  currentPage: -1,
                  pageSize: 10,
                  talentResumeId: this.tempData.id,
                };
                this.$request(
                  "post",
                  "/talentInterview/page",
                  requestData,
                  (data) => {
                    let allUser = this.allEmployeeList.map((item) => {
                      return {
                        id: item.value,
                        name: item.label,
                      };
                    });
                    this.tempData.interviewRecord = {
                      title: "面试记录",
                      list: data.records,
                    };
                    this.humanDrawerData = humanData.humanDrawerData(
                      this.tempData.name,
                      authorityList,
                      this.whetherAgencyHead, // 是否是机构负责人
                      {
                        allUser,
                      },
                      "简历库"
                    );
                    this.humanDrawerData = this.$initDrawerAuthorityData.initDrawerData(
                      this.humanDrawerData,
                      this.tempData
                    );
                    this.humanDrawerData.flag = true;
                  },
                  (errorMessage) => {
                    this.$message.error(errorMessage);
                  }
                );
              }
            );
          }
        );
        // 3.将权限数据对应到界面
      } else {
        this.popoCanael();
      }
    },
    componentsOperation(data) {
      switch (data.model) {
        case "interviewRecord":
          // 面试记录
          this.interviewData = data.value;
          this.dialogData.width = "500px";
          this.dialogData.title = "";
          this.dialogData.buttonArray = [];
          this.dialogData.data = [
            {
              type: "select",
              title: "面试官",
              key: "interviewerId",
              value: "",
              required: true,
              options: this.allEmployeeList,
            },
            {
              type: "time",
              title: "面试时间",
              key: "interviewTime",
              value: "",
              required: true,
              pickerOptions: this.pickerOptions,
            },
            {
              type: "textarea",
              title: "面试备注",
              key: "interviewRemark",
              value: "",
            },
          ];
          if (data.value) {
            // 编辑
            this.dialogData.title = "编辑面试信息";
            this.dialogData.buttonArray = [
              {
                text: "确认",
                active: "sure_interview_edit",
              },
              { text: "取消", active: "cancel" },
            ];
            this.dialogData.data.forEach((item) => {
              item.value = data.value[item.key];
            });
          } else {
            // 新增
            this.dialogData.title = "添加面试信息";
            this.dialogData.buttonArray = [
              {
                text: "确认",
                active: "sure_interview_add",
              },
              { text: "取消", active: "cancel" },
            ];
          }
          this.dialogData.flag = true;
          break;
        case "interviewCompanyRecord":
          // 工作经历
          this.dialogData.width = "500px";
          this.dialogData.title = "添加工作经历";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_add_company_record",
            },
            { text: "取消", active: "cancel" },
          ];
          this.dialogData.data = [
            {
              type: "input",
              title: "公司名称",
              key: "company",
              value: "",
              required: true,
            },
            {
              type: "input",
              title: "职位",
              key: "position",
              value: "",
              required: true,
            },
            {
              type: "textarea",
              title: "工作内容",
              key: "content",
              value: "",
              required: true,
            },
            {
              type: "time_date_scope",
              title: "工作时间",
              key: "time",
              value: "",
              required: true,
            },
          ];
          this.dialogData.flag = true;
          break;
        default:
          break;
      }
    },
    /**
     * 右边抽屉父子组件连锁事件
     */
    showDrawer(value) {
      this.humanDrawerData = value;
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.humanDrawerData.flag = false;
    },
    saveDrawerData() {},
    /**
     * 添加标签事件
     */
    addTags(data) {
      // if (
      //   this.clientInfoDrawerData.data.modelList[data.i].line[data.j].type ==
      //   "tags-select-obj"
      // ) {
      if (this.humanDrawerData.data.modelList[data.i].line[data.j].isSelect) {
        let value = this.humanDrawerData.data.modelList[data.i].line[data.j]
          .tempValue;

        if (
          value &&
          this.humanDrawerData.data.modelList[data.i].line[
            data.j
          ].value.indexOf(value) < 0
        ) {
          // 排除重复选项
          this.humanDrawerData.data.modelList[data.i].line[data.j].value.push(
            value
          );
          this.addAllocation(value.id);
        }
        this.humanDrawerData.data.modelList[data.i].line[
          data.j
        ].isSelect = false;
        this.humanDrawerData.data.modelList[data.i].line[data.j].tempValue = "";
      } else {
        this.humanDrawerData.data.modelList[data.i].line[
          data.j
        ].isSelect = true;
      }
      // }
    },
    /**-
     * 抽屉中tag移除事件
     * data(tag,i,j):tag->移除的tag,i为第几个model,j为第几line
     */
    tagsHandleClose(data) {
      console.log("data", data);
      this.humanDrawerData.data.modelList[data.i].line[data.j].value.splice(
        this.humanDrawerData.data.modelList[data.i].line[data.j].value.indexOf(
          data.tag
        ),
        1
      );
      // this.deleteAllocation(data.tag.id);
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.resetPage();
    },
    /**
     * 表格排序事件
     */
    sortChange(data) {
      this.defaultSort.prop = data.prop ? "gmt_create" : "";
      this.defaultSort.order = data.order == null ? "" : data.order;
      this.initTableData();
    },
    resetPage() {
      this.pageData.currentPage = 1;
      this.initTableData();
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#human-resume {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .human-resume-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .human-resume-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      box-sizing: border-box;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .human-resume-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .human-resume-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .human-resume-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        /deep/ .el-cascader {
          .el-cascader__tags {
            flex-wrap: nowrap;
          }
        }
      }
      .human-resume-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        &:hover {
          background-color: #abbed3;
        }
      }
      .human-resume-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .human-resume-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .human-resume-screen-but-boder {
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
        padding: 0 15px;
      }
      .human-resume-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .human-resume-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .human-resume-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .human-resume-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .human-resume-table-card-tab {
          width: 120px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .human-resume-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
      }
      .human-resume-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        /deep/ .el-input__inner {
          border: 0;
          background-color: #fff;
        }
        /deep/ .el-input__suffix {
          display: none;
        }
        /deep/ .el-select__tags {
          flex-wrap: nowrap;
        }
        .table-image {
          width: 40px;
          height: 40px;
        }
        .phone {
          color: red;
          transform: rotateY(180deg);
          cursor: pointer;
          margin-left: 3px;
        }
      }
      .human-resume-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;

        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
