/**
 * 获得角色列表
 */
export function getAuthorityList() {
    let data = [{
        id: "",
        authName: "系统管理员",
        companyId: "", //公司ID
        company: "公司一", //公司名称
        departmentId: "", // 部门ID
        department: "---",
        des: "" // 描述
    }, {
        id: "",
        authName: "机构负责人",
        companyId: "", //公司ID
        company: "公司一", //公司名称
        departmentId: "", // 部门ID
        department: "---",
        des: "" // 描述
    }, {
        id: "",
        authName: "公司主管",
        companyId: "", //公司ID
        company: "公司一", //公司名称
        departmentId: "", // 部门ID
        department: "部门一",
        des: "" // 描述
    }, {
        id: "",
        authName: "部门主管",
        companyId: "", //公司ID
        company: "公司一", //公司名称
        departmentId: "", // 部门ID
        department: "部门一",
        des: "" // 描述
    }, {
        id: "",
        authName: "员工",
        companyId: "", //公司ID
        company: "公司一", //公司名称
        departmentId: "", // 部门ID
        department: "部门一",
        des: "" // 描述
    }, {
        id: "",
        authName: "服务顾问",
        companyId: "", //公司ID
        company: "公司一", //公司名称
        departmentId: "", // 部门ID
        department: "部门二",
        des: "" // 描述
    }, {
        id: "",
        authName: "财务会计",
        companyId: "", //公司ID
        company: "公司一", //公司名称
        departmentId: "", // 部门ID
        department: "部门三",
        des: "" // 描述
    }, {
        id: "",
        authName: "税务会计",
        companyId: "", //公司ID
        company: "公司一", //公司名称
        departmentId: "", // 部门ID
        department: "部门二",
        des: "" // 描述
    }]
    return data
}

/**
 * 系统权限设置数据-->商机模块
 */
export function getSystemThreadAuthorityData() {
    let data = {
        model: "thread", // 模块
        modelName: "商机", // 模块
        modelShow: true, // 当前模块的可见性
        modelMenuAuthor: [{ // 当前模块的子菜单的可见性
            menu: "我的商机",
            model: "my-thread",
            isShow: true
        }, {
            menu: "全部商机",
            model: "all-thread",
            isShow: true
        }, {
            menu: "商机池",
            model: "threads",
            isShow: true
        }, {
            menu: "回收站",
            model: "thread-recyle",
            isShow: true
        }, {
            menu: "设置",
            model: "thread-setting",
            isShow: true
        }],
        authorData: [{ // 数据的权限
            title: "销售商机",
            model: "my-thread",
            buttonAuth: [{ // 操作按钮的权限
                title: "新增客户商机",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "导出",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "跟进",
                active: "genjin",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "转成交",
                active: "zhuanchengjiao",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "恢复商机",
                active: "recover_thread",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "查看客户",
                active: "showClient",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "置为无效",
                active: "zhiweiwuxiao",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "释放至商机池",
                active: "shifang",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "删除",
                active: "delete_thread",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "操作记录",
                active: "operating_record",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }],
            data: [{
                title: "商机名称",
                value: 1, // 1为读写;2为只读;3为不可见
                width: "100",
                key: "threadName",
                isShow: true,
                isDisable: false
            }, {
                title: "跟进联系人",
                value: 1, // 1为读写;2为只读;3为不可见
                width: "200",
                key: "followName",
                isShow: true,
                isDisable: false
            }, {
                title: "商机状态",
                key: "threadStatus",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "跟进阶段",
                key: "followStage",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "所属部门",
                key: "department",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "片区",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "商机创建人",
                key: "createName",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "客户来源",
                key: "customerSource",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "联系人",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "手机",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "增值税类型",
                key: "addedValueTaxType",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "创建时间",
                key: "createTime",
                width: "120",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "地区",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "详细地址",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "意向产品",
                key: "intentionalProduct",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "备注",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "跟进记录",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "上次联系时间",
                key: "lastContactTime",
                width: "120",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "下次联系时间",
                key: "nextContactTime",
                width: "120",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "最近分配时间",
                key: "allocateTime",
                width: "150",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }]
        }, { // 数据的权限
            title: "全部商机",
            model: "all-thread",
            buttonAuth: [{ // 操作按钮的权限
                title: "导出",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "跟进",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "转成交",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "恢复商机",
                active: "recover_thread",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "查看客户",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "置为无效",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "释放至商机池",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "删除",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "操作记录",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }],
            data: [{
                title: "商机名称",
                value: 1, // 1为读写;2为只读;3为不可见
                width: "100",
                key: "threadName",
                isShow: true,
                isDisable: false
            }, {
                title: "跟进联系人",
                value: 1, // 1为读写;2为只读;3为不可见
                width: "200",
                key: "followName",
                isShow: true,
                isDisable: false
            }, {
                title: "商机状态",
                key: "threadStatus",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "跟进阶段",
                key: "followStage",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "所属部门",
                key: "department",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "片区",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "商机创建人",
                key: "createName",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "客户来源",
                key: "customerSource",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "联系人",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "手机",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "增值税类型",
                key: "addedValueTaxType",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "创建时间",
                key: "createTime",
                width: "120",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "地区",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "详细地址",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "意向产品",
                key: "intentionalProduct",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "备注",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "跟进记录",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "上次联系时间",
                key: "lastContactTime",
                width: "120",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "下次联系时间",
                key: "nextContactTime",
                width: "120",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "最近分配时间",
                key: "allocateTime",
                width: "150",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }]
        }, { // 数据的权限
            title: "商机池",
            model: "threads",
            buttonAuth: [{ // 操作按钮的权限
                title: "导出",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "分配",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "转成交",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "恢复商机",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "删除",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "操作记录",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }],

            data: [{
                title: "商机名称",
                value: 1, // 1为读写;2为只读;3为不可见
                width: "100",
                key: "threadName",
                isShow: true,
                isDisable: false
            }, {
                title: "跟进联系人",
                value: 1, // 1为读写;2为只读;3为不可见
                width: "200",
                key: "followName",
                isShow: true,
                isDisable: false
            }, {
                title: "商机状态",
                key: "threadStatus",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "跟进阶段",
                key: "followStage",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "所属部门",
                key: "department",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "片区",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "商机创建人",
                key: "createName",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "客户来源",
                key: "customerSource",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "联系人",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "手机",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "增值税类型",
                key: "addedValueTaxType",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "创建时间",
                key: "createTime",
                width: "120",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "地区",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "详细地址",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "意向产品",
                key: "intentionalProduct",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "备注",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "跟进记录",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "上次联系时间",
                key: "lastContactTime",
                width: "120",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "下次联系时间",
                key: "nextContactTime",
                width: "120",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "最近分配时间",
                key: "allocateTime",
                width: "150",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }]
        }, { // 数据的权限
            title: "回收站",
            model: "thread-recyle",
            buttonAuth: [{ // 操作按钮的权限
                title: "导出",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "还原",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "恢复商机",
                active: "recover_thread",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "彻底删除",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "操作记录",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }],

            data: [{
                title: "商机名称",
                value: 1, // 1为读写;2为只读;3为不可见
                width: "100",
                key: "threadName",
                isShow: true,
                isDisable: false
            }, {
                title: "跟进联系人",
                value: 1, // 1为读写;2为只读;3为不可见
                width: "200",
                key: "followName",
                isShow: true,
                isDisable: false
            }, {
                title: "商机状态",
                key: "threadStatus",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "跟进阶段",
                key: "followStage",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "所属部门",
                key: "department",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "片区",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "商机创建人",
                key: "createName",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "客户来源",
                key: "customerSource",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "联系人",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "手机",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "增值税类型",
                key: "addedValueTaxType",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "创建时间",
                key: "createTime",
                width: "120",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "地区",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "详细地址",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "意向产品",
                key: "intentionalProduct",
                width: "100",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "备注",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "跟进记录",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "上次联系时间",
                key: "lastContactTime",
                width: "120",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "下次联系时间",
                key: "nextContactTime",
                width: "120",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "最近分配时间",
                key: "allocateTime",
                width: "150",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }]
        }, { // 数据的权限
            title: "设置",
            model: "thread-setting",
            buttonAuth: [{
                title: "选项值设置",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "流程设置",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "权限设置",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }],
            data: []
        }]
    }
    return data
}

/**
 * 系统权限设置数据-->合同模块
 */
export function getSystemContractAuthorityData() {
    let data = {
        model: "contract", // 模块
        modelName: "合同", // 模块
        modelShow: true, // 当前模块的可见性
        modelMenuAuthor: [{ // 当前模块的子菜单的可见性
            menu: "周期合同",
            model: "contract-info",
            isShow: true
        }, {
            menu: "合同审核",
            model: "contract-check",
            isShow: true
        }, {
            menu: "合同派工",
            model: "contract-work",
            isShow: true
        }, {
            menu: "续签管理",
            model: "contract-renew",
            isShow: true
        }, {
            menu: "设置",
            model: "contract-setting",
            isShow: true
        }],
        authorData: [{ // 数据的权限
            title: "周期合同",
            model: "contract-info",
            buttonAuth: [{ // 操作按钮的权限
                title: "汇款",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "删除合同",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "正常结束",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "异常终止",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "操作记录",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }],
            data: [{
                title: "合同状态",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "推荐人",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "签单人",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "签单日期",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "来源",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "产品名称",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "合同编号",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "续签状态",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "签约期限",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "合同有效期",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "自动续签",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "凭证交接",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "服务项目",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "回款信息",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "收费情况",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "合同支出",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "合同附件",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }]
        }, { // 数据的权限
            title: "合同审核",
            model: "contract-check",
            buttonAuth: [{ // 操作按钮的权限
                title: "汇款",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "删除合同",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "正常结束",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "异常终止",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "操作记录",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }],
            data: [{
                title: "合同状态",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "推荐人",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "签单人",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "签单日期",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "来源",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "产品名称",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "合同编号",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "续签状态",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "签约期限",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "合同有效期",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "自动续签",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "凭证交接",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "服务项目",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "回款信息",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "收费情况",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "合同支出",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "合同附件",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }]
        }, { // 数据的权限
            title: "合同派工",
            model: "contract-work",
            buttonAuth: [{ // 操作按钮的权限
                title: "汇款",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "删除合同",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "正常结束",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "异常终止",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "操作记录",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }],
            data: [{
                title: "合同状态",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "推荐人",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "签单人",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "签单日期",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "来源",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "产品名称",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "合同编号",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "续签状态",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "签约期限",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "合同有效期",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "自动续签",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "凭证交接",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "服务项目",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "回款信息",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "收费情况",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "合同支出",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "合同附件",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }]
        }, { // 数据的权限
            title: "续签管理",
            model: "contract-renew",
            buttonAuth: [{ // 操作按钮的权限
                title: "汇款",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "删除合同",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "正常结束",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "异常终止",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "操作记录",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }],
            data: [{
                title: "合同状态",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "推荐人",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "签单人",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "签单日期",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "来源",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "产品名称",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "合同编号",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "续签状态",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "签约期限",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "合同有效期",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "自动续签",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "凭证交接",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "服务项目",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "回款信息",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "收费情况",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "合同支出",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "合同附件",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }]
        }, { // 数据的权限
            title: "设置",
            model: "contract-setting",
            buttonAuth: [{
                title: "权限设置",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "流程设置",
                value: 1, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }],
            data: []
        }]
    }
    return data
}

/**
 * 系统权限设置数据--> 系统操作
 */
export function getSystemOperationAuthorityData() {
    let data = {
        model: "system", // 模块
        modelName: "系统设置", // 模块
        modelShow: true, // 当前模块的可见性
        modelMenuAuthor: [{ // 当前模块的子菜单的可见性
            menu: "机构设置",
            model: "company-system",
            isShow: true
        }, {
            menu: "用户设置",
            model: "employ-system",
            isShow: true
        }, {
            menu: "部门设置",
            model: "department-system",
            isShow: true
        }],
        authorData: [{ // 数据的权限
            title: "机构设置",
            model: "company-system",
            buttonAuth: [{
                title: "新建机构",
                value: 2, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "修改机构信息",
                value: 2, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "删除机构",
                value: 2, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }],
            data: []
        }, { // 数据的权限
            title: "用户设置",
            model: "employ-system",
            buttonAuth: [{
                title: "新建员工",
                value: 2, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "修改员工信息",
                value: 2, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "删除员工",
                value: 2, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }],
            data: []
        }, { // 数据的权限
            title: "部门设置",
            model: "department-system",
            buttonAuth: [{
                title: "编辑公司信息",
                value: 2, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "新增部门",
                value: 2, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "编辑部门",
                value: 2, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "删除部门",
                value: 2, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "查看部门角色",
                value: 2, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "新增角色",
                value: 2, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "编辑角色",
                value: 2, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "删除角色",
                value: 2, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "编辑角色权限",
                value: 2, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }, {
                title: "角色分配",
                value: 2, // 1为读写;2为只读;3为不可见
                isShow: true,
                isDisable: false
            }],
            data: []
        }]
    }
    return data
}

/**
 * 权限值格式化
 */
export function formatValueData(array) {
    let newArray = []
    if (array.length == 0) {
        return []
    }
    array.forEach(element => {
        if (element.value == 1) {
            // 读写
            element.isShow = true
            element.isDisable = false
        } else if (element.value == 2) {
            // 只读
            element.isShow = true
            element.isDisable = true
        } else {
            // 不可见
            element.isShow = false
            element.isDisable = true
        }
        newArray.push(element)
    });
    return newArray
}

/**
 * 获取树属性options
 */
export function getTreeOptions(treeData) {
    let option = {
        tooltip: {
            trigger: "item",
            triggerOn: "mousemove",
            // formatter: "{a}:{b}:{c}"
        },
        series: [{
            type: "tree",
            data: treeData,

            left: "5%",
            right: "5%",
            // top: "8%",
            // bottom: "10%",
            // width: "90%",
            edgeShape: "polyline",

            symbol: "rectangle", //结点的形状 图形
            symbolSize: [80, 30], //形状大小 图形大小

            initialTreeDepth: -1, //默认全展开
            orient: "vertical", // horizontal,,vertical
            edgeForkPosition: "50%", //结点内容到根节点之间的距离
            expandAndCollapse: true,

            itemStyle: {
                color: "rgba(56, 222, 235, 1)",
                // borderColor: "rgba(255, 255, 255, 0)",
                borderWidth: 0,
            },
            label: {
                //描述文本
                position: "inside",
                formatter: function(name) {
                    if (!name.data.name) return "";
                    if (name.data.name.length > 4) {
                        return name.data.name.slice(0, 4) + "...";
                    }
                    return name.data.name;
                },
                rotate: 0,
                verticalAlign: "middle",
                align: "center",
                fontSize: 14,
                position: ["50%", "50%"], //相对于图形的偏移位置
            },

            leaves: {
                //叶子结点
                label: {
                    position: "inside",
                    rotate: 0,
                    verticalAlign: "middle",
                    align: "center",
                    formatter: function(name) {
                        if (!name.data.name) return "";
                        if (name.data.name.length > 5) {
                            return name.data.name.slice(0, 5) + "...";
                        }
                        return name.data.name;
                    },
                    rotate: 0,
                    position: ["50%", "50%"],
                },
            },

            animationDurationUpdate: 750,
        }, ],
    }
    return option
}

/**
 * 获取部门树数据
 */
export function getDepartmentTreeData() {
    let treeData = [{
        id: "gongsiId",
        fatherId: "",
        name: "公司",
        children: [{
            id: "bumenId1",
            fatherId: "gongsiId",
            name: "部门一",
            children: [{
                id: "bumen1-1",
                fatherId: "bumenId1",
                name: "部门一1组"
            }, {
                id: "bumen1-2",
                fatherId: "bumenId1",
                name: "部门一2组"
            }]
        }, {
            id: "bumenId2",
            fatherId: "gongsiId",
            name: "部门二",
            children: [{
                id: "bumen2-1",
                fatherId: "bumenId2",
                name: "部门二1组"
            }, {
                id: "bumen2-2",
                fatherId: "bumenId2",
                name: "部门二2组"
            }]
        }, {
            id: "bumenId3",
            fatherId: "gongsiId",
            name: "部门三"
        }]
    }]
    return treeData
}

/**
 * 根据id找到对应的节点
 */
export function findNodeById(id, tree) {
    if (tree.id == id) {
        return tree
    }
    if (tree.children) {
        for (let index = 0; index < tree.children.length; index++) {
            let element = findNodeById(id, tree.children[index]);
            if (element) {
                return element;
            }

        }
    }
}

/**
 * 获取角色树数据
 */
export function getAuthorityTreeData(department) {
    let treeData = [{
        id: "bumenID",
        fatherId: "",
        name: department,
        children: [{
            id: "zhuguan1",
            fatherId: "bumenID",
            name: "主管1",
            children: [{
                id: "kuaiji1-1",
                fatherId: "zhuguan1",
                name: "会计1"
            }, {
                id: "kuaiji1-2",
                fatherId: "zhuguan1",
                name: "会计2"
            }]
        }, {
            id: "zhuguan2",
            fatherId: "bumenID",
            name: "主管2",
            children: [{
                id: "kuaji2-1",
                fatherId: "zhuguan2",
                name: "会计3"
            }, {
                id: "kuaiji2-2",
                fatherId: "zhuguan2",
                name: "会计4"
            }]
        }, {
            id: "kuaiji3",
            fatherId: "bumenID",
            name: "会计5"
        }]
    }]
    return treeData
}

/**
 * huo
 */