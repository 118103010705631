var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"archives-setting"}},[_c('div',{staticClass:"archives-table-content"},[_c('div',{staticClass:"archives-table-card"},[_c('div',{staticClass:"archives-table-card-content"},[_c('div',{staticClass:"archives-table-card-content-tags-setting"},[_c('div',{staticClass:"archives-table-card-content-tags-setting-left"},_vm._l((_vm.menuData),function(item,index){return _c('div',{key:index,class:{
                'archives-table-card-content-tags-setting-left-line': true,
                'is-active': item.name == _vm.curMenu,
              },on:{"click":function($event){return _vm.changeTab(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"archives-table-card-content-tags-setting-right"},[_c('div',{staticClass:"archives-table-card-content-tags-setting-right-add"},[_c('div',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
                  active: 'add',
                  array: _vm.allFunctionButtons,
                }),expression:"{\n                  active: 'add',\n                  array: allFunctionButtons,\n                }"}],staticClass:"butt",on:{"click":_vm.addTags}},[_vm._v(" 添加选项 ")])]),_c('div',{staticClass:"archives-table-card-content-tags-setting-right-tree"},[_c('el-tree',{attrs:{"data":_vm.list,"node-key":"id","props":_vm.defaultProps,"expand-on-click-node":false,"draggable":"","default-expand-all":false,"default-expanded-keys":_vm.defaultExpandedKeys},on:{"node-drop":_vm.handleDrop},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var data = ref.data;
                var node = ref.node;
return [_c('el-row',{staticClass:"archives-table-card-content-tags-setting-right-tree-line",attrs:{"type":"flex","align":"middle"}},[_c('el-col',{staticStyle:{"text-align":"left"},attrs:{"span":21},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.handleNodeClick(data, node)}}},[(data.flag)?_c('el-input',{directives:[{name:"focus",rawName:"v-focus"}],on:{"blur":_vm.updateInput},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return $event.target.blur($event)}},model:{value:(_vm.curInput),callback:function ($$v) {_vm.curInput=$$v},expression:"curInput"}}):_c('span',[_c('span',[_vm._v(_vm._s(data.name))])])],1),_c('el-col',{attrs:{"span":3}},[_c('el-row',{attrs:{"type":"flex","justify":"end"}},[_c('el-col',[_vm._v(_vm._s(data.manager))]),_c('el-col',[(data.children)?_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('span',[_vm._v(" 操作"),_c('i',{staticClass:"el-icon-arrow-down"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{directives:[{name:"showFunction",rawName:"v-showFunction",value:({
                                  active: 'addChildren',
                                  array: _vm.allFunctionButtons,
                                }),expression:"{\n                                  active: 'addChildren',\n                                  array: allFunctionButtons,\n                                }"}],nativeOn:{"click":function($event){return _vm.addClick(data, node)}}},[_vm._v(" 添加 ")])],1)],1):_vm._e()],1)],1)],1)],1)]}}])})],1)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }