<template>
  <div id="add_reimbursemen_itemt">
    <!-- 报销详情显示 -->
    <div class="top">
      <div class="is">
        <div class="ist">
          <span style="width: 15px; color: red">*</span><span>报销类型:</span>
        </div>
        <div class="isv">
          <el-select
            v-model="data.type"
            placeholder="请选择"
            clearable
            filterable
            :disabled="!flag"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="item" v-for="(item, index) in data.detail" :key="index">
        <div class="line">
          <div class="money">
            <div class="title">
              <span style="width: 15px; color: red">*</span>明细金额:
            </div>
            <div class="value">
              <el-input
                v-model="item.amount"
                placeholder="请输入"
                clearable
                :disabled="!flag"
                @change="changeAmount(item, index)"
              ></el-input>
            </div>
            <div class="moneyText">
              (大写:<span>{{ item.amountText }}</span
              >)
            </div>
          </div>
          <div class="time">
            <div class="title">
              <span style="width: 15px; color: red">*</span>发生时间
            </div>
            <div class="value">
              <el-date-picker
                v-model="item.occurTime"
                type="date"
                placeholder="选择日期"
                :disabled="!flag"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="line">
          <div class="tie">
            <span style="width: 15px; color: red"></span>支付方式:
          </div>
          <div class="vas">
            <el-select
              v-model="item.payWay"
              placeholder="请选择"
              clearable
              filterable
              :disabled="!flag"
            >
              <el-option
                v-for="item in payOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="tie">
            <span style="width: 15px; color: red"></span>报销说明:
          </div>
          <div class="vas">
            <el-input
              v-model="item.remark"
              placeholder="请输入"
              type="textarea"
              :rows="2"
              clearable
              :disabled="!flag"
            ></el-input>
          </div>
        </div>
        <div class="line">
          <div class="tie">
            <span style="width: 15px; color: red"></span>报销附件:
          </div>
          <div class="icons">
            <div
              class="icon"
              v-for="(it, ii) in item.appendix"
              :key="ii"
              v-viewer="{ movable: false }"
            >
              <img :src="it" style="width: 100%; height: 100%" />
              <div class="dele" @click="deleteItem(ii, index)" v-if="flag">
                <i class="el-icon-delete"></i>
              </div>
            </div>
            <div class="icon" v-if="flag">
              <el-upload
                class="avatar-uploader"
                :action="action"
                :show-file-list="false"
                :headers="headers"
                :data="{ systemName: 'finance', title: index }"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <span
                  ><i class="el-icon-circle-plus-outline"></i> 添加附件</span
                >
              </el-upload>
            </div>
          </div>
        </div>
        <div class="line" v-if="flag">
          <div class="but" @click="butOption(0, index)">添加明细</div>
          <div class="but" @click="butOption(1, index)">复制</div>
          <div class="but" @click="butOption(2, index)" v-if="index > 0">
            删除
          </div>
        </div>
      </div>
    </div>
    <div class="biot">
      <div class="tie">备注:</div>
      <div class="vas">
        <el-input
          v-model="data.des"
          placeholder="请输入"
          type="textarea"
          :rows="3"
          :disabled="!flag"
          clearable
        ></el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reimbursemenDetailData: {
      type: Object,
      default: function() {
        return {
          type: "", // 报销类型
          amountAll: "", // 总金额
          modeName: "报销流程",
          submitUser: this.baseInfo.userId,
          des: "",
          detail: [
            {
              amount: "", // 明细金额（元）
              payWay: "", // 付款方式
              amountText: "",
              occurTime: "", // 发生时间
              remark: "", // 报销说明
              appendix: [""], // 附件
              uploadParameter: {
                systemName: "finance",
                title: "报销附件",
              },
            },
          ],
        };
      },
    },
    isFlag: {
      type: Boolean,
      default: function() {
        return true;
      },
    },
  },
  data() {
    return {
      baseInfo: {},
      typeOptions: [], // 报销类型
      payOptions: [], // 付款方式
      data: this.reimbursemenDetailData,
      action: this.$fileBaseurl + "/uploadImageByMultipartFile",
      flag: this.isFlag,

      headers: {
        token: "95931b371eb64f4a9c862983ca8a5d15",
      },
    };
  },
  methods: {
    getTagList() {
      let requestData = {
        type: ["报销类型", "付款方式"],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          console.log("当前获取到的标签组:", data);
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.type == "报销类型") {
              this.typeOptions = element.value;
            }
            if (element.type == "付款方式") {
              this.payOptions = element.value;
            }
          }
          console.log(this.searchData);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    // 校验金额,并转大写
    changeAmount(item, index) {
      console.log("金额校验", item);
      if (!this.$formatMathDecimal(item.amount)) {
        this.data.detail[index].amount = "";
        this.data.detail[index].amountText = "";
        this.$message({
          message: "金额只能为数字!",
          type: "error",
        });
      } else {
        this.data.detail[index].amountText = this.$money.required(item.amount);
      }
    },
    // 添加/复制/删除明细
    butOption(active, index) {
      let item = {};
      switch (active) {
        case 0:
          // 添加明细
          item = {
            amount: "", // 明细金额（元）
            amountText: "",
            occurTime: "", // 发生时间
            remark: "", // 报销说明
            appendix: [], // 附件
          };
          this.data.detail.push(JSON.parse(JSON.stringify(item)));
          break;
        case 1:
          // 复制明细
          item = JSON.parse(JSON.stringify(this.data.detail[index]));
          this.data.detail.push(JSON.parse(JSON.stringify(item)));
          break;
        case 2:
          //   删除明细
          this.data.detail.splice(index, 1);
          break;

        default:
          break;
      }
    },
    // 删除附件
    deleteItem(ii, index) {
      this.data.detail[index].appendix.splice(ii, 1);
    },
    // 附件上传之前的校验
    beforeAvatarUpload(file) {
      let isJPG = file.type === "image/jpeg" || file.type === "image/png";

      if (!isJPG) {
        this.$message.error("上传图片格式的附件!");
      }
      return isJPG;
    },
    // 附件上传成功后的事件
    handleAvatarSuccess(response, file, fileList) {
      console.log("附件上传成功:", response);
      let index = Number.parseInt(response.result.title);
      this.data.detail[index].appendix.push(response.result.path);
    },
  },
  mounted() {
    console.log(this.flag);
    this.getTagList();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
  },
};
</script>

<style lang="less" scoped>
#add_reimbursemen_itemt {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  > .top {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .is {
      width: 0;
      flex: 1;
      height: 100%;
      display: flex;
      > .ist {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        margin-right: 5px;
      }
      > .isv {
        width: 0;
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  > .content {
    width: 100%;
    height: auto;
    max-height: 650px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    > .item {
      width: 100%;
      height: auto;
      border-bottom: 1px solid #a3a3a3;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding-bottom: 5px;
      > .line {
        width: 100%;
        height: auto;
        display: flex;
        margin-top: 8px;
        > .money {
          width: 0;
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          > .title {
            width: auto;
            height: 100%;
            margin-right: 5px;
            display: flex;
            align-items: center;
            font-size: 14px;
          }
          > .value {
            width: 0;
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
          }
          > .moneyText {
            width: auto;
            min-width: 20%;
            height: 100%;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #969696;
            span {
              color: rgb(255, 127, 36);
            }
          }
        }
        > .time {
          width: 40%;
          height: 100%;
          display: flex;
          align-items: center;
          > .title {
            width: auto;
            height: 100%;
            margin-right: 5px;
            display: flex;
            align-items: center;
            font-size: 14px;
          }
        }
        > .tie {
          width: auto;
          height: 100%;
          display: flex;
          align-items: flex-start;
          font-size: 14px;
          margin-right: 5px;
        }
        > .vas {
          width: 0;
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        > .icons {
          width: 0;
          height: auto;
          flex: 1;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          > .icon {
            width: 80px;
            height: 120px;
            border: 1px solid #cecece;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            color: #cecece;
            position: relative;
            transition: 0.3s;
            padding: 2px;
            margin: 2px;
            &:hover {
              cursor: pointer;
              color: #2873ff;
            }
            > i {
              margin-right: 5px;
            }
            > .dele {
              width: 100%;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding-right: 10px;
              font-size: 14px;
              color: #ff7919;
              position: absolute;
              top: 0;
              cursor: pointer;
            }
          }
        }
        > .but {
          width: auto;
          padding: 0 20px;
          height: 30px;
          font-size: 14px;
          color: #2873ff;
          transition: 0.3s;
          &:hover {
            cursor: pointer;
            color: #ff7919;
          }
        }
      }
    }
  }
  > .biot {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    > .tie {
      width: auto;
      height: 100%;
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      margin-right: 5px;
    }
    > .vas {
      width: 0;
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
