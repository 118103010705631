<template>
  <div id="role_setting" v-loading="loading">
    <div class="screen">
      <div class="screen-line">
        <div class="role-screen-search">
          <el-input
            placeholder="搜索角色名称"
            suffix-icon="el-icon-search"
            v-model="searchData.input"
          >
          </el-input>
        </div>
        <div class="role-screen-active"></div>
        <div
          class="role-screen-but"
          v-if="buttonAuth.add < 3"
          @click="tableOper({}, 0)"
        >
          新建角色
        </div>
      </div>
    </div>
    <div class="table-content">
      <div class="role-content" @click="closeUpdateTableDrawer">
        <div class="lines">
          <div
            class="line"
            v-for="(item, index) in roleList"
            :key="index"
            @click.stop="rowClick(item)"
          >
            <div>{{ item.authorityName }}</div>
            <div>
              <el-button
                type="text"
                style="color: red"
                v-if="
                  buttonAuth.dele < 3 &&
                  item.authorityName != '服务顾问' &&
                  item.authorityName != '客户经理' &&
                  item.authorityName != '收费负责人' &&
                  item.authorityName != '开票员' &&
                  item.authorityName != '合同审核员' &&
                  item.authorityName != '出纳'
                "
                @click.stop="tableOper(item, 2)"
                >删除</el-button
              >
              <el-button
                type="text"
                v-if="buttonAuth.edit < 3"
                @click.stop="tableOper(item, 1)"
                >编辑</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>

    <!-- 右边抽屉 -->
    <div id="my-drawer" v-if="roledDrawerData.flag">
      <my_drawer
        :drawerData="roledDrawerData"
        @show="showDrawer"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @saveDrawerData="saveDrawerData"
        @tagsHandleClose="tagsHandleClose"
        @addTags="addTags"
        @drawerOper="drawerOper"
      ></my_drawer>
    </div>
  </div>
</template>

<script>
import my_drawer from "../components/my-drawer.vue";
import my_dialog from "../components/dialog.vue";
import * as roleData from "../assets/js/roleData.js";
export default {
  components: {
    my_drawer,
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      loading: false,
      searchData: {
        input: "",
      },
      roledDrawerData: roleData.getRoleDrawerData("", "", [], 1),
      roleList: [
        {
          authorityId: "",
          authorityName: "客户经理",
        },
        {
          authorityId: "",
          authorityName: "服务顾问",
        },
      ],
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        id: "",
        price: "", // 临时存储价格
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      employeeList: [],
      tempAuthorityId: "", // 临时角色ID,
      tempModel: "", // 临时model
      tempAuthorityData: {}, // 临时存储权限数据
      tempAuthorityData1: {}, // 临时存储权限数据
      tempData: {},
      buttonAuth: {
        add: 1,
        edit: 1,
        oper: 1,
        dele: 1,
      },
    };
  },
  methods: {
    /**
     * 获取界面的按钮权限
     */
    getModelAuthority() {
      this.$authority.getModelAuthority(
        ["businessRole", "businessRole"],
        "modelChildMenu",
        this,
        false,
        (data) => {
          if (data.length > 0) {
            this.buttonAuth.add = this.$systemBaseData.judgeButtonAuthority(
              "新增角色",
              data
            );
            this.buttonAuth.edit = this.$systemBaseData.judgeButtonAuthority(
              "编辑角色",
              data
            );
            this.buttonAuth.dele = this.$systemBaseData.judgeButtonAuthority(
              "删除角色",
              data
            );
            this.buttonAuth.oper = this.$systemBaseData.judgeButtonAuthority(
              "设置默认人员",
              data
            );
          }
          this.getEmployeeList();
        }
      );
    },
    /**
     * 获取该公司所有在职员工
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId, // 当前系统所选公司ID
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/isCharge",
        requestData,
        (data) => {
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.employeeList.push(dd);
          });
          this.getBusinessAuthorityList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 获取全部业务角色
     */
    getBusinessAuthorityList() {
      this.loading = true;
      let requestData = {
        companyId: this.baseInfo.company.companyId, // 当前系统所选公司ID
        type: "4",
      };
      this.$request(
        "post",
        "/role/getBusinessAuthorityList",
        requestData,
        (data) => {
          this.loading = false;
          this.roleList = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 行点击事件
     */
    rowClick(data) {
      console.log("行点击事件", this.roledDrawerData);
      this.tempData = data;
      this.tempAuthorityId = data.authorityId;
      let requestData = {
        id: data.authorityId,
      };
      this.$request(
        "post",
        "/setting/getBusinessAuthorityInfoById",
        requestData,
        (resultData) => {
          this.roledDrawerData = roleData.getRoleDrawerData(
            resultData.authorityName,
            resultData,
            this.employeeList,
            this.buttonAuth.oper
          );
          this.roledDrawerData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );

      // this.roledDrawerData = roleData.getRoleDrawerData(
      //   "",
      //   "",
      //   this.employeeList,
      //   this.buttonAuth.oper
      // );
      // this.roledDrawerData.flag = true;
    },
    /**
     * 表格按钮操作事件
     */
    tableOper(data, flag) {
      switch (flag) {
        case 0:
          // 新建业务角色权限
          if (this.buttonAuth.add == 1) {
            this.dialogData.title = "添加角色";
            this.dialogData.buttonArray = [
              {
                text: "确定",
                active: "submit_add",
              },
              {
                text: "取消",
                active: "cancel",
              },
            ];
            this.dialogData.data = [
              {
                type: "input",
                title: "角色名称",
                value: "",
              },
            ];
            this.dialogData.flag = true;
          } else {
            this.$message.error(
              this.$errorMessage.getErrorMessageInfo("authority")
            );
          }
          break;
        case 1:
          // 编辑业务角色权限
          if (this.buttonAuth.edit == 1) {
            this.dialogData.title = "编辑角色";
            this.tempAuthorityId = data.authorityId;
            this.dialogData.buttonArray = [
              {
                text: "确定",
                active: "edit_chose_model",
              },
              {
                text: "取消",
                active: "cancel",
              },
            ];
            this.dialogData.data = [
              {
                title: "模块",
                type: "select",
                value: "",
                options: this.$systemBaseData.modelListToModelOptions(),
              },
            ];
            this.dialogData.flag = true;
          } else {
            this.$message.error(
              this.$errorMessage.getErrorMessageInfo("authority")
            );
          }
          break;
        case 2:
          // 删除业务角色权限
          if (this.buttonAuth.dele == 1) {
            this.$confirm("此操作将永久删除该类型, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$request(
                  "post",
                  "/setting/deleteAuthority",
                  { authorityId: data.authorityId },
                  (data) => {
                    this.$message({
                      type: "success",
                      message: "删除成功",
                      duration: 1000,
                    });
                    this.getBusinessAuthorityList();
                  },
                  (errorMessage) => {
                    this.$message.error(errorMessage);
                    this.loading = false;
                  }
                );
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消删除",
                });
              });
          } else {
            this.$message.error(
              this.$errorMessage.getErrorMessageInfo("authority")
            );
          }
          break;

        default:
          break;
      }
    },
    /**
     * 弹出框或抽屉按钮操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "submit_add":
          // 确定添加角色
          this.loading = true;
          requestData = {
            authorityName: this.dialogData.data[0].value, // 要添加的角色名称
            companyId: this.baseInfo.company.companyId, // 当前系统所选公司ID
            authority: this.$authority.allAuthorityConfiguration(),
            flag: "0", // 0为没有默认人员;1为默认自己;2为默认指定人员
            users: [],
          };
          this.$request(
            "post",
            "/setting/addBusinessAuthority",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: this.$errorMessage.getErrorMessageInfo("add"),
                duration: 1000,
              });
              this.getBusinessAuthorityList();
              this.dialogData.flag = false;
              this.loading = false;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.loading = false;
            }
          );

          break;
        case "edit_chose_model":
          // 确定选择要修改的角色模块
          requestData = { authorityId: this.tempAuthorityId };
          this.tempModel = this.dialogData.data[0].value;
          this.$request(
            "post",
            "/setting/getAuthorityList",
            requestData,
            (data) => {
              this.tempAuthorityData = JSON.parse(JSON.stringify(data));
              this.tempAuthorityData1 = JSON.parse(JSON.stringify(data));
              this.dialogData.title = "修改角色权限";
              this.dialogData.width = "720px";
              this.dialogData.buttonArray = [
                {
                  text: "确认",
                  active: "edit_authority",
                },
                {
                  text: "取消",
                  active: "cancel",
                },
              ];
              this.dialogData.data = [
                {
                  title: "",
                  type: "authority_dialog",
                  data: this.$authority.getAllRoleAuthorityList(
                    this.tempModel,
                    data
                  ),
                },
              ];
              console.log("权限具体信息:", this.dialogData.data);
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "edit_authority":
          requestData = {
            authorityId: this.tempAuthorityId,
            authorityData: this.$authority.formatSubmitAllRoleAuthorityList(
              this.tempAuthorityData,
              this.tempModel,
              this.dialogData.data[0].data
            ),
            authorityDataOld: this.tempAuthorityData1,
          };
          this.$request(
            "post",
            "/setting/saveAuthorityList",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: this.$errorMessage.getErrorMessageInfo("update"),
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getBusinessAuthorityList();
              this.dialogData.flag = false;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
              this.dialogData.flag = false;
            }
          );
          break;
        case "cancel":
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },
    /**
     * 抽屉中tag移除事件
     * data(tag,i,j):tag->移除的tag,i为第几个model,j为第几line
     */
    tagsHandleClose(data) {
      this.roledDrawerData.data.modelList[data.i].line[data.j].value.splice(
        this.roledDrawerData.data.modelList[data.i].line[data.j].value.indexOf(
          data.tag
        ),
        1
      );
      this.saveDrawerData(
        this.roledDrawerData.data.modelList[data.i].line[data.j].title
      );
    },
    /**
     * 抽屉中添加tag事件
     * data(i,j):i为第几个model,j为第几line
     * 首先获取所有产品allProductList,
     * 其次拿到当前商机中以选中的意向产品
     */
    addTags(data) {
      let tempTags = this.roledDrawerData.data.modelList[data.i].line[data.j]
        .value;
      let flag = this.roledDrawerData.data.modelList[data.i].line[data.j]
        .isSelect;
      if (!flag) {
        // 按钮转下拉框
        this.roledDrawerData.data.modelList[data.i].line[
          data.j
        ].isSelect = true;
        this.roledDrawerData.data.modelList[data.i].line[data.j].tempValue = {};
      } else {
        // 下拉框转按钮
        this.roledDrawerData.data.modelList[data.i].line[data.j].value.push(
          JSON.parse(
            JSON.stringify(
              this.roledDrawerData.data.modelList[data.i].line[data.j].tempValue
            )
          )
        );
        this.roledDrawerData.data.modelList[data.i].line[
          data.j
        ].isSelect = false;
        this.saveDrawerData();
      }
    },
    /**
     * 保存抽屉事件
     */
    saveDrawerData() {
      let requestData = {
        authorityId: this.tempAuthorityId, // 当前要修改的角色ID
        authorityName: this.roledDrawerData.data.modelList[0].line[0].value, // 要添加的角色名称
        flag: this.roledDrawerData.data.modelList[0].line[1].value, // 0为没有默认人员;1为默认自己;2为默认指定人员
        users: this.roledDrawerData.data.modelList[0].line[2].value,
      };
      console.log("保存抽屉组件:", requestData);
      this.$request(
        "post",
        "/setting/updateBusinessModel",
        requestData,
        (data) => {
          this.$message({
            type: "success",
            message: this.$errorMessage.getErrorMessageInfo("save"),
            duration: 1000,
          });
          this.rowClick(this.tempData);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 右边抽屉父子组件连锁事件
     */
    showDrawer(value) {
      this.roledDrawerData = value;
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      console.log("关闭抽屉");
      if (
        this.roledDrawerData.data.modelList[0].line[1].value == "2" &&
        this.roledDrawerData.data.modelList[0].line[2].value.length == 0
      ) {
        // 当默认人员选择的时指定人员时,必须选择默认员工
        this.$message({
          message: "请给角色选择指定员工!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      this.roledDrawerData.flag = false;
      this.getBusinessAuthorityList();
    },
    /**
     * 关闭dialog事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  mounted() {
    this.loading = true;
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelAuthority();
  },
};
</script>

<style lang="less" scoped>
#role_setting {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .role-screen-search {
    width: 210px;
    min-width: 210px;
    height: 30px;
    margin-left: 10px;
  }

  .role-screen-checked {
    width: 140px;
    height: 30px;
    min-width: 140px;
    font-size: @contentFontSize;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .role-screen-active {
    flex: 1;
  }
  .role-screen-but {
    width: 120px;
    min-width: 120px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
    margin-right: 20px;
  }
  .role-screen-but:hover {
    cursor: pointer;
    background-color: @activeColor2;
  }
  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    overflow: hidden;
    .role-content {
      width: 98%;
      height: 98%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      display: flex;
      flex-direction: column;
      .lines {
        flex: 1;
        height: 0;
        width: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        &::-webkit-scrollbar {
          width: 1px;
        }
        .line {
          width: 100%;
          height: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: @contentFontSize;
          padding: 0 50px;
          box-sizing: border-box;
          border-bottom: 1px solid @boderColor;
          &:hover {
            cursor: pointer;
          }
          .el-button {
            min-width: 50px;
          }
        }
      }
    }
  }
}
</style>