<template>
  <div id="my-contract">
    <!-- 周期合同 -->
    <div class="screen">
      <div class="screen-line">
        <div class="contract-screen-fiftrate">
          <el-popover
            placement="bottom-start"
            width="250"
            trigger="manual"
            :visible-arrow="false"
            v-model="popoData.popoVisible"
          >
            <div class="popover-search">
              <popo_search
                :list="popoData.list"
                @cancel="popoCanael"
                @reset="popoReset"
                @makeSure="popoMakeSure"
              ></popo_search>
            </div>
            <div slot="reference" @click="popoverOpen">
              筛选 <i class="el-icon-s-operation"></i>
            </div>
          </el-popover>
        </div>
        <div class="contract-screen-search">
          <el-select
            v-model="searchData.userId"
            placeholder="请选择客户负责人"
            @change="selectInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="contract-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="contract-screen-search">
          <el-input
            placeholder="客户全称/编号/合同编号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="contract-screen-checked"></div>
        <div class="contract-screen-active"></div>
        <div
          class="contract-screen-but-select"
          v-showFunction="{
            active: 'create',
            array: contractAllFunctionButtons,
          }"
          @click="handleClick({}, 0)"
        >
          新增合同
        </div>
        <el-dropdown
          trigger="click"
          @command="commandMenu"
          v-if="whetherAgencyHead"
        >
          <div class="contract-screen-but-boder">
            导出 <i class="el-icon-arrow-down"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="0">导出全部</el-dropdown-item>
            <el-dropdown-item :command="1">导出所选</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="screen-line" v-if="searchData.screenList.length > 0">
        <div class="title">筛选条件:</div>
        <el-tag
          v-for="(tag, index) in searchData.screenList"
          :key="index"
          closable
          size="small"
          @close="closeScreen(index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>
    <div class="contract-table">
      <div class="contract-table-card">
        <div class="contract-table-card-tabs">
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': tabindex == 0,
            }"
            @click="changeTab(0)"
          >
            全部
          </div>
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': tabindex == 1,
            }"
            @click="changeTab(1)"
          >
            将到期合同
          </div>
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': tabindex == 2,
            }"
            @click="changeTab(2)"
          >
            已到期合同
          </div>
          <div class="contract-table-card-active">
            <!-- <div class="textArea">合同总金额:</div>
            <div class="numArea">{{ contractBase.totalMoney }}</div>
            <div class="textArea">已收:</div>
            <div class="numArea">{{ contractBase.receivedMoney }}:</div>
            <div class="textArea">已审核收入:</div>
            <div class="numArea">{{ contractBase.checkMoney }}</div>
            <div class="textArea">利润:</div>
            <div class="numArea">{{ contractBase.profits }}</div> -->
          </div>
          <div class="contract-table-card-edit">
            <i class="el-icon-edit-outline" @click="updateTableMenu"></i>
          </div>
        </div>
        <div class="contract-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            @row-click="rowClick"
            style="width: 100%"
            height="98%"
            :row-style="rowStyle"
            v-loading="loading"
            @sort-change="sortChange"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="30" fixed>
            </el-table-column>
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <template v-for="(item, index) in tabMenu">
              <el-table-column
                :key="index"
                :property="item.key"
                v-if="item.authority == 1"
                :fixed="index == 0"
                :label="item.name"
                :width="item.key == 'firstChargeTime' ? 150 : ''"
                :min-width="item.key == 'clientName' ? 270 : 110"
                show-overflow-tooltip
                :sortable="
                  item.key == 'dateDue' ||
                  item.key == 'contractStartTime' ||
                  item.key == 'firstChargeTime' ||
                  item.key == 'signTime'
                    ? 'custom'
                    : false
                "
              >
                <template slot="header">
                  <div v-if="item.key == 'clientName'">客户全称</div>
                </template>
                <template slot-scope="scope">
                  <div
                    v-if="item.key == 'clientName'"
                    class="hri link"
                    @click.stop="showClient(scope.row)"
                  >
                    <i
                      class="el-icon-notebook-2"
                      @click.stop="showGenjin(scope.row)"
                    ></i>
                    {{ scope.row[item.key] }}
                  </div>
                  <div class="hri" v-else-if="item.key == 'contractNumber'">
                    <!-- 是否显示电子合同标识 -->
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="电子合同"
                      placement="top"
                    >
                      <i
                        class="el-icon-star-on"
                        v-if="scope.row.electronicUrl != ''"
                        @click.stop="showAttachmentByContract(scope.row)"
                      ></i>
                    </el-tooltip>
                    {{ scope.row[item.key] }}
                  </div>
                  <div v-else-if="item.key == 'services'" class="hri">
                    <!-- 服务项 -->
                    <div
                      v-for="(ddd, dd) in scope.row[item.key]"
                      :key="dd"
                      class="table-tip"
                      :style="{
                        borderColor: ddd.color,
                        color: ddd.color,
                      }"
                    >
                      <el-tooltip :content="ddd.tip" placement="top">
                        <div>{{ ddd.text }}</div>
                      </el-tooltip>
                    </div>
                  </div>
                  <div
                    class="hri"
                    v-else-if="item.key == 'status' && scope.row.checkDes != ''"
                  >
                    <el-tooltip :content="scope.row.checkDes" placement="top">
                      <div>{{ scope.row.status }}</div>
                    </el-tooltip>
                  </div>
                  <div
                    class="hri"
                    v-else-if="item.key == 'status' && scope.row.checkDes == ''"
                  >
                    {{ scope.row.status }}
                  </div>
                  <div
                    v-else-if="
                      item.key == 'serviceConsultant' || item.key == 'charge'
                    "
                    class="hri"
                  >
                    <!-- 服务顾问 -->
                    <div v-for="(ddd, dd) in scope.row[item.key]" :key="dd">
                      <span v-if="dd == 0">
                        {{ ddd }}
                      </span>
                      <span v-else>,{{ ddd }}</span>
                    </div>
                  </div>
                  <div v-else-if="item.key == 'signDepartment'" class="hri">
                    <!-- 签单人部门 -->
                    <div v-for="(ddd, dd) in scope.row[item.key]" :key="dd">
                      <span v-if="dd == 0">
                        {{ ddd.name }}
                      </span>
                      <span v-else>,{{ ddd.name }}</span>
                    </div>
                  </div>
                  <div v-else-if="item.key == 'autoRenew'">
                    <!-- 自动续签 -->
                    <span v-if="scope.row[item.key] == '1'">是</span>
                    <span v-if="scope.row[item.key] == '0'">否</span>
                  </div>

                  <div
                    class="hri"
                    v-else-if="item.key == 'receiveStatus'"
                    :style="{
                      color:
                        scope.row.receiveStatus == '已收款'
                          ? '#82c91e'
                          : scope.row.receiveStatus == '财务驳回' ||
                            scope.row.receiveStatus == '未收款'
                          ? '#e03131'
                          : '#66B1FF',
                    }"
                  >
                    <!-- 收款状态 -->
                    {{ scope.row.receiveStatus }}
                  </div>
                  <div v-else-if="item.key == 'attachment'">
                    <!-- 是否有和他附件 -->
                    <span v-if="scope.row[item.key] == 'Y'">有</span>
                    <span v-if="scope.row[item.key] == 'N'">无</span>
                  </div>
                  <div v-else>
                    {{ scope.row[item.key] }}
                  </div>
                </template>
              </el-table-column>
            </template>

            <el-table-column label="操作" width="120" fixed="right">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  v-if="
                    scope.row.status == '待提交' ||
                      scope.row.status == '合同审核不通过'
                  "
                  @click.native.stop="handlClick(scope.row, 'submit')"
                  >待提交</el-button
                >
                <el-button
                  type="text"
                  :style="{
                    color: scope.row.color,
                  }"
                  v-showFunction="{
                    active: 'receivable',
                    array: contractAllFunctionButtons,
                  }"
                  v-if="
                    scope.row.status != '待提交' &&
                      scope.row.status != '待合同审核' &&
                      scope.row.status != '合同审核不通过' &&
                      scope.row.color != '#82c91e'
                  "
                  @click.native.stop="handlClick(scope.row, 'audit')"
                  >待查账</el-button
                >
                <el-button
                  type="text"
                  :style="{
                    color: scope.row.color,
                  }"
                  v-if="
                    scope.row.status != '待提交' && scope.row.color == '#82c91e'
                  "
                  v-showFunction="{
                    active: 'create',
                    array: contractAllFunctionButtons,
                  }"
                  >已到账</el-button
                >
                <el-button
                  type="text"
                  v-if="scope.row.status == '待下单'"
                  v-showFunction="{
                    active: 'orders',
                    array: contractAllFunctionButtons,
                  }"
                  @click.native.stop="handlClick(scope.row, 'order')"
                  >待下单</el-button
                >
                <!-- <el-button
                  type="text"
                  v-if="
                    scope.row.status == '待合同审核' ||
                    scope.row.status == '待财务审核'
                  "
                  @click="handlClick(scope.row, 'check')"
                  >审核</el-button
                > -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="contract-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 自定义列 -->
    <div id="my-dialog" v-if="tragerDialogData.flag">
      <div class="my-dialog-cc">
        <div class="my-dialog-title">
          <div class="my-dialog-title-text">{{ tragerDialogData.title }}</div>
          <div class="my-dialog-title-active"></div>
          <div class="my-dialog-title-close" @click="closeUpdateTableMenu">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="my-dialog-content">
          <new_drager
            :dragerData="tragerDialogData.data"
            @show="showDrager"
            @dragging="dragging"
            @closeUpdateTableMenu="closeUpdateTableMenu"
            @markSureUpdateTableMenu="markSureUpdateTableMenu"
          ></new_drager>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @buttonOper="drawerOper"
        @choseProductTipOne="choseProductTipOne"
        @handleAvatarSuccess="handleAvatarSuccess"
        @changeFlag="changeFlag"
        @addFollowRecord="followRecord"
        @toGenerateElectronicContract="toGenerateElectronicContract"
        @confirm="confirm"
        @deleteItem="deleteItem"
        @handleSelectionChange="handleSelectionChange"
      ></my_dialog>
    </div>

    <!-- 右边抽屉 -->
    <div id="my-drawer" v-if="contractDrawerData.flag">
      <my_drawer
        :drawerData="contractDrawerData"
        :allFunctionButtons="allFunctionButtons"
        :model="model"
        @show="showDrawer"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @saveDrawerData="saveDrawerDataInit"
        @tagsHandleClose="tagsHandleClose"
        @addTags="addTags"
        @drawerOper="drawerOper"
        @enter="enterCreateContract"
        @showExpendDetails="showExpendDetails"
        @addExpend="addExpend"
        @showExpend="showExpend"
        @radioChange="radioChange"
        @cancelServiceItems="cancelServiceItems"
        @deleteService="deleteService"
        @showAttachment="showAttachment"
        @lineOpers="lineOper"
        @linkNow="linkNow"
        @linkNowFile="linkNowFile"
        @linkFile="linkFile"
        @linkClitent="showClientByID"
        @showRelevanceClient="showRelevanceClient"
        @deleteRelevanceClient="deleteRelevanceClient"
        @addRelevanceClient="addRelevanceClient"
        @enterTag="enterTag"
        @deleteTag="deleteTag"
        @saveTag="saveTag"
        @delete="delQualification"
        @contract="linkContract"
        @copy="copy"
        @archives="linkArchives"
        @tipChange="tipChange"
      ></my_drawer>
    </div>
  </div>
</template>

<script>
import drager from "@/components/drager.vue";
import my_drawer from "@/components/my-drawer.vue";
import my_dialog from "@/components/dialog.vue";
import contractDraw from "@/components/contractDraw";
import popo_search from "@/components/popo-search.vue";
import * as contractDrawData from "@/assets/js/contractDraw.js";
import * as clientInfoData from "@/assets/js/clientInfoData.js";
import * as allContractData from "./js/contractData.js";
import * as allClientData from "../client/js/clientData.js";
import * as invoiceData from "@/assets/js/invoiceData.js";
import { getNewResourceDrawerData } from "../../assets/js/myThread.js";
import * as archivesData from "@/views/archives/js/archives.js";
import * as expressageData from "@/views/expressage/js/expressage.js";

export default {
  components: {
    drager,
    contractDraw,
    my_drawer,
    popo_search,
    my_dialog,
  },
  data() {
    return {
      linkContractData: {},
      tag: "",
      rowStyle: this.$rowStyle,
      baseInfo: {},
      loading: true,
      whetherAgencyHead: false,
      searchData: {
        input: "",
        checked: false,
        screenButton_add: 1, // 新增客户
        screenButton_improt: 1, // 导入
        screenButton_export: 1, // 导出
        userId: "", //
        userList: [],
        tempUserId: [],
        departTreeData: [],
        department: "",
        screenList: [],
        payOptions: [],
        contractNumber: "",
        lossReasonOptions: [],
      },
      popoData: {
        popoVisible: false,
        list: contractDrawData.contractInfoSearchData([], [], "", "", []),
      },
      tabindex: 0,
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tabMenu: contractDrawData.contractInfotableMenuData(),
      contractBase: {
        totalMoney: "120000", // 合同总金额
        receivedMoney: "2000", // 总已收金额
        checkMoney: "1000", // 合同总已审核金额
        profits: "10000", // 总利润
      },
      tableMenuData: {
        selectMenuData: [], // 真正显示的表头数据
        chooseableMenuData: [], // 隐藏的表头
      },
      tableData: [],
      multipleSelection: [],
      tableButIndex: -1,
      tragerDialogData: {
        title: "自定义列",
        flag: false,
        data: {},
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      contractDrawerData: contractDrawData.contractInfoDrawerData(
        "",
        [],
        [],
        {
          text: "",
          active: "",
        },
        []
      ),
      defaultSort: { prop: "", order: "" },
      tempTags: [],
      tempAllEmpolyeeList: [],
      tempContract: { id: "", name: "" }, // 临时合同
      tempClient: {},
      tempServices: [], // 该合同所包含的所有服务
      buttonAuth: [],
      productList: [], // 所有合同模板/产品数据
      professionList: [], // 所有合同执行模板
      serviceItemList: [], // 所有服务项数据
      serviceTypeList: [], // 所有服务类别数据
      employeeList: [], // 所有员工
      departmentList: [], // 所有员工
      clientList: [], // 所有客户
      accountList: [], // 收款账号
      contractSettingButtonList: [],
      tipsData: {
        clientTipsList: [], // 客户标签
        pianquList: [], // 片区标签
        levelList: [], // 客户等级
        sourceList: [], // 客户来源
      },
      isRenew: false,
      ttempClient: {},
      tempSign: "",
      cntractCost: "", // 合同成本
      model: "contractInfo",
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "contractInfo"
      ),
      contractAllFunctionButtons: this.$featureData.getAllFunctionButtons(
        "contractInfo"
      ),
      contractDrawerOptions: this.$tagData.getDrawerOptionsList("contractInfo"), // 抽屉中的下拉框配置项
      contractDrawerAuthorityData: [],
      contractDrawerDataAuthorityList: [],
      clientAllFunctionButtons: this.$featureData.getAllFunctionButtons(
        "clientInfo"
      ),
      clientDrawerOptions: this.$tagData.getDrawerOptionsList("clientInfo"), // 客户抽屉中的下拉框配置项
      clientDrawerAuthorityData: [],
      clientDrawerDataAuthorityList: [],
      tempInvoiceId: "",
      categoryList: [],
      locationList: [],
      fileOrder: [{ name: "根目录", parent: "", id: "" }],
      contractInvoiceInfo: {
        multipleSelectionReceipt: [], // 选择的收款单号
        invoiceData: {}, // 选择的收款单号发票数据
        receiptNumberList: [], // 收款单号列表
        receiptIdList: [], // 收款单号id列表
        sellerInvoiceData: {}, // 销售方数据
        buyerInvoiceData: {}, // 销售方数据
      },
    };
  },
  methods: {
    getModelFeatureAuthority() {
      // this.allFunctionButtons = [];
      // this.contractAllFunctionButtons = [];
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "contractInfo",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.contractAllFunctionButtons = data;
          this.getClientModelFeatureAuthority();
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    // 获取客户的按钮权限
    getClientModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "clientInfo",
        this.clientAllFunctionButtons,
        (data) => {
          this.clientAllFunctionButtons = data;
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.initMenu();
        this.getTagList();
        this.getAllClientNum();
      });
    },
    getAllClientNum() {
      this.$request(
        "get",
        "/dataDictionary/queryClientNumber",
        {},
        (data) => {
          this.clientDrawerOptions.clientNumberList = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    // 获取当前所需标签
    getTagList() {
      let requestData = {
        type: ["报销类型", "付款方式", "流失原因"],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          console.log("当前获取到的标签组:", data);
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.type == "付款方式") {
              this.searchData.payOptions = element.value;
            }
            if (element.type == "流失原因") {
              this.searchData.lossReasonOptions = element.value;
            }
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    initMenu() {
      this.$featureData.getAttributeData(
        "contractInfo",
        true,
        [],
        (attributeData) => {
          this.$menuData.getModelMenuAndAuthority(
            "contractInfo",
            attributeData,
            this,
            (data) => {
              this.tabMenu = data;
              console.log("this.tabMenu", this.tabMenu);
              this.tragerDialogData.data = JSON.parse(
                JSON.stringify(this.tabMenu)
              );
              this.getEmployeeListByUserId();
              this.$tagData.getTagList(
                this.contractDrawerOptions,
                ["客户标签", "片区", "客户来源", "客户等级", "增值税类型"],
                this.baseInfo.company.companyId,
                this
              );
              this.$tagData.getTagList(
                this.clientDrawerOptions,
                [
                  "合约主体",
                  "收费定额",
                  "片区",
                  "客户来源",
                  "增值税类型",
                  "客户等级",
                  "客户标签",
                  "资质类型",
                ],
                this.baseInfo.company.companyId,
                this
              );
            }
          );
        }
      );
    },
    getGlobalVariable() {
      let [, ...employeeList] = this.searchData.userList;
      this.employeeList = [];
      employeeList.forEach((element) => {
        let dd = {
          label: element.userName,
          value: element.userId,
        };
        this.employeeList.push(dd);
      });
      this.contractDrawerOptions.allUser = this.employeeList;
      this.clientDrawerOptions.allUser = this.employeeList;

      let productList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllProductList)
      );
      this.productList = productList;
      this.contractDrawerOptions.productList = [];
      productList.forEach((element) => {
        let dd = {
          label: element.name,
          value: element.id,
        };
        this.contractDrawerOptions.productList.push(dd);
      });

      let departmentList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllDepartmentList)
      );
      this.departmentList = [];
      departmentList.forEach((element) => {
        let dd = {
          label: element.departmentName,
          value: element.departmentId,
        };
        this.departmentList.push(dd);
      });

      let serviceTypeList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllServiceTypeList)
      );
      this.serviceTypeList = serviceTypeList;
      this.contractDrawerOptions.serviceTypeList = [];
      serviceTypeList.forEach((element) => {
        let dd = {
          label: element.typeName,
          value: element.id,
        };
        this.contractDrawerOptions.serviceTypeList.push(dd);
      });

      let serviceItemList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllServicesItemList)
      );
      this.serviceItemList = serviceItemList;
      this.contractDrawerOptions.serviceList = [];
      serviceItemList.forEach((element) => {
        let dd = {
          label: element.name,
          value: element.id,
        };
        this.contractDrawerOptions.serviceList.push(dd);
      });

      let accountList = JSON.parse(
        JSON.stringify(this.$store.getters.getCompantAccountList)
      );
      this.accountList = accountList;

      let clientList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllClientByUserId)
      );
      this.clientList = [];
      clientList.forEach((element) => {
        let dd = {
          id: element.clientId,
          clientName: element.clientName,
        };
        this.clientList.push(dd);
      });

      let professionList = JSON.parse(
        JSON.stringify(this.$store.getters.getAllProfessionList)
      );
      this.professionList = professionList;
      console.log("下拉框属性:", this.contractDrawerOptions);
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeListByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = data;
          this.tempAllEmpolyeeList = [];
          data.forEach((element) => {
            this.tempAllEmpolyeeList.push(element.userId);
          });
          this.searchData.userList.splice(0, 0, {
            userId: "all",
            userName: "全部",
          });
          this.getDepartmentTreeByUserId();
          this.getGlobalVariable();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取部门树
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        // "/setting/getDepartmentTreeByUserId",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      // 点击报销 付款信息 跳转至合同
      if (this.searchData.contractNumber) {
        this.searchData.userId = "all";
        this.searchData.input = this.searchData.contractNumber;
        this.searchData.tempUserId = this.tempAllEmpolyeeList;
      }
      this.tableData = [];
      this.loading = true;
      let requestData = {
        userId: this.searchData.tempUserId,
        companyId: this.baseInfo.company.companyId, // 当前公司ID
        status: this.tabindex, // 合同状态:"0"->全部合同;"1"->将到期合同;"2"->已到期合同
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        search: this.searchData.input, // 客户全称/编号/合同编号筛选客户
        list: this.searchData.screenList,
        sortProp: this.defaultSort.prop, // 手动排序项
        sortOrder: this.defaultSort.order, // 手动排序规则 ascending descending ""
        // list: this.$systemBaseData.makesureSearchData(this.popoData.list),
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
      };

      this.$request(
        "post",
        "/contract/queryContract",
        requestData,
        (data) => {
          this.searchData.contractNumber = "";
          this.pageData.total = data.total; // 获取总条目数
          // this.contractBase = data.baseData;
          // this.tableData = data.tableData;
          this.tableData = contractDrawData.getInitTableData(
            data.tableData,
            this.tableMenuData.selectMenuData,
            this
          );
          this.$nextTick(() => {
            this.$refs.singleTable.doLayout();
          });
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.tempAllEmpolyeeList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      let productList = []; // 服务产品
      let serviceItemList = []; // 服务项目
      let companyData = {
        employeeList: this.employeeList, // 员工列表
        departmentList: this.departmentList, // 部门列表
      };
      let industryTypeList = []; // 行业分类
      let tipsData = {
        clientTipsList: this.tipsData.clientTipsList, // 客户标签
        pianquList: this.tipsData.pianquList, // 片区标签
        clientLevelList: this.tipsData.levelList, // 客户等级
        customerSourceList: this.tipsData.sourceList, // 客户来源
      };
      this.productList.forEach((element) => {
        let dd = {
          label: element.name,
          value: element.id,
        };
        productList.push(dd);
      });
      this.serviceItemList.forEach((element) => {
        let dd = {
          label: element.name,
          value: element.id,
        };
        serviceItemList.push(dd);
      });

      this.popoData.list = contractDrawData.contractInfoSearchData(
        productList,
        serviceItemList,
        companyData,
        tipsData,
        industryTypeList
      );
      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选弹出框确认事件
     */
    popoMakeSure() {
      this.searchData.screenList = this.$systemBaseData.remakesureSearchData(
        this.popoData.list
      );
      this.popoCanael();
      this.initTableData();
      // let requestData = {
      //   userId: this.baseInfo.userId,
      //   companyId: this.baseInfo.company.companyId,
      //   currentPage: this.pageData.currentPage, // 当前页数
      //   pageSize: this.pageData.pageSize, // 每页条数
      //   state: "0",
      //   list: this.$systemBaseData.makesureSearchData(this.popoData.list),
      // };

      // this.loading = true;
      // this.tableData = [];
      // console.log("确认筛选数据:", requestData);
      // this.$request(
      //   "post",
      //   "/contract/filtrateContract",
      //   requestData,
      //   (data) => {
      //     this.tabindex = "0"; // 默认在全部商机中查找
      //     this.pageData.total = data.total; // 获取总条目数
      //     this.tableData = contractDrawData.getInitTableData(
      //       data.tableData,
      //       this.tableMenuData.selectMenuData,
      //       this
      //     );
      //     this.loading = false;
      //     this.popoCanael();
      //   },
      //   (errorMessage) => {
      //     this.$message.error(errorMessage);
      //     // this.loading = false;
      //   }
      // );
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
    },
    /**
     * tab切换事件
     * index: "0"->全部合同;"1"->将到期合同;"2"->已到期合同
     */
    changeTab(index) {
      this.tabindex = index;
      this.initTableData();
    },
    /**
     * 表格中的操作事件
     */
    handlClick(data, active) {
      this.tempContract.id = data.id;
      this.tempContract.name = data.clientName;
      this.tempClient.value = data.clientId;
      let requestData = {};
      switch (active) {
        case "submit":
          // 提交
          if (data.cost == "") {
            this.$message({
              message: "合同成本不能为空!",
              duration: 1000,
              type: "error",
            });
            return;
          }
          this.submitContract();
          break;
        case "audit":
          // 查账
          this.ttempClient = data;
          // if (data.color == "#ced4da") {
          //   this.$message({
          //     message: "当前合同金额有误!",
          //     type: "warning",
          //     duration: 1000,
          //   });
          //   return;
          // }
          // if (data.color == "#82c91e") {
          //   this.$message({
          //     message: "当前合同已全部回款!",
          //     type: "success",
          //     duration: 1000,
          //   });
          //   return;
          // }
          this.drawerOper("add_huikuan");
          break;
        case "check":
          // 审核
          break;
        case "order":
          this.dialogData.title = "选择执行流程";
          this.dialogData.width = "40%";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [
            {
              type: "new-order",
              title: "",
              value: {
                clientName: this.tempContract.name,
                contractId: this.tempContract.id,
              },
            },
          ];
          this.dialogData.flag = true;

          break;

        default:
          break;
      }
    },

    /**
     * 表格界面按钮操作事件
     */
    handleClick(data, flag) {
      if (!this.popoData.popoVisible) {
        if (!this.contractDrawerData.flag) {
          switch (flag) {
            case 0:
              // 新增合同
              if (this.searchData.screenButton_add == 2) {
                this.$message.error(
                  this.$errorMessage.getErrorMessageInfo("authority")
                );
                return;
              }
              let list = [];
              this.isRenew = false;
              this.clientList.forEach((client) => {
                list.push({ value: client.id, label: client.clientName });
              });
              // 新增客户抽屉数据
              this.contractDrawerData = contractDrawData.getNewClientDrawerData(
                list
              );
              this.contractDrawerData.flag = true;
              break;
            case 1:
              // 导出合同
              this.exportAllContract();
              break;

            default:
              break;
          }
        } else {
          this.closeUpdateTableDrawer();
        }
      } else {
        this.popoCanael();
      }
    },
    commandMenu(e) {
      if (e == 0) {
        this.exportAllContract();
      } else {
        this.exportChoiceContract();
      }
    },
    /**
     * 导出所有合同
     */
    exportAllContract() {
      let requestData = {
        userId: this.searchData.tempUserId,
        companyId: this.baseInfo.company.companyId, // 当前公司ID
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
        search: this.searchData.input, // 客户全称/编号/合同编号筛选客户
        status: this.tabindex, // 合同状态:"0"->全部合同;"1"->将到期合同;"2"->已到期合同
      };
      window.location.href =
        this.$baseurl +
        "/contract/exportAllContract?data=" +
        JSON.stringify(requestData);
    },
    /**
     * 导出所选合同
     */
    exportChoiceContract() {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请先选择要导出的合同内容!");
        return;
      }
      let contractIds = [];
      for (let index = 0; index < this.multipleSelection.length; index++) {
        const element = this.multipleSelection[index];
        contractIds.push(element.id);
      }
      let requestData = {
        contractIds: contractIds,
        companyId: this.baseInfo.company.companyId,
      };
      window.location.href =
        this.$baseurl +
        "/contract/exportChoiceContract?data=" +
        JSON.stringify(requestData);
    },
    /**
     * 回车创建合同
     * 根据所选的客户ID,判断该客户是否在此之前创建过其它合同
     *      拥有其它合同:
     *          展示合同列表
     *      没有合同:(可能性不大)
     *          直接展示服务产品列表
     */
    enterCreateContract() {
      this.tempClient = this.contractDrawerData.data.modelList[0].line[0].value;
      this.tempContract.name = this.tempClient.label;
      let requestData = {
        clientId: this.tempClient.value,
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/contract/getAllContractByClientId",
        requestData,
        (data) => {
          this.contractDrawerData = contractDrawData.getNewClientTableDrawerData(
            data,
            this.tempClient.label
          );
          this.contractDrawerData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 表格单行点击事件
     */
    rowClick(row, column, event) {
      if (!this.contractDrawerData.flag) {
        //防止重复点击
        this.tempContract.id = row.id;
        this.tempContract.name = row.clientName;
        this.tempClientName = row.clientName;
        this.tempClient.value = row.clientId;
        this.ttempClient = row;

        // this.$featureData.getAttributeData(
        //   "contractInfo",
        //   false,
        //   allContractData.returnDrawerKeys("合同信息"),
        //   (authorityData) => {
        //     this.contractDrawerAuthorityData = authorityData;
        //     // 2.拿对应属性的权限
        //     this.$authority.getModelFeatureAuthority(
        //       this,
        //       "contractInfo",
        //       authorityData,
        //       (authorityList) => {
        //         this.contractDrawerDataAuthorityList = authorityList;
        this.showContractInfoByID();
        // this.contractDrawerData.flag = true;
        //       }
        //     );
        //   }
        // );
      } else {
        this.closeUpdateTableDrawer();
      }
    },

    /**
     * 获取合同信息具体数据
     */
    showContractInfoByID() {
      // 1.拿到合同的按钮权限
      this.allFunctionButtons = JSON.parse(
        JSON.stringify(this.contractAllFunctionButtons)
      );
      this.model = "contractInfo";
      setTimeout(() => {
        this.$featureData.getAttributeData(
          "contractInfo",
          false,
          allContractData.returnDrawerKeys("合同信息"),
          (authorityData) => {
            this.contractDrawerAuthorityData = authorityData;
            console.log("现在的数据是", this.contractDrawerAuthorityData);
            // 2.拿对应属性的权限
            this.$authority.getModelFeatureAuthority(
              this,
              "contractInfo",
              authorityData,
              (authorityList) => {
                this.contractDrawerDataAuthorityList = authorityList;
                // this.showContractInfoByID();
                let requestData = {
                  contractId: this.tempContract.id,
                  userId: this.baseInfo.userId,
                  companyId: this.baseInfo.company.companyId,
                  authorityData: this.$initDrawerAuthorityData.getKeysByAuthorityList(
                    this.contractDrawerAuthorityData
                  ),
                };
                this.$request(
                  "post",
                  "/contract/showContractByID",
                  requestData,
                  (data) => {
                    console.log("当前合同信息", data);
                    // 3.将权限数据对应到界面
                    let butText = {
                      text: "提交",
                      active: "submit",
                    };
                    if (
                      data.data.status == "待提交" ||
                      data.data.status == "合同审核不通过"
                    ) {
                      butText = {
                        text: "提交",
                        active: "submit",
                        key: "cancel",
                      };
                    } else {
                      butText = {
                        text: "电子合同生成",
                        active: "electronic_contract",
                        key: "electronicContract",
                      };
                    }
                    this.contractDrawerData = allContractData.contractInfoDrawerData(
                      this.tempClientName,
                      this.contractDrawerDataAuthorityList,
                      this.whetherAgencyHead, // 是否是机构负责人
                      this.contractDrawerOptions,
                      "合同信息",
                      butText
                    );
                    // this.tempSign = data.data.sign;
                    this.contractDrawerData = this.$initDrawerAuthorityData.initDrawerData(
                      this.contractDrawerData,
                      data.data
                    );
                    allContractData.initData(
                      data,
                      this.contractDrawerData,
                      this.contractAllFunctionButtons,
                      this.whetherAgencyHead
                    );
                    this.contractDrawerData.status = this.whetherAgencyHead
                      ? ""
                      : data.data.status;
                    console.log("打开抽屉:", this.contractDrawerData);

                    this.contractDrawerData.flag = true;
                  },
                  (errorMessage) => {
                    this.$message.error(errorMessage);
                  }
                );
                // this.contractDrawerData.flag = true;
              }
            );
          }
        );
      }, 0);
    },

    /**
     * 点击客户
     */
    showClient(row) {
      this.tempContract.id = row.id;
      this.tempContract.name = row.clientName;
      this.tempClient.value = row.clientId;
      this.tempClientName = row.clientName;
      this.ttempClient = row;
      this.showClientByID();
    },
    /**
     * 根据客户ID,查看客户信息
     */
    showClientByID() {
      this.allFunctionButtons = JSON.parse(
        JSON.stringify(this.clientAllFunctionButtons)
      );
      this.$featureData.getAttributeData(
        "clientInfo",
        false,
        allClientData.returnDrawerKeys("客户信息"),
        (authorityData) => {
          this.clientDrawerAuthorityData = authorityData;
          // 2.拿对应属性的权限
          this.$authority.getModelFeatureAuthority(
            this,
            "clientInfo",
            authorityData,
            (authorityList) => {
              this.clientDrawerDataAuthorityList = authorityList;
              // 4.拿具体的值
              let requestData = {
                clientId: this.tempClient.value,
                userId: this.baseInfo.userId,
                companyId: this.baseInfo.company.companyId,
                authorityData: this.$initDrawerAuthorityData.getKeysByAuthorityList(
                  this.clientDrawerAuthorityData
                ), // 客户的具体模块权限
              };
              this.$request(
                "post",
                "/client/showClientInfoByID",
                requestData,
                (data) => {
                  // 3.将权限数据对应到界面
                  this.contractDrawerData = allClientData.clientInfoDrawerData(
                    this.tempClientName,
                    this.clientDrawerDataAuthorityList,
                    this.whetherAgencyHead, // 是否是机构负责人
                    this.clientDrawerOptions,
                    "客户信息"
                  );
                  // 5.将具体的值匹配到界面
                  if (data.data.source.length > 0) {
                    data.data.source = data.data.source[0];
                  } else {
                    data.data.source = "";
                  }
                  // 单独处理所在地区数据
                  // if (data.data.area.length == 0) {
                  //   data.data.area = ["","",""]
                  // }
                  this.contractDrawerData = this.$initDrawerAuthorityData.initDrawerData(
                    this.contractDrawerData,
                    data.data
                  );
                  console.log(
                    "处理业务角色前的抽屉数据",
                    this.contractDrawerData
                  );
                  // 单独处理业务角色
                  let isShow = this.contractDrawerData.data.modelList[4].line[0]
                    .isShow;
                  let isDisable = this.contractDrawerData.data.modelList[4]
                    .line[0].isDisable;
                  this.contractDrawerData.data.modelList[4].line = [];
                  let userList = [];

                  this.clientDrawerOptions.allUser.forEach((element) => {
                    let dd = {
                      id: element.value,
                      name: element.label,
                    };
                    userList.push(dd);
                  });

                  for (let i = 0; i < data.data.role.length; i++) {
                    let element = data.data.role[i];
                    let line = {
                      id: element.id,
                      horizontal: true,
                      isShow: isShow,
                      isDisable: isDisable,
                      isSelect: false,
                      tempValue: "",
                      title: element.title,
                      key: "role",
                      type: "tags-select-obj",
                      value: element.value,
                      options: userList,
                    };
                    this.contractDrawerData.data.modelList[4].line.push(line);
                  }

                  this.contractDrawerData.data.customer = data.relevanceClient;
                  this.contractDrawerData.flag = true;
                }
              );
            }
          );
        }
      );
    },
    /**
     * 查看客户的关联客户
     */
    showRelevanceClient() {
      let requestData = {
        userId: this.baseInfo.userId,
        clientId: this.tempClient.value,
      };
      this.$request(
        "post",
        "/client/getAllRelevanceClientListByClientId",
        requestData,
        (data) => {
          this.contractDrawerData.data.gridData = data;
          this.contractDrawerData.data.customer = data.length;
        }
      );
    },
    /**
     * 手动解除关联客户
     */
    deleteRelevanceClient(id) {
      console.log("手动解除关联客户");
      let requestData = {
        clientId: this.tempClient.value,
        relevanceClientId: id,
      };
      this.$request(
        "post",
        "/client/removeRelevanceClientByClientId",
        requestData,
        (data) => {
          this.$message({
            message: "关联客户解除成功!",
            type: "success",
          });
          this.showRelevanceClient();
        }
      );
    },
    /**
     * 手动添加关联客户
     */
    addRelevanceClient() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        clientId: this.tempClient.value,
      };
      this.$request(
        "post",
        "/client/getNoAssociationClient",
        requestData,
        (data) => {
          let options = [];
          data.forEach((element) => {
            let dd = {
              label: element.clientName,
              value: element.clientId,
            };
            options.push(dd);
          });
          this.dialogData.title = "手动添加关联客户";
          this.dialogData.width = "40%";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push(
            {
              text: "确定",
              active: "makesure_relevance",
            },
            {
              text: "取消",
              active: "cancel",
            }
          );
          this.dialogData.data = [
            {
              type: "select-object-collapse",
              title: "",
              value: [],
              options: options,
              placeholder: "选择客户",
            },
          ];
          this.dialogData.flag = true;
        }
      );
    },
    /**
     * 确定手动关联客户
     */
    makesureRelevance() {
      let list = [];
      this.dialogData.data[0].value.forEach((element) => {
        list.push(element.value);
      });
      let requestData = {
        clientId: this.tempClient.value, // 当前客户
        relevanceClient: list, // 要关联的客户数组
      };
      this.$request(
        "post",
        "/client/setRelevanceClientByClientId",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.$message({
            message: "手动关联客户成功!",
            type: "success",
          });
          this.showRelevanceClient();
        }
      );
    },
    /**
     * 根据客户ID,查看客户联系人
     */
    showClientContactDataByID() {
      let requestData = {
        clientId: this.tempClient.value,
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.showContactsOrNot()
        .then((res) => {
          this.$request(
            "post",
            "/client/showClientContactByID",
            requestData,
            (data) => {
              this.contractDrawerData = allClientData.getDrawerData(
                this.tempClientName,
                this.clientDrawerOptions,
                "联系人"
              );
              // 单独处理数据
              let itemData = JSON.parse(
                JSON.stringify(this.contractDrawerData.data.modelList[0])
              );
              if (data.length > 0) {
                this.contractDrawerData.data.modelList = [];
              }
              for (let index = 0; index < data.length; index++) {
                let element = data[index];
                let item_data = JSON.parse(JSON.stringify(itemData));
                for (let i = 0; i < item_data.line.length; i++) {
                  let ele = item_data.line[i];
                  ele.value = element[ele.key1];
                }
                this.contractDrawerData.data.modelList.push(item_data);
              }
              this.contractDrawerData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        })
        .catch(() => {
          this.$message.error("暂无权限, 请联系管理员!");
        });
    },
    /**
     * 查看联系人权限
     */
    showContactsOrNot() {
      let p = new Promise((resolve, reject) => {
        let requestData = {
          userId: this.baseInfo.userId,
          companyId: this.baseInfo.company.companyId,
          clientId: this.tempClient.value,
        };
        this.$request(
          "post",
          "/client/showContactsOrNot",
          requestData,
          (data) => {
            if (data) {
              resolve();
            } else {
              reject();
            }
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      });
      return p;
    },
    /**
     * 显示合同列表
     */
    getAllContractByClientId() {
      let requestData = {
        clientId: this.tempClient.value,
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/contract/getAllContractByClientId",
        requestData,
        (data) => {
          this.contractDrawerData = allClientData.getDrawerData(
            this.tempClientName,
            this.clientDrawerOptions,
            "合同"
          );
          // 单独处理表格数据
          this.contractDrawerData.data.modelList[0].line[0].tableData = data;
          this.contractDrawerData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     *右击改变表头显示与否事件
     */
    changeStatus(data) {
      if (data.index == 0) {
        data.item.isDisable = true;
        this.tragerDialogData.data.selectMenuData.splice(
          this.tragerDialogData.data.selectMenuData.indexOf(data.item),
          1
        );
        this.tragerDialogData.data.chooseableMenuData.push(data.item);
      } else {
        data.item.isDisable = false;
        this.tragerDialogData.data.chooseableMenuData.splice(
          this.tragerDialogData.data.chooseableMenuData.indexOf(data.item),
          1
        );
        this.tragerDialogData.data.selectMenuData.push(data.item);
      }
    },
    /**
     * 确定修改表头数据
     */
    markSureUpdateTableMenu(resultData) {
      console.log("确定修改表头数据:", resultData);
      this.tabMenu = JSON.parse(JSON.stringify(resultData));
      this.tragerDialogData.flag = false;
      this.initTableData();
      this.$menuData.saveTableMenuData(resultData, "contractInfo", this);
    },
    /**
     * 修改表头顺序dialog打开
     */
    updateTableMenu() {
      this.tragerDialogData.flag = true;
    },
    /**
     * 抽屉中tag移除事件
     * data(tag,i,j):tag->移除的tag,i为第几个model,j为第几line
     */
    tagsHandleClose(data) {
      this.contractDrawerData.data.modelList[data.i].line[data.j].value.splice(
        this.contractDrawerData.data.modelList[data.i].line[
          data.j
        ].value.indexOf(data.tag),
        1
      );
      this.saveDrawerDataInit("角色信息");
    },
    /**
     * 抽屉中添加tag事件
     * data(i,j):i为第几个model,j为第几line
     */
    addTags(data) {
      if (this.contractDrawerData.title == "合同") {
        this.tempTags = this.contractDrawerData.data.modelList[data.i].line[
          data.j
        ].value;
        let v = "2";
        this.tempTags.push(v);
        this.saveDrawerDataInit("合同信息");
      }
      if (this.contractDrawerData.title == "客户") {
        if (
          this.contractDrawerData.data.modelList[data.i].line[data.j].isSelect
        ) {
          let value = this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].tempValue;
          if (
            value &&
            this.contractDrawerData.data.modelList[data.i].line[
              data.j
            ].value.indexOf(value) < 0
          ) {
            // 排除重复选项
            this.contractDrawerData.data.modelList[data.i].line[
              data.j
            ].value.push(value);
            this.saveDrawerData("角色信息");
          }
          this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].isSelect = false;
          this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].tempValue = "";
        } else {
          this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].isSelect = true;
        }
      }
    },
    /**
     * 抽屉组件中按钮操作事件(主要是显示弹出框事件)
     */
    drawerOper(active, setData) {
      let dd = {};
      let requestData = {};
      switch (active) {
        // 合同抽屉操作
        case "info":
          // 合同信息
          if (this.contractDrawerData.title == "客户") {
            this.showClientByID();
          }
          if (this.contractDrawerData.title == "合同") {
            this.showContractInfoByID();
          }
          break;
        case "huikuan":
          // 收款记录
          this.getReceiptRecord();
          break;
        case "shoufei":
          // 收费情况
          this.getCharge();
          break;
        case "zhichu":
          // 合同支出
          this.getExpendByContractId();
          break;
        case "fujian":
          // 合同附件
          this.getAttachmentByContractId();
          break;
        case "fapiao":
          // 合同发票
          this.getInvoiceByContractId();
          // this.getCharge();
          break;
        case "add_huikuan":
          // 添加回款
          console.log("该合同所有服务项:", this.tempServices);
          if (this.ttempClient.color == "#ced4da") {
            this.$message({
              message: "当前合同金额有误!",
              type: "warning",
              duration: 1000,
            });
            return;
          }
          if (this.ttempClient.color == "#82c91e") {
            this.$message({
              message: "当前合同已全部回款!",
              type: "success",
              duration: 1000,
            });
            return;
          }
          requestData = {
            contractId: this.tempContract.id,
          };
          this.$request(
            "post",
            "/contract/getAllServiceItemsByContractId",
            requestData,
            (data) => {
              this.tempServices = [];
              data.forEach((element) => {
                let serves = {
                  name: element.name,
                  money: element.price,
                  expendMoney: "",
                  des: "",
                };
                this.tempServices.push(serves);
              });
              let options = [];
              let accountList = [];
              this.tempServices.forEach((element) => {
                let ll = {
                  label: element.name,
                  value: element.name,
                };
                options.push(ll);
              });
              this.accountList.forEach((element) => {
                let accountList1 = "";
                element.accountList.forEach((account) => {
                  accountList1 =
                    accountList1 == ""
                      ? account.account
                      : accountList1 + "," + account.account;
                });

                let dd = {
                  label: element.owner,
                  value: accountList1, // 拿到该收款商户对应的收款账号组
                };
                accountList.push(dd);
              });
              this.dialogData.title = "添加回款信息";
              this.dialogData.buttonArray = [];
              this.dialogData.width = "50%";
              this.dialogData.data = [
                {
                  type: "add_transaction",
                  title: "",
                  value: {
                    id: this.baseInfo.company.companyId, // 公司id
                    name: this.tempContract.name, // 客户名称
                    options: options, // 回款服务项
                    accountList: accountList, // 收款账户
                  },
                },
              ];
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );

          break;

        case "sure_huikuan":
          // 确认添加回款信息
          let file = {
            path: setData.file,
            title: "付款凭证附件",
          };
          requestData = {
            contractId: this.tempContract.id, // 合同ID
            addTime: this.$formatTime(new Date(), "YYYY-MM-DD HH:mm:ss"), // 回款时间
            money: setData.money, // 回款金额
            service: setData.service, // 回款服务项
            account: setData.account, // 收款账户
            receivedId: setData.receivedId, // 交易单号
            file: file, // 回款单附件
            userId: this.baseInfo.userId, // 当前操作人
            status: "待审核", // 回款状态
            flag: true,
            companyId: this.baseInfo.company.companyId,
          };

          console.log("确认添加回款记录:", requestData);
          this.$request(
            "post",
            "/contract/chargeContract",
            requestData,
            (data) => {
              console.log("我的数据", setData.receivedId.voucherNo);
              this.$message({
                message: "操作成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "查账领取收款记录,收款单号:" +
                  setData.receivedId.voucherNo +
                  "!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  // this.showContractInfoByID();
                  this.initTableData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "make_invoice":
          this.contractInvoiceInfo = {
            multipleSelectionReceipt: [], // 选择的收款单号
            invoiceData: {}, // 选择的收款单号发票数据
            receiptNumberList: [], // 收款单号列表
            receiptIdList: [], // 收款单号id列表
            sellerInvoiceData: {}, // 销售方数据
            buyerInvoiceData: {}, // 销售方数据
          };
          let requestData = {
            contractId: this.tempContract.id,
          };
          this.$request(
            "post",
            "/contract/getReceiptRecord",
            requestData,
            (data) => {
              this.dialogData.title = "选择收款单号";
              this.dialogData.width = "1000px";
              this.dialogData.buttonArray = [
                {
                  text: "确定",
                  active: "sure_choose",
                },
                {
                  text: "取消",
                  active: "cancel",
                },
              ];
              this.dialogData.data = [
                {
                  title: "",
                  type: "table",
                  select: true,
                  value: "",
                  data: data || [],
                  props: [
                    {
                      prop: "voucherNo",
                      label: "收款单号",
                      width: "200",
                    },
                    {
                      prop: "money",
                      label: "收款金额",
                      width: "100",
                    },
                    {
                      prop: "auditedIncome",
                      label: "已收款",
                      width: "100",
                    },
                    {
                      prop: "accruedAssets",
                      label: "未收款",
                      width: "100",
                    },
                    {
                      prop: "addTime",
                      label: "收款时间",
                      with: "150",
                    },
                    {
                      prop: "status",
                      label: "收款状态",
                      width: "100",
                    },
                    {
                      prop: "payee",
                      label: "收款人",
                      width: "100",
                    },
                  ],
                },
              ];
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "create-contract":
          // 新建合同
          this.dialogData.title = "选择产品";
          this.dialogData.width = "50%";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [
            {
              type: "new-contract",
              title: "",
              value: { clientName: this.tempClient.label },
            },
          ];
          this.dialogData.flag = true;
          break;
        case "submit":
          // 合同提交
          for (
            let index = 0;
            index < this.contractDrawerData.data.modelList.length;
            index++
          ) {
            let element = this.contractDrawerData.data.modelList[index];
            for (let i = 0; i < element.line.length; i++) {
              let ele = element.line[i];
              if (ele.key == "cost" && ele.value == "") {
                this.$message({
                  message: "合同成本不能为空!",
                  duration: 1000,
                  type: "error",
                });
                return;
              }
            }
          }

          this.submitContract();

          break;
        case "add_free_serves":
          // 添加赠送的服务项
          for (
            let index = 0;
            index < this.contractDrawerData.data.modelList.length;
            index++
          ) {
            let element = this.contractDrawerData.data.modelList[index];
            for (let i = 0; i < element.line.length; i++) {
              let ele = element.line[i];
              if (ele.key == "freeServes") {
                if (!ele.isDisable) {
                  console.log("当前赠送服务项:", ele);
                  dd = {
                    isEdit: true, // 是否是编辑状态
                    flag: true, // 是否是新增
                    serviceItem: {}, //服务项
                    serviceTime: "", // 服务时间
                  };
                  ele.value.push(dd);
                } else {
                  this.$message({
                    message:
                      "当前合同状态不可编辑或者您没有权限进行编辑,请联系机构负责人或系统负责人!",
                    duration: 1000,
                    type: "error",
                  });
                }
              }
            }
          }
          break;
        case "expend_submit":
          // 合同支出提交
          requestData = {
            contractId: this.tempContract.id, // 合同ID
            expendNum: this.dialogData.data[0].value.expendNum, // 支出编号
            expendType: this.dialogData.data[0].value.expendType, // 支出类型
            expendStatus: "完成", // 支出状态  待提交  待审核  完成  审核不通过
            time: this.$formatTime(new Date(), "YYYY-MM-DD HH:mm:ss"), // 确认支出时间  当状态为完成时自动填充
            title: this.dialogData.data[0].value.title, //收款账户
            file: this.dialogData.data[0].value.file, // 附件
            // services: this.dialogData.data[0].value.services,
            services: [
              {
                name: "测试",
                expendMoney: 1000,
              },
            ],
            user: this.baseInfo.userId,
          };
          if (requestData.title == "") {
            this.$message.error("支出标题不能为空!");
            return;
          }
          if (requestData.file == "") {
            this.$message.error("支出附件不能为空!");
            return;
          }
          // if (requestData.services[0].expendMoney == "") {
          //   this.$message.error("支出金额不能为空!");
          //   return;
          // }
          this.$request(
            "post",
            "/contract/updateExpend",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "添加支出!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.getExpendByContractId();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "expend_echeck":
          // 合同支出审核
          requestData = {
            contractId: this.tempContract.id, // 合同ID
            expendNum: this.dialogData.data[0].value.expendNum, // 支出编号
            expendType: this.dialogData.data[0].value.expendType, // 支出类型
            expendStatus: "完成", // 支出状态  待提交  待审核  完成  审核不通过
            time: this.dialogData.data[0].value.time, // 确认支出时间  当状态为完成时自动填充
            receiptAccount: this.dialogData.data[0].value.receiptAccount, //收款账户
            receiptName: this.dialogData.data[0].value.receiptName, // 收款人姓名
            blank: this.dialogData.data[0].value.blank, // 开户银行  如徽商银行等
            des: this.dialogData.data[0].value.des, // 支出备注
            file: this.dialogData.data[0].value.file, // 附件
            services: this.dialogData.data[0].value.services,
          };
          this.$request(
            "post",
            "/contract/updateExpend",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "支出审核!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.getExpendByContractId();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "renew":
          // 续签
          this.dialogData.title = "续签合同";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push(
            {
              text: "确定",
              active: "sure_renew",
            },
            {
              text: "取消",
              active: "cancel",
            }
          );

          this.dialogData.data = [
            {
              type: "renew",
              title: "",
              value: {
                flag: "0",
                // time: time1,
                time: this.contractDrawerData.data.modelList[1].line[4].value2,
                year: "",
                month: "",
              },
            },
          ];
          this.dialogData.flag = true;
          break;
        case "sure_renew":
          this.isRenew = true;
          switch (this.dialogData.data[0].value.flag) {
            case 0:
            case "0":
              // 延长当前合同有效期
              this.isRenew = true;
              this.contractDrawerData.data.modelList[1].line[3].value[0] = parseInt(
                parseInt(this.dialogData.data[0].value.year) +
                  parseInt(
                    this.contractDrawerData.data.modelList[1].line[3].value[0]
                  )
              );
              this.contractDrawerData.data.modelList[1].line[3].value[1] = parseInt(
                parseInt(this.dialogData.data[0].value.month) +
                  parseInt(
                    this.contractDrawerData.data.modelList[1].line[3].value[1]
                  )
              );
              if (
                this.contractDrawerData.data.modelList[1].line[3].value[1] >= 12
              ) {
                this.contractDrawerData.data.modelList[1].line[3].value[0]++;
                this.contractDrawerData.data.modelList[1].line[3].value[0] =
                  this.contractDrawerData.data.modelList[1].line[3].value[0] %
                  12;
              }
              this.contractDrawerData.data.modelList[1].line[2].value =
                "原合同续签";
              this.dialogData.flag = false;
              this.saveDrawerData("延长合同有效期");
              break;
            case "1":
            case 1:
              // 新建续签合同
              this.drawerOper("create-contract");
              break;

            default:
              break;
          }
          break;
        case "end_the_manual":
          // 手动结束合同
          this.$confirm("该合同未到期或未全部回款, 是否继续结束?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/contract/setContractFinish",
                { contractId: this.tempContract.id },
                (data) => {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                    duration: 1000,
                  });
                  this.contractDrawerData.flag = false;
                  this.$operationRecords.saveOperationRecords(
                    this.tempContract.id,
                    "手动结束合同有限期!",
                    this.baseInfo,
                    "contract",
                    this,
                    (data2) => {
                      this.initTableData();
                    }
                  );
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                  this.dialogData.flag = false;
                }
              );
            })
            .catch(() => {});
          break;
        case "delete_contract":
          // 删除合同
          this.$request(
            "post",
            "/contract/beforeDelContract",
            { contractId: this.tempContract.id },
            (data) => {
              if (data.flag) {
                // 正常删除  当前合同没有收款记录
                this.deleteContract();
              } else {
                // 有收款记录,提示删除
                this.$confirm("该合同已有收款记录,是否继续删除?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(() => {
                    this.deleteContract();
                  })
                  .catch(() => {});
              }
            }
          );
          break;
        case "electronic_contract":
          // 点击生成电子合同
          // this.$message.error("系统升级中!");
          this.$confirm("此操作会覆盖之前生成的电子合同,是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let requestData = {
                clientId: this.tempClient.value,
                userId: this.baseInfo.userId,
                companyId: this.baseInfo.company.companyId,
              };
              this.$request(
                "post",
                "/client/showClientContactByID",
                requestData,
                (clientContactData) => {
                  this.dialogData.title = "电子合同生成";
                  this.dialogData.width = "40%";
                  this.dialogData.buttonArray = [];
                  this.dialogData.data = [
                    {
                      type: "select_electronic_contract",
                      title: "",
                      value: {
                        signInfo: {},
                        contact: clientContactData,
                        signName: this.tempSign, // 签单人
                      },
                      flag: false,
                    },
                  ];
                  this.dialogData.flag = true;
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case "operating_record_contract":
          // 查看合同的操作记录
          this.$operationRecords.showOperationRecords(
            this.tempContract.id,
            "contract",
            this
          );
          break;
        // 收款记录操作
        case "sure_choose":
          // if (this.contractInvoiceInfo.multipleSelectionReceipt.length == 0) {
          //   this.$message.error("请先选择收款单号");
          //   return;
          // }
          this.contractInvoiceInfo.money = 0;
          this.contractInvoiceInfo.receiptNumberList = [];
          this.contractInvoiceInfo.receiptIdList = [];
          this.contractInvoiceInfo.multipleSelectionReceipt.forEach((item) => {
            this.contractInvoiceInfo.money += +item.money;
            this.contractInvoiceInfo.receiptNumberList.push(item.voucherNo);
            this.contractInvoiceInfo.receiptIdList.push(item.id);
          });
          requestData = this.contractInvoiceInfo.receiptNumberList;
          // 检查收款单号对应收款机构是否一致 一致返回收款机构企业信息(销售方)
          this.$request(
            "post",
            "/client/getByReceiptNumbers",
            requestData,
            (data) => {
              this.contractInvoiceInfo.sellerInvoiceData = data;
              let requestData2 = {
                id: this.tempClient.value,
                queryInvoiceTaxAccountFlag: false, // 是否查询税务局账号信息 false 不查
              };
              this.$request(
                "post",
                "/client/getClientInfoByClientID",
                requestData2,
                (data2) => {
                  this.contractInvoiceInfo.buyerInvoiceData = data2;
                  this.dialogData.title = "确认开票";
                  this.dialogData.width = "600px";
                  this.dialogData.buttonArray = [
                    {
                      text: "确定",
                      active: "sure_kp",
                    },
                    {
                      text: "取消",
                      active: "cancel",
                    },
                  ];
                  this.dialogData.data = [
                    {
                      title: "",
                      type: "contract-invoice-dialog",
                      value: this.contractInvoiceInfo,
                    },
                  ];
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_kp":
          this.ccc();
          break;
        case "save_invoice":
          // 发票保存
          this.saveInvoiceDetails().then(() => {
            this.$message.success("发票保存成功!");
            this.dialogData.flag = false;
            this.initTableData();
          });
          break;
        case "sure_editTime":
          // 确认修改收款时间
          requestData = {
            contractId: this.tempContract.id, //合同 id
            receiptIds: [this.linkContractData.id], // 收款记录 id
            addTime: this.dialogData.data[0].value,
          };
          this.$request(
            "post",
            "/contract/updateReceipt",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.initTableData();
              this.getReceiptRecord();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        // 客户抽屉操作
        case "lianxiren":
          // 获取客户抽屉 联系人列表数据
          this.showClientContactDataByID();
          break;
        case "hetong":
          // 获取客户抽屉 合同列表数据
          this.getAllContractByClientId();
          break;

        case "qualification":
          // 获取客户抽屉 资质列表数据
          this.getQualificationByClient();
          break;
        case "archives":
          // 获取客户抽屉 档案列表数据
          this.getArchivesListByClientId();
          break;
        case "cloud":
          // 获取客户抽屉 云盘列表数据
          this.getCloudByClientId();
          break;
        case "newContact":
          let temp = {
            line: [
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "姓名",
                type: "input-but",
                value: "",
                buts: [
                  {
                    text: "公开",
                    active: "gongkai",
                  },
                  {
                    text: "删除",
                    active: "shanchu",
                  },
                ],
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "职位",
                type: "select",
                value: "老板",
                options: [
                  {
                    label: "老板",
                    value: "老板",
                  },
                  {
                    label: "法人",
                    value: "法人",
                  },
                  {
                    label: "股东",
                    value: "股东",
                  },
                  {
                    label: "会计",
                    value: "会计",
                  },
                  {
                    label: "其他",
                    value: "其他",
                  },
                ],
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "电话",
                type: "input",
                value: "",
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "手机",
                type: "input",
                value: "",
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "邮件",
                type: "input",
                value: "",
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "备注",
                type: "input",
                value: "",
              },
            ],
          };
          this.contractDrawerData.data.modelList.push(temp);
          break;
        case "create_contract":
          // 新建合同
          this.drawerOper("create-contract");
          break;
        case "delete_client":
          // 删除客户
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_delete_client",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "删除,同时删除其对应商机",
            value: this.tempClientName,
          });
          this.dialogData.flag = true;
          break;
        case "makesure_delete_client":
          // 确定删除
          requestData = {
            clientId: this.tempClient.value,
            status: 0,
            userId: this.baseInfo.userId,
            companyId: this.baseInfo.company.companyId,
          };
          this.$request(
            "post",
            "/client/deleteClientStatus",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.contractDrawerData.flag = false;
              this.$message({
                message: "客户删除成功,删除的客户可在客户回收站中还原!",
                duration: 1000,
                type: "success",
              });
              this.$operationRecords.saveOperationRecords(
                this.tempClient.value,
                "删除客户!",
                this.baseInfo,
                "client",
                this,
                (data2) => {
                  this.initTableData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "run_off":
          // 申请代账流失
          this.toApplyForCustomerChurn();
          break;
        case "confirm_that_client_loss":
          // 确定客户流失
          dd = {
            typeName: this.dialogData.data[0].value.label, // 服务类型名称
            id: this.dialogData.data[0].value.value, // 服务类型ID
          };
          requestData = {
            clientId: this.tempClient.value,
            serviceType: dd,
            lossReason: this.dialogData.data[1].value,
            userId: this.baseInfo.userId,
            companyId: this.baseInfo.company.companyId,
          };
          this.$request(
            "post",
            "/client/clientChurnByClientId",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.contractDrawerData.flag = false;
              this.$message({
                message: "成功申请客户流失!",
                duration: 1000,
                type: "success",
              });
              this.$operationRecords.saveOperationRecords(
                this.tempClient.value,
                "申请客户流失!",
                this.baseInfo,
                "client",
                this,
                (data2) => {
                  this.initTableData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;

        case "pause_serve":
          // 暂停代账服务

          break;
        case "zhuanweifeidaizhang":
          // 转为非代账
          break;
        case "get_companyInfo":
          // 获取工商信息
          this.getCompanyInfo();
          break;
        case "genjin":
          // 添加跟进记录
          this.showGenjin(this.ttempClient);
          break;

        case "makesure_relevance":
          // 确定手动添加关联客户
          this.makesureRelevance();
          break;
        case "adding_associated_customer":
          // 手动添加关联客户
          this.addRelevanceClient();
          break;
        case "operating_record":
          // 查看客户的操作记录
          this.$operationRecords.showOperationRecords(
            this.tempClient.value,
            "client",
            this
          );
          break;
        case "sure_refund":
          requestData = {
            contractId: this.tempContract.id, // 合同ID
            addTime: this.$formatTime(new Date(), "YYYY-MM-DD HH:mm:ss"), // 回款时间
            money: -this.dialogData.data[1].value, // 退款金额
            service: this.linkContractData.service, // 回款服务项
            account: this.linkContractData.account, // 收款账户
            receivedId: {
              id: this.linkContractData.receivedId,
              voucherNo: this.linkContractData.voucherNo,
            }, // 交易单号
            file: {
              path: "",
            }, // 回款单附件
            userId: this.baseInfo.userId, // 当前操作人
            status: "待审核", // 回款状态
            flag: false,
            companyId: this.baseInfo.company.companyId,
          };
          this.$request(
            "post",
            "/contract/chargeContract",
            requestData,
            (res) => {
              this.$message({
                message: "退款成功!",
                type: "success",
                duration: 1000,
              });
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "退款记录,收款单号:" + this.linkContractData.voucherNo + "!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.dialogData.flag = false;
                  this.getReceiptRecord();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;

        case "cancel_expend":
          this.dialogData.flag = false;
          this.getExpendByContractId();
          break;
        case "addExpend":
          this.dialogData.data[1].value = setData;
          break;
        case "sure_addExpend":
          requestData = {
            companyId: this.baseInfo.company.companyId,
            contractId: this.tempContract.id, // 合同 id
            clientId: this.tempClient.value, // 客户 id
            createUserId: this.baseInfo.userId, // 创建人 id
            reasons: this.dialogData.data[0].data.reasons, // 付款事由
            receiptNumber: this.dialogData.data[0].data.receiptNumber, //  收款单号
            paymentAmount: this.dialogData.data[0].data.paymentAmount, //  付款金额
            paymentMethod: this.dialogData.data[0].data.paymentMethod, //  付款方式
            paymentDate: this.dialogData.data[0].data.paymentDate, // 付款日期
            payeeName: this.dialogData.data[0].data.payeeName, // 收款人全称
            bankAccount: this.dialogData.data[0].data.bankAccount, // 银行账户
            bankOfDeposit: this.dialogData.data[0].data.bankOfDeposit, // 开户行
            remark: this.dialogData.data[0].data.remark, // 备注说明
            attachments: this.dialogData.data[0].data.attachments.toString(), // 附件
            payer: this.dialogData.data[0].data.payer, // 付款单位
          };
          console.log("requestData", requestData);
          if (!requestData.paymentAmount) {
            this.$message.error("付款金额必填!");
            return;
          }
          if (!requestData.paymentMethod) {
            this.$message.error("付款方式必填!");
            return;
          }
          if (!requestData.reasons) {
            this.$message.error("付款理由必填!");
            return;
          }
          if (!requestData.paymentDate) {
            this.$message.error("付款日期必填!");
            return;
          }
          if (!requestData.payeeName) {
            this.$message.error("收款人全称必填!");
            return;
          }
          if (!requestData.bankAccount) {
            this.$message.error("银行账户必填!");
            return;
          }
          if (!requestData.bankOfDeposit) {
            this.$message.error("开户行必填!");
            return;
          }
          if (!requestData.payer) {
            this.$message.error("付款单位必填!");
            return;
          }
          this.$request(
            "post",
            "/contractExpenditure/addExpenditure",
            requestData,
            (data) => {
              this.$message({
                message: "支出添加成功!",
                type: "success",
                duration: 1000,
              });

              // 添加常用信息
              let requestData2 = {
                userId: this.baseInfo.userId,
                payeeName: this.dialogData.data[0].data.payeeName, // 收款人全称
                bankAccount: this.dialogData.data[0].data.bankAccount, // 银行账户
                bankOfDeposit: this.dialogData.data[0].data.bankOfDeposit, // 开户行
              };
              this.$request(
                "post",
                "/contractCommonExpenses/add",
                requestData2,
                (data) => {
                  this.$operationRecords.saveOperationRecords(
                    this.tempContract.id,
                    "新建支出!",
                    this.baseInfo,
                    "contract",
                    this,
                    (data2) => {
                      this.dialogData.flag = false;
                      this.getExpendByContractId();
                    }
                  );
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        //  客户抽屉资质操作
        case "create_qualification":
          // 新建资质
          this.dialogData.title = "新建资质";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_create_qualification",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.id = "";
          this.dialogData.data = [
            {
              title: "资质名称",
              type: "input",
              value: "",
              isDisable: false,
            },
            {
              title: "资质类型",
              type: "select",
              options: this.clientDrawerOptions.qualificationList,
              value: "",
              isDisable: false,
            },
            {
              title: "资质开始时间",
              type: "time_date",
              value: "",
              isDisable: false,
            },
            {
              title: "资质到期时间",
              type: "time_date",
              value: "",
              isDisable: false,
            },
            {
              title: "上传附件",
              type: "upload_file",
              value: {
                value: "", // 客户资质
                action: this.$fileBaseurl + "/uploadImageByMultipartFile",
                headers: {
                  token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                  systemName: "finance",
                  title: "客户资质",
                },
              },
              flag: false,
            },
          ];
          this.dialogData.flag = true;
          break;
        case "makesure_create_qualification":
          // 确定添加资质
          requestData = {
            id: this.dialogData.id, // 有则修改,空则添加
            companyId: this.baseInfo.company.companyId, // 公司id
            clientId: this.tempClient.value, // 客户id
            name: this.dialogData.data[0].value, // 名称
            type: this.dialogData.data[1].value, // 标签
            startServiceDate: this.dialogData.data[2].value, // 开始时间 年月日
            endServiceDate: this.dialogData.data[3].value, // 到期时间 年月日
            certificate: this.dialogData.data[4].value.value, // 证书路径
          };
          if (requestData.name == "") {
            this.$message.error("资质名称不能为空!");
            return;
          }
          if (requestData.type == "") {
            this.$message.error("资质类型不能为空!");
            return;
          }
          if (requestData.startServiceDate == "") {
            this.$message.error("资质开始日期不能为空!");
            return;
          }
          if (requestData.endServiceDate == "") {
            this.$message.error("资质到期日期不能为空!");
            return;
          }
          if (requestData.certificate == "") {
            this.$message.error("资质附件不能为空!");
            return;
          }
          this.$request(
            "post",
            "/qualification/conserveQualificationInfo",
            requestData,
            (data) => {
              this.$message.success("资质添加成功!");
              this.dialogData.flag = false;
              this.getQualificationByClient();
            }
          );
          break;

        // 客户抽屉档案操作
        case "create_archives":
          // 新增档案
          this.dialogData.width = "700px";
          this.dialogData.title = "新增档案";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "add_archives",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          (this.dialogData.data = [
            {
              title: "",
              type: "add_archives",
              data: archivesData.addArchivesDialogData(
                this.clientList,
                this.categoryList,
                this.locationList,
                false
              ),
            },
          ]),
            console.log("新增档案dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;
          break;
        case "add_archives":
          this.addArchives();
          break;
        case "sure_get":
          // 确认领用
          requestData = {
            ids: [this.archivesData.id], // 档案ids
            status: 1, // 档案状态：0-未领用 1-已领用 2-已交付
            locationId: "", // 档案位置 id
            recipientId: this.baseInfo.userId, // 领取人 id
            operatorId: this.baseInfo.userId, // 操作人 id
          };
          this.$request(
            "post",
            "/archivesInfo/edit",
            requestData,
            (data) => {
              this.$message.success("领用成功");
              this.dialogData.flag = false;
              this.getArchivesListByClientId();
            },
            (errormessage) => {
              this.$message.error(errormessage);
            }
          );
          break;
        case "sure_return":
          // 确认归还
          requestData = {
            ids: [this.archivesData.id], // 档案idsE
            status: 0, // 档案状态：0-未领用 1-已领用 2-已交付
            locationId: this.dialogData.data[0].locationId[
              this.dialogData.data[0].locationId.length - 1
            ], // 档案位置 id
            recipientId: "", // 领取人 id
            operatorId: this.baseInfo.userId, // 操作人 id
          };
          if (!requestData.locationId) {
            this.$message.error("请选择归还位置!");
            return;
          }
          this.$request(
            "post",
            "/archivesInfo/edit",
            requestData,
            (data) => {
              this.$message.success("归还成功");
              this.dialogData.flag = false;
              this.getArchivesListByClientId();
            },
            (errormessage) => {
              this.$message.error(errormessage);
            }
          );
          break;
          break;
        case "sure_move":
          // 确认转移
          requestData = {
            ids: [this.archivesData.id], // 档案ids
            status: 0, // 档案状态：0-未领用 1-已领用 2-已交付
            locationId: this.dialogData.data[0].locationId[
              this.dialogData.data[0].locationId.length - 1
            ], // 档案位置 id
            operatorId: this.baseInfo.userId, // 操作人 id
          };
          if (!requestData.locationId) {
            this.$message.error("请选择转移位置!");
            return;
          }
          this.$request(
            "post",
            "/archivesInfo/edit",
            requestData,
            (data) => {
              this.$message.success("转移成功");
              this.dialogData.flag = false;
              this.getArchivesListByClientId();
            },
            (errormessage) => {
              this.$message.error(errormessage);
            }
          );
          break;
        case "sure_ziqu":
          // 自取
          requestData = {
            ids: [this.archivesData.id], // 档案ids
            status: 2, // 档案状态：0-未领用 1-已领用 2-已交付
            locationId: "", // 档案位置 id
            operatorId: this.baseInfo.userId, // 操作人 id
          };

          this.$request(
            "post",
            "/archivesInfo/edit",
            requestData,
            (data) => {
              this.$message.success("交付成功");
              this.dialogData.flag = false;
              this.getArchivesListByClientId();
            },
            (errormessage) => {
              this.$message.error(errormessage);
            }
          );
          break;
        case "sure_youji":
          // 快递
          this.dialogData.flag = false;
          this.dialogData.width = "1000px";
          this.dialogData.title = "寄件";
          this.dialogData.buttonArray = [];
          this.$request(
            "get",
            `/expressAddress/getSendAddress?userId=${this.baseInfo.userId}`,
            {},
            (data) => {
              this.$request(
                "post",
                "/archivesInfo/getDeliverable",
                {
                  clientId: this.tempClientId,
                  userId: this.baseInfo.userId,
                },
                (archivesData) => {
                  (this.dialogData.data = [
                    {
                      title: "",
                      type: "mail",
                      data: expressageData.mailDialog(
                        this.clientList,
                        data,
                        archivesData,
                        this.archivesData
                      ),
                    },
                  ]),
                    console.log("寄件dialog显示:", this.dialogData.data);
                  this.dialogData.flag = true;
                },
                (errormessage) => {
                  this.$message.error(errormessage);
                }
              );
            },
            (errormessage) => {
              this.$message.error(errormessage);
            }
          );
          break;
        //  客户抽屉云盘操作
        case "search":
          if (this.contractDrawerData.data.input == "") {
            this.getCloudByClientId();
            return;
          }
          requestData = {
            clientId: this.tempClient.value, // 客户 id
            fileName: this.contractDrawerData.data.input, //文件名
            clientAppShow: "", // 客户是否可见  '' 所有 0-客户不可见 1-客户可见
          };
          this.$request(
            "post",
            "/fileManage/queryFiles",
            requestData,
            (data) => {
              this.reSetFileOrder();
              this.transformCloudData(data);
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "addFolder":
          // 新建文件夹
          this.dialogData.title = "新建文件夹";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "make_sure_addFolder",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "文件夹名称",
              type: "input",
              value: "",
            },
          ];
          this.dialogData.flag = true;
          break;
        case "make_sure_addFolder":
          // 确认新建文件夹
          requestData = {
            clientId: this.tempClient.value, // 客户 id
            parentId: this.fileOrder[this.fileOrder.length - 1].id, // 父级目录 id
            name: this.dialogData.data[0].value.trim(), // 本级目录名称
            renewUserId: this.baseInfo.userId, // 进行更新操作的用户 id
          };
          this.$request(
            "post",
            "/fileManage/newFolder",
            requestData,
            (data) => {
              this.$message.success("新建成功");
              this.dialogData.flag = false;
              this.getCloudByClientId();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "reNameFile":
          // 重命名文件或文件夹
          if (this.contractDrawerData.multipleSelection.length == 0) {
            this.$message.warning("请选勾选要操作的文件或文件夹");
            return;
          }
          if (this.contractDrawerData.multipleSelection.length > 1) {
            this.$message.warning("不允许批量编辑，请勾选一项");
            return;
          }
          let item = this.contractDrawerData.multipleSelection[0];
          let updatedText = "";
          if (item.type !== 0) {
            // 利用字符串切片获取最后一个点之前的部分
            updatedText = item.name.slice(0, item.name.lastIndexOf("."));
          } else {
            updatedText = item.name;
          }
          this.dialogData.title = "重命名文件夹";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "make_sure_reNameFile",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "文件夹名称",
              type: "input",
              value: updatedText,
            },
          ];
          this.dialogData.flag = true;
          break;
        case "make_sure_reNameFile":
          // 确认重命名文件或文件夹
          requestData = {
            clientId: this.tempClient.value, // 客户 ids
            id: this.contractDrawerData.multipleSelection[0].id, // 修改的目录或文件 id
            parentId: this.contractDrawerData.multipleSelection[0].parent, // 父级目录 id
            name: this.dialogData.data[0].value, // 新名称
            renewUserId: this.baseInfo.userId, // 进行更新操作的用户 id
          };
          this.$request(
            "post",
            "/fileManage/updateName",
            requestData,
            (data) => {
              this.$message.success("重命名成功");
              this.dialogData.flag = false;
              this.getCloudByClientId();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "moveFile":
          // 移动文件或文件夹
          if (this.contractDrawerData.multipleSelection.length == 0) {
            this.$message.warning("请选勾选要操作的文件或文件夹");
            return;
          }
          this.dialogData.title = "移动文件";
          this.dialogData.buttonArray = [
            {
              text: "移动到此处",
              active: "make_sure_moveFile",
            },
          ];
          this.dialogData.data = [
            {
              type: "moveFile",
              data: {
                clientId: this.tempClient.value, // 客户id
                targetFolderId: "", // 选中要移动的文件夹,
                multipleSelection: this.contractDrawerData.multipleSelection,
              },
            },
          ];
          this.dialogData.flag = true;
          break;
        case "make_sure_moveFile":
          // 确认移动文件或文件夹
          requestData = {
            movingIdList: this.contractDrawerData.multipleSelection.map(
              (item) => item.id
            ), // 要移动的文件或文件夹 id 集合
            targetFolderId: this.dialogData.data[0].data.targetFolderId, // 目标文件夹 id
            renewUserId: this.baseInfo.userId, // 进行更新操作的用户 id
          };
          if (requestData.targetFolderId == "") {
            this.$message.warning("请选择要移动的位置!");
            return;
          }
          this.$request(
            "post",
            "/fileManage/move",
            requestData,
            (data) => {
              this.$message.success("移动成功!");
              this.dialogData.flag = false;
              this.reSetFileOrder();
              this.getCloudByClientId();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "deleteFile":
          // 删除文件或文件夹
          if (this.contractDrawerData.multipleSelection.length == 0) {
            this.$message.warning("请选勾选要操作的文件或文件夹");
            return;
          }
          requestData = {
            ids: this.contractDrawerData.multipleSelection.map(
              (item) => item.id
            ),
            renewUserId: this.baseInfo.userId, // 进行更新操作的用户 id
          };
          this.$confirm("确定要删除该项数据吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/fileManage/delBatch",
                requestData,
                (data) => {
                  this.$message.success("删除成功!");
                  this.getCloudByClientId();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case "uploadFile":
          // 上传文件
          let formData = new FormData();
          formData.append("clientId", this.tempClient.value);
          formData.append("renewUserId", this.baseInfo.userId);
          formData.append(
            "parentId",
            this.fileOrder[this.fileOrder.length - 1].id
          );
          formData.append("name", setData.name);
          formData.append("mf", setData.mf);
          this.$requestByFormData(
            "post",
            "/fileManage/newFile",
            formData,
            (data) => {
              this.$message.success("上传成功");
              this.dialogData.flag = false;
              this.getCloudByClientId();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "downloadFile":
          // 下载文件或文件夹
          if (this.contractDrawerData.multipleSelection.length == 0) {
            this.$message.warning("请选勾选要操作的文件或文件夹");
            return;
          }
          requestData = {
            ids: this.contractDrawerData.multipleSelection.map(
              (item) => item.id
            ),
          };
          window.location.href =
            this.$baseurl +
            "/fileManage/download?params=" +
            JSON.stringify(requestData);
          break;
        case "cancel":
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },
    /*
     * 保存发票详情
     */
    saveInvoiceDetails() {
      let that = this;
      let p = new Promise(function(resolve, reject) {
        if (that.contractInvoiceInfo.invoiceData.specialFieldEcho) {
          that.contractInvoiceInfo.invoiceData.specialField = invoiceData.transSpecialField(
            that.contractInvoiceInfo.invoiceData
          );
        }
        let requestData = {
          ...that.contractInvoiceInfo.invoiceData,
          createUserId: that.baseInfo.userId, // 创建人 id
          // inOrOut: "1",
          receiptNumber: that.contractInvoiceInfo.receiptNumberList.toString(), // 关联的收款单号
          receiptId: that.contractInvoiceInfo.receiptIdList.toString(), // 关联 收款单号 id
          contractId: that.tempContract.id, // 合同 id
        };
        requestData.paperTicketCodeEcho = JSON.stringify(
          requestData.paperTicketCodeEcho
        );
        requestData.specialField = JSON.stringify(requestData.specialField);
        requestData.specialFieldEcho = JSON.stringify(
          requestData.specialFieldEcho
        );
        requestData.collectField = JSON.stringify(requestData.collectField);
        requestData.collectFieldEcho = JSON.stringify(
          requestData.collectFieldEcho
        );
        that.$request(
          "post",
          "/invoice/saveInvoice",
          requestData,
          (data) => {
            // 拿到发票id 提交发票时使用
            that.tempInvoiceId = data.id;
            that.dialogData.flag = false;
            // 更新合同抽屉 收款记录数据
            let requestData2 = {
              invoiceId: data.id, // 发票 id
              contractId: that.tempContract.id, //合同 id
              receiptIds: that.contractInvoiceInfo.receiptIdList, // 收款记录 id
            };
            that.$request(
              "post",
              "/contract/updateReceipt",
              requestData2,
              (data) => {
                resolve(true);
              },
              (errorMessage) => {
                that.$message.error(errorMessage);
              }
            );
          },
          (errorMessage) => {
            reject(errorMessage);
            that.$message.error(errorMessage);
          }
        );
      });
      return p;
    },
    confirm(data) {
      this.drawerOper("sure_huikuan", data);
    },
    /**
     * 确定生成电子合同
     */
    toGenerateElectronicContract(dataInfo) {
      console.log(dataInfo);
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        contractModelId: dataInfo.contractId,
        contractId: this.tempContract.id,
        signInfoId: dataInfo.signInfoId, // 签约主体ID
        phone: dataInfo.phone, // 电子合同签字人的手机号
        signName: dataInfo.signName, // 签单人名字
      };
      this.loading = true;
      this.$request(
        "post",
        "/contractModel/sureElectronicContract",
        requestData,
        (data) => {
          this.$message({
            message: "电子合同生成成功!",
            type: "success",
            duration: 1000,
          });
          this.loading = false;
          this.dialogData.flag = false;
        },
        (errorMessage) => {
          this.loading = false;
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 合同删除
     */
    deleteContract() {
      let requestData = {
        contractId: this.tempContract.id,
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$confirm("此操作将永久删除该合同, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request(
            "post",
            "/contract/deleteContract",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "删除成功",
                duration: 1000,
              });
              this.contractDrawerData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "合同删除!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.initTableData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    linkNow(dd) {
      this.tempContract.id = dd.item.id;
      // 1.拿合同界面的权限
      this.getModelFeatureAuthority();
      // 2.展示合同
      this.showContractInfoByID();
    },
    /**
     * 客户
     */
    /**
     * 申请客户流失
     */
    toApplyForCustomerChurn() {
      let requestData = {
        clientId: this.tempClient.value,
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/client/getAllServiceTypeListByClientId",
        requestData,
        (data) => {
          this.dialogData.title = "选择流失类型";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push(
            {
              text: "确定",
              active: "confirm_that_client_loss",
            },
            {
              text: "取消",
              active: "cancel",
            }
          );

          let options = [];
          data.forEach((element) => {
            let dd = {
              label: element.typeName,
              value: element.id,
            };
            options.push(dd);
          });
          let options2 = [];
          this.searchData.lossReasonOptions.forEach((element) => {
            let dd = {
              label: element.name,
              value: element.value,
            };
            options2.push(dd);
          });
          this.dialogData.data = [
            {
              title: "流失类型",
              type: "select-obj",
              value: { label: "全部", value: "all" },
              options: options,
            },
            {
              title: "流失原因",
              type: "select",
              value: "",
              options: options2,
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 添加跟进记录
     */
    addFollowRecord() {
      let requestData = {
        clientId: this.tempClient.value, // 商机ID
        userId: this.baseInfo.userId, // 操作人ID
        content: this.dialogData.data[0].value, // 当前操作
        time: this.dialogData.data[1].value, // 下次跟进时间
      };
      // if (requestData.time == "") {
      //   this.$message.error("下次跟进时间不能为空!");
      //   return;
      // }
      this.$request(
        "post",
        "/followRecord/addFollowRecord",
        requestData,
        (data) => {
          this.$message({
            message: "跟进记录添加成功!",
            type: "success",
            duration: 1000,
          });
          this.$operationRecords.saveOperationRecords(
            this.tempClient.value,
            "添加跟进记录!",
            this.baseInfo,
            "client",
            this,
            (data2) => {
              this.dialogData.flag = false;
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取工商信息
     * 根据企业全称调用企查查接口,获取企业税号、增值税类型、行业分类、所在地址、详细地址
     */
    getCompanyInfo() {
      this.$request(
        "get",
        "/qicc/search?keyword=" +
          this.contractDrawerData.data.modelList[2].line[0].value,
        {},
        (data) => {
          this.contractDrawerData.data.modelList[2].line[0].value =
            data.name == ""
              ? this.contractDrawerData.data.modelList[2].line[1].value
              : data.name;
          this.contractDrawerData.data.modelList[2].line[1].value =
            data.taxNum == ""
              ? this.contractDrawerData.data.modelList[2].line[1].value
              : data.taxNum;
          this.contractDrawerData.data.modelList[2].line[4].value =
            data.address == ""
              ? []
              : this.$addressAutomaticallyMatch.addressAutomaticallyMatch(
                  data.address
                );
          if (
            this.contractDrawerData.data.modelList[2].line[4].value == null ||
            this.contractDrawerData.data.modelList[2].line[4].value.length < 3
          ) {
            this.contractDrawerData.data.modelList[2].line[4].value = [];
          }
          this.contractDrawerData.data.modelList[2].line[5].value =
            data.address == ""
              ? this.contractDrawerData.data.modelList[2].line[5].value
              : data.address;
          this.saveDrawerData("工商信息");
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 更新/修改/添加客户联系人
     */
    updateClientContactByID() {
      if (
        !clientInfoData.formatClientContactDrawerData(
          this.contractDrawerData.data.modelList
        ).flag
      ) {
        this.$message({
          message: "联系人手机号格式不正确,请确认填入正确的手机号!",
          type: "error",
          duration: 1000,
        });
        // this.getMyThreadDetailsByThreadId();
        return;
      }
      let requestData = {
        clientId: this.tempClient.value,
        data: clientInfoData.formatClientContactDrawerData(
          this.contractDrawerData.data.modelList
        ).list,
      };
      this.$request(
        "post",
        "/client/updateClientContactByID",
        requestData,
        (data) => {
          this.$message({
            message: "客户联系人操作成功!",
            type: "success",
            duration: 1000,
          });
          this.$operationRecords.saveOperationRecords(
            this.tempClient.value,
            "更新客户联系人!",
            this.baseInfo,
            "client",
            this,
            (data2) => {}
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 抽屉中删除当前联系人
     */
    lineOper(data) {
      switch (data.active) {
        case "gongkai":
          break;
        case "shanchu":
          this.$confirm("此操作将永久删除该联系人, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.contractDrawerData.data.modelList.splice(data.i, 1);
              this.updateClientContactByID();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
        default:
          break;
      }
    },

    /**
     * 客户结束
     */

    /**
     * 改变上传位置
     */
    changeFlag(val) {},
    /**
     * 附件上传成功
     */
    handleAvatarSuccess(data) {
      console.log("付款凭证上传成功", data);
      if (data.result.title == "客户资质") {
        this.$message({
          message: "付款凭证上传成功!",
          type: "success",
          duration: 1000,
        });
        this.dialogData.data[4].value.value = data.result;
      } else if (data.result.title == "新建支出") {
        // 新建支出
        this.dialogData.data[0].data.attachments.push(data.result.path);
      }
    },
    deleteItem(index) {
      this.dialogData.data[0].data.attachments.splice(index, 1);
    },
    /**
     * 删除具体赠送的服务项
     */
    deleteService(j) {
      for (
        let index = 0;
        index < this.contractDrawerData.data.modelList.length;
        index++
      ) {
        let element = this.contractDrawerData.data.modelList[index];
        for (let i = 0; i < element.line.length; i++) {
          let ele = element.line[i];
          if (ele.key == "freeServes") {
            // 赠送服务项
            console.log("删除赠送服务项");
            ele.value.splice(j, 1);
          }
        }
      }
      // this.contractDrawerData.data.modelList[3].line[0].value.splice(index, 1);
      this.saveDrawerData("删除赠送服务项");
    },
    /**
     * 取消赠送服务项修改
     */
    cancelServiceItems() {
      this.showContractInfoByID();
    },
    /**
     * 获取收款记录
     */
    getReceiptRecord() {
      console.log("获取回款记录");
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getReceiptRecord",
        requestData,
        (data) => {
          this.contractDrawerData = allContractData.contractInfoDrawerData(
            this.tempClientName,
            this.contractDrawerDataAuthorityList,
            this.whetherAgencyHead, // 是否是机构负责人
            this.contractDrawerOptions,
            "收款记录",
            {}
          );
          this.contractDrawerData.data.modelList[0].line[0].tableData = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 查看具体附件内容
     */
    linkNowFile(data) {
      if (data.item.fileType == "img") {
        // 附件是图片
        this.dialogData.title = "查看收款附件";
        this.dialogData.width = "30%";
        this.dialogData.data = [
          {
            type: "show_img",
            value: data.item.file,
            title: "",
          },
        ];
        this.dialogData.buttonArray = [];
        this.dialogData.flag = true;
      } else if (data.item.file != "") {
        // 附件是文件
        // window.open(data.item.file, "_blank");

        if (this.$keyType.keyType(data.item.file) == "Object") {
          window.open(data.item.file.path, "_blank");
        } else if (this.$keyType.keyType(data.item.file) == "String") {
          window.open(data.item.file, "_blank");
        } else {
          this.$message.error("附件格式错误,请联系管理员!");
        }
      }
    },
    /**
     * 查看云盘内容
     */
    linkFile(data) {
      console.log("data", data);
      if (data.item.type == 0) {
        // 文件夹
        this.fileOrder.splice(data.item.level, 0, {
          name: data.item.name,
          id: data.item.id,
          parent: data.item.parent,
        });
        this.getCloudByClientId();
      } else {
        window.open(data.item.uri, "_blank");
      }
    },
    /**
     * 获取收费情况
     */
    getCharge() {
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getCharge",
        requestData,
        (data) => {
          this.contractDrawerData = allContractData.contractInfoDrawerData(
            this.tempClientName,
            this.contractDrawerDataAuthorityList,
            this.whetherAgencyHead, // 是否是机构负责人
            this.contractDrawerOptions,
            "收费情况",
            {}
          );
          this.contractDrawerData.data.modelList[0].line[0].tableData = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 点击表格中的图标展示合同附件
     */
    showAttachmentByContract(row) {
      this.tempContract.id = row.id;
      this.tempContract.name = row.clientName;
      this.tempClientName = row.clientName;
      this.tempClient.value = row.clientId;
      this.ttempClient = row;
      this.contractDrawerData.flag = true;
      this.getAttachmentByContractId();
    },

    /**
     * 获取合同支出情况
     */
    getExpendByContractId() {
      this.$request(
        "get",
        "/contractExpenditure/listByContractId?contractId=" +
          this.tempContract.id,
        {},
        (data) => {
          this.$request(
            "get",
            "/contract/getCostById?id=" + this.tempContract.id,
            {},
            (data2) => {
              this.contractDrawerData = allContractData.contractInfoDrawerData(
                this.tempClientName,
                this.contractDrawerDataAuthorityList,
                this.whetherAgencyHead, // 是否是机构负责人
                this.contractDrawerOptions,
                "合同支出",
                {}
              );
              this.contractDrawerData.data.modelList[0].line[0].value.money = data2;

              this.contractDrawerData.data.modelList[0].line[0].value.data = allContractData.contractExpendData(
                data
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取合同附件数据
     */
    getAttachmentByContractId() {
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getAttachmentByContractId",
        requestData,
        (data) => {
          this.contractDrawerData = allContractData.contractInfoDrawerData(
            this.tempClientName,
            this.contractDrawerDataAuthorityList,
            this.whetherAgencyHead, // 是否是机构负责人
            this.contractDrawerOptions,
            "合同附件",
            {}
          );
          this.contractDrawerData.data.modelList[0].line[0].value.data = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取合同发票数据
     */
    getInvoiceByContractId() {
      let requestData = {
        contractId: this.tempContract.id,
        // userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        sellerName: "", // 客户名称 当客户名称为空时,获取所有客户
        status: "", // 发票状态
        billingDate: [], // 开票时间
        createUserId: "", // 创建人
        billingUserId: "", // 实际开票人
        drawerName: "", // 开票员
        buyerName: "", // 购买方名称
        type: "", // 发票类型
        pageSize: this.pageData.pageSize, // 每页条数
        currentPage: this.pageData.currentPage, // 当前页数
        userIds: this.searchData.tempUserId,
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
      };
      this.$request(
        "post",
        "/invoice/queryContractInvoice",
        requestData,
        (data) => {
          this.contractDrawerData = allContractData.contractInfoDrawerData(
            this.tempClientName,
            this.contractDrawerDataAuthorityList,
            this.whetherAgencyHead, // 是否是机构负责人
            this.contractDrawerOptions,
            "合同发票",
            {}
          );
          this.contractDrawerData.data.modelList[0].line[0].tableData = invoiceData.transTableData(
            data.records
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 显示支出详情
     */
    showExpendDetails(data) {
      let dd = {
        ...data,
        client: this.tempContract.name,
      };
      this.dialogData.title = "支出";
      this.dialogData.width = "40%";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "取消",
        active: "cancel_expend",
      });
      this.dialogData.data = [
        {
          type: "expend_details",
          title: "",
          value: dd,
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 添加支出
     */
    addExpend() {
      this.dialogData.width = "700px";
      this.dialogData.title = "新建支出";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push(
        {
          text: "确定",
          active: "sure_addExpend",
        },
        {
          text: "取消",
          active: "cancel",
        }
      );
      this.$request(
        "get",
        "/contract/getReceiptVoucherNo?contractId=" + this.tempContract.id,
        {},
        (data) => {
          let receiptNumber = data.toString();
          this.dialogData.data = [
            {
              type: "contract_expend",
              data: allContractData.contractExpend(
                this,
                this.searchData.payOptions,
                receiptNumber,
                "",
                "contract"
              ),
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 确认创建合同
     */
    choseProductTipOne(data) {
      // 新建合同
      if (this.dialogData.title == "选择产品") {
        let requestData = {
          userId: this.baseInfo.userId,
          companyId: this.baseInfo.company.companyId,
          productId: data.id, // 合同模板ID
          clientId: this.tempClient.value, // 客户ID
          isRenew: this.isRenew,
          oldContractId: this.isRenew ? this.tempContract.id : "", // 原合同ID
          expenses: "0", // 原合同费用(续签才有,可能为空)
        };
        this.$request(
          "post",
          "/contract/addContract",
          requestData,
          (data) => {
            this.dialogData.flag = false;
            this.contractDrawerData.flag = false;
            this.initTableData();
            this.tempContract.id = data.contractId;
            this.tempClientName = this.tempContract.name;
            this.$message({
              message: "合同创建成功!",
              duration: 1000,
              type: "success",
            });
            // this.initTableData();
            this.$operationRecords.saveOperationRecords(
              this.tempClient.value,
              "新建合同!",
              this.baseInfo,
              "client",
              this,
              (data2) => {
                this.$operationRecords.saveOperationRecords(
                  this.tempContract.id,
                  "新建合同!",
                  this.baseInfo,
                  "contract",
                  this,
                  (data2) => {
                    this.showContractInfoByID();
                  }
                );
              }
            );
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }

      // 确认下单
      if (this.dialogData.title == "选择执行流程") {
        let requestData = {
          userId: this.baseInfo.userId,
          companyId: this.baseInfo.company.companyId,
          professionId: data.id, // 合同模板ID
          contractId: this.tempContract.id, // 原合同ID
        };
        this.$request(
          "post",
          "/contract/placeOrder",
          requestData,
          (data) => {
            this.dialogData.flag = false;
            this.contractDrawerData.flag = false;
            this.$message({
              message: "操作成功!",
              duration: 1000,
              type: "success",
            });
            this.$operationRecords.saveOperationRecords(
              this.tempContract.id,
              "合同下单!",
              this.baseInfo,
              "contract",
              this,
              (data2) => {
                this.initTableData();
              }
            );
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }

      // 新建支出
      if (this.dialogData.title == "新建支出") {
        let dd = {
          client: this.tempClient.label,
          contractNum: this.tempContract.name,
          contractId: this.tempContract.id,
          expendStatus: "待提交",
          expendNum: "",
          time: "",
          expendType: data.name,
          user: this.baseInfo.userId,
          title: "",
          file: "", // 附件
          services: this.tempServices,
        };
        this.$request(
          "post",
          "/contract/addExpend",
          dd,
          (data) => {
            this.$message({
              message: "支出添加成功!",
              type: "success",
              duration: 1000,
            });
            this.dialogData.flag = false;
            this.$operationRecords.saveOperationRecords(
              this.tempContract.id,
              "新建支出!",
              this.baseInfo,
              "contract",
              this,
              (data2) => {
                this.getExpendByContractId();
              }
            );
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }
    },
    /**
     * 合同提交
     */
    submitContract() {
      this.$confirm("确定合同提交?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let requestData = {
            userId: this.baseInfo.userId,
            id: this.tempContract.id,
            // professionId: data.id, // 执行流程IDID
          };
          this.$request(
            "post",
            "/contract/submitContract",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.contractDrawerData.flag = false;
              this.$message({
                message: "合同提交成功!",
                duration: 1000,
                type: "success",
              });
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "合同提交!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.initTableData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消提交",
            duration: 1000,
          });
        });
    },
    saveDrawerDataInit(drawerTitle) {
      this.isRenew = false;
      this.saveDrawerData(drawerTitle);
    },
    /**
     * 保存抽屉数据
     */
    saveDrawerData(drawerTitle) {
      let requestData = {};
      console.log("保存数据:", this.contractDrawerData);
      if (this.contractDrawerData.title == "合同") {
        switch (this.contractDrawerData.cardIndex) {
          case 0:
            // 合同信息的保存
            if (
              contractDrawData.formatContractInfoDrawerData(
                this.contractDrawerData.data.modelList
              )
            ) {
              requestData = {
                contractId: this.tempContract.id,
                userId: this.baseInfo.userId,
                clientId: this.tempClient.value,
                companyId: this.baseInfo.company.companyId,
                data: contractDrawData.formatContractInfoDrawerData(
                  this.contractDrawerData.data.modelList
                ),
                isRenew: this.isRenew,
              };
              console.log("获取到的数据是", requestData);
              for (let index = 0; index < requestData.data.length; index++) {
                const element = requestData.data[index];
                if (
                  (element.title == "成本" || element.title == "优惠金额") &&
                  element.value != ""
                ) {
                  if (!this.$formatMathDecimal(element.value)) {
                    this.$message({
                      message: "金额格式不对,请校验后重新输入!",
                      type: "error",
                      duration: 1000,
                    });

                    return;
                  }
                }
              }
              this.$request(
                "post",
                "/contract/updateContractInfoByID",
                requestData,
                (data) => {
                  this.initTableData();
                  this.$message({
                    message: "保存成功!",
                    type: "success",
                    duration: 1000,
                  });
                  this.$operationRecords.saveOperationRecords(
                    this.tempContract.id,
                    "修改" + drawerTitle + "信息!",
                    this.baseInfo,
                    "contract",
                    this,
                    (data2) => {
                      this.showContractInfoByID();
                      this.initTableData();
                    }
                  );
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            } else {
              this.$message.error("服务项名称不能为空!");
            }
            break;
          case 4:
            // 合同附件的上传
            this.contractDrawerData.data.modelList[0].line[0].value.data.forEach(
              (element) => {
                if (this.$keyType.keyType(element.url) != "Object") {
                  let uu = {
                    path: element.url,
                    title: "合同附件",
                  };
                  element.url = uu;
                }
              }
            );
            requestData = {
              contractId: this.tempContract.id,
              userId: this.baseInfo.userId,
              clientId: this.tempClient.value,
              companyId: this.baseInfo.company.companyId,
              data: this.contractDrawerData.data.modelList[0].line[0].value
                .data,
            };
            this.$request(
              "post",
              "/contract/saveAttachmentByContractId",
              requestData,
              (data) => {
                this.$message({
                  message: "附件操作成功!",
                  type: "success",
                  duration: 1000,
                });
                this.$operationRecords.saveOperationRecords(
                  this.tempContract.id,
                  "上传合同附件!",
                  this.baseInfo,
                  "contract",
                  this,
                  (data2) => {
                    this.getAttachmentByContractId();
                    this.initTableData();
                  }
                );
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
            break;

          default:
            break;
        }
      }
      if (this.contractDrawerData.title == "客户") {
        requestData = {
          clientId: this.tempClient.value,
          companyId: this.baseInfo.company.companyId,
          data: [],
        };
        switch (this.contractDrawerData.cardIndex) {
          case 0:
            // 更新客户信息
            requestData.data = clientInfoData.formatClientInfoDrawerData(
              this.contractDrawerData.data.modelList
            );
            this.$request(
              "post",
              "/client/updateClient",
              requestData,
              (data) => {
                this.$message({
                  message: "修改成功!",
                  type: "success",
                  duration: 1000,
                });
                this.$operationRecords.saveOperationRecords(
                  this.tempClient.value,
                  "修改" + drawerTitle + "信息!",
                  this.baseInfo,
                  "client",
                  this,
                  (data2) => {
                    this.initTableData();
                    this.getAllClientNum();
                  }
                );
                // this.initTableData();
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
            break;
          case 1:
            // 更新客户联系人
            this.updateClientContactByID();
            break;

          default:
            break;
        }
      }
    },
    /**
     * 是否赠送服务项单选框选择事件
     */
    radioChange(data) {
      console.log("是否赠送服务项单选框选择事件", data);
      for (
        let index = 0;
        index < this.contractDrawerData.data.modelList.length;
        index++
      ) {
        let element = this.contractDrawerData.data.modelList[index];
        for (let i = 0; i < element.line.length; i++) {
          let ele = element.line[i];
          if (data.item.title == "是否赠送服务" && ele.key == "freeServes") {
            if (data.val == "Y") {
              ele.isShow = true;
              ele.isDisable = false;

              ele.value = [];
            } else {
              ele.isShow = false;
              ele.isDisable = true;

              ele.value = [];
            }
          }
          if (data.item.title == "是否优惠" && ele.key == "discounts") {
            if (data.val == "Y") {
              ele.isShow = true;
              ele.isDisable = false;
              ele.value = "0.00";
            } else {
              ele.isShow = false;
              ele.isDisable = true;
              ele.value = "";
            }
          }
        }
      }
      // if (data.item.title == "是否赠送服务") {
      //   if (data.val == "Y") {
      //     this.contractDrawerData.data.modelList[2].line[0].isShow = true;
      //   } else {
      //     this.contractDrawerData.data.modelList[2].line[0].isShow = false;
      //     this.contractDrawerData.data.modelList[2].line[0].value = [];
      //   }
      // }
      // if (data.item.title == "是否优惠") {
      //   if (data.val == "Y") {
      //     this.contractDrawerData.data.modelList[0].line[8].isShow = true;
      //     this.contractDrawerData.data.modelList[0].line[8].value = "0.00";
      //   } else {
      //     this.contractDrawerData.data.modelList[0].line[8].isShow = false;
      //     this.contractDrawerData.data.modelList[0].line[8].value = "";
      //   }
      // }
      this.saveDrawerData();
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },

    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },

    showExpend(value) {
      console.log("showExpend", value);
      this.dialogData.data[0] = value;
    },
    /**
     * 右边抽屉父子组件连锁事件
     */
    showDrawer(value) {
      this.contractDrawerData = value;
    },
    /**
     * 修改表头顺序dialog关闭
     */
    closeUpdateTableMenu() {
      this.tragerDialogData.flag = false;
    },
    /**
     * 拖拽表头排序父子组件连锁事件
     */
    showDrager(value) {
      this.tableMenuData = value;
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.reSetFileOrder();
      this.contractDrawerData.flag = false;
    },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 关闭筛选标签事件
     */
    closeScreen(index) {
      this.searchData.screenList.splice(index, 1);
      this.initTableData();
    },
    /**
     * 表头div拖动方法
     */

    dragging(value) {
      console.log("div拖动", value);
    },
    /**
     * 合同附件父子组件连锁事件
     */

    showAttachment(data) {},
    /**
     * 查看跟进记录
     */

    showGenjin(clientData) {
      this.ttempClient = clientData;
      let requestData = {
        clientId: clientData.clientId,
        threadId: "",
      };
      this.$request(
        "post",
        "/followRecord/showFollowRecord",
        requestData,
        (data) => {
          this.dialogData.title = "跟进记录-" + clientData.clientName;
          this.dialogData.buttonArray = [];
          this.dialogData.data = [
            {
              type: "genjinjilu",
              value: {
                clientId: clientData.id,
                threadId: "",
                hasContent: true,
                content: "",
                time: "",
                list: data,
                hasTarget: false,
                target: "",
                hasPhase: false,
                phase: "",
              },
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 确定添加跟进记录
     */

    followRecord(recordData) {
      console.log("跟进记录:", recordData);
      let requestData = {
        clientId: this.ttempClient.clientId, // 商机ID
        threadId: "", // 商机ID
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId, // 操作人ID
        content: recordData.content, // 当前操作
        time: recordData.time, // 下次跟进时间
        stage: "",
      };
      if (requestData.content == "") {
        this.$message.error("跟进内容不能为空!");
        return;
      }
      this.$request(
        "post",
        "/followRecord/addFollowRecord",
        requestData,
        (data) => {
          this.$message({
            message: "跟进记录添加成功!",
            type: "success",
            duration: 1000,
          });
          this.$operationRecords.saveOperationRecords(
            this.ttempClient.clientId,
            "添加跟进记录:" + recordData.content + "!",
            this.baseInfo,
            "client",
            this,
            (data2) => {
              this.showGenjin(this.ttempClient);
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 添加产品标签
     */

    enterTag(data) {
      console.log("添加的数据是", data);
      this.contractDrawerData.data.modelList[data.i].line[data.j].value.push(
        data.value
      );

      this.saveDrawerDataInit();
    },
    /**
     * 删除产品标签
     */

    deleteTag(data) {
      this.contractDrawerData.data.modelList[data.i].line[data.j].value.splice(
        data.iii,
        1
      );

      this.saveDrawerDataInit();
    },
    /**
     * 保存产品标签
     */
    saveTag(data) {
      if (data.iii === "") {
        this.contractDrawerData.data.modelList[data.i].line[
          data.j
        ].value.splice(data.iii, 1);
      }
      this.saveDrawerDataInit();
    },
    /**
     * 显示资质列表
     */
    getQualificationByClient() {
      let requestData = {
        clientId: this.tempClient.value,
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/qualification/getQualificationByClient",
        requestData,
        (data) => {
          console.log(data);
          this.contractDrawerData = allClientData.getDrawerData(
            this.tempClientName,
            this.clientDrawerOptions,
            "资质"
          );
          // 单独处理表格数据
          this.contractDrawerData.data.modelList[0].line[0].tableData = data;
          this.contractDrawerData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 编辑, 删除资质
     */
    delQualification(value) {
      let requestData = {};
      switch (value.active) {
        case "deleteQualification":
          // 删除资质
          this.$confirm("此操作将永久删除该资质文件, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                clientId: this.tempClient.value,
                id: value.id,
              };
              this.$request(
                "post",
                "/qualification/delQualification",
                requestData,
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.$operationRecords.saveOperationRecords(
                    this.tempClientId,
                    "删除资质" + value.row.name + "!",
                    this.baseInfo,
                    "clinet",
                    this,
                    (data2) => {
                      this.getQualificationByClient();
                    }
                  );
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
        case "editQualification":
          // 编辑资质
          console.log("contract", value.row);
          this.dialogData.title = "编辑资质";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_create_qualification",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.id = value.id;
          this.dialogData.data = [
            {
              title: "资质名称",
              type: "input",
              value: value.row.name,
              isDisable: false,
            },
            {
              title: "资质类型",
              type: "select",
              options: this.clientDrawerOptions.qualificationList,
              value: value.row.type,
              isDisable: false,
            },
            {
              title: "资质开始时间",
              type: "time_date",
              value: value.row.startServiceDate,
              isDisable: false,
            },
            {
              title: "资质到期时间",
              type: "time_date",
              value: value.row.endServiceDate,
              isDisable: false,
            },
            {
              title: "上传附件",
              type: "upload_file",
              value: {
                value: value.row.file, // 客户资质
                action: this.$fileBaseurl + "/uploadImageByMultipartFile",
                headers: {
                  token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                  systemName: "finance",
                  title: "客户资质",
                },
              },
              flag: false,
            },
          ];
          this.dialogData.flag = true;

          break;
        default:
          break;
      }
    },
    /**
     * 根据客户id查询档案列表
     */
    getArchivesListByClientId() {
      let requestData = {
        clientId: this.tempClient.value,
      };
      this.$request(
        "post",
        "/archivesInfo/listByClientId",
        requestData,
        (data) => {
          this.contractDrawerData = allClientData.getDrawerData(
            this.tempClientName,
            this.clientDrawerOptions,
            "档案"
          );
          // 单独处理表格数据
          this.contractDrawerData.data.modelList[0].line[0].tableData = archivesData.transformArchivesInfoTableData(
            data
          );
          this.contractDrawerData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取档案物品列表
     */
    getTreeListByCategory() {
      this.$request(
        "post",
        "/archivesCategory/getTreeList",
        { flag: false },
        (data) => {
          this.categoryList = data;
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    /**
     * 获取档案柜位置列表
     */
    getTreeListByLocation() {
      this.$request(
        "post",
        "/archivesLocation/getTreeList",
        { flag: false },
        (data) => {
          this.locationList = data;
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    /**
     * 新增档案
     */
    addArchives() {
      let categoryIdList = [];
      this.dialogData.data[0].data.categoryList.forEach((item) => {
        categoryIdList.push(...item.categoryIdList);
      });
      let requestData = {
        clientId: this.tempClient.value,
        categoryIdList: categoryIdList,
        locationId: this.dialogData.data[0].data.locationId,
        createId: this.baseInfo.userId,
      };

      if (requestData.clientId == "") {
        this.$message.error("请选择客户名称");
        return;
      }
      if (requestData.locationId == "") {
        this.$message.error("请选择录入位置");
        return;
      }
      if (requestData.categoryIdList.length == 0) {
        this.$message.error("请选择录入物品");
        return;
      }

      this.$request(
        "post",
        "/archivesInfo/add",
        requestData,
        (data) => {
          this.$message.success("录入成功");
          this.dialogData.flag = false;
          this.getArchivesListByClientId();
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    /**
     * 档案操作
     */
    linkArchives(value) {
      this.archivesData = value.row;
      switch (value.active) {
        case "get":
          // 领取档案
          this.dialogData.width = "25%";
          this.dialogData.title = "领用档案";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "sure_get",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];

          (this.dialogData.data = [
            {
              title: "",
              type: "get_archives",
              data: {
                data: [
                  {
                    clientName: value.row.clientName,
                    name: [value.row.name],
                  },
                ],
              },
            },
          ]),
            console.log("领用档案dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;
          break;
        case "return":
          // 归还档案
          this.dialogData.width = "25%";
          this.dialogData.title = "归还档案";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "sure_return",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          (this.dialogData.data = [
            {
              title: "归还位置",
              type: "cascader",
              locationId: "",
              placeholder: "请选择归还位置",
              flag: false,
              options: this.locationList,
              props: { multiple: false, value: "id", label: "name" }, // 是否可多选
              collapse_tags: false, // 多选时是否折叠面板
            },
          ]),
            console.log("归还档案dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;
          break;
          break;
        case "move":
          this.dialogData.width = "25%";
          this.dialogData.title = "转移档案";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "sure_move",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          (this.dialogData.data = [
            {
              title: "转移位置",
              type: "cascader",
              locationId: "",
              placeholder: "请选择转移位置",
              flag: false,
              options: this.locationList,
              props: { multiple: false, value: "id", label: "name" }, // 是否可多选
              collapse_tags: false, // 多选时是否折叠面板
            },
          ]),
            console.log("转移档案dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;
          break;
        case "jiaofu":
          this.dialogData.width = "25%";
          this.dialogData.title = "交付档案";
          this.dialogData.buttonArray = [
            {
              text: "自取",
              active: "sure_ziqu",
            },
            {
              text: "快递",
              active: "sure_youji",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          (this.dialogData.data = [
            {
              title: "",
              type: "get_archives",
              data: {
                data: [
                  {
                    clientName: value.row.clientName,
                    name: [value.row.name],
                  },
                ],
              },
            },
          ]),
            console.log("交付档案dialog显示:", this.dialogData.data);
          this.dialogData.flag = true;
          break;
        case "delete":
          this.$confirm("确认将该档案删除吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.delArchives();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
          break;
        default:
          break;
      }
    },
    /**
     * 删除档案
     */
    delArchives() {
      let requestData = {
        ids: [this.archivesData.id],
      };
      this.$request(
        "post",
        "/archivesInfo/del",
        requestData,
        (data) => {
          this.$message.success("删除成功");
          this.getArchivesListByClientId();
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    /**
     * 根据客户id查询云盘信息
     */
    getCloudByClientId(parent = "") {
      let requestData = {
        clientId: this.tempClient.value,
        parentId: this.fileOrder[this.fileOrder.length - 1].id,
        clientAppShow: "", // 客户是否可见  '' 所有 0-客户不可见 1-客户可见
      };
      this.$request(
        "post",
        "/fileManage/query",
        requestData,
        (data) => {
          this.transformCloudData(data);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    transformCloudData(data) {
      data.forEach((item) => {
        if (item.uri) {
          // 使用正则表达式匹配最后一个点后的文字
          let match = item.uri.match(/\.([^\.]+)$/);
          if (match && match[1]) {
            item.fileType = match[1];
          }
          item.uri = this.$fileBaseurl + "/" + item.uri;
        }
      });
      this.contractDrawerData = allClientData.getDrawerData(
        this.tempClientName,
        this.clientDrawerOptions,
        "云盘",
        "",
        this
      );
      // 单独处理表格数据
      this.contractDrawerData.data.modelList[0].line[0].tableData = data;
      this.contractDrawerData.data.fileOrder = this.fileOrder;
      this.contractDrawerData.flag = true;
    },
    /**
     * 重置文件目录
     */
    reSetFileOrder() {
      this.fileOrder = [{ name: "根目录", parent: "", id: "" }];
    },
    /**
     * 云盘 tip 目录点击
     */
    tipChange(data) {
      if (data.tipi + 1 !== this.fileOrder.length) {
        this.fileOrder.splice(data.tipi + 1);
        this.getCloudByClientId();
      }
    },
    /**
     * 收款记录操作
     */
    async linkContract(value) {
      this.linkContractData = value.row;
      switch (value.active) {
        case "refund":
          // 退款
          // 编辑资质
          this.dialogData.width = "30%";
          this.dialogData.title = "退款";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "sure_refund",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "最大退款金额",
              type: "input",
              value: this.linkContractData.money,
              flag: true,
            },
            {
              title: "退款金额",
              type: "input-number",
              value: "",
              isDisable: false,
            },
          ];
          this.dialogData.flag = true;
          break;
        case "apply":
          this.$request(
            "get",
            "/client/getByReceiptNumber?receiptNumber=" +
              this.linkContractData.voucherNo,
            {},
            (data) => {
              if (data.isInvoicing === "Y") {
                // 2. 处理开票信息
                this.getClientInfoByClientID(data.id, data);
              } else {
                this.$message.error("该销售方不是开票客户, 请先设置!");
              }
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          return;
          // 开票
          // 1. 检查该收款记录对应收款的单号的公司名称是不是开票客户

          break;
        case "editTime":
          // 修改收款时间
          this.dialogData.title = "修改收款时间";
          this.dialogData.width = "30%";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "sure_editTime",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "收款时间",
              type: "time",
              value: "",
            },
          ];
          this.dialogData.flag = true;
          break;
        default:
          break;
      }
    },
    /**
     * @description 开票dialog打开
     * @param id 销售方 clientId
     * @param sellInvoiceData 销售方 发票信息
     */
    getClientInfoByClientID(id, sellInvoiceData) {
      // 获取购买方发票信息
      let requestData = {
        id: this.tempClient.value,
        queryInvoiceTaxAccountFlag: false, // 是否查询税务局账号信息 false 不查
      };
      this.$request(
        "post",
        "/client/getClientInfoByClientID",
        requestData,
        (clientData) => {
          this.$request(
            "get",
            "/invoiceRemark/queryByClient?clientId=" + this.tempClient.value,
            requestData,
            (clientInvoiceRemark) => {
              this.dialogData.title = "发票开具";
              this.dialogData.buttonArray = [
                {
                  text: "保存",
                  active: "save_invoice",
                },
                {
                  text: "取消",
                  active: "cancel",
                },
              ];
              this.$set(clientData, "clientId", this.tempClient.value);
              this.$set(clientData, "clientInvoiceRemark", clientInvoiceRemark);
              this.dialogData.data = [
                {
                  type: "invoice_dialog",
                  title: "",
                  data: invoiceData.getInvoiceDialogDataByContract(
                    clientData, // 客户信息 购买方
                    this.baseInfo.company.companyId,
                    "", // 发票信息 空
                    false, // 是否是销项发票
                    sellInvoiceData, // 销售方信息
                    this.linkContractData.auditedIncome, // 收款金额
                    this
                  ),
                },
              ];
              this.dialogData.flag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          this.dialogData.width = "1300px";
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 开票
     */
    ccc() {
      // 获取购买方发票信息
      this.$request(
        "get",
        "/invoiceRemark/queryByClient?clientId=" + this.tempClient.value,
        {},
        (clientInvoiceRemark) => {
          this.$set(
            this.contractInvoiceInfo.buyerInvoiceData,
            "clientId",
            this.tempClient.value
          );
          this.$set(
            this.contractInvoiceInfo.buyerInvoiceData,
            "clientInvoiceRemark",
            clientInvoiceRemark
          );
          let requestData = {
            clientId: this.contractInvoiceInfo.sellerInvoiceData.id,
          };
          // 获取开票人信息
          this.$request(
            "post",
            "/client/getDrawerList",
            requestData,
            (data) => {
              this.contractInvoiceInfo.sellerInvoiceData.casherName =
                data.casherList[0]; // 收款人名称
              this.contractInvoiceInfo.sellerInvoiceData.reviewerName =
                data.reviewerList[0]; // 复核人名称
              this.contractInvoiceInfo.sellerInvoiceData.drawerName =
                data.drawerList[0]; // 开票人名称
              this.contractInvoiceInfo.invoiceData = invoiceData.getInvoiceDialogDataByContract(
                this.contractInvoiceInfo.buyerInvoiceData, // 客户信息 购买方
                this.baseInfo.company.companyId,
                "", // 发票信息 空
                false, // 是否是销项发票
                this.contractInvoiceInfo.sellerInvoiceData, // 销售方信息
                this.contractInvoiceInfo.money, // 收款金额
                this
              );
              this.drawerOper("save_invoice");
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    copy(title, phone) {
      let requestData = {
        userId: this.baseInfo.userId,
        date: this.$formatTime(new Date(), "YYYY-MM-DD HH:mm:ss"), // 时间
        copyContent: phone, // 复制内容
        count: 1, // 复制次数,
        copyModule: "客户",
        name: title, // 客户名称
      };
      this.$request(
        "post",
        "/copyListening/add",
        requestData,
        (data) => {},
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 表格排序事件
     */
    sortChange(data) {
      this.defaultSort.prop = data.order == null ? "" : data.prop;
      this.defaultSort.order = data.order == null ? "" : data.order;
      this.initTableData();
    },
    handleSelectionChange(val) {
      this.contractInvoiceInfo.multipleSelectionReceipt = val;
    },
  },
  mounted() {
    this.loading = true;
    this.getTreeListByLocation();
    this.getTreeListByCategory();
    this.isCharge();
    if (this.$route.query.contractNumber) {
      this.searchData.contractNumber = this.$route.query.contractNumber;
    }
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.searchData.userId = this.baseInfo.userId;
    this.searchData.tempUserId = [this.baseInfo.userId];

    this.getModelFeatureAuthority();
    this.$store.dispatch("getCompantAccountList", this);
  },
};
</script>

<style lang="less" scoped>
#my-contract {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .contract-screen-fiftrate {
    width: 65px;
    min-width: 65px;
    height: 30px;
    border: 1px solid @boderColor;
    box-sizing: border-box;
    color: @boderActiveColor;
    font-size: @contentFontSize;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: 0.3s;
    i {
      margin: 0 4px;
    }
  }
  .contract-screen-fiftrate:hover {
    cursor: pointer;
    border-color: @boderActiveColor;
  }
  .contract-screen-search {
    width: 210px;
    min-width: 210px;
    height: 30px;
    margin-left: 10px;
    display: flex;
  }
  .contract-screen-checked {
    width: 140px;
    height: 30px;
    min-width: 140px;
    font-size: @contentFontSize;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contract-screen-active {
    flex: 1;
  }
  .contract-screen-but-select {
    width: 140px;
    min-width: 160px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
    cursor: pointer;
    .contract-screen-but-select_but {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
    }
    .contract-screen-but-select_but:hover {
      background-color: @activeColor2;
    }
    .contract-screen-but-select_select {
      width: 30px;
      height: 100%;
      border-left: 1px solid @activeColor3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .contract-screen-but {
    width: 100px;
    min-width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .contract-screen-but:hover {
    cursor: pointer;
    background-color: @activeColor2;
  }
  .contract-screen-but-boder {
    width: auto;
    min-width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid @activeColor;
    font-size: @contentFontSize;
    background-color: @activeBackground;
    color: @activeColor;
    margin: 0 5px;
    margin-right: 10px;
    border-radius: 4px;
    transition: 0.5s;
    padding: 0 20px;
    > i {
      margin-left: 8px;
    }
  }
  .contract-screen-but-boder:hover {
    cursor: pointer;
    background-color: @activeBackground2;
  }

  .contract-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .contract-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .contract-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .contract-table-card-tab {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .contract-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .contract-table-card-active {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: @contentFontSize;
          .textArea {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .numArea {
            height: 100%;
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: @activeColor;
          }
        }
        .contract-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .contract-table-card-content {
        flex: 1;
        width: 98%;
      }
      .contract-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }

  .table-tip {
    height: 30px;
    width: auto;
    padding: 2px 5px;
    margin: 0 3px;
    border: 1px solid #333;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hri {
    display: flex;
    i {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
  .link {
    color: @activeColor;
    transition: 0.3s;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
