<template>
  <!-- 微信待加 -->
  <div id="weixin-resource">
    <div class="weixin-resource-screen">
      <div class="weixin-resource-screen-line">
        <div class="weixin-resource-screen-search">
          <el-input
            placeholder="商机名称/联系人/手机号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>

        <div class="weixin-resource-screen-search">
          <el-input
            placeholder="请输入地址"
            v-model="searchData.address"
            @keyup.enter.native="initTableData"
          >
          </el-input>
        </div>
        <div class="weixin-resource-screen-search">
          <el-select
            v-model="searchData.userId"
            placeholder="请选择商机跟进人"
            @change="selectInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="weixin-resource-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="weixin-resource-screen-active"></div>
      </div>
    </div>

    <div class="weixin-resource-table">
      <div class="weixin-resource-table-card">
        <div class="weixin-resource-content">
          <div class="weixin-resource-table-card-tabs">
            <div
              :class="{
                'weixin-resource-table-card-tab': true,
                'is-active': tabIndex == 0,
              }"
              @click="changeTab(0)"
            >
              未完成
            </div>
            <div
              :class="{
                'weixin-resource-table-card-tab': true,
                'is-active': tabIndex == 1,
              }"
              @click="changeTab(1)"
            >
              已完成
            </div>
            <div class="weixin-resource-table-card-active"></div>
          </div>
          <el-table
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            v-loading="loading"
            @sort-change="sortChange"
            :row-style="{ height: '60px' }"
          >
            <el-table-column type="index" width="40" fixed> </el-table-column>
            <el-table-column
              prop="name"
              label="客户名称"
              show-overflow-tooltip
              width="230"
            >
            </el-table-column>
            <el-table-column
              prop="gmtCreate"
              label="创建时间"
              sortable="custom"
              width="150"
            >
            </el-table-column>
            <el-table-column prop="followUserName" label="跟进人" width="130">
            </el-table-column>
            <el-table-column prop="contact" label="联系人" width="130">
            </el-table-column>
            <el-table-column prop="phone" label="手机" width="130">
              <template slot-scope="scope">
                {{ scope.row.phone }}
                <span @click="handleClick(scope.row, 1)">
                  <i class="el-icon-phone-outline phone"></i>
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="地址" show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              prop="followUpContent"
              label="跟进记录"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              label="完成时间"
              v-if="tabIndex == 1"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>{{ scope.row.completedTime }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  v-if="tabIndex == 0"
                  @click="handleClick(scope.row, 0)"
                  type="primary"
                  size="mini"
                  icon="el-icon-edit-outline"
                  plain
                  v-showFunction="{
                    active: 'complete',
                    array: allFunctionButtons,
                  }"
                  >完成</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="weixin-resource-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :clientName="dialogData.clientName"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @handleAvatarSuccess="handleAvatarSuccess"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import * as taxData from "@/views/tax/js/tax.js";
import my_dialog from "@/components/dialog.vue";

export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      tabIndex: 0,
      loading: false,
      baseInfo: {},
      tableData: [],
      searchData: {
        input: "",
        userId: "",
        userIdList: [],
        tempUserId: [],
        address: "",
        department: [],
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "weixinResources"
      ),
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      tempWeiXinData: {},
      whetherAgencyHead: false,
      defaultSort: { prop: "gmt_create", order: "descending" },
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "weixinResources",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getDepartmentTreeByUserId();
      });
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getEmployeeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = this.baseInfo.userId;
          this.selectInitTableData(this.baseInfo.userId);
        },

        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      // this.tableData = [];
      this.loading = true;
      let requestData = {
        name: this.searchData.input, // 客户简称
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "", // 部门 id
        userIdList: this.searchData.tempUserId, // 员工 id 集合
        address: this.searchData.address, // 地址
        completedOrNot: this.tabIndex, // 是否完成
        sortProp: this.defaultSort.prop, // 手动排序项
        sortOrder: this.defaultSort.order, // 手动排序规则 ascending descending ""
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request(
        "post",
        "/reThreadWx/pageByCondition",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.tableData.forEach((item) => {
            let contacts = JSON.parse(item.contacts)[0];
            item.contact = contacts.name;
            item.phone = contacts.phone[0]
              ? contacts.phone[0]
              : contacts.phone[1];
          });
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },

    /**
     * 界面操作
     */
    handleClick(data, flag) {
      this.tempWeiXinData = data;
      let requestData = {};
      switch (flag) {
        case 0:
          // 完成备注
          this.$confirm("确认该资源该添加微信?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                ids: [],
              };
              requestData.ids.push(this.tempWeiXinData.id);
              this.$request(
                "post",
                "/reThreadWx/completeBatch",
                requestData,
                (data) => {
                  this.$message.success("操作成功!");
                  this.initTableData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case 1:
          // 拨打电话
          this.callNumber();
          break;
        default:
          break;
      }
    },
    /**
     * tab切换事件
     */
    changeTab(index) {
      this.tabIndex = index;
      this.initTableData();
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },

    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 客户地址导入成功
     */
    handleAvatarSuccess(response) {
      this.$message({
        message: "导入成功!",
        type: "success",
        duration: 1000,
      });
      this.dialogData.data[1].value.value = response.result;
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    /**
     * 表格排序事件
     */
    sortChange(data) {
      this.defaultSort.prop = data.prop ? "gmt_create" : "";
      this.defaultSort.order = data.order == null ? "" : data.order;
      this.initTableData();
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
  beforeDestroy() {
    // 在组件销毁前取消防抖，确保callNumber存在
    if (this.callNumber && typeof this.callNumber.cancel === "function") {
      this.callNumber.cancel();
    }
  },
};
</script>

<style lang="less" scoped>
#weixin-resource {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .weixin-resource-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .weixin-resource-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .weixin-resource-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .weixin-resource-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .weixin-resource-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .weixin-resource-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        &:hover {
          background-color: #abbed3;
        }
      }
      .weixin-resource-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .weixin-resource-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .weixin-resource-screen-but-boder {
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
        padding: 0 15px;
      }
      .weixin-resource-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .weixin-resource-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .weixin-resource-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .weixin-resource-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .weixin-resource-table-card-tab {
          width: 120px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .weixin-resource-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
      }
      .weixin-resource-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        .phone {
          color: red;
          transform: rotateY(180deg);
          cursor: pointer;
          margin-left: 3px;
        }
      }
      .weixin-resource-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;

        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
