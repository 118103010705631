<template>
  <div id="dialog" :style="{ width: width }">
    <div class="dialog-title">
      <div class="dialog-title-text">
        <span>{{ title }}</span>
        <span v-if="clientName"> -- {{ clientName }}</span>
      </div>
      <div
        v-if="title == '发票预览' && data[0].data.submitRemark"
        class="dialog-title-submitRemark"
        style="color: red;"
      >
        备注:{{ data[0].data.submitRemark }}
      </div>
      <!-- <div
        class="dialog-title-push"
        v-if="
          title == '发票预览' &&
            data[0].data &&
            data[0].data.status == '1' &&
            data[0].data.flag == true
        "
      >
        <el-button
          size="mini"
          type="success"
          plain
          :loading="data[0].data.loadingBtn"
          icon="el-icon-s-promotion"
          @click="dialogOper('push')"
          v-showFunction="{
            active: 'push',
            array: allFunctionButtons,
          }"
          >{{ data[0].data.loadingBtn ? "推送中" : "推送开具" }}</el-button
        >
        <el-button
          size="mini"
          type="success"
          :loading="data[0].data.loadingBtn"
          icon="el-icon-s-promotion"
          @click="dialogOper('split_push')"
          v-showFunction="{
            active: 'splitPush',
            array: allFunctionButtons,
          }"
          >{{ data[0].data.loadingBtn ? "推送中" : "拆分推送" }}</el-button
        >
      </div> -->
      <div class="dialog-title-colse" @click="colseDialog">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <div class="dialog-content" :style="dialogStyle">
      <div
        class="dialog-content-line"
        :style="{
          alignItems:
            item.type == 'radio'
              ? 'center'
              : item.type == 'text'
              ? 'center'
              : 'normal',
          width: item.width ? item.width : '95%',
        }"
        v-for="(item, index) in data"
        :key="index"
      >
        <div class="dialog-content-line-title" v-if="item.title">
          <span class="required" v-if="item.required">*</span
          ><span>{{ item.title }}:</span>
        </div>

        <div class="dialog-content-line-value" v-if="item.type == 'reminder'">
          <i
            class="el-icon-warning-outline"
            :style="{ width: '50px', height: '50px', color: '#fa0a0a' }"
          ></i>
          确定将"{{ item.value }}"{{ item.text }}?
        </div>

        <div class="dialog-content-line-value" v-if="item.type == 'input'">
          <el-input
            v-model="item.value"
            :placeholder="item.placeholder"
            :disabled="item.flag"
          ></el-input>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'input-number'"
        >
          <!-- 只能输入数字, 且小数点后只能输入两位 -->
          <el-input
            :onkeyup="(item.value = item.value.replace(/[^0-9.]/g, ''))"
            oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
            v-model="item.value"
            :placeholder="item.placeholder"
            :disabled="item.flag"
          ></el-input>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'input-number2'"
        >
          <!-- 只能输入数字 -->
          <el-input
            :onkeyup="(item.value = item.value.replace(/[^0-9.]/g, ''))"
            v-model="item.value"
            :placeholder="item.placeholder"
            :disabled="item.flag"
          >
            <span v-if="item.suffix_icon" slot="suffix">{{
              item.suffix_icon
            }}</span></el-input
          >
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'doubleInput'"
        >
          <div
            class="dialog-content-line-value-doubleInput"
            v-for="(doubleIt, doubleInd) in item.item"
            :key="doubleInd"
          >
            <div class="doubleInput-title">
              {{ doubleIt.title }}
            </div>
            <el-input
              class="doubleInput-value"
              v-model="doubleIt.value"
              :placeholder="doubleIt.placeholder"
              :disabled="doubleIt.flag"
            ></el-input>
          </div>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'input_psw'">
          <el-input v-model="item.value" show-password></el-input>
        </div>
        <div
          class="dialog-content-line-value"
          style="
            color: #bfbfbf;
            font-size: 12px;
            white-space: pre-wrap;
            text-align: left;
          "
          v-if="item.type == 'prompt'"
        >
          <!-- 提示 -->
          {{ item.value }}
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'text'">
          {{ item.value }}
        </div>
        <div
          class="dialog-content-line-value height_auto"
          v-if="item.type == 'text_left'"
        >
          {{ item.value }}
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'text-but'">
          {{ item.value }}
          <div class="dialog-content-line-value-active"></div>
          <!-- <div
            class="dialog-content-line-value-but"
            @click="dialogOper(item.active)"
          >
            {{ item.text }}
          </div> -->
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'input-but'">
          <el-input
            v-model="item.value"
            :placeholder="item.placeholder"
            :disabled="item.flag"
          ></el-input>
          <div
            class="dialog-content-line-value-but"
            @click="dialogOper(item.active)"
          >
            {{ item.text }}
          </div>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'input-but-search-select'"
        >
          <el-autocomplete
            v-model="item.value"
            placeholder="请输入内容"
            :fetch-suggestions="querySearchAsync"
          ></el-autocomplete>
          <div
            class="dialog-content-line-value-but"
            @click="dialogOper(item.active)"
          >
            {{ item.text }}
          </div>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'reduce-people'"
        >
          <el-autocomplete
            :popper-append-to-body="false"
            popper-class="my-autocomplete"
            v-model="item.name"
            :fetch-suggestions="
              (querySearch, cb) => {
                querySearch2(querySearch, cb, item.clientId);
              }
            "
            placeholder="请输入内容"
            @select="handleSelect"
          >
            <template slot-scope="{ item }">
              <div class="name">{{ item.name }}</div>
              <span class="addr">{{ item.identityCard }}</span>
            </template>
          </el-autocomplete>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'input-but-search-select-refresh'"
        >
          <!-- 刷新收款单号 -->
          <!-- <el-autocomplete
            v-model="item.value"
            placeholder="请输入内容"
            :fetch-suggestions="querySearchAsync"
          ></el-autocomplete> -->
          <el-select
            v-model="item.value"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading"
            value-key="id"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.voucherNoAndName"
              :value="item"
            >
            </el-option>
          </el-select>
          <div
            class="dialog-content-line-value-but"
            @click="dialogOper('refresh')"
          >
            刷新
          </div>
          <div
            class="dialog-content-line-value-but"
            @click="dialogOper(item.active)"
          >
            {{ item.text }}
          </div>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'input_disable'"
        >
          <el-input v-model="item.value" disabled></el-input>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'radio'">
          <el-radio-group
            v-model="item.value"
            @change="changeFlag"
            :disabled="item.flag"
          >
            <el-radio
              :label="it.value"
              v-for="(it, ii) in item.options"
              :key="ii"
              >{{ it.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'checkbox'"
          style="height: auto"
        >
          <el-checkbox-group v-model="item.value">
            <el-checkbox
              v-for="(it, ind) in item.options"
              :key="ind"
              :label="it.value"
              >{{ it.label }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'input_carriage'"
        >
          <el-input
            v-model="item.value"
            :placeholder="item.placeholder"
            @keyup.enter.native="enter"
          ></el-input>
        </div>
        <div
          class="dialog-content-line-value flex-wrap"
          v-if="item.type == 'select_array'"
        >
          <div
            :class="{ div_tip: true, div_tip_active: it.flag }"
            v-for="(it, ii) in item.value"
            :key="ii"
            @click="choseProductTip(item.value, it)"
          >
            {{ it.title }}
          </div>
        </div>
        <div
          class="dialog-content-line-value flex-wrap"
          v-if="item.type == 'select_array_one'"
        >
          <div
            :class="{ div_tip: true, div_tip_active: it.flag }"
            v-for="(it, ii) in item.value"
            :key="ii"
            @click="choseProductTipOne(it)"
          >
            <!-- @click="dialogOper('addExpend', it)" -->
            {{ it.name }}
          </div>
        </div>
        <div
          class="dialog-content-line-value black"
          v-if="item.type == 'textarea_black'"
        >
          <el-input
            type="textarea"
            :rows="item.rows || 2"
            v-model="item.value"
            :placeholder="item.placeholder"
            :disabled="item.disabled"
          ></el-input>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'textarea'">
          <el-input
            type="textarea"
            :rows="item.rows || 2"
            v-model="item.value"
            :placeholder="item.placeholder"
            :disabled="item.disabled"
          ></el-input>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'textarea_auto'"
          style="flex-direction: column; align-items: flex-start;"
        >
          <span style="font-size: 14px; margin-bottom: 10px"
            >请粘贴或输入文本到下框中，系统可帮助您识别出寄/收件人信息</span
          >
          <el-input
            type="textarea"
            v-model="item.value"
            :autosize="{ minRows: 4, maxRows: 4 }"
            :placeholder="item.placeholder"
          ></el-input>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'select-double'"
        >
          <el-select
            v-model="item.value[0]"
            filterable
            :placeholder="item.placeholder[0]"
            :disabled="item.flag[0]"
          >
            <el-option
              v-for="it in item.options[0]"
              :key="it.value"
              :label="it.label"
              :value="it.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="item.value[1]"
            filterable
            :placeholder="item.placeholder[1]"
            :disabled="item.flag[1]"
          >
            <el-option
              v-for="it in item.options[1]"
              :key="it.value"
              :label="it.label"
              :value="it.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'select'">
          <el-select
            v-model="item.value"
            filterable
            :multiple="item.multiple"
            :collapse-tags="item.collapseTags"
            :placeholder="item.placeholder"
            :disabled="item.flag"
          >
            <el-option
              v-for="it in item.options"
              :key="it.value"
              :label="it.label"
              :value="it.value"
              :disabled="it.disabled"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'autocomplete'"
        >
          <el-autocomplete
            v-model="item.value"
            :fetch-suggestions="fetchSuggestions"
            :placeholder="item.placeholder"
            :trigger-on-focus="false"
            @select="fetchSuggestionsSelect"
          >
          </el-autocomplete>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'select-filterable'"
        >
          <el-select
            v-model="item.value"
            filterable
            remote
            reserve-keyword
            :placeholder="item.placeholder"
            :disabled="item.flag"
            :remote-method="remoteMethod2"
            :loading="item.loading"
          >
            <el-option
              @click.native="remoteChange2(it)"
              v-for="it in item.options"
              :key="it.value"
              :label="it.label"
              :value="it.value"
            >
            </el-option>
          </el-select>
        </div>
        <div
          style="height: 200px; align-items: start;"
          class="dialog-content-line-value"
          v-if="item.type == 'area-select'"
        >
          <area-select
            v-model="item.value"
            :type="item.types"
            :level="item.level"
            :data="pcaa"
            placeholder="请选择地区"
          ></area-select>
        </div>
        <div
          style="height: 200px; align-items: start;"
          class="dialog-content-line-value"
          v-if="item.type == 'diy-area-select'"
        >
          <diy-area-select :value="item.value"> </diy-area-select>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'cascader'">
          <el-cascader
            v-model="item.locationId"
            :placeholder="item.placeholder"
            :options="item.options"
            :props="item.props"
            :collapse-tags="item.collapse_tags"
            clearable
            filterable
          ></el-cascader>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'cascader-tree'"
        >
          <el-cascader
            v-model="item.value"
            :placeholder="item.placeholder"
            :options="item.options"
            :props="item.props"
            :collapse-tags="item.collapse_tags"
            clearable
            filterable
            @change="changeCascader"
          ></el-cascader>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'select-obj'">
          <el-select
            v-model="item.value"
            :placeholder="item.placeholder"
            filterable
            value-key="value"
          >
            <el-option
              v-for="it in item.options"
              :key="it.value"
              :label="it.label"
              :value="it"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="dialog-content-line-value heAuto"
          v-if="item.type == 'select-object'"
        >
          <el-select
            v-model="item.value"
            :placeholder="item.placeholder"
            multiple
            filterable
            value-key="value"
            :collapse-tags="item.collapseTags"
          >
            <el-option
              v-for="it in item.options"
              :key="it.value"
              :label="it.label"
              :value="it"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'select-object-collapse'"
        >
          <el-select
            v-model="item.value"
            :placeholder="item.placeholder"
            multiple
            filterable
            collapse-tags
            value-key="value"
          >
            <el-option
              v-for="it in item.options"
              :key="it.value"
              :label="it.label"
              :value="it"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'select-search'"
        >
          <el-select
            v-model="item.value"
            :placeholder="item.placeholder"
            filterable
          >
            <el-option
              v-for="it in item.options"
              :key="it.value"
              :label="it.label"
              :value="it.value"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'select-order'"
        >
          <el-select
            v-model="item.employee.value"
            :placeholder="item.employee.placeholder"
            multiple
            filterable
            value-key="value"
          >
            <el-option
              v-for="it in item.employee.options"
              :key="it.value"
              :label="it.label"
              :value="it"
            >
            </el-option>
          </el-select>
          <el-checkbox
            style="marginLeft: 10px"
            v-model="item.orderUser.value"
            >{{ item.orderUser.title }}</el-checkbox
          >
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'time'">
          <el-date-picker
            v-model="item.value"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="item.pickerOptions"
          >
          </el-date-picker>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'time_date'">
          <el-date-picker
            v-model="item.value"
            type="date"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'time_month'">
          <el-date-picker
            v-model="item.value"
            type="month"
            placeholder="选择月份"
            value-format="yyyy-MM"
          >
          </el-date-picker>
        </div>
        <div
          class="dialog-content-line-value time-month"
          style="width: 50%"
          v-if="item.type == 'time_date_scope'"
        >
          <el-date-picker
            v-model="item.value"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="yyyy-MM-dd"
            :clearable="item.clearable"
            @change="dialogOper(item.active)"
          >
          </el-date-picker>
        </div>
        <div
          class="dialog-content-line-value time-month"
          style="width: 50%"
          v-if="item.type == 'time_month_scope'"
        >
          <el-date-picker
            v-model="item.value"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            value-format="yyyy-MM"
            :clearable="item.clearable"
            @change="dialogOper(item.active)"
          >
          </el-date-picker>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'times'">
          <el-date-picker
            v-model="item.value"
            type="datetimerange"
            :picker-options="item.pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :value-format="item.value_format || 'yyyy-MM-dd HH:mm:ss'"
          >
          </el-date-picker>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'timess'">
          <el-date-picker
            style="width: 250px;"
            v-model="item.value1"
            align="right"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            :clearable="false"
          >
          </el-date-picker>
          <el-time-select
            v-model="item.value2"
            :picker-options="{
              start: '07:00',
              step: '00:30',
              end: '23:30',
            }"
            placeholder="选择时间"
            :clearable="false"
          >
          </el-time-select>
          <span style="white-space: nowrap; font-size: 14px; margin: 0 5px"
            >出访时长:
          </span>
          <el-select
            style="width: 200px"
            v-model="item.value3"
            placeholder="请选择"
          >
            <el-option
              v-for="it in item.options"
              :key="it.value"
              :label="it.label"
              :value="it.value"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'table-select'"
        >
          <div class="dialog-content-line-value-table-select">
            <div class="dialog-content-line-value-table-select-left">left</div>
            <div class="dialog-content-line-value-table-select-right">
              right
            </div>
          </div>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'table'">
          <el-table
            :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
            :data="item.data"
            highlight-current-row
            style="width: 100%"
            :height="item.height"
            :row-class-name="rowClassName"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              v-if="item.select"
              type="selection"
              width="50"
              fixed
              :selectable="checkSelectable"
            ></el-table-column>
            <el-table-column type="index" width="50" fixed></el-table-column>
            <el-table-column
              v-for="(item, index) in item.props"
              :fixed="index == 0"
              :key="item.prop"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
            >
            </el-table-column>
          </el-table>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'table-summary'"
        >
          <el-table
            :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
            :data="item.data"
            highlight-current-row
            style="width: 100%"
            :height="item.height"
            :row-class-name="rowClassName"
          >
            <el-table-column type="index" width="50" fixed> </el-table-column>
            <el-table-column
              v-for="(item, index) in item.props"
              :fixed="index == 0"
              :key="item.prop"
              :prop="item.prop"
              :label="item.label"
            >
            </el-table-column>
          </el-table>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'add-tip-select-search'"
        >
          <el-select
            v-model="item.value"
            :placeholder="item.placeholder"
            filterable
            @change="changeHandle(item.value, index)"
            value-key="value"
          >
            <el-option
              v-for="it in item.options"
              :key="it.value"
              :label="it.label"
              :value="it"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'select-search-url'"
        >
          <el-select
            v-model="item.value"
            :placeholder="item.placeholder"
            filterable
            @change="selectHandel(item.value)"
            value-key="value"
          >
            <el-option
              v-for="it in item.options"
              :key="it.value"
              :label="it.label"
              :value="it"
            >
            </el-option>
          </el-select>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'tips'">
          <el-tag
            v-for="tag in item.value"
            :key="tag.value"
            closable
            @close="handleClose(tag, index, 0)"
          >
            {{ tag.label }}
          </el-tag>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'tips_text'">
          <el-tag
            v-for="tag in item.value"
            :key="tag"
            closable
            @close="handleClose(tag, index, 1)"
          >
            {{ tag }}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm(index)"
            @blur="handleInputConfirm(index)"
          >
          </el-input>
          <el-button
            type="success"
            class="button-new-tag"
            @click="showInput"
            size="mini"
            >+</el-button
          >
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'tips_select'"
        >
          <el-tag
            v-for="tag in item.value"
            :key="tag"
            closable
            @close="handleClose(tag, index, 1)"
          >
            {{ tag }}
          </el-tag>
          <el-select
            class="input-new-tag"
            v-if="inputVisible"
            filterable
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @change="handleInputConfirm(index)"
          >
            <el-option
              v-for="itt in item.options"
              :key="itt.value"
              :label="itt.label"
              :value="itt.value"
            >
            </el-option>
          </el-select>
          <el-button
            type="success"
            class="button-new-tag"
            @click="showInput"
            size="mini"
            >+</el-button
          >
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'tips_selects'"
        >
          <el-tag
            v-for="tag in item.value"
            :key="tag.id"
            closable
            class="tip_tip"
            @close="handleClose(tag, index, 1)"
          >
            {{ tag.name }}
          </el-tag>
          <el-select
            class="input-new-tag"
            v-if="item.inputVisible"
            v-model="item.tempValue"
            ref="saveTagInput"
            size="small"
            value-key="id"
            @change="handleInputConfirm1(item, index)"
            filterable
          >
            <el-option
              v-for="itt in item.options"
              :key="itt.id"
              :label="itt.name"
              :value="itt"
            >
            </el-option>
          </el-select>
          <el-button
            type="success"
            class="button-new-tag"
            @click="showInput1(item)"
            size="mini"
            >+</el-button
          >
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'tips_selects_dis'"
        >
          <el-tag
            v-for="tag in item.value"
            :key="tag.id"
            :closable="item.isShow"
            class="tip_tip"
            @close="handleClose(tag, index, 1)"
            effect="plain"
          >
            {{ tag.name }}
          </el-tag>
          <el-select
            class="input-new-tag"
            v-if="item.inputVisible"
            v-model="item.tempValue"
            ref="saveTagInput"
            size="small"
            value-key="id"
            @change="handleInputConfirm1(item, index)"
            filterable
          >
            <el-option
              v-for="itt in item.options"
              :key="itt.id"
              :label="itt.name"
              :value="itt"
            >
            </el-option>
          </el-select>
          <el-button
            type="success"
            class="button-new-tag"
            @click="showInput1(item)"
            size="mini"
            v-if="item.isShow"
            >+</el-button
          >
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'add-but'">
          <el-button
            type="success"
            @click="dialogOper(item.active)"
            size="mini"
            >{{ item.text }}</el-button
          >
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'button'">
          <div v-if="item.value.length > 0">
            <el-tag v-for="tag in item.value" :key="tag.id">{{
              tag.text
            }}</el-tag>
          </div>
          <el-button
            type="success"
            @click="dialogOper(item.active)"
            size="mini"
            >{{ item.text }}</el-button
          >
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'drawer'">
          <my_drawer
            :drawerData="item.data"
            @closeUpdateTableDrawer="colseDialog"
            @saveDrawerData="saveDrawerData"
          ></my_drawer>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'role_tree'">
          <role_tree
            :roleTreeData="item.data"
            @show="authorityShow"
          ></role_tree>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'authority_tips'"
        >
          <authority_tips :authorityTipsData="item.data"></authority_tips>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'authority_dialog'"
        >
          <authority_dialog
            :authorityData="item.data"
            @show="authorityShow"
          ></authority_dialog>
        </div>
        <div
          class="dialog-content-line-value flex-column"
          v-if="item.type == 'genjinjilu'"
        >
          <FollowUpRecords
            :hasContent="item.value.hasContent"
            :content="item.value.content"
            :time="item.value.content"
            :list="item.value.list"
            :hasTarget="item.value.hasTarget"
            :target="item.value.target"
            :hasPhase="item.value.hasPhase"
            :phase="item.value.phase"
            :allFunctionButtons="allFunctionButtons"
            @addFollowRecord="addFollowRecord"
          ></FollowUpRecords>
          <!-- <div class="dialog-content-line-value-genjin1">
            <div class="dialog-content-line-value-genjin-line-input">
              <div class="title">跟进记录:</div>
              <div class="value">
                <el-input
                  type="textarea"
                  :rows="2"
                  v-model="item.content"
                  placeholder="请输入跟进记录"
                ></el-input>
              </div>
            </div>
            <div class="dialog-content-line-value-genjin-line-input">
              <div class="title">下次联系时间:</div>
              <div class="value">
                <el-date-picker
                  v-model="item.time"
                  type="datetime"
                  placeholder="选择日期时间"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div
            class="dialog-content-line-value-genjin"
            v-for="(it, ii) in item.value"
            :key="ii"
          >
            <div class="dialog-content-line-value-genjin-line">
              <div class="dialog-content-line-value-genjin-icon">
                <el-image
                  style="width: 50px; height: 50px"
                  :src="it.icon"
                  fit="cover"
                ></el-image>
              </div>
              <div class="dialog-content-line-value-genjin-user">
                {{ it.userName }}
              </div>
              <div class="dialog-content-line-value-genjin-time">
                {{ it.time }}
              </div>
            </div>
            <div class="dialog-content-line-value-genjin-line">
              <div class="dialog-content-line-value-genjin-content">
                {{ it.operation }}
              </div>
            </div>
          </div> -->
        </div>
        <!-- 包含的服务项 -->
        <div class="dialog-content-line-value" v-if="item.type == 'serve_item'">
          <service_item_table :serviceItemData="item.data"></service_item_table>
        </div>

        <div class="dialog-content-line-value" v-if="item.type == 'renew'">
          <!-- 续签管理 -->
          <dialog_renew
            :renewData="item.value"
            @show="showRenew"
          ></dialog_renew>
        </div>

        <div
          class="dialog-content-line-value"
          v-if="item.type == 'expend_details'"
        >
          <!-- 支出详情 -->
          <expend_details
            :expendDetailsData="item.value"
            @showExpend="showExpend"
            @buttonOper="buttonOper"
          ></expend_details>
        </div>

        <div
          class="dialog-content-line-value"
          v-if="item.type == 'invoice_dialog'"
        >
          <!-- 销项发票开具dialog -->
          <invoice_dialog :invoiceDialog="item.data"></invoice_dialog>
        </div>

        <div
          class="dialog-content-line-value"
          v-if="item.type == 'invoice_income_dialog'"
        >
          <!-- 进项发票开具dialog -->
          <InvoiceIncomeDdialog
            :invoiceDialog="item.data"
          ></InvoiceIncomeDdialog>
        </div>

        <div
          class="dialog-content-line-value"
          v-if="item.type == 'invoice'"
          style="height: 700px"
        >
          <!-- 发票预览dialog -->
          <invoice :invoiceData="item.data"></invoice>
        </div>

        <div
          class="dialog-content-line-value"
          v-if="item.type == 'dialog_customers'"
          style="height: 700px"
        >
          <!-- 常用客户 -->
          <!-- <customer :customerData="item.data" @show="showCustomer"></customer> -->
          <dialog-customers
            :customerData="item.data"
            @show="showCustomer"
          ></dialog-customers>
          <!-- <test_dialog></test_dialog> -->
          <!-- <commondity></commondity> -->
        </div>

        <div
          class="dialog-content-line-value"
          v-if="item.type == 'commondity'"
          style="height: 850px"
        >
          <!-- 货物选择dialog -->
          <commondity></commondity>
        </div>

        <!-- 客户发票库存设置 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'invoice-stock'"
        >
          <div class="invoice-stock">
            <el-tabs
              tab-position="left"
              style="height: 500px;"
              v-model="item.value.activeName"
              @tab-click="handleClick"
            >
              <el-tab-pane
                v-for="ele in item.value.menu"
                :key="ele.value"
                :label="ele.label"
                :name="ele.name"
              >
                <div class="invoice-stock-top">
                  发票限额:
                  <el-radio-group
                    style="display: flex;
                    align-content: center;
                    flex-wrap: wrap;
                    margin-left: 10px"
                    v-model="item.value.quota"
                    @change="dialogOper('changeQuota')"
                  >
                    <el-radio :label="1">万元版</el-radio>
                    <el-radio :label="10">十万元版</el-radio>
                    <el-radio :label="100">百万元版</el-radio>
                  </el-radio-group>
                </div>
                <div class="box">
                  <el-card
                    class="box-card"
                    shadow="hover"
                    v-for="(it, index) in item.value.value.curInvoiceTypeQuota"
                    :key="index"
                  >
                    <div slot="header" class="clearfix">
                      <span
                        v-if="it.createName"
                        class="box-card-title"
                        style="float: left;"
                        >领票人: {{ it.createName }}</span
                      >
                      <el-button-group v-if="it.clientId" style="float: right;">
                        <!-- <el-button
                          type="primary"
                          icon="el-icon-edit"
                          size="mini" 
                          @click="dialogOper('edit', it)"
                        ></el-button> -->
                        <el-button
                          type="primary"
                          icon="el-icon-delete"
                          size="mini"
                          @click="dialogOper('delete', it)"
                        ></el-button>
                      </el-button-group>
                      <el-button
                        style="float: right;"
                        v-if="!it.clientId"
                        type="primary"
                        size="mini"
                        @click="dialogOper('make_sure_add', it)"
                        >确定</el-button
                      >
                      <span v-if="!it.surplus == ''" class="box-card-title"
                        >剩余票数: {{ it.surplus }}
                      </span>
                      <span v-if="!it.total == ''" class="box-card-title"
                        >总票数: {{ it.total }}
                      </span>
                    </div>
                    <div class="box-card-line">
                      <div class="invoice-stock-line">
                        <div class="invoice-stock-line-title">发票号码:</div>
                        <div class="invoice-stock-line-value">
                          <el-input
                            v-model="it.startNo"
                            :disabled="it.flag"
                            placeholder="请输入起始号码"
                          ></el-input>
                          <span style="min-width: 15px"> --- </span>
                          <el-input
                            v-model="it.endNo"
                            :disabled="it.flag"
                            placeholder="请输入截止号码"
                          ></el-input>
                        </div>
                      </div>
                      <div class="invoice-stock-line">
                        <div class="invoice-stock-line-title">发票代码:</div>
                        <div class="invoice-stock-line-value">
                          <el-input
                            v-model="it.code"
                            :disabled="it.flag"
                            placeholder="请输入内容"
                          ></el-input>
                        </div>
                      </div>
                      <!-- <div class="invoice-stock-line">
                        <div class="invoice-stock-line-title">限额: </div>
                        <div class="invoice-stock-line-value">
                          <el-select
                            v-model="it.quota"
                            :disabled="it.flag"
                            placeholder="请选择发票限额"
                          >
                            <el-option 
                              v-for="itt in item.value.template"
                              :key="itt.value"
                              :label="itt.label"
                              :value="itt.value"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div> -->
                    </div>
                  </el-card>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>

        <!-- 开票人员设置 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'invoice-drawer'"
        >
          <div class="invoice-drawer">
            <div class="invoice-drawer-line">
              <div class="invoice-drawer-line-value">
                <div class="invoice-drawer-line-value-tit">收款人:</div>
                <div class="invoice-drawer-line-value-val">
                  <el-tag
                    :key="tag"
                    v-for="(tag, index) in item.value.casherList"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, index, 2)"
                  >
                    {{ tag }}
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="inputVisible1"
                    v-model="inputValue1"
                    v-focus
                    size="small"
                    @keyup.enter.native="handleInputConfirm2(1)"
                    @blur="handleInputConfirm2(1)"
                  >
                  </el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="showInput2(1)"
                    >添加人员</el-button
                  >
                </div>
              </div>
            </div>
            <div class="invoice-drawer-line">
              <div class="invoice-drawer-line-value">
                <div class="invoice-drawer-line-value-tit">复核人:</div>
                <div class="invoice-drawer-line-value-val">
                  <el-tag
                    :key="index"
                    v-for="(tag, index) in item.value.reviewerList"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, index, 3)"
                  >
                    {{ tag }}
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="inputVisible2"
                    v-model="inputValue2"
                    v-focus
                    size="small"
                    @keyup.enter.native="handleInputConfirm2(2)"
                    @blur="handleInputConfirm2(2)"
                  >
                  </el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    size="mini"
                    @click="showInput2(2)"
                    >添加人员</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 流水配置项设置 -->
        <div class="dialog-content-line-value" v-if="item.type == 'tax-drawer'">
          <div class="invoice-drawer">
            <div class="invoice-drawer-line">
              <div class="invoice-drawer-line-value">
                <div class="invoice-drawer-line-value-tit">
                  {{ item.value.title }}
                </div>
                <div class="invoice-drawer-line-value-val">
                  <el-tag
                    :key="tag.id"
                    v-for="(tag, index) in item.value.list"
                    closable
                    :disable-transitions="false"
                    @close="taxStatement('del', index)"
                  >
                    {{ tag.label }}
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="inputVisible1"
                    v-model="inputValue1"
                    v-focus
                    size="small"
                    @keyup.enter.native="taxStatement('add')"
                    @blur="taxStatement('add')"
                  >
                  </el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="showInput2(1)"
                    >添加</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="dialog-content-line-value"
          v-if="item.type == 'product-model'"
          style="height: 650px"
        >
          <!-- 合同模板编辑dialog -->
          <ProductModel :produceModelData="item.value"></ProductModel>
        </div>

        <div
          class="dialog-content-line-value"
          v-if="item.type == 'new-contract'"
        >
          <!-- 新建合同dialog -->
          <NewContract
            :newContract="item.value"
            @choseProductTipOne="choseProductTipOne"
          ></NewContract>
        </div>

        <div
          class="dialog-content-line-value"
          v-if="item.type == 're-new-contract'"
        >
          <!-- 新建合同dialog -->
          <ReNewContract
            :newContract="item.value"
            @choseProductTipOne="choseProductTipOne"
          ></ReNewContract>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'new-order'">
          <!-- 新建合同dialog -->
          <NewOrder
            :newOrder="item.value"
            @choseProductTipOne="choseProductTipOne"
          ></NewOrder>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 're-new-order'"
        >
          <!-- 新建合同dialog -->
          <ReNewOrder
            :newOrder="item.value"
            @choseProductTipOne="choseProductTipOne"
          ></ReNewOrder>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'bind_client_table'"
        >
          <!-- 给客户绑定企业微信客户 -->
          <BindClientTable
            :bindClientTableData="item.value"
            @bindWechatClient="bindWechatClient"
            @searchData="searchData"
            @show="showBindClient"
          >
          </BindClientTable>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'bind_client_group_table'"
        >
          <!-- 给客户绑定企业微信群 -->
          <BindClientGroupTable
            :bindClientTableData="item.value"
            @bindWechatClient="bindWechatGroupClient"
            @updateWechatClient="updateWechatGroupClient"
            @getALlWechatGroupClientList="getALlWechatGroupClientList"
            @searchData="searchData"
            @show="showBindClientGroup"
          >
          </BindClientGroupTable>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'financial_audit'"
        >
          <!-- 财务查账 -->
          <FinancialAudit :financialAuditData="item.value" @rejectA="rejectA">
          </FinancialAudit>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'performance-calculation-rules'"
        >
          <!-- 编辑/添加服务类别是,设置业绩计算规则 -->
          <PerformanceCalculationRules
            :performanceCalculationRulesData="item.value"
          >
          </PerformanceCalculationRules>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'upload'">
          <div
            class="dialog-content-line-value-img"
            v-if="item.value.value != ''"
            v-viewer="{ movable: false }"
          >
            <img
              :src="item.value.value"
              alt=""
              style="width: 80px; height: 100px"
            />
          </div>
          <el-upload
            class="avatar-uploader"
            :action="item.value.action"
            :show-file-list="false"
            :headers="item.value.headers"
            :data="item.value.uploadParameter"
            :on-success="handleAvatarSuccess"
            :disabled="item.flag"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'uploads'">
          <div class="icons">
            <div
              class="icon"
              v-for="(image, index) in item.value.value"
              :key="index"
              v-viewer="{ movable: false }"
            >
              <img :src="image" style="width: 100%; height: 100%" />
              <div class="dele" @click="deleteItem(index)">
                <i class="el-icon-delete"></i>
              </div>
            </div>
            <div class="icon">
              <el-upload
                class="avatar-uploader"
                :action="item.value.action"
                :show-file-list="false"
                :headers="item.value.headers"
                :data="item.value.uploadParameter"
                :on-success="handleAvatarSuccess"
                :disabled="item.flag"
              >
                <span
                  ><i class="el-icon-circle-plus-outline"></i> 添加附件</span
                >
              </el-upload>
            </div>
          </div>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'upload_file'"
        >
          <!-- 上传文件 -->
          <div
            class="dialog-content-line-value-img"
            v-if="
              item.value.value != '' &&
                (item.value.value.path.indexOf('png') > 0 ||
                  item.value.value.path.indexOf('jpg') > 0 ||
                  item.value.value.path.indexOf('jpeg') > 0)
            "
            v-viewer="{ movable: false }"
          >
            <img
              :src="item.value.value.path"
              alt=""
              style="width: 80px; height: 100px"
            />
          </div>
          <div
            v-else-if="item.value.value != ''"
            @click="showFileByOthersPage(item.value.value.path)"
          >
            {{ item.value.value.name }}
          </div>
          <el-upload
            class="avatar-uploader"
            :action="item.value.action"
            :show-file-list="false"
            :headers="item.value.headers"
            :data="item.value.uploadParameter"
            :on-success="handleAvatarSuccess"
            :disabled="item.flag"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'upload_img_png'"
        >
          <!-- 只能上传PNG图片 -->
          <div
            class="dialog-content-line-value-img"
            v-if="item.value.value != ''"
            v-viewer="{ movable: false }"
          >
            <img
              :src="item.value.value"
              alt=""
              style="width: 100px; height: 100px"
            />
          </div>
          <el-upload
            class="avatar-uploader"
            :action="item.value.action"
            :show-file-list="false"
            :headers="item.value.headers"
            :data="item.value.uploadParameter"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :disabled="item.flag"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'show_img'"
          v-viewer="{ movable: false }"
        >
          <el-image
            v-if="item.value"
            style="width: 100px; height: 100px"
            :src="item.value"
            alt=""
            fit="fill"
          ></el-image>
          <div v-else style="fontSize: 14px">暂无附件</div>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'show_imgs'"
          v-viewer="{ movable: false }"
        >
          <!-- <img :src="item.value" alt="" object-fit="scale-down" /> -->
          <el-image
            style="margin: 0 5px 5px 0"
            v-for="(image, index) in item.value"
            :key="index"
            :src="image"
            alt=""
            fit="fill"
          ></el-image>
        </div>
        <div
          class="dialog-content-line-value1"
          v-if="item.type == 'show_img_1'"
          v-viewer="{ movable: false }"
        >
          <!-- <img :src="item.value" alt="" object-fit="scale-down" /> -->
          <el-image
            style="width: 350px; height: 350px"
            :src="item.value"
            alt=""
            fit="fill"
          ></el-image>
          <div class="tip" @click="downloadIamge()">二维码下载</div>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'custom-to-do'"
        >
          <!-- 自定义待办事项 -->
          <CustomToDo
            :customToDoData="item.value"
            @showCustomToDoData="showCustomToDoData"
          >
          </CustomToDo>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'voucher_for'"
        >
          <!-- 回款单领取 -->
          <VoucherFor :voucherForData="item.value" @voucherFor="voucherFor">
          </VoucherFor>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'bind_client_applet'"
        >
          <!-- 开通小程序 -->
          <BindClientApplet :bindClientAppletData="item.value">
          </BindClientApplet>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'intention_the_product'"
        >
          <!-- 添加意向产品 -->
          <IntentionTheProduct :newContract="item.value"></IntentionTheProduct>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'leave_content'"
        >
          <!-- 查看离职详情 -->
          <LeaveContent :leaveContentData="item.value"></LeaveContent>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'knowledge'">
          <!-- 知识库 -->
          <KnowledgeData :knowledgeData="item.value"></KnowledgeData>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'improt_resources'"
        >
          <!-- 导入资源 -->
          <ImprotResources :improtResourcesData="item.value"></ImprotResources>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'import_invoice'"
        >
          <!-- 导入资源 -->
          <ImportInvoice :importInvoiceData="item.value"></ImportInvoice>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'select_electronic_contract'"
        >
          <!-- 生成电子合同是,选择电子合同模板 -->
          <SelectElectronicContract
            :SelectElectronicContractData="item.value"
            @toGenerateElectronicContract="toGenerateElectronicContract"
          ></SelectElectronicContract>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'role_authority'"
        >
          <!-- 修改权限属性数据 -->
          <RoleAuthority :roleAuthority="item.value"></RoleAuthority>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'business-role'"
        >
          <!-- 业务角色 -->
          <BusinessRole
            :businessRole="item.value"
            @makeSureBusinessRole="makeSureBusinessRole"
          ></BusinessRole>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'depertment_tree'"
        >
          <!-- 获取部门树 -->
          <el-cascader
            v-model="item.value"
            placeholder="请选择部门"
            :options="item.options"
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'depertment_trees'"
        >
          <!-- 获取部门树 -->
          <el-cascader
            v-model="item.value"
            placeholder="请选择部门"
            :options="item.options"
            :show-all-levels="false"
            :props="{
              checkStrictly: true,
              value: 'id',
              multiple: true,
              emitPath: false,
            }"
            filterable
          ></el-cascader>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'money_range'"
        >
          <!-- 金额范围 -->
          <el-input
            v-model="item.value[0]"
            placeholder="请输入金额下限"
          ></el-input>
          <span>~</span>
          <el-input
            v-model="item.value[1]"
            placeholder="请输入金额上限"
          ></el-input>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'add-reimbursemen'"
        >
          <!-- 新建报销 -->
          <AddReimbursemen
            :reimbursemenDetailData="item.value"
            :isFlag="item.isEditFlag"
          ></AddReimbursemen>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'show-reimbursemen'"
        >
          <!-- 查看报销 -->
          <ShowReimbursemen
            :reimbursemenDetailData="item.value"
            @payerChange="payerChange"
          ></ShowReimbursemen>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'show-reimbursemen-approy'"
        >
          <!-- 批量报销审核或付款 -->
          <ShowReimbursemenApproy
            :reimbursemenDetailData="item.value"
            :isFlag="item.flag"
          ></ShowReimbursemenApproy>
        </div>
        <!-- 添加回款信息 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'add_transaction'"
        >
          <dialogTransaction
            :transactionData="item.value"
            @confirm="confirm"
          ></dialogTransaction>
        </div>
        <!-- 新增档案 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'add_archives'"
        >
          <AddArchives :archivesData="item.data"></AddArchives>
        </div>
        <!-- 领取档案 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'get_archives'"
        >
          <GetArchives :archivesData="item.data"></GetArchives>
        </div>
        <!-- 寄件 -->
        <div class="dialog-content-line-value" v-if="item.type == 'mail'">
          <mail ref="mailRef" :mailData="item.data"></mail>
        </div>
        <!-- 选择收件人 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'selectRecipient'"
        >
          <selectRecipient :selectRecipientData="item.data"></selectRecipient>
        </div>
        <!-- 运单详情 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'get_delivery'"
        >
          <GetDeliveryDetails :DeliveryData="item.data"></GetDeliveryDetails>
        </div>
        <!-- 新增, 修改快递地址 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'delivery_detail'"
        >
          <DeliveryDetail :DeliveryDetail="item.data"></DeliveryDetail>
        </div>
        <!-- 地址导入 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'address_import'"
        >
          <div class="dialog-content-line-value-address">
            <div class="address-import-left">
              <div class="address-import-input-line">
                <el-upload
                  class="avatar-uploader"
                  :action="item.value.action"
                  :headers="item.value.headers"
                  :data="{ systemName: 'finance', title: '客户地址' }"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUploadExcel"
                >
                  <div class="address-import-input-line-button">浏览</div>
                </el-upload>
              </div>
              <div style="text-align: left; color: #999; font-size: 14px">
                上传地址表格说明：
              </div>
              <div class="address-import-line">
                <div>
                  2、不要对列名做修改, 修改后将无法导入该字段的数据
                </div>
                <div>
                  3、收件人、电话、省、市、区、详细地址任意项缺失或不符合格式的,
                  不导入
                </div>
                <div>
                  4、导入时, 不填写客户简称, 自动创建为非客户地址（仅自己可见）
                </div>
                <div>
                  5、填写正确的客户简称,将自动创建为客户地址（填写错误不创建地址）
                </div>
                <div>
                  6、若客户已有地址,且对该客户地址数据有更新权限,则更新该客户地址
                </div>
                <div>
                  <p>
                    还没下载表格:
                    <span
                      class="link"
                      @click="downloadTemplate(item.value.modelType)"
                      >下载导入模板</span
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="address-import-right">
              <div style="margin-bottom: 20px">导入历史</div>
              <div>注: 最多显示近1个月的导入历史</div>
            </div>
          </div>
        </div>
        <!-- 充值 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'charge_money'"
        >
          <div class="dialog-content-line-value-chargeMoney">
            <div class="dialog-content-line-value-chargeMoney-content">
              <div
                :class="{
                  'dialog-content-line-value-chargeMoney-item': true,
                  active: curInd == index,
                }"
                v-for="(item, index) in item.data[0].moneyList"
                :key="item.title"
                @click="changeAmount(item, index)"
              >
                {{ item.title }}
              </div>
            </div>
            <div
              v-if="item.data[0].isShow"
              class="dialog-content-line-value-chargeMoney-input"
            >
              <el-input
                :onkeyup="
                  (item.data[0].amount = item.data[0].amount.replace(
                    /[^0-9.]/g,
                    ''
                  ))
                "
                oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
                v-model="item.data[0].amount"
                maxlength="8"
                placeholder="请输入>=1的数字"
              ></el-input
              ><span style="font-size: 22px; font-weight: 600;">元</span>
            </div>
            <div class="dialog-content-line-value-chargeMoney-info">
              <el-checkbox v-model="item.data[0].agreement"></el-checkbox>
              <span style="margin-left: 7px;">我已同意并阅读</span
              ><span style="color: #ff8040; cursor: pointer;"
                >《用户充值协议》</span
              >
            </div>
          </div>
        </div>
        <!-- 充值二维码 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'account_qrCode'"
        >
          <div class="dialog-content-line-value-accountQrCode">
            <div class="accountQrCode">
              <img :src="item.data[0].url" alt="" />
              <!-- <qriously :value="item.data[0].url" :size="150" /> -->
            </div>
            <div class="accountQrCode-text" style="font-size: 16px;">
              请使用微信扫码付款
            </div>
            <div
              class="accountQrCode-text"
              v-if="item.data[0].rowData.receiverClientName"
            >
              <span
                >客户名称: {{ item.data[0].rowData.receiverClientName }}</span
              >
            </div>
            <div
              class="accountQrCode-text"
              v-if="item.data[0].rowData.deliveryId"
            >
              <span style="margin-right: 10px;"
                >运单号: {{ item.data[0].rowData.deliveryId }}</span
              >
              <span
                >金额:
                <span style="color: #40a3ff;">{{
                  item.data[0].rowData.freight
                }}</span></span
              >
            </div>
            <!-- <div
              v-if="!item.data[0].rowData"
              style="color: #999; font-size: 12px;"
            >
              充值完成后，请根据您的情况点击下面按钮。
            </div> -->
          </div>
        </div>
        <!-- 快递开票 -->
        <!-- <div
          class="dialog-content-line-value"
          v-if="item.type == 'expressage_invoice'"
        >
          <div class="expressage_invoice">
            <div class="expressage_invoice-card">
              123
            </div>
            <div class="expressage_invoice-card">
              123
            </div>
            <div class="expressage_invoice-card">
              123
            </div>
          </div>
        </div> -->
        <!-- 公司社保详情 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'company_social_detail'"
        >
          <div class="company_social"></div>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'table_social'"
        >
          <tableSocial :data="item.data"></tableSocial>
        </div>
        <!-- 工商提成详情 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'table_human'"
        >
          <tableHuman :data="item.data"></tableHuman>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'table_payroll'"
        >
          <tablePayroll
            :data="item.data"
            :allFunctionButtons="item.allFunctionButtons"
            @showVisitDetail="showVisitDetail"
          ></tablePayroll>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'add_social'">
          <addSocial :data="item.data"></addSocial>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'social_person'"
        >
          <div class="social-person">
            <div class="search-line">
              <div class="item">
                <el-date-picker
                  v-model="item.data.date"
                  type="monthrange"
                  range-separator="至"
                  start-placeholder="开始月份"
                  end-placeholder="结束月份"
                  value-format="yyyy-MM"
                  :clearable="false"
                  @change="dialogOper(item.data.active)"
                >
                </el-date-picker>
              </div>
              <div class="item">
                <el-button
                  type="primary"
                  size="small"
                  @click="dialogOper('export')"
                  >导出</el-button
                >
              </div>
            </div>
            <div class="content">
              <el-table
                :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
                :data="item.data.tableData"
                highlight-current-row
                style="width: 100%"
                :height="item.data.height"
                show-summary
              >
                <el-table-column type="index" width="50" fixed>
                </el-table-column>
                <el-table-column
                  v-for="(it, i) in item.data.props"
                  :fixed="i == 0"
                  :key="it.prop"
                  :prop="it.prop"
                  :label="it.label"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <!-- 添加报销付款 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'contract_expend'"
        >
          <div class="contract_expend_content">
            <div class="contract_expend_item">
              <div class="contract_expend_item_title">收款单号:</div>
              <div class="contract_expend_item_value">
                <el-input
                  v-model="item.data.receiptNumber"
                  placeholder="请输入收款单号"
                  :disabled="item.data.disabled"
                ></el-input>
              </div>
            </div>
            <div class="contract_expend_item">
              <div class="contract_expend_item_title">付款金额:</div>
              <div class="contract_expend_item_value">
                <el-input
                  :onkeyup="
                    (item.data.paymentAmount = item.data.paymentAmount.replace(
                      /[^0-9.]/g,
                      ''
                    ))
                  "
                  oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value"
                  v-model="item.data.paymentAmount"
                  placeholder="请输入付款金额"
                ></el-input>
              </div>
            </div>
            <div class="contract_expend_item">
              <div class="contract_expend_item_title">付款方式:</div>
              <div class="contract_expend_item_value">
                <el-select
                  v-model="item.data.paymentMethod"
                  placeholder="请选择付款方式"
                >
                  <el-option
                    v-for="it in item.data.paymentMethodOptions"
                    :key="it.value"
                    :label="it.label"
                    :value="it.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="contract_expend_item">
              <div class="contract_expend_item_title">付款日期:</div>
              <div class="contract_expend_item_value">
                <el-date-picker
                  v-model="item.data.paymentDate"
                  type="date"
                  placeholder="请选择付款日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="contract_expend_item">
              <div class="contract_expend_item_title">收款人全称:</div>
              <div class="contract_expend_item_value">
                <el-autocomplete
                  class="inline-input"
                  v-model="item.data.payeeName"
                  value-key="payeeName"
                  :fetch-suggestions="contractExpendQuerySearch"
                  placeholder="请输入收款人全称"
                  :trigger-on-focus="false"
                  @select="expendSelect"
                ></el-autocomplete>
                <!-- <el-input
                  v-model="item.data.payeeName"
                  placeholder="请输入收款人全称"
                ></el-input> -->
              </div>
            </div>
            <div class="contract_expend_item">
              <div class="contract_expend_item_title">银行账户:</div>
              <div class="contract_expend_item_value">
                <el-input
                  v-model="item.data.bankAccount"
                  placeholder="请输入银行账户"
                ></el-input>
              </div>
            </div>
            <div class="contract_expend_item">
              <div class="contract_expend_item_title">开户行:</div>
              <div class="contract_expend_item_value">
                <el-input
                  v-model="item.data.bankOfDeposit"
                  placeholder="请输入开户行"
                ></el-input>
              </div>
            </div>
            <div class="contract_expend_item">
              <div class="contract_expend_item_title">付款单位:</div>
              <div class="contract_expend_item_value">
                <el-select
                  v-model="item.data.payer"
                  placeholder="请选择付款单位"
                >
                  <el-option
                    v-for="it in item.data.payerOptions"
                    :key="it.value"
                    :label="it.name"
                    :value="it.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="contract_expend_item">
              <div class="contract_expend_item_title">备注说明:</div>
              <div class="contract_expend_item_value">
                <el-input v-model="item.data.remark" type="textarea"></el-input>
              </div>
            </div>
            <div class="contract_expend_item">
              <div class="contract_expend_item_title">付款理由:</div>
              <div class="contract_expend_item_value">
                <el-input
                  v-model="item.data.reasons"
                  type="textarea"
                ></el-input>
              </div>
            </div>
            <div class="contract_expend_item">
              <div class="contract_expend_item_title">附件:</div>
              <div class="contract_expend_item_value">
                <div class="icons">
                  <div
                    class="icon"
                    v-for="(item, index) in item.data.attachments"
                    :key="index"
                    v-viewer="{ movable: false }"
                  >
                    <img :src="item" style="width: 100%; height: 100%" />
                    <div class="dele" @click="deleteItem(index)">
                      <i class="el-icon-delete"></i>
                    </div>
                  </div>
                  <div class="icon">
                    <el-upload
                      class="avatar-uploader"
                      :action="item.data.uploadData.value.action"
                      :show-file-list="false"
                      :headers="item.data.uploadData.value.headers"
                      :data="{ systemName: 'finance', title: '新建支出' }"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                    >
                      <span
                        ><i class="el-icon-circle-plus-outline"></i>
                        添加附件</span
                      >
                    </el-upload>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'payroll_detail'"
        >
          <payrollDetail :payrollDetail="item.data"></payrollDetail>
        </div>
        <!-- 公首页告详情 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'notice_detail'"
        >
          <noticeDetail :data="item.data"></noticeDetail>
        </div>
        <!-- 云盘文件移动 -->
        <div class="dialog-content-line-value" v-if="item.type == 'moveFile'">
          <moveFile :fileData="item.data"></moveFile>
        </div>
        <div class="dialog-content-line-value" v-if="item.type == 'line-row'">
          <lineRow :data="item.data"></lineRow>
        </div>
        <!-- 客户编号设置 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'client-number'"
        >
          <div class="client-number">
            <div class="client-number-item">
              <div class="client-number-item-title">前缀:</div>
              <div class="client-number-item-value">
                <el-input
                  v-model="item.value.prefix"
                  placeholder="请输入前缀"
                ></el-input>
              </div>
            </div>
            <div class="client-number-item">
              <div class="client-number-item-title">开始值:</div>
              <div class="client-number-item-value">
                <el-input
                  v-model="item.value.start"
                  placeholder="请输入开始值"
                ></el-input>
              </div>
            </div>
            <div class="client-number-item">
              <div class="client-number-item-title">结束值:</div>
              <div class="client-number-item-value">
                <el-input
                  v-model="item.value.end"
                  placeholder="请输入结束值"
                ></el-input>
              </div>
            </div>
            <div class="client-number-item">
              <div class="client-number-item-title">后缀:</div>
              <div class="client-number-item-value">
                <el-input
                  v-model="item.value.suffix"
                  placeholder="请输入后缀"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <!-- 添加涉税编号 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'taxIssueCode'"
        >
          <taxIssueCode :data="item.data" @addCode="addCode"></taxIssueCode>
        </div>
        <!-- 发票备注 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'invoice-remark'"
        >
          <div class="dialog-content-line-value-invoice-remark">
            <div
              class="dialog-content-line-value-invoice-remark-item"
              v-for="(it, ind) in item.value"
              :key="it.id"
            >
              {{ ind + 1 }}、<el-input v-model="it.remark"></el-input>

              <i
                v-if="ind == 0"
                class="el-icon-circle-plus-outline"
                @click="addItem(it, ind)"
              ></i>
            </div>
          </div>
        </div>
        <!-- 呼叫设置 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'number-setting'"
        >
          <div class="dialog-content-line-value-number-setting">
            <div
              class="dialog-content-line-value-number-setting-item"
              v-for="(it, ind) in item.value"
              :key="ind"
            >
              <el-card :class="{ 'box-card': true, active: it.enabled == '1' }">
                <div slot="header" class="header">
                  <div>组合{{ ind + 1 }}</div>
                  <div>
                    <el-button
                      v-if="it.enabled == '0'"
                      type="text"
                      @click="launch(it, ind)"
                      >启用</el-button
                    >
                    <el-button
                      type="text"
                      style="color: #F56C6C"
                      @click="deleteItem(ind)"
                      >删除</el-button
                    >
                  </div>
                </div>
                <div class="card-line">
                  <div class="card-line-title">主叫号:</div>
                  <div class="card-line-value">
                    <el-input v-model="it.callNumber"></el-input>
                  </div>
                </div>
                <div class="card-line">
                  <div class="card-line-title">中间号:</div>
                  <div class="card-line-value">
                    <el-input v-model="it.middleNumber"></el-input>
                  </div>
                </div>
              </el-card>
            </div>
          </div>
        </div>

        <!-- 发票抬头 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'contract-invoice-dialog'"
        >
          <contractInvoiceDialog
            :allFunctionButtons="allFunctionButtons"
            :data="item.value"
          ></contractInvoiceDialog>
        </div>
        <!-- 知识库 试题添加 编辑 -->
        <div
          class="dialog-content-line-value"
          v-if="item.type == 'examinationAdd'"
        >
          <examinationAdd
            :examinationInfo="item.categoryList"
            :formData="item.formData"
            :btnFlag="item.btnFlag"
            :allFunctionButtons="item.allFunctionButtons"
            @addExamination="addExamination"
            @cancelExamination="cancelExamination"
          ></examinationAdd>
        </div>
        <!-- 知识库 试卷生成 -->
        <div class="dialog-content-line-value" v-if="item.type == 'testPaper'">
          <testPaper :data="item.value"></testPaper>
        </div>
        <!-- 任务 参与人 -->
        <div class="dialog-content-line-value" v-if="item.type == 'filters'">
          <filters :componentsData="item.data"></filters>
        </div>
      </div>
    </div>
    <div class="dialog-buts">
      <el-button
        class="dialog-but"
        v-for="(item, index) in buttonArray"
        :key="index"
        :style="{
          backgroundColor:
            item.active == 'cancel' || item.active == 'passApprove'
              ? '#C0C4CC'
              : '#409EFF',
        }"
        :loading="item.loading"
        @click="dialogOper(item.active)"
        size="small"
        v-showFunction="{ active: item.key, array: allFunctionButtons }"
      >
        {{ item.text }}</el-button
      >
    </div>
  </div>
</template>
<script>
import { pca, pcaa } from "area-data";
import my_drawer from "./my-drawer.vue";
import authority_dialog from "./authority-dialog.vue";
import authority_tips from "./authority-tips.vue";
import service_item_table from "./serviceItemTable.vue";
import role_tree from "./role-tree.vue";
import * as threadData from "../assets/js/myThread.js";
import expend_details from "./expendDetails.vue";
import dialog_renew from "./dialog-renew.vue";
import invoice_dialog from "./invoice-dialog.vue";
import InvoiceIncomeDdialog from "./invoice-dialog-income.vue";
import invoice from "./invoice-model.vue";
import commondity from "./commodity.vue";
import DialogCustomers from "./customer1.vue";
import ProductModel from "./product-model.vue";
import NewContract from "./re-new-contract.vue";
import ReNewContract from "./new-contract.vue";
import ReNewOrder from "./new-order.vue";
import NewOrder from "./re-new-order.vue";
import test_dialog from "../views/test.vue";
import BindClientTable from "../components/bind-client-table.vue";
import BindClientGroupTable from "../components/bind-client-group-table.vue";
import FinancialAudit from "../components/financial-audit.vue";
import PerformanceCalculationRules from "../components/performance-calculation-rules.vue";
import CustomToDo from "../components/custom-to-do.vue";
import VoucherFor from "../components/voucher_for.vue";
import BindClientApplet from "../components/bind-client-applet.vue";
import IntentionTheProduct from "../components/intention-the-product.vue";
import LeaveContent from "../components/leaveContent.vue";
import KnowledgeData from "../components/knowledge.vue";
import ImprotResources from "../components/improt-resources.vue";
import ImportInvoice from "../components/import-invoice.vue";
import FollowUpRecords from "../components/follow-up-records.vue";
import SelectElectronicContract from "../components/select-electronic-contract.vue";
import RoleAuthority from "../components/role-authority.vue";
import BusinessRole from "../components/business-role.vue";
import AddReimbursemen from "../views/reimbursement/components/add_reimbursemen_itemt.vue";
import ShowReimbursemen from "../views/reimbursement/components/show_reimbursemen_itemt.vue";
import ShowReimbursemenApproy from "../views/reimbursement/components/show_reimbursemen_itemt_approy.vue";
import dialogTransaction from "../components/dialog_transaction.vue";
import AddArchives from "@/views/archives/components/add_archives";
import GetArchives from "@/views/archives/components/get_archives";
import GetDeliveryDetails from "@/views/expressage/components/get_delivery";
import DeliveryDetail from "@/views/expressage/components/delivery_detail";
import mail from "@/views/expressage/components/mail";
import selectRecipient from "@/views/expressage/components/selectRecipient";
import tableSocial from "@/views/tax/components/table_social";
import addSocial from "@/views/tax/components/add_social";
import payrollDetail from "@/views/human/components/payroll-detail";
import noticeDetail from "../components/notice_detail";
import moveFile from "../components/move-file";
import lineRow from "./line-row.vue";
import diyAreaSelect from "./diy-area-select.vue";
import tableHuman from "@/views/human/components/table-human";
import tablePayroll from "@/views/human/components/table-payroll";
import taxIssueCode from "@/views/invoice/components/tax_issue_code";
import contractInvoiceDialog from "@/components/contract-invoice-dialog";
import filters from "@/views/task/components/filters";

export default {
  name: "dialog",
  props: {
    allFunctionButtons: {
      type: Array,
      default: function() {
        return [];
      },
    },
    data: {
      type: Array,
      default: function() {
        return [
          {
            type: "drawer",
            data: {},
          },
        ];
      },
    },
    title: {
      type: String,
      default: "添加跟进记录",
    },
    clientName: {
      type: String,
      default: "",
    },
    buttonArray: {
      type: Array,
      default: function() {
        return [
          {
            text: "提交",
            active: "submit",
          },
        ];
      },
    },
    width: {
      type: String,
      default: "30%",
    },
    dialogStyle: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    my_drawer,
    authority_dialog,
    authority_tips,
    role_tree,
    service_item_table,
    expend_details,
    dialog_renew,
    invoice_dialog,
    InvoiceIncomeDdialog,
    invoice,
    commondity,
    "dialog-customers": DialogCustomers,
    ProductModel,
    test_dialog,
    NewContract,
    ReNewContract,
    BindClientTable,
    BindClientGroupTable,
    FinancialAudit,
    PerformanceCalculationRules,
    NewOrder,
    ReNewOrder,
    CustomToDo,
    VoucherFor,
    BindClientApplet,
    IntentionTheProduct,
    LeaveContent,
    KnowledgeData,
    ImprotResources,
    ImportInvoice,
    FollowUpRecords,
    SelectElectronicContract,
    RoleAuthority,
    BusinessRole,
    AddReimbursemen,
    ShowReimbursemen,
    ShowReimbursemenApproy,
    dialogTransaction,
    AddArchives,
    GetArchives,
    GetDeliveryDetails,
    DeliveryDetail,
    mail,
    selectRecipient,
    tableSocial,
    addSocial,
    payrollDetail,
    noticeDetail,
    moveFile,
    lineRow,
    diyAreaSelect,
    tableHuman,
    tablePayroll,
    taxIssueCode,
    contractInvoiceDialog,
    examinationAdd: () =>
      import("../views/knowledge/components/examination_add.vue"),
    testPaper: () => import("../views/knowledge/components/test_paper.vue"),
    filters,
  },
  data() {
    return {
      baseInfo: {},
      checkboxGroup1: [],
      inputVisible: false,
      inputVisible1: false,
      inputVisible2: false,
      inputVisible3: false,
      inputValue: "",
      inputValue1: "",
      inputValue2: "",
      inputValue3: "",
      loading: false,
      options: [],
      curInd: -1,
      nameList: [],
      restaurants: [],
      pcaa: pcaa,
    };
  },
  name: "my_dialog",

  methods: {
    changeCascader(value) {
      this.$emit("changeCascader", value);
    },
    // 附件上传之前的校验
    beforeAvatarUpload(file) {
      let isJPG = file.type === "image/jpeg" || file.type === "image/png";

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      return isJPG;
    },

    querySearch2(queryString, cb, clientId) {
      let requestData = {
        clientId: clientId, // 客户id
        name: "", // 名称
      };
      this.$request(
        "post",
        "/socialSecurity/searchForEmployees",
        requestData,
        (data) => {
          this.nameList = data;
          var nameList = this.nameList;
          var results = queryString
            ? nameList.filter(this.createFilter(queryString))
            : nameList;
          // 调用 callback 返回建议列表的数据
          cb(results);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    createFilter(queryString) {
      return (value) => {
        return value.name.indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleSelect(item) {
      this.data[0].name = item.name;
      this.data[0].gender = item.gender;
      this.data[0].identityCard = item.identityCard;
    },
    handleClick(tab, event) {
      this.$emit("dialogOper", "change", tab);
    },
    confirm(data) {
      this.$emit("confirm", data);
    },
    choseProductTip(array, data) {
      data.flag = !data.flag;
      let index = threadData.getPurposeNum(array);
      if (index > 5) {
        data.flag = !data.flag;
        this.$message.error("最多选择5中产品!");
        return;
      }
    },
    choseProductTipOne(data) {
      this.$emit("choseProductTipOne", data);
    },
    enter() {
      this.$emit("enter");
    },
    colseDialog() {
      this.$emit("colseDialog");
    },
    dialogOper(active, data) {
      this.$emit("dialogOper", active, data);
    },
    authorityShow(data) {
      this.$emit("authorityShow", data);
    },
    showExpend(data) {
      this.$emit("showExpend", data);
      console.log("showExpend", data);
    },
    showRenew(data) {
      this.$emit("showRenew", data);
    },
    buttonOper(data) {
      this.$emit("buttonOper", data);
    },
    handleClose(tag, index, flag) {
      switch (flag) {
        case 0:
          if (this.baseInfo.userId == "super") {
            this.data[index].value.splice(
              this.data[index].value.indexOf(tag),
              1
            );
          } else {
            this.$message.error("您没有权限!");
          }
          break;
        case 1:
          this.data[index].value.splice(this.data[index].value.indexOf(tag), 1);
          break;
        case 2:
          this.data[0].value.casherList.splice(index, 1);
          this.$emit("dialogOper", "delete");
          break;
        case 3:
          this.data[0].value.reviewerList.splice(index, 1);
          this.$emit("dialogOper", "delete");
          break;
        case 4:
          this.data[0].value.drawerList.splice(index, 1);
          this.$emit("dialogOper", "delete");
          break;
        default:
          break;
      }
    },
    /**
     * el-tag 打开输入框事件
     */
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    showInput1(data) {
      data.inputVisible = true;
    },
    showInput2(active) {
      switch (active) {
        case 1:
          this.inputVisible1 = true;
          break;
        case 2:
          this.inputVisible2 = true;
          break;
        case 3:
          this.inputVisible3 = true;
          break;
        default:
          break;
      }
    },
    handleInputConfirm(index) {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.data[index].value.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    handleInputConfirm1(data, index) {
      let inputValue = JSON.parse(JSON.stringify(data.tempValue));
      if (inputValue) {
        if (
          !data.value.some((item) => {
            if (item.id == inputValue.id) {
              return true;
            }
          })
        ) {
          data.value.push(inputValue);
        }
      }
      data.inputVisible = false;
      // data.tempValue = {};
    },
    /**
     * 开票人员设置
     */
    handleInputConfirm2(active) {
      switch (active) {
        case 1:
          if (this.inputValue1) {
            this.data[0].value.casherList.push(this.inputValue1);
            this.$emit("dialogOper", "add");
          }
          break;
        case 2:
          if (this.inputValue2) {
            this.data[0].value.reviewerList.push(this.inputValue2);
            this.$emit("dialogOper", "add");
          }
          break;
        case 3:
          if (this.inputValue3) {
            this.data[0].value.drawerList.push(this.inputValue3);
            this.$emit("dialogOper", "add");
          }
          break;
        default:
          break;
      }
      this.inputValue1 = "";
      this.inputValue2 = "";
      this.inputValue3 = "";
      this.inputVisible1 = false;
      this.inputVisible2 = false;
      this.inputVisible3 = false;
    },
    /**
     * 企业流水设置
     */
    taxStatement(active, index) {
      switch (active) {
        case "add":
          if (this.inputValue1) {
            this.$emit("dialogOper", "add", this.inputValue1);
          }
          break;
        case "del":
          this.$emit("dialogOper", "del", index);
          break;
        default:
          break;
      }
      this.inputValue1 = "";
      this.inputVisible1 = false;
    },
    changeHandle(val, index) {
      if (this.baseInfo.userId == "super") {
        if (
          !this.data[index + 1].value.some((item) => {
            if (item.value == val.value) {
              return true;
            }
          })
        ) {
          this.data[index + 1].value.push(val);
        }
      } else {
        this.$message.error("您没有权限!");
      }
      // this.data[index + 1].value.push(val);
    },
    selectHandel(data) {
      this.$emit("selectHandel", data);
    },
    saveDrawerData(title) {
      this.$emit("saveDrawerData", title);
    },
    showCustomer(data) {
      this.$emit("showCustomer", data);
    },
    bindWechatClient(data) {
      this.$emit("bindWechatClient", data);
    },
    bindWechatGroupClient(data) {
      this.$emit("bindWechatGroupClient", data);
    },
    updateWechatGroupClient(data) {
      this.$emit("updateWechatGroupClient", data);
    },
    getALlWechatGroupClientList() {
      this.$emit("getALlWechatGroupClientList");
    },
    handleAvatarSuccess(response, file, fileList) {
      console.log(file);
      let dd = {
        result: {
          path: response.result.path,
          title: response.result.title,
          name: file.name,
        },
      };
      this.$emit("handleAvatarSuccess", dd);
    },
    /**
     * 只能上传PNG图片
     */
    beforeAvatarUploadPNG(file) {
      let isJPG = file.type === "image/png";
      let isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传图片只能是 PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isJPG && isLt2M;
    },
    /**
     * 只能上传Excel文件
     */
    beforeAvatarUploadExcel(file) {
      var type = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = type === "xls";
      const extension2 = type === "xlsx";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!extension && !extension2) {
        this.$message.error("只能上传excel的文件!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return (extension || extension2) && isLt2M;
    },
    changeFlag(val) {
      this.$emit("changeFlag", val);
    },
    showCustomToDoData(val) {
      this.$emit("showCustomToDoData", val);
    },
    searchData(data) {
      this.$emit("searchData", data);
    },
    showBindClientGroup(val) {
      this.$emit("showBindClientGroup", val);
    },
    showBindClient(val) {
      this.$emit("showBindClient", val);
    },
    voucherFor(val) {
      this.$emit("voucherFor", val);
    },
    rejectA(data) {
      this.$emit("rejectA", data);
    },
    /**
     * 输入框远程搜索
     */
    querySearchAsync(queryString, cb) {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        voucherNo: queryString, // 交易单号
      };

      this.$request(
        "post",
        "/contract/getReceivedBeforeCharge",
        requestData,
        (respontData) => {
          let returnData = [];
          for (let index = 0; index < respontData.length; index++) {
            let element = respontData[index];
            let dd = {
              value: element.voucherNoAndName,
              id: element.id,
            };
            returnData.push(dd);
          }
          cb(returnData);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 实时远程搜索收款单
     */
    remoteMethod(query) {
      this.options = [];
      if (query !== "") {
        this.loading = true;
        let requestData = {
          userId: this.baseInfo.userId,
          companyId: this.baseInfo.company.companyId,
          voucherNo: query, // 交易单号
        };

        this.$request(
          "post",
          "/contract/getReceivedBeforeCharge",
          requestData,
          (respontData) => {
            this.options = respontData;
            this.loading = false;
          },
          (errorMessage) => {
            this.loading = false;
            this.$message.error(errorMessage);
          }
        );
      }
    },
    /**
     * 实时远程搜索收款单
     */
    remoteMethod2(query, item) {
      if (query !== "") {
        this.$emit("remoteMethod2", query);
      }
    },
    remoteChange2(val) {
      this.$emit("remoteChange2", val);
    },
    fetchSuggestions(queryString, cb) {
      this.$emit("fetchSuggestions", queryString, cb);
    },
    fetchSuggestionsSelect(val) {
      this.$emit("fetchSuggestionsSelect", val);
    },
    /**
     * 确定添加跟进记录
     */
    addFollowRecord(data) {
      this.$emit("addFollowRecord", data);
    },
    /**
     * 确定生成电子合同
     */
    toGenerateElectronicContract(data) {
      this.$emit("toGenerateElectronicContract", data);
    },
    /**
     * 电子合同二维码下载
     */
    downloadIamge() {
      this.$emit("downloadIamge");
    },
    makeSureBusinessRole(data) {
      this.$emit("makeSureBusinessRole", data);
    },
    /**
     * 在另一个页面打开文件
     */
    showFileByOthersPage(path) {
      if (this.$keyType.keyType(path) == "Object") {
        window.open(path.path, "_blank");
      } else if (this.$keyType.keyType(path) == "String") {
        window.open(path, "_blank");
      } else {
        this.$message.error("附件格式错误,请联系管理员!");
      }
    },
    /**
     *
     */
    pushed() {
      this.$emit("pushed");
    },
    /**
     * 下载导入模版
     */
    downloadTemplate(type) {
      window.location.href = this.$baseurl + "/downResourcesMode?type=" + type;
    },
    changeAmount(item, index) {
      this.curInd = index;
      // 如果下标加一== 数组长度 说明选中跟其他 显示输入框 否则隐藏
      if (index + 1 == this.data[0].data[0].moneyList.length) {
        this.data[0].data[0].amount = "";
        this.data[0].data[0].isShow = true;
      } else {
        this.data[0].data[0].amount = item.value;
        this.data[0].data[0].isShow = false;
      }
    },
    rowClassName({ row, rowIndex }) {
      if (row.reductionSign === "0") {
        return "reduce";
      } else if (row.dataSources == "2") {
        return "add";
      }
    },
    // 查看支出详情
    expendDetails(expenditureId) {
      this.$emit("expendDetails", expenditureId);
    },
    payerChange(payer) {
      this.$emit("payerChange", payer);
    },
    contractExpendQuerySearch(queryString, cb) {
      this.$request(
        "get",
        "/contractCommonExpenses/listByUserId?userId=" + this.baseInfo.userId,
        {},
        (data) => {
          let restaurants = data;
          let results = queryString
            ? restaurants.filter(this.contractExpendCreateFilter(queryString))
            : restaurants;
          // 调用 callback 返回建议列表的数据
          cb(results);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    contractExpendCreateFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.payeeName
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) !== -1
        );
      };
    },
    expendSelect(item) {
      this.data[0].data.bankAccount = item.bankAccount; // 银行账号
      this.data[0].data.bankOfDeposit = item.bankOfDeposit; // 开户行
    },
    showVisitDetail(row) {
      this.$emit("showVisitDetail", row);
    },
    addCode(data) {
      this.$emit("addCode", data);
    },
    /**
     * 删除子项
     */
    deleteItem(index) {
      this.$emit("deleteItem", index);
    },
    /**
     * 添加子项
     */
    addItem(item, index) {
      this.$emit("addItem", { item, index });
    },
    launch(item, index) {
      this.$emit("launch", { item, index });
    },
    handleSelectionChange(val) {
      this.$emit("handleSelectionChange", val);
    },
    checkSelectable(row) {
      switch (this.title) {
        case "选择收款单号":
          return parseFloat(row.money) > 0 && !row.invoiceId;
          break;
        default:
          break;
      }
    },
    // lazyLoading() {
    //   let dom = document.querySelector(".el-table__body-wrapper");
    //   dom.addEventListener("scroll", (v) => {
    //     console.log("111");
    //     const scrollDistance =
    //       dom.scrollHeight - dom.scrollTop - dom.clientHeight;
    //     console.log("鼠标滑动-scrollDistance", scrollDistance);
    //     // if (scrollDistance <= 0) {  //分辨率问题，如果设置 100% ，滑倒最底部，scrollDistance的值 可能为 0.201 到 -0.201
    //   });
    // },
    addExamination() {},
    cancelExamination() {},
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    // this.lazyLoading();
  },
};
</script>

<style lang="less" scoped>
#dialog {
  /deep/ .black .el-textarea.is-disabled .el-textarea__inner {
    color: #333;
  }
  height: auto;
  max-height: 95vh;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  .dialog-title {
    position: relative;
    width: 100%;
    height: 40px;
    min-height: 40px;
    border-bottom: 1px solid @boderColor;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .dialog-title-text {
      margin-left: 10px;
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: @activeColor;
    }
    .dialog-title-submitRemark {
      display: flex;
      position: absolute;
      left: 100px;
      top: 10px;
    }
    .dialog-title-push {
      position: absolute;
      right: 13%;
      top: 6px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: end;
    }
    .dialog-title-colse {
      width: 30px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .dialog-title-colse:hover {
      color: #fa0a0a;
    }
  }
  .dialog-content {
    width: 100%;
    height: auto;
    min-height: 50px;
    max-height: 900px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    &::-webkit-scrollbar {
      width: 1px;
    }
    .dialog-content-line {
      display: flex;
      width: 95%;
      height: auto;
      min-height: 30px;
      // max-height: 300px;
      overflow-y: auto;
      margin: 10px;
      &::-webkit-scrollbar {
        width: 1px;
      }
      .dialog-content-line-title {
        width: 20%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        margin-right: 5px;
        min-height: 30px;
        .required {
          font-size: 18px;
          color: red;
        }
      }
      .dialog-content-line-value,
      .dialog-content-line-value1 {
        flex: 1;
        height: 100%;
        width: 0;
        max-height: 900px;
        display: flex;
        align-items: center;
        .div_tip {
          width: 30%;
          height: 20%;
          min-height: 50px;
          margin: 10px 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid @boderColor;
          transition: 0.3s;
          box-sizing: border-box;
          font-size: @contentFontSize4;
          &:hover {
            cursor: pointer;
            background-color: @editColor;
            color: #fff;
            border: 1px solid @activeColor3;
            box-sizing: border-box;
          }
        }
        /deep/ .area-select-wrap {
          display: flex;
          .area-select {
            width: 100px;
            margin-left: 8px;
            box-sizing: border-box;
            &:hover {
              transition: 1s;
            }
            &:first-child {
              margin-left: 0;
            }
            .area-selected-trigger {
              padding: 7px 20px 7px 10px;
              text-align: left;
              font-weight: 500;
              &:hover {
                padding: 7px 20px 7px 10px;
              }
            }
            .area-select-option.selected {
              color: @activeColor;
            }
            .area-selectable-list-wrap {
              max-height: 150px;
            }
          }
        }
        /deep/ .el-table--scrollable-y .el-table__body-wrapper {
          overflow-x: auto;
          height: 300px;
          &::-webkit-scrollbar {
            height: 8px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.2);
          }
        }
        /deep/ .el-autocomplete {
          .my-autocomplete {
            width: 100px;
            li {
              text-align: left;
              line-height: normal;
              padding: 7px;
              .name {
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .addr {
                font-size: 12px;
                color: #b4b4b4;
              }

              .highlighted .addr {
                color: #ddd;
              }
            }
          }
        }
        /deep/ .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 100%;
        }
        .dialog-content-line-value-genjin {
          width: 100%;
          height: 80px;
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid @boderColor;
          overflow-y: auto;
          &:nth-last-child(1) {
            border: none;
          }
          .dialog-content-line-value-genjin-line {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .dialog-content-line-value-genjin-icon {
              width: 50px;
              height: 100%;
              display: flex;
              align-items: center;
            }
            .dialog-content-line-value-genjin-user {
              height: 100%;
              width: 70%;
              font-size: 11px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
            .dialog-content-line-value-genjin-time {
              height: 100%;
              font-size: 11px;
              display: flex;
              align-items: center;
            }
            .dialog-content-line-value-genjin-content {
              height: 100%;
              font-size: 12px;
              color: rgb(158, 158, 158);
            }
          }
        }
        .dialog-content-line-value-genjin1 {
          width: 100%;
          height: auto;
          min-height: 120px;
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid @boderColor;
          .dialog-content-line-value-genjin-line-input {
            width: 100%;
            height: auto;
            display: flex;
            margin-bottom: 10px;
            .title {
              width: 20%;
              height: auto;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              font-size: 12px;
            }
            .value {
              width: 0;
              height: 100%;
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
          }
        }
        .dialog-content-line-value-active {
          flex: 1;
        }
        .dialog-content-line-value-but {
          width: auto;
          min-width: 70px;
          height: 30px;
          padding: 0 5px;
          margin: 0 5px;
          box-sizing: border-box;
          border: 1px solid @activeColor;
          color: @activeColor;
          font-size: 12px;
          border-radius: 3px;
          transition: 0.3s;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            cursor: pointer;
            background-color: @activeColor;
            color: #fff;
          }
        }
        .dialog-content-line-value-img {
          margin: 0 2px;
        }
        .dialog-content-line-value-doubleInput {
          display: flex;
          align-items: baseline;
          .doubleInput-title {
            width: 57.5%;
            font-size: 14px;
            margin-right: 5px;
            min-height: 30px;
            text-align: right;
          }
          .doubleInput-value {
            width: 79.5%;
          }
        }
        .dialog-content-line-value-address {
          display: flex;
          width: 100%;
          height: auto;
          .address-import-left {
            width: 50%;
            padding: 0 10px;
            border-right: 1px solid #d1d3d8;
            .address-import-input-line {
              display: flex;
              font-size: 14px;
              /deep/ .avatar-uploader {
                width: 100%;
                height: 36px;
                display: flex;
                flex-direction: row-reverse;
                align-items: flex-end;
                justify-content: flex-end;
                margin-bottom: 10px;
                .el-upload-list {
                  width: 200px;
                }
              }
              .address-import-input-line-button {
                border: 1px solid @baseColor;
                border-radius: 3px;
                width: 60px;
                line-height: 25px;
                height: 25px;
                color: @baseColor;
                margin: 0 20px;
              }
            }
            .address-import-line {
              display: flex;
              flex-direction: column;
              text-align: left;
              font-size: 14px;
              line-height: 1.7;
              .link {
                color: @baseColor;
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
          .address-import-right {
            width: 50%;
            display: flex;
            flex-direction: column;
            font-size: 14px;
            align-items: start;
            padding: 0 10px;
          }
        }
        .dialog-content-line-value-chargeMoney {
          font-size: 14px;
          padding: 0 5px 10px 5px;
          word-break: break-all;
          .dialog-content-line-value-chargeMoney-content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .dialog-content-line-value-chargeMoney-item {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 116px;
              height: 60px;
              background: #fff;
              border: 1px solid #c4c6cc;
              border-radius: 6px;
              cursor: pointer;
              font-size: 18px;
              font-weight: 600;
              margin-top: 20px;
              color: #333;
            }
            .active {
              background-color: #f0f6f9;
              border-color: @baseColor;
              color: @baseColor;
            }
          }
          .dialog-content-line-value-chargeMoney-input {
            display: flex;
            align-items: center;

            margin-top: 10px;
            border-bottom: 1px solid #999;
            padding: 5px 5px;

            /deep/ .el-input__inner {
              border: 0 !important;
              font-size: 15px;
            }
            &:hover {
              border-bottom: 1px solid @baseColor;
            }
          }
          .dialog-content-line-value-chargeMoney-info {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin-top: 20px;
          }
        }
        .dialog-content-line-value-accountQrCode {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .accountQrCode {
            margin: 15px;
          }
          .accountQrCode-text {
            margin-bottom: 15px;
            font-size: 14px;
          }
        }
        .expressage_invoice {
          width: 100%;
          display: flex;
          flex-direction: column;
          .expressage_invoice-line {
            display: flex;
          }
        }
        .social-person {
          flex-direction: column;
          width: 100%;
          .search-line {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 10px 0;
          }
        }
        .contract_expend_content {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .contract_expend_item {
            width: 50%;
            margin: 12px 0;
            display: flex;
            align-items: baseline;
            justify-content: right;

            &:nth-child(n + 9) {
              align-items: flex-start;
            }
            .contract_expend_item_title {
              font-size: 14px;
              margin-right: 8px;
              width: 30%;
              // min-width: 90px;
              // width: 90px;
              text-align: right;
            }
            .contract_expend_item_value {
              width: 70%;
              display: flex;
              > .icons {
                width: 0;
                height: auto;
                flex: 1;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                > .icon {
                  width: 80px;
                  height: 120px;
                  border: 1px solid #cecece;
                  box-sizing: border-box;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 13px;
                  color: #cecece;
                  position: relative;
                  transition: 0.3s;
                  padding: 2px;
                  margin: 2px;
                  &:hover {
                    cursor: pointer;
                    color: #2873ff;
                  }
                  > i {
                    margin-right: 5px;
                  }
                  > .dele {
                    width: 100%;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding-right: 10px;
                    font-size: 14px;
                    color: #ff7919;
                    position: absolute;
                    top: 0;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
        > .icons {
          width: 0;
          height: auto;
          flex: 1;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          > .icon {
            width: 80px;
            height: 120px;
            border: 1px solid #cecece;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            color: #cecece;
            position: relative;
            transition: 0.3s;
            padding: 2px;
            margin: 2px;
            &:hover {
              cursor: pointer;
              color: #2873ff;
            }
            > i {
              margin-right: 5px;
            }
            > .dele {
              width: 100%;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding-right: 10px;
              font-size: 14px;
              color: #ff7919;
              position: absolute;
              top: 0;
              cursor: pointer;
            }
          }
        }

        .client-number {
          width: 100%;
          display: flex;
          .client-number-item {
            width: 25%;
            display: flex;
            align-items: center;
            .client-number-item-title {
              min-width: 80px;
              font-size: 14px;
            }
            .client-number-item-value {
            }
          }
        }
        .dialog-content-line-value-invoice-remark {
          width: 100%;
          min-height: 300px;
          display: flex;
          flex-direction: column;
          .dialog-content-line-value-invoice-remark-item {
            width: 90%;
            display: flex;
            align-items: center;
            margin: 10px 0;
            .el-icon-circle-plus-outline {
              font-size: 20px;
              cursor: pointer;
              margin-left: 10px;
              color: #409eff;
            }
          }
        }
        .dialog-content-line-value-number-setting {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          height: 500px;
          justify-content: center;
          align-content: flex-start;
          .dialog-content-line-value-number-setting-item {
            width: 95%;
            margin: 10px 0;

            .text {
              font-size: 14px;
            }
            .item {
              margin-bottom: 18px;
            }
            .box-card {
              width: 100%;
              height: 140px;
              .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
              .card-line {
                display: flex;
                align-items: center;

                margin-bottom: 20px;
                .card-line-title {
                  width: 30%;
                }
                .card-line-value {
                  width: 70%;
                }
              }
            }
            /deep/ .el-card__header {
              padding: 10px;
            }
            /deep/ .el-card__body {
              padding: 20px 10px;
              display: flex;
              font-size: 14px;
            }
            .active {
              background-color: #ecf5ff !important;
            }
          }
        }
        .dialog-content-line-value-table-select {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
      .dialog-content-line-value1 {
        flex-direction: column;
        > .tip {
          width: 100%;
          height: 50px;
          font-size: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.3s;
          &:hover {
            cursor: pointer;
            color: @tipColor;
            text-decoration: underline;
          }
        }
      }
      .heAuto {
        /deep/ .el-input__inner {
          height: auto;
        }
      }
      .invoice-drawer {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        .invoice-drawer-line {
          width: 100%;
          height: auto;

          flex-wrap: wrap;
          border-bottom: 1px solid @boderColor;
          padding: 5px 0;
          box-sizing: border-box;
          .invoice-drawer-line-value {
            width: 100%;
            height: auto;
            display: flex;
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-top: 3px;
            .invoice-drawer-line-value-tit {
              width: auto;
              min-width: 100px;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding-right: 5px;
              box-sizing: border-box;
            }
            .invoice-drawer-line-value-val {
              display: flex;
              flex-wrap: wrap;
              .el-tag {
                margin-right: 10px;
                margin-bottom: 5px;
              }
              .button-new-tag {
                height: 32px;
                line-height: 30px;
                padding-top: 0;
                padding-bottom: 0;
                margin-left: 0;
              }
              .input-new-tag {
                width: 90px;
                margin-left: 0;
                vertical-align: bottom;
              }
            }
          }
        }
      }
      .invoice-stock {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        /deep/ .el-tabs--left .el-tabs__nav-wrap.is-left {
          margin: 0 15px;
        }
        /deep/ .el-card__header {
          padding: 10px 3%;
          border-bottom: 1px solid #ebeef5;
          box-sizing: border-box;
        }
        /deep/ .el-card__body {
          padding: 0;
        }
        .text {
          font-size: 14px;
        }
        .clearfix:before,
        .clearfix:after {
          display: table;
          content: "";
        }
        .clearfix:after {
          clear: both;
        }
        .invoice-stock-top {
          display: flex;
          align-content: center;
          flex-wrap: wrap;
          font-size: 14px;
          margin-bottom: 10px;
        }
        .box {
          height: 500px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            display: none;
          }
          .box-card {
            width: 99%;
            margin-bottom: 20px;
            .box-card-title {
              float: right;
              line-height: 28px;
              font-size: 14px;
              margin-right: 10px;
            }
            .box-card-line {
              display: flex;
              flex-direction: column;
            }
          }
          .invoice-stock-line {
            width: 100%;
            height: auto;
            display: flex;
            margin-top: 3px;
            padding: 5px;
            box-sizing: border-box;
            .invoice-stock-line-title {
              width: 10%;
              min-width: 65px;
              height: 30px;
              display: flex;
              flex-direction: row-reverse;
              align-items: center;
              font-size: 14px;
            }
            .invoice-stock-line-value {
              width: 90%;
              height: 30px;
              display: flex;
              align-items: center;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .dialog-buts {
    width: 100%;
    height: 50px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .dialog-but {
      width: auto;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 12px;
      border-radius: 5px;
      padding: 0 15px;
      box-sizing: border-box;
      margin: 0 5px;
      border-radius: 5px;
    }
    .dialog-but:hover {
      cursor: pointer;
    }
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .flex-column {
    flex-direction: column;
  }
  .div_tip_active {
    border: 2px solid @activeColor !important;
    box-sizing: border-box;
    // background-color: aqua;
  }

  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
  .height_auto {
    height: auto !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 13px;
    line-height: 28px;
    color: rgb(0, 140, 255);
  }
  .tip_tip {
    margin-left: 5px;
  }
  .el-cascader {
    width: 100%;
  }
}
</style>
