<template>
  <div id="human-employee">
    <!-- 员工信息 -->
    <div class="human-employee-screen">
      <div class="human-employee-screen-line">
        <div class="human-employee-screen-search">
          <el-input
            v-model="searchData.title"
            placeholder="请输入姓名/工号"
            @keyup.enter.native="resetPage"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="resetPage"
            ></el-button>
          </el-input>
        </div>
        <div class="human-employee-screen-search">
          <el-cascader
            v-model="searchData.activeDepartment"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="cccc"
            clearable
            :show-all-levels="false"
            :collapse-tags="true"
            :props="{ multiple: true, emitPath: false, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="human-employee-screen-search">
          <el-select
            v-model="searchData.userId"
            placeholder="请选择绑定用户"
            @change="resetPage"
            filterable
            clearable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="human-employee-screen-search">
          <el-select
            v-model="searchData.activePost"
            placeholder="请选择员工岗位"
            @change="resetPage"
            filterable
            clearable
          >
            <el-option
              v-for="item in tagList.positionList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="human-employee-screen-active"></div>
        <el-button
          type="primary"
          size="small"
          @click="handleClick('', 0)"
          style="margin-right: 10px"
          v-showFunction="{
            active: 'add',
            array: allFunctionButtons,
          }"
          >新增员工</el-button
        >
      </div>
      <div class="human-employee-screen-line">
        <div class="human-employee-screen-search">
          <el-select
            v-model="searchData.subjectOfContract"
            placeholder="请选择合同主体"
            @change="resetPage"
            filterable
            clearable
          >
            <el-option
              v-for="item in tagList.subjectOfContractList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="human-employee-screen-search">
          <el-date-picker
            v-model="searchData.entryDate"
            type="daterange"
            range-separator="至"
            start-placeholder="入职时间"
            end-placeholder="入职时间"
            :value-format="'yyyy-MM-dd'"
            @change="resetPage"
          >
          </el-date-picker>
        </div>
        <div class="human-employee-screen-search">
          <el-checkbox
            v-model="searchData.contractExpirationDate"
            @change="initTableData"
            >合同三个月到期</el-checkbox
          >
        </div>
      </div>
      <div
        class="human-employee-screen-line"
        v-if="searchData.screenList.length > 0"
      >
        <div class="title">筛选条件:</div>
        <el-tag
          v-for="(tag, index) in searchData.screenList"
          :key="index"
          closable
          size="small"
          @close="closeScreen(index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>
    <div class="table-content">
      <div class="human-employee-table-card">
        <div class="human-employee-table-card-tabs">
          <div
            :class="{
              'human-employee-table-card-tab': true,
              'is-active': tabindex == '',
            }"
            @click="changeTab('')"
          >
            全部
          </div>
          <div
            :class="{
              'human-employee-table-card-tab': true,
              'is-active': tabindex == '在职',
            }"
            @click="changeTab('在职')"
          >
            在职
          </div>
          <div
            :class="{
              'human-employee-table-card-tab': true,
              'is-active': tabindex == '离职',
            }"
            @click="changeTab('离职')"
          >
            离职
          </div>
        </div>
        <div class="human-employee-content">
          <el-table
            :data="tableData"
            highlight-current-row
            :row-style="{ height: '60px' }"
            style="width: 100%"
            height="98%"
            v-loading="loading"
            @row-click="rowClick"
          >
            <el-table-column type="index" width="40" fixed> </el-table-column>
            <el-table-column
              prop="name"
              label="姓名"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="jobNumber"
              label="工号"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="userName"
              label="绑定用户"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="sex"
              label="性别"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="age"
              label="年龄"
              width="80"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="qualification"
              label="学历"
              width="80"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="status"
              label="状态"
              width="80"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="entryDate"
              label="入职时间"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="activeDepartmentName"
              label="部门"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="activePost"
              label="岗位"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="salary"
              label="薪资"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="phone"
              label="手机号"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="idCard"
              label="身份证号"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="socialSecurityPurchase"
              label="社保购买状态"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="subjectOfContract"
              label="合同主体"
              width="150"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="contractExpirationDate"
              label="合同到期时间"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="annex" label="附件" width="130">
              <template slot-scope="scope">
                <img
                  v-if="scope.row.annex"
                  v-viewer="{ movable: false }"
                  @click.stop
                  class="table-image"
                  :src="scope.row.annex"
                  alt=""
                  style="margin-right: 5px"
                />
              </template>
            </el-table-column>
            <el-table-column label="操作" width="300" fixed="right">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  plain
                  icon="el-icon-edit-outline"
                  v-showFunction="{
                    active: 'edit',
                    array: allFunctionButtons,
                  }"
                  @click.stop="handleClick(scope.row, 1)"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  plain
                  icon="el-icon-s-order"
                  v-if="scope.row.status == '在职'"
                  v-showFunction="{
                    active: 'dischargeProcedure',
                    array: allFunctionButtons,
                  }"
                  @click.stop="handleClick(scope.row, 2)"
                  >离职办理</el-button
                >
                <el-button
                  type="success"
                  size="mini"
                  plain
                  icon="el-icon-circle-check"
                  v-if="!scope.row.userName"
                  v-showFunction="{
                    active: 'bindUser',
                    array: allFunctionButtons,
                  }"
                  @click.stop="handleClick(scope.row, 3)"
                  >绑定用户</el-button
                >
                <el-button
                  type="warning"
                  size="mini"
                  plain
                  icon="el-icon-circle-close"
                  v-if="scope.row.userName"
                  v-showFunction="{
                    active: 'unbindUser',
                    array: allFunctionButtons,
                  }"
                  @click.stop="handleClick(scope.row, 4)"
                  >解绑用户</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="human-employee-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :dialogStyle="dialogData.dialogStyle"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @handleAvatarSuccess="handleAvatarSuccess"
        @fetchSuggestions="fetchSuggestions"
        @fetchSuggestionsSelect="fetchSuggestionsSelect"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
    <!-- 右边抽屉 -->
    <div id="my-drawer" v-if="humanDrawerData.flag">
      <my_drawer
        :drawerData="humanDrawerData"
        :allFunctionButtons="allFunctionButtons"
        :model="'humanEmployee'"
        @drawerOper="drawerOper"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
      ></my_drawer>
    </div>
  </div>
</template>

<script>
import * as expressageData from "@/views/expressage/js/expressage.js";
import my_dialog from "@/components/dialog.vue";
import my_drawer from "@/components/my-drawer.vue";
import popo_search from "@/components/popo-search.vue";
import * as humanData from "@/views/human/js/humanData.js";
import { parseGeoJSON } from "echarts";

export default {
  components: {
    popo_search,
    my_dialog,
    my_drawer,
  },
  data() {
    return {
      loading: false,
      baseInfo: {},
      tabindex: "",
      tableData: [],
      searchData: {
        status: "",
        userId: "",
        title: "",
        entryDate: [],
        activePost: "",
        subjectOfContract: "",
        contractExpirationDate: false,
        activeDepartment: [],
        tempUserId: [],
        userList: [],
        allUserList: [],
        screenList: [],
        departTreeData: [],
        allDepartTreeData: [],
      },
      popoData: {
        popoVisible: false,
        list: expressageData.expressageInfoSearchData(),
      },
      arrayList: {},
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "humanEmployee"
      ),
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      tagList: {
        positionList: [], // 应聘职位
        qualificationList: [], // 学历
        subjectOfContractList: [], // 合同主体
      },
      humanDrawerData: humanData.humanDrawerData(
        "员工信息",
        [],
        false,
        {},
        "员工信息"
      ),
      tempAllEmpolyeeList: [],
      tempData: {},
      whetherAgencyHead: false,
      allRoleList: [],
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "humanEmployee",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeListForDownBox();
      });
    },
    /**
     * 获取所有人员列表
     */
    getEmployeeListForDownBox() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.searchData.allUserList = data;
          this.getDepartmentTreeByUserId();
        }
      );
    },

    /**
     * 获取所有下属列表 包括自己
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getDepartmentTree();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取部门
     */
    getDepartmentTree() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTree",
        requestData,
        (data) => {
          this.searchData.allDepartTreeData = [data];
          this.getBusinessAuthorityList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有业务角色
     */
    getBusinessAuthorityList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/role/getBusinessAuthorityList",
        requestData,
        (data) => {
          this.allRoleList = [];
          data.forEach((element) => {
            let dd = {
              label: element.authorityName,
              value: element.authorityId,
            };
            this.allRoleList.push(dd);
          });
          this.getTagList();
          this.getEmployeeListByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeListByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = JSON.parse(JSON.stringify(data));
          this.tempAllEmpolyeeList = [];
          this.searchData.userList.forEach((element) => {
            this.tempAllEmpolyeeList.push(element.userId);
          });
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取客户片区
     */
    getTagList() {
      let requestData = {
        type: ["应聘职位", "学历", "合同主体"],
        companyId: this.baseInfo.company.companyId,
      };
      const tagMapping = {
        应聘职位: "positionList",
        学历: "qualificationList",
        合同主体: "subjectOfContractList",
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          const mapTagItems = (items) =>
            items.map((item) => ({
              label: item.name,
              value: item.value,
            }));

          data.forEach((element) => {
            const tagListKey = tagMapping[element.type];
            if (tagListKey) {
              this.tagList[tagListKey] = mapTagItems(element.value);
            }
          });
          console.log("tagList", this.tagList);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        title: this.searchData.title, //筛选项
        entryDate: this.searchData.entryDate, // 筛选项 日程时间
        status: this.tabindex, // 筛选项 状态
        activePost: this.searchData.activePost, // 筛选项 岗位
        userId: this.searchData.userId,
        activeDepartment: this.searchData.activeDepartment, // 筛选项 部门
        subjectOfContract: this.searchData.subjectOfContract, // 筛选项 合同主体
        contractExpirationDate: this.searchData.contractExpirationDate, // 筛选项 合同是哪个月到期
        pageSize: this.pageData.pageSize, // 每页条数
        currentPage: this.pageData.currentPage, // 当前页数
      };
      this.$request(
        "post",
        "/employee/page",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    changeTab(flag) {
      this.tabindex = flag;
      this.resetPage();
    },
    /**
     * 界面操作
     */
    handleClick(data, flag) {
      this.tempData = data;
      let requestData = {};
      let options = [];
      switch (flag) {
        case 0:
          this.dialogData.width = "800px";
          this.dialogData.title = "添加员工信息";
          this.dialogData.dialogStyle = {
            flexDirection: "row",
            flexWrap: "wrap",
          };
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_add",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              width: "45%",
              type: "autocomplete",
              title: "姓名",
              key: "name",
              required: true,
              value: "",
            },
            {
              width: "45%",
              type: "input",
              title: "工号",
              key: "jobNumber",
              value: "",
            },
            {
              width: "45%",
              type: "radio",
              title: "性别",
              key: "sex",
              required: true,
              value: "男",
              options: [
                {
                  label: "男",
                  value: "男",
                },
                {
                  label: "女",
                  value: "女",
                },
              ],
            },
            {
              width: "45%",
              type: "input-number2",
              title: "年龄",
              key: "age",
              required: true,
              value: "",
            },
            {
              width: "45%",
              type: "select",
              title: "学历",
              key: "qualification",
              required: true,
              value: "",
              options: this.tagList.qualificationList,
            },
            {
              width: "45%",
              type: "time_date",
              title: "出生日期",
              key: "birthday",
              required: true,
              value: "",
            },
            {
              width: "45%",
              type: "time_date",
              title: "入职日期",
              key: "entryDate",
              required: true,
              value: this.$formatTime(new Date(), "YYYY-MM-DD"),
            },
            {
              width: "45%",
              type: "cascader-tree",
              title: "部门",
              key: "activeDepartment",
              required: true,
              value: "",
              options: this.searchData.allDepartTreeData,
              props: { value: "id", emitPath: false, checkStrictly: true },
            },
            {
              width: "45%",
              type: "select",
              title: "岗位",
              key: "activePost",
              required: true,
              value: "",
              options: this.tagList.positionList,
            },
            {
              width: "45%",
              type: "input-number2",
              title: "薪资",
              key: "salary",
              required: true,
              value: "",
            },
            {
              width: "45%",
              type: "input-number2",
              title: "手机号",
              key: "phone",
              required: true,
              value: "",
            },
            {
              width: "45%",
              type: "input-number2",
              title: "身份证号",
              key: "idCard",
              required: true,
              value: "",
            },
            {
              width: "45%",
              type: "input-number2",
              title: "银行卡号",
              key: "bankCard",
              required: true,
              value: "",
            },
            {
              width: "45%",
              type: "textarea",
              title: "户口所在地",
              key: "domicileL",
              value: "",
              rows: 1,
            },
            {
              width: "45%",
              type: "textarea",
              title: "现居地址",
              key: "address",
              value: "",
              rows: 1,
            },
            {
              width: "45%",
              type: "radio",
              title: "社保购买状态",
              key: "socialSecurityPurchase",
              required: true,
              value: "有效",
              options: [
                {
                  label: "有效",
                  value: "有效",
                },
                {
                  label: "无效",
                  value: "无效",
                },
              ],
            },
            {
              width: "45%",
              type: "select",
              title: "合同主体",
              key: "subjectOfContract",
              value: "",
              options: this.tagList.subjectOfContractList,
            },
            {
              width: "45%",
              type: "time_date",
              title: "合同到期时间",
              key: "contractExpirationDate",
              value: "",
            },
            {
              width: "45%",
              type: "input-number2",
              title: "紧急联系人1",
              key: "firstEmergencyContact",
              value: "",
            },
            {
              width: "45%",
              type: "input-number2",
              title: "紧急联系人2",
              key: "secondEmergencyContact",
              value: "",
            },
            {
              width: "45%",
              title: "上传附件",
              key: "annex",
              type: "upload_img_png",
              value: {
                value: "",
                action: this.$fileBaseurl + "/uploadImageByMultipartFile",
                headers: {
                  token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                  systemName: "finance",
                  title: "员工信息附件",
                },
              },
            },
          ];
          this.dialogData.flag = true;
          break;
        case 1:
          this.dialogData.width = "800px";
          this.dialogData.title = "编辑员工信息";
          this.dialogData.dialogStyle = {
            flexDirection: "row",
            flexWrap: "wrap",
          };
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_edit",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              width: "45%",
              type: "autocomplete",
              title: "姓名",
              key: "name",
              required: true,
              value: this.tempData.name,
            },
            {
              width: "45%",
              type: "input",
              title: "工号",
              key: "jobNumber",
              value: this.tempData.jobNumber,
            },
            {
              width: "45%",
              type: "radio",
              title: "性别",
              key: "sex",
              required: true,
              value: "男",
              options: [
                {
                  label: "男",
                  value: "男",
                },
                {
                  label: "女",
                  value: "女",
                },
              ],
            },
            {
              width: "45%",
              type: "input-number2",
              title: "年龄",
              key: "age",
              required: true,
              value: this.tempData.age.toString(),
            },
            {
              width: "45%",
              type: "select",
              title: "学历",
              key: "qualification",
              required: true,
              value: this.tempData.qualification,
              options: this.tagList.qualificationList,
            },
            {
              width: "45%",
              type: "time_date",
              title: "出生日期",
              key: "birthday",
              required: true,
              value: this.tempData.birthday,
            },
            {
              width: "45%",
              type: "time_date",
              title: "入职日期",
              key: "entryDate",
              required: true,
              value: this.tempData.entryDate,
            },
            {
              width: "45%",
              type: "cascader-tree",
              title: "部门",
              key: "activeDepartment",
              required: true,
              value: this.tempData.activeDepartment,
              options: this.searchData.allDepartTreeData,
              props: { value: "id", emitPath: false, checkStrictly: true },
            },
            {
              width: "45%",
              type: "select",
              title: "岗位",
              key: "activePost",
              required: true,
              value: this.tempData.activePost,
              options: this.tagList.positionList,
            },
            {
              width: "45%",
              type: "input-number2",
              title: "薪资",
              key: "salary",
              required: true,
              value: this.tempData.salary.toString(),
            },
            {
              width: "45%",
              type: "input-number2",
              title: "手机号",
              key: "phone",
              required: true,
              value: this.tempData.phone,
            },
            {
              width: "45%",
              type: "input",
              title: "身份证号",
              key: "idCard",
              required: true,
              value: this.tempData.idCard,
            },
            {
              width: "45%",
              type: "input-number2",
              title: "银行卡号",
              key: "bankCard",
              required: true,
              value: this.tempData.bankCard,
            },
            {
              width: "45%",
              type: "textarea",
              title: "户口所在地",
              key: "domicileL",
              value: this.tempData.domicileL,
              rows: 1,
            },
            {
              width: "45%",
              type: "textarea",
              title: "现居地址",
              key: "address",
              value: this.tempData.address,
              rows: 1,
            },
            {
              width: "45%",
              type: "radio",
              title: "社保购买状态",
              key: "socialSecurityPurchase",
              required: true,
              value: this.tempData.socialSecurityPurchase,
              options: [
                {
                  label: "有效",
                  value: "有效",
                },
                {
                  label: "无效",
                  value: "无效",
                },
              ],
            },
            {
              width: "45%",
              type: "select",
              title: "合同主体",
              key: "subjectOfContract",
              value: this.tempData.subjectOfContract,
              options: this.tagList.subjectOfContractList,
            },
            {
              width: "45%",
              type: "time_date",
              title: "合同到期时间",
              key: "contractExpirationDate",
              value: this.tempData.contractExpirationDate,
            },
            {
              width: "45%",
              type: "input",
              title: "紧急联系人1",
              key: "firstEmergencyContact",
              value: this.tempData.firstEmergencyContact,
            },
            {
              width: "45%",
              type: "input",
              title: "紧急联系人2",
              key: "secondEmergencyContact",
              value: this.tempData.secondEmergencyContact,
            },
            {
              width: "45%",
              title: "上传附件",
              key: "annex",
              type: "upload_img_png",
              value: {
                value: this.tempData.annex,
                action: this.$fileBaseurl + "/uploadImageByMultipartFile",
                headers: {
                  token: "95931b371eb64f4a9c862983ca8a5d15",
                },
                uploadParameter: {
                  systemName: "finance",
                  title: "员工信息附件",
                },
              },
            },
          ];
          this.dialogData.flag = true;
          break;
        case 2:
          this.$confirm("确认要将该员工状态改为离职吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                id: this.tempData.id,
                status: "离职",
              };
              this.$request(
                "post",
                "/employee/update",
                requestData,
                (data) => {
                  this.$message.success("离职办理成功!");
                  this.initTableData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case 3:
          this.dialogData.width = "500px";
          this.dialogData.title = "绑定用户";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_bind",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          options = this.searchData.allUserList.map((item) => {
            return {
              label: item.userName,
              value: item.userId,
            };
          });
          this.dialogData.data = [
            {
              type: "select",
              title: "选择绑定用户",
              key: "userId",
              value: "",
              options,
            },
          ];
          this.dialogData.flag = true;
          break;
        case 4:
          this.$confirm("确认要为该员工解除绑定关系吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                id: this.tempData.id,
                userId: "",
              };
              this.$request(
                "post",
                "/employee/update",
                requestData,
                (data) => {
                  this.$message.success("解除绑定关系成功!");
                  this.initTableData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        default:
          break;
      }
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      let flag = false;
      let flag1 = true;
      switch (active) {
        case "sure_add":
          this.dialogData.data.forEach((item) => {
            requestData[item.key] = item.value;
            if (!requestData[item.key] && item.required) flag = true;
          });
          requestData.annex = this.dialogData.data[20].value.value; // 附件
          if (flag) return this.$message.error("请将信息填写完整");
          if (!this.$phoneCheck.mobilePhoneNumberCheck(requestData.phone)) {
            flag1 = false;
          }
          if (!flag1) {
            return this.$message.error(
              "手机号格式不正确,请确认填入正确的手机号!"
            );
          }
          this.$request(
            "post",
            "/employee/add",
            requestData,
            (data) => {
              this.$message.success("添加成功!");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_edit":
          this.dialogData.data.forEach((item) => {
            requestData[item.key] = item.value;
            if (!requestData[item.key] && item.required) flag = true;
          });
          requestData.id = this.tempData.id; // id
          requestData.annex = this.dialogData.data[20].value.value; // 附件
          if (flag) return this.$message.error("请将信息填写完整");
          if (!this.$phoneCheck.mobilePhoneNumberCheck(requestData.phone)) {
            flag1 = false;
          }
          if (!flag1) {
            return this.$message.error(
              "手机号格式不正确,请确认填入正确的手机号!"
            );
          }
          this.$request(
            "post",
            "/employee/update",
            requestData,
            (data) => {
              this.$message.success("编辑成功!");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_bind":
          requestData = {
            id: this.tempData.id, // id
            userId: this.dialogData.data[0].value, // 绑定用户
          };
          this.$request(
            "post",
            "/employee/update",
            requestData,
            (data) => {
              this.$message.success("绑定用户成功!");
              this.dialogData.flag = false;
              this.initTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    /**
     * 表格单行点击事件
     */
    rowClick(row) {
      if (!this.popoData.popoVisible) {
        this.tempData = row;
        this.$featureData.getAttributeData(
          "humanEmployee",
          false,
          humanData.returnDrawerKeys("humanEmployee", "员工信息"),
          (authorityData) => {
            this.drawerAuthorityData = authorityData;
            // 2.拿对应属性的权限
            this.$authority.getModelFeatureAuthority(
              this,
              "humanEmployee",
              authorityData,
              (authorityList) => {
                // 3.将权限数据对应到界面
                this.humanDrawerData = humanData.humanDrawerData(
                  this.tempData.name,
                  authorityList,
                  this.whetherAgencyHead, // 是否是机构负责人
                  {},
                  "员工信息"
                );
                this.humanDrawerData = this.$initDrawerAuthorityData.initDrawerData(
                  this.humanDrawerData,
                  this.tempData
                );
                this.humanDrawerData.flag = true;
              }
            );
          }
        );

        // 3.将权限数据对应到界面
      } else {
        this.popoCanael();
      }
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.humanDrawerData.flag = false;
    },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
      this.searchData.screenList = [];
    },
    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      // console.log("所有数组数据:", this.arrayList);
      this.popoData.list[1].options = []; // 下单员工
      this.popoData.list[1].options = this.searchData.allUserList.map(
        (item, index) => {
          return { label: item.userName, value: item.userId };
        }
      );
      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选弹出框确认事件
     */
    popoMakeSure() {
      this.searchData.screenList = this.$systemBaseData.remakesureSearchData(
        this.popoData.list
      );
      this.popoData.popoVisible = false;
      this.initTableData();
    },
    /**
     * 关闭筛选标签事件
     */
    closeScreen(index) {
      this.searchData.screenList.splice(index, 1);
      this.initTableData();
    },
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.tempAllEmpolyeeList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.resetPage();
    },
    resetPage() {
      this.pageData.currentPage = 1;
      this.initTableData();
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    handleAvatarSuccess(data) {
      this.$message.success("上传成功");
      if (data.result.title == "员工信息附件") {
        this.dialogData.data[20].value.value = data.result.path;
      }
    },
    fetchSuggestions(queryString, cb) {
      console.log("queryString", queryString);
      this.$request(
        "get",
        "/talentResume/listByName?name=" + queryString,
        {},
        (data) => {
          if (data.length > 0) {
            let result = data.map((item) => {
              return {
                label: item.name,
                value: item.name,
                departmentApplied: item.departmentApplied,
                positionApplied: item.positionApplied,
                phone: item.phone,
              };
            });
            cb(result);
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    fetchSuggestionsSelect(val) {
      this.dialogData.data.forEach((item) => {
        switch (item.key) {
          case "name":
            item.value = val.value;
            break;
          case "activeDepartment":
            item.value = val.departmentApplied;
            break;
          case "activePost":
            item.value = val.positionApplied;
            break;
          case "phone":
            item.value = val.phone;
            break;
          default:
            break;
        }
      });
    },
    cccc(val) {
      console.log("val", val);
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#human-employee {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .human-employee-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .human-employee-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .human-employee-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .human-employee-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .human-employee-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
      .human-employee-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        &:hover {
          background-color: #abbed3;
        }
      }
      .human-employee-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        &:hover {
          cursor: pointer;
          background-color: @activeColor2;
        }
      }

      .human-employee-screen-but-boder {
        width: 50px;
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .human-employee-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }

  .table-content {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .human-employee-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .human-employee-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .human-employee-table-card-tab {
          width: 130px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .human-employee-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .human-employee-table-card-active {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: @contentFontSize;
          .textArea {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .numArea {
            height: 100%;
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: @activeColor;
          }
        }
        .human-employee-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .human-employee-content {
        width: 100%;
        height: 95%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        .tableHighLight {
          color: @activeColor;
          cursor: pointer;
        }
        .table-image {
          width: 40px;
          height: 40px;
        }
      }
      .human-employee-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
