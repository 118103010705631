<template>
  <div id="invoice-dialog">
    <!-- 填写销项发票的dialog -->
    <div
      class="invoice-line remark"
      v-if="value.clientInvoiceRemark.length > 0"
    >
      <div style="margin-right: 10px">发票备注:</div>
      <div v-for="(it, ind) in value.clientInvoiceRemark" :key="it.id">
        <span style="margin-right: 10px">{{ ind + 1 }}、{{ it.remark }}</span>
      </div>
    </div>
    <div class="invoice-line">
      <el-radio-group ref="radio" v-model="value.type" @change="changeTitle">
        <el-radio :label="'82'">数电普票</el-radio>
        <el-radio :label="'81'">数电专票</el-radio>
        <el-radio :label="'86'">数电纸普</el-radio>
        <el-radio :label="'85'">数电纸专</el-radio>
      </el-radio-group>
      <div style="flex: 1"></div>
      <div class="invoice-line-text">
        <el-checkbox
          style="margin-left: 30px"
          size="small"
          :true-label="1"
          :false-label="0"
          v-model="value.isUrgent"
          >加急</el-checkbox
        >
      </div>
    </div>
    <div class="invoice-line">
      <div class="invoice-line-affair">
        <div>特定业务:</div>
        <div>
          <el-select
            v-model="value.specialTicketCode"
            @change="changeSpecialTicketCode"
          >
            <el-option
              v-for="(item, index) in affairList"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="invoice-line-affair">
        <div>征收方式:</div>
        <div>
          <el-select v-model="value.collect" @change="changeCollect">
            <el-option
              v-for="(item, index) in collectList"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div
        class="invoice-line-affair"
        v-if="value.type == '85' || value.type == '86'"
      >
        <div>选择票类:</div>
        <div>
          <el-select v-model="value.paperTicketCode">
            <el-option
              v-for="(item, index) in value.paperTicketCodeEcho"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div style="flex: 1"></div>
      <div class="invoice-line-text">
        <el-switch
          v-model="value.includeTaxFlag"
          :disabled="value.includeTaxDisable"
          @change="changeTaxs"
          :active-value="1"
          :inactive-value="0"
          active-text="含税"
        >
        </el-switch>
      </div>
      <!-- <div class="invoice-line-text">授信额度:{{ value.LimitAmount }}</div> -->
    </div>
    <div class="invoice-line">
      <div class="invoice-line-left">
        <div>购买方</div>
      </div>
      <div class="invoice-line-middle">
        <div class="invoice-line-middle-item">
          <div class="invoice-line-middle-item-it">
            <div class="invoice-line-middle-item-span">
              <div class="invoice-required">*</div>
              <div class="invoice-active"></div>
              名称:
            </div>
            <div class="invoice-line-middle-item-input">
              <el-autocomplete
                class="inline-input"
                v-model="value.buyerName"
                :fetch-suggestions="querySearch"
                placeholder="请输入个人或企业名称"
                :trigger-on-focus="false"
                @select="handleSelect"
                value-key="name"
              >
                <el-button slot="append" :loading="flag1" @click="searchTex"
                  >赋税号</el-button
                >
              </el-autocomplete>
              <!-- </el-input> -->
            </div>
          </div>
          <div class="invoice-line-middle-item-it">
            <div class="invoice-line-middle-item-span">
              <div class="invoice-required">*</div>
              <div class="invoice-active"></div>
              纳税人识别号:
            </div>
            <div class="invoice-line-middle-item-input">
              <el-input
                v-model="value.buyerTaxpayerNum"
                placeholder="请输入纳税人识别号"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="invoice-line-middle-item">
          <div class="invoice-line-middle-item-it">
            <div class="invoice-line-middle-item-span">
              地址:
            </div>
            <div class="invoice-line-middle-item-input">
              <el-input
                v-model="value.buyerAddress"
                placeholder="请输入地址"
              ></el-input>
            </div>
          </div>
          <div class="invoice-line-middle-item-it">
            <div class="invoice-line-middle-item-span">
              电话:
            </div>
            <div class="invoice-line-middle-item-input">
              <el-input
                v-model="value.buyerTel"
                placeholder="请输入电话"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="invoice-line-middle-item">
          <div class="invoice-line-middle-item-span">
            <!-- <div class="invoice-required">*</div>gh -->
            开户:
          </div>
          <div class="invoice-line-middle-item-input">
            <el-input
              v-model="value.buyerBankName"
              placeholder="请输入开户行"
            ></el-input>
          </div>
          <div class="invoice-line-middle-item-span">
            账号:
          </div>
          <div class="invoice-line-middle-item-input">
            <el-input
              v-model="value.buyerBankAccount"
              placeholder="请输入账号"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="invoice-line" style="padding: 0">
      <div class="invoice-table">
        <div
          class="invoice-table-line"
          style="position: sticky; top: 0; left: 0; background-color: #FFFFFF; z-index: 999;"
        >
          <div class="invoice-table-line-cell">
            <div class="invoice-required">*</div>
            货物或应税劳务、服务名称
          </div>
          <div class="invoice-table-line-cell">规格型号</div>
          <div class="invoice-table-line-cell">单位</div>
          <div class="invoice-table-line-cell">数量</div>
          <div class="invoice-table-line-cell">单价</div>
          <div class="invoice-table-line-cell">
            <div class="invoice-required">*</div>
            金额
          </div>
          <div class="invoice-table-line-cell">
            <div class="invoice-required">*</div>
            税率
          </div>
          <div class="invoice-table-line-cell">
            <div class="invoice-required">*</div>
            税额
          </div>
          <div class="invoice-table-line-cell">
            操作
            <i class="el-icon-circle-plus-outline" @click="add"></i>
          </div>
        </div>
        <div
          class="invoice-table-line"
          v-for="(item, index) in value.itemList"
          :key="index"
        >
          <div class="invoice-table-line-cell">
            <el-autocomplete
              v-if="item.flag"
              v-model="item.goodsName"
              :fetch-suggestions="querySearch2"
              placeholder="请输入货物或应税劳务、服务名称"
              :trigger-on-focus="false"
              @select="handleSelect2($event, index)"
              value-key="name"
            >
              <el-button
                class="invoice-table-line-cell-button"
                slot="append"
                size="mini"
                @click="searchCode(item, index)"
                >赋码</el-button
              >
            </el-autocomplete>
            <span v-else @click="item.flag = !item.flag"
              >*{{ item.goodsType }}*{{ item.goodsName }}</span
            >
          </div>
          <div class="invoice-table-line-cell">
            <el-input
              :disabled="item.disabled"
              placeholder="请输入规格型号"
              v-model="item.specificationModel"
            >
            </el-input>
          </div>
          <div class="invoice-table-line-cell">
            <el-input
              :disabled="item.disabled"
              placeholder="请输入单位"
              v-model="item.meteringUnit"
            >
            </el-input>
          </div>
          <div class="invoice-table-line-cell">
            <el-input
              :disabled="item.disabled"
              placeholder="请输入数量"
              v-model="item.quantity"
              @blur="changeAmount(item)"
            >
            </el-input>
          </div>
          <div class="invoice-table-line-cell">
            <el-input
              :disabled="item.disabled"
              placeholder="请输入单价"
              v-model="item.unitPrice"
              @blur="changeAmount(item)"
            >
            </el-input>
          </div>
          <div class="invoice-table-line-cell">
            <el-input
              :disabled="item.disabled"
              ref="invoiceAmount"
              placeholder="请输入金额"
              v-model="item.invoiceAmount"
              @focus="focusInvoiceAmount"
              @blur="changeTax(item)"
            >
            </el-input>
          </div>
          <div class="invoice-table-line-cell">
            <el-select
              :disabled="item.disabled"
              ref="select"
              v-model="item.taxRateValue"
              filterable
              placeholder="请选择"
              @change="changeTax(item)"
            >
              <el-option
                v-for="it in taxRateValueList"
                :key="it.value"
                :label="it.label"
                :value="it.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="invoice-table-line-cell">
            <el-input
              placeholder="请输入税额"
              v-model="item.taxRateAmount"
              disabled
            >
            </el-input>
          </div>
          <div class="invoice-table-line-cell">
            <i class="el-icon-help" @click="settingGoods(item, index)"></i>
            <i class="el-icon-remove-outline" @click="deleteItem(index)"></i>
          </div>
        </div>
        <div class="invoice-table-line" v-if="value.discountFlag">
          <div class="invoice-table-line-cell leftal" style="color: red">
            合计折扣:
          </div>
          <div class="invoice-table-line-cell" style="border: none"></div>
          <div class="invoice-table-line-cell" style="border: none"></div>
          <div class="invoice-table-line-cell" style="border: none"></div>
          <div class="invoice-table-line-cell" style="border: none"></div>
          <div class="invoice-table-line-cell leftal" style="color: red">
            ¥{{ value.allDiscountMoney == "" ? 0 : value.allDiscountMoney }}
          </div>
          <div class="invoice-table-line-cell"></div>
          <div class="invoice-table-line-cell leftal" style="color: red">
            ¥{{ value.allDiscountTax == "" ? 0 : value.allDiscountTax }}
          </div>
          <div class="invoice-table-line-cell" style="border: none"></div>
        </div>
        <div class="invoice-table-line">
          <div class="invoice-table-line-cell leftal">合计:</div>
          <div class="invoice-table-line-cell" style="border: none"></div>
          <div class="invoice-table-line-cell" style="border: none"></div>
          <div class="invoice-table-line-cell" style="border: none"></div>
          <div class="invoice-table-line-cell" style="border: none"></div>
          <div class="invoice-table-line-cell leftal">
            ¥{{ value.allMoney == "" ? 0 : value.allMoney }}
          </div>
          <div class="invoice-table-line-cell"></div>
          <div class="invoice-table-line-cell leftal">
            ¥{{ value.allTax == "" ? 0 : value.allTax }}
          </div>
          <div class="invoice-table-line-cell" style="border: none"></div>
        </div>
        <div class="invoice-table-line">
          <div class="invoice-table-line-cell leftal">
            价税合计(大写):{{ value.totalAmountText }}
          </div>
          <div class="invoice-table-line-cell leftal">
            小写:{{ value.totalAmount }}
          </div>
        </div>
      </div>
    </div>
    <div class="invoice-line" v-if="value.type == '026' || value.type == '028'">
      <div class="invoice-line-left">信息</div>
      <div class="invoice-line-middle">
        <div class="invoice-line-middle-item">
          <div class="invoice-line-middle-item-span">
            <!-- <div class="invoice-required">*</div> -->
            收票人手机:
          </div>
          <div class="invoice-line-middle-item-input">
            <el-input
              v-model="value.takerTel"
              placeholder="请输入收票人手机"
            ></el-input>
          </div>
        </div>
        <div class="invoice-line-middle-item">
          <div class="invoice-line-middle-item-span">
            <!-- <div class="invoice-required">*</div> -->
            收票人邮箱:
          </div>
          <div class="invoice-line-middle-item-input">
            <el-input
              v-model="value.takerEmail"
              placeholder="请输入收票人邮箱"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="invoice-line">
      <div class="invoice-line-left">销售方</div>
      <div class="invoice-line-middle">
        <div class="invoice-line-middle-item">
          <div class="invoice-line-middle-item-it">
            <div class="invoice-line-middle-item-span">
              <div class="invoice-required">*</div>
              <div class="invoice-active"></div>
              名称:
            </div>
            <div class="invoice-line-middle-item-input">
              {{ value.sellerName }}
            </div>
          </div>
          <div class="invoice-line-middle-item-it">
            <div class="invoice-line-middle-item-span">
              <div class="invoice-required">*</div>
              <div class="invoice-active"></div>
              纳税人识别号:
            </div>
            <div class="invoice-line-middle-item-input">
              {{ value.sellerTaxpayerNum }}
            </div>
          </div>
        </div>
        <div class="invoice-line-middle-item">
          <div class="invoice-line-middle-item-it">
            <div class="invoice-line-middle-item-span">
              地址、电话:
            </div>
            <div class="invoice-line-middle-item-input">
              {{ value.sellerAddress }},{{ value.sellerTel }}
            </div>
          </div>
        </div>
        <div class="invoice-line-middle-item">
          <div class="invoice-line-middle-item-it">
            <div class="invoice-line-middle-item-span">
              开户行及账号:
            </div>
            <div class="invoice-line-middle-item-input">
              {{ value.sellerBankName }},{{ value.sellerBankAccount }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="invoice-line-right">
        展示销方方银行账号信息
      </div> -->
    </div>
    <div
      class="invoice-line"
      v-if="
        value.specialFieldEcho &&
          Object.keys(value.specialFieldEcho).length !== 0
      "
    >
      <div class="invoice-line-special-info" style="padding: 10px 0">
        <div class="invoice-line-special-info-title">
          特定信息-{{ value.specialFieldEcho.title }}
        </div>
        <div class="invoice-line-special-info-value">
          <div
            class="invoice-line-special-info-value-item"
            v-for="(item, index) in value.specialFieldEcho.list"
            :style="{ width: item.type == 'table' ? '100%' : 'auto' }"
            :key="index"
          >
            <div class="title" v-if="item.title">
              <span class="is_require" v-if="item.is_require">*</span
              >{{ item.title }}:
            </div>
            <div class="value" v-if="item.type == 'input'">
              <el-input
                v-model="item.value"
                placeholder="请输入"
                @focus="showInput(item)"
              ></el-input>
            </div>
            <div class="value" v-else-if="item.type == 'select'">
              <el-select
                v-model="item.value"
                placeholder="请选择"
                @change="changeInput(item)"
              >
                <el-option
                  v-for="(item, index) in item.options"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="value" v-else-if="item.type == 'area'">
              <el-input
                placeholder="请选择地区"
                v-model="item.value"
                :disabled="true"
              >
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-location-information"
                  @click="showArea(item)"
                ></i>
              </el-input>
            </div>
            <div class="value" v-else-if="item.type == 'times'">
              <el-date-picker
                v-model="item.value"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
            <div
              class="value"
              v-else-if="item.type == 'table'"
              style="width: 100%"
            >
              <el-table
                :data="item.value"
                style="width: 100%"
                :header-cell-style="{
                  color: '#a15f3b',
                  'font-weight': '400',
                  'font-size': '12px',
                }"
              >
                <template v-for="(it, ind) in item.tableData">
                  <el-table-column
                    :key="ind"
                    :prop="it.prop"
                    :label="it.label"
                    :width="it.width"
                    show-overflow-tooltip
                  >
                    <template slot-scope="scope">
                      <el-select
                        v-if="it.type == 'select'"
                        v-model="scope.row[it.prop]"
                      >
                        <el-option
                          v-for="(ele, i) in it.options"
                          :key="i"
                          :label="ele.label"
                          :value="ele.value"
                        ></el-option>
                      </el-select>
                      <el-input
                        v-else-if="it.type == 'input'"
                        v-model="scope.row[it.prop]"
                        :disabled="it.disabled"
                      >
                        <i
                          v-if="it.icon"
                          slot="suffix"
                          :class="{ 'el-input__icon': true, [it.icon]: true }"
                          @click="showAreaTable(scope.row, it.prop)"
                        ></i>
                      </el-input>
                      <el-date-picker
                        v-else-if="it.type == 'time'"
                        v-model="scope.row[it.prop]"
                        type="date"
                        placeholder="选择日期"
                        :clearable="false"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>
                      <template v-else-if="it.type == 'button'">
                        <i
                          v-for="(btn, i) in it.button"
                          :key="i"
                          :class="{ 'el-input__icon': true, [btn.icon]: true }"
                          @click="buttonClick(item, btn, scope.$index)"
                        ></i>
                      </template>
                    </template>
                  </el-table-column>
                </template>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="invoice-line">
      <div class="invoice-line-left">备注</div>
      <div class="invoice-line-middle" style="border: none">
        <el-input
          type="textarea"
          v-model="value.remark"
          placeholder="请输入备注"
          :rows="2"
        ></el-input>
      </div>
    </div>
    <div class="invoice-line">
      <span style="margin-left: 40px"
        ><el-checkbox
          v-model="value.showBuyerBankFlag"
          true-label="Y"
          false-label="N"
          >展示购方银行账号</el-checkbox
        ></span
      >
      <span style="margin-left: 40px"
        ><el-checkbox
          v-model="value.showBuyerAddressFlag"
          true-label="Y"
          false-label="N"
          >展示购方地址电话</el-checkbox
        ></span
      >
      <span style="margin-left: 40px"
        ><el-checkbox
          v-model="value.showSellerBankFlag"
          true-label="Y"
          false-label="N"
          >展示销方银行账号</el-checkbox
        ></span
      >
      <span style="margin-left: 40px"
        ><el-checkbox
          v-model="value.showSellerAddressFlag"
          true-label="Y"
          false-label="N"
          >展示销方地址电话</el-checkbox
        ></span
      >
    </div>
    <div class="invoice-line space-between">
      <div class="line-cell">
        <span>收款人:</span>

        <el-autocomplete
          v-model="value.casherName"
          :fetch-suggestions="
            (querySearch, cb) => {
              querySearch3(querySearch, cb, 1);
            }
          "
          placeholder="请输入内容"
          @select="select($event, 1)"
          value-key="name"
        ></el-autocomplete>
      </div>
      <div class="line-cell">
        <span>复核人:</span>

        <el-autocomplete
          v-model="value.reviewerName"
          :fetch-suggestions="
            (querySearch, cb) => {
              querySearch3(querySearch, cb, 2);
            }
          "
          placeholder="请输入内容"
          @select="select($event, 2)"
        ></el-autocomplete>
      </div>
      <div class="line-cell">
        <span>开票员:</span>

        <el-autocomplete
          v-model="value.drawerName"
          :fetch-suggestions="
            (querySearch, cb) => {
              querySearch3(querySearch, cb, 3);
            }
          "
          placeholder="请输入内容"
          @select="select($event, 3)"
        ></el-autocomplete>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialog.flag">
      <my_dialog
        :width="dialog.width"
        :title="dialog.title"
        :buttonArray="dialog.buttonArray"
        :data="dialog.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @addCode="addCode"
      ></my_dialog>
      <!-- :allFunctionButtons="allFunctionButtons" -->
    </div>
    <el-dialog
      title="设置折扣行"
      :visible.sync="dialogData.flag"
      width="30%"
      :before-close="handleClose"
      center
      :modal="false"
    >
      <div class="invoice-dialog-content">
        <div class="invoice-dialog-content-line">
          <div class="invoice-dialog-content-line-title">原商品名称:</div>
          <div class="invoice-dialog-content-line-value">
            {{
              value.itemList[tempIndex]
                ? value.itemList[tempIndex].goodsName
                : ""
            }}
          </div>
        </div>
        <div class="invoice-dialog-content-line">
          <div class="invoice-dialog-content-line-title">原商品金额:</div>
          <div class="invoice-dialog-content-line-value">
            {{
              value.itemList[tempIndex]
                ? value.itemList[tempIndex].invoiceAmount
                : ""
            }}
          </div>
        </div>
        <div class="invoice-dialog-content-line">
          <div class="invoice-dialog-content-line-title">原商品税率:</div>
          <div class="invoice-dialog-content-line-value">
            {{
              value.itemList[tempIndex]
                ? value.itemList[tempIndex].taxRateValue
                : ""
            }}
          </div>
        </div>
        <div class="invoice-dialog-content-line">
          <div class="invoice-dialog-content-line-title">折扣率:</div>
          <div class="invoice-dialog-content-line-value">
            <el-input
              style="width: 200px"
              v-model="input"
              placeholder="请输入内容"
              @blur="changeDiscountAmount"
              @keyup.enter.native="changeDiscountAmount"
            >
              <span slot="suffix">%</span>
            </el-input>
          </div>
        </div>
        <div class="invoice-dialog-content-line">
          <div class="invoice-dialog-content-line-title">折扣金额:</div>
          <div class="invoice-dialog-content-line-value">
            <!-- {{ dialogData.discountAmount }} 元 -->
            <el-input
              style="width: 200px"
              v-model="dialogData.discountAmount"
              placeholder="请输入内容"
              @blur="changeDiscountRate"
              @keyup.enter.native="changeDiscountRate"
            >
              <span slot="suffix">元</span>
            </el-input>
          </div>
        </div>
        <div class="invoice-dialog-content-line">
          <div class="invoice-dialog-content-line-title">折扣税额:</div>
          <div class="invoice-dialog-content-line-value">
            {{ dialogData.discountTaxRateAmount }} 元
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="makeSure">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="货物编号选择"
      :visible.sync="goodsDialog.flag"
      width="1250px"
      :before-close="handleClose"
      :modal="false"
      top="10vh"
    >
      <div class="goods-dialog-content">
        <commondity
          :commondityData="goodsDialog.data"
          @checkCode="checkCode"
        ></commondity>
      </div>
    </el-dialog>
    <!-- 添加差额凭证 -->
    <el-dialog
      title="差额录入"
      class="balance-dialog"
      :visible.sync="balanceDialog.flag"
      width="25%"
      append-to-body
    >
      <el-form
        label-width="110px"
        :model="value.collectFieldEcho"
        :rules="rules"
        ref="ruleForm"
        :validate-on-rule-change="false"
      >
        <el-form-item label="凭证类型" prop="pzlx">
          <el-select
            v-model="value.collectFieldEcho.pzlx"
            placeholder="请选择"
            @change="changeBalanceDocument"
          >
            <el-option
              v-for="item in value.balanceDocumentList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="含税销售额" prop="hsxsje">
          <el-input
            v-model="value.collectFieldEcho.hsxsje"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="数电票号码" prop="fphm">
          <el-input
            v-model="value.collectFieldEcho.fphm"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="开具日期" prop="kjrq">
          <el-date-picker
            v-model="value.collectFieldEcho.kjrq"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="凭证合计金额" prop="pzhjje">
          <el-input
            v-model="value.collectFieldEcho.pzhjje"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="本次扣除金额" prop="bckcje">
          <el-input
            v-model="value.collectFieldEcho.bckcje"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="bz">
          <el-input
            v-model="value.collectFieldEcho.bz"
            type="textarea"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="balanceDialog.flag = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="addBalanceDocument"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pca, pcaa } from "area-data";
import commondity from "./commodity.vue";
import * as invoiceData from "../assets/js/invoiceData";
import my_dialog from "@/components/dialog.vue";
export default {
  components: {
    commondity,
    my_dialog: () => import("@/components/dialog.vue"),
  },
  props: ["invoiceDialog"],
  data() {
    return {
      pcaa: pcaa,
      value: this.invoiceDialog,
      taxRateValueList: [],
      dialogData: {
        flag: false,
        discountAmount: 0,
        discountTaxRateAmount: 0,
      },
      dialog: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      balanceDialog: {
        flag: false,
      },
      rules: {},
      tempValue: {},
      tempIndex: 0,
      input: "",
      allCustomers: [], // 常用客户列表
      allCustomers2: [], // 智能赋码列表
      allCustomers3: [], // 开票人员列表
      tempGoods: {},
      goodsDialog: {
        flag: false,
        data: {
          treeData: [],
          tableData: [],
          name: "",
        },
      },
      curGoodsName: "",
      flag: false,
      tempData: {},
      prop: "",
      affairList: invoiceData.invoiceAffair(),
      collectList: invoiceData.invoiceCollect(),
      flag1: false,
    };
  },
  watch: {
    invoiceDialog: {
      handler(newVal) {
        //父组件值变化->>子组件也变化
        this.value = this.invoiceDialog;
      },
      immediate: true,
      deep: true,
    },
    value: {
      handler(newVal) {
        this.$emit("show", newVal);
      },
      immediate: true,
      deep: true,
    },
    // invoiceDialog() {
    //   //父组件值变化->>子组件也变化
    //   this.value = this.invoiceDialog;
    // },
    // value(newVal) {
    //   // this.value = newVal;
    //   console.log("侦听数据", newVal);
    //   //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
    //   this.$emit("show", newVal);
    // },
  },
  methods: {
    /**
     * @param {Object} item 表格行数据
     * @param {Object} btn 表格按钮配置信息
     * @param {Number} index 表格行索引
     */
    buttonClick(item, btn, index) {
      console.log("12312", item);
      switch (btn.active) {
        case "add":
          let addObject = {};
          item.value.forEach((item, index) => {
            let keys = Object.keys(item);
            for (let index = 0; index < keys.length; index++) {
              if (!item[keys[index]]) {
                this.$message.warning("请完善上一行信息!");
                throw new Error("请完善上一行信息！");
              }
            }
          });
          item.tableData.forEach((item) => {
            if (item.prop) {
              addObject[item.prop] = "";
            }
          });
          console.log("addObject", addObject);
          item.value.splice(index + 1, 0, addObject);
          break;
        case "delete":
          if (item.value.length <= 1) {
            this.$message.warning("至少保留一条数据");
            return;
          }
          item.value.splice(index, 1);
          break;
        default:
          break;
      }
    },
    /**
     * 通过客户ID,获取客户对应的常用客户
     */
    getAllCustomersByClientId() {
      let requestData = {
        clientId: this.value.clientId,
      };

      this.$request(
        "post",
        "/activeCustomer/queryAllCustomers",
        requestData,
        (data) => {
          this.allCustomers = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 模糊匹配
     */
    querySearch(queryString, cb) {
      var restaurants = this.allCustomers; // 输入框匹配信息
      var results = queryString // 输入框的值
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) !==
          -1
        );
      };
    },
    /**
     * 智能赋码
     */
    querySearch2(queryString, cb) {
      let requestData = {
        name: queryString,
      };
      let results = "";
      this.$request(
        "post",
        "/invoiceInfo/intelligentCoding",
        requestData,
        (data) => {
          this.allCustomers2 = data;
          this.curGoodsName = queryString;
          // 调用 callback 返回建议列表的数据
          cb(this.allCustomers2);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    handleSelect(item) {
      this.value.buyerTaxpayerNum = item.taxNum;
      this.value.buyerAddress = item.address;
      this.value.buyerTel = item.tel;
      this.value.buyerBankName = item.bank;
      this.value.buyerBankAccount = item.bankAccount;
    },
    handleSelect2(item, index) {
      this.value.itemList[index].goodsType = item.name;
      this.value.itemList[index].goodsName = this.curGoodsName;
      this.value.itemList[index].taxClassificationCode = item.value;
      this.value.itemList[index].flag = false;
    },
    /**
     * 开票人员模糊匹配
     */
    querySearch3(queryString, cb, index) {
      let requestData = {
        clientId: this.value.clientId, // 客户ID
      };
      this.$request(
        "post",
        "/client/getDrawerList",
        requestData,
        (data) => {
          switch (index) {
            case 1:
              this.allCustomers3 = data.casherList; // 收款人
              break;
            case 2:
              this.allCustomers3 = data.reviewerList; // 复核人
              break;
            case 3:
              this.allCustomers3 = data.drawerList; // 开票员
              break;
            default:
              break;
          }
          let restaurants = this.allCustomers3.map((item) => {
            return {
              name: item,
              value: item,
            };
          });
          var results = queryString
            ? restaurants.filter(this.createFilter(queryString))
            : restaurants;

          // 调用 callback 返回建议列表的数据
          cb(results);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 查询开票员
     */
    queryInvoiceClerk() {
      let requestData = {
        clientId: this.value.clientId, // 客户ID
      };
      this.$request(
        "post",
        "/client/getDrawerList",
        requestData,
        (data) => {
          if (this.value.casherName == "") {
            this.value.casherName = data.casherList[0]; // 收款人
          }
          if (this.value.reviewerName == "") {
            this.value.reviewerName = data.reviewerList[0]; // 复核人
          }
          if (this.value.drawerName == "") {
            this.value.drawerName = data.drawerList[0]; // 开票员
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 确定开票人员
     */
    select(item, active) {
      switch (active) {
        case 1:
          this.value.casherName = item.name;
          break;
        case 2:
          this.value.reviewerName = item.name;
          break;
        case 3:
          this.value.drawerName = item.name;
          break;
        default:
          break;
      }
    },
    /**
     * 根据企业名称,获取企业税号等信息
     */
    searchTex() {
      this.flag1 = true;
      this.$request(
        "get",
        "/qicc/search?keyword=" + this.value.buyerName,
        {},
        (data) => {
          this.value.buyerName = data.name;
          this.value.buyerTaxpayerNum = data.taxNum;
          this.value.buyerAddress = data.address;
          this.value.buyerBankName = "";
          this.value.buyerBankAccount = "";
          this.value.buyerTel = "";
          this.flag1 = false;
        },
        (errorMessage) => {
          this.flag1 = false;
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 修改发票类型,同步修改发票标题, 发票限额
     */
    changeTitle(e) {
      this.value.title = invoiceData.changeTitle(e); // 发票标题
      let data = invoiceData.paperTicketCodeEcho(e);
      this.value.paperTicketCode = data.code; // 全电纸质发票票种代码
      this.value.paperTicketCodeEcho = data.list; // 全电纸质发票票种代码列表
      console.log("this.value", this.value);
    },
    /**
     * 修改特定业务
     */
    changeSpecialTicketCode(e) {
      this.value.specialFieldEcho = invoiceData.changeSpecialFieldEcho(
        this.value.specialTicketCode
      );
    },
    changeInput(item) {
      switch (this.value.specialTicketCode) {
        case "03":
          // 建筑服务
          let flag = this.value.specialFieldEcho.list.some(
            (item) => item.key == "kdsbz" && item.value == "Y"
          );
          if (!flag) {
            this.value.specialFieldEcho.list.forEach((item) => {
              if (item.key == "kqysssxbgglbm") item.value = "";
            });
          }
          break;
        default:
          break;
      }
    },
    showInput(item) {
      switch (this.value.specialTicketCode) {
        case "03":
          // 建筑服务
          let flag = this.value.specialFieldEcho.list.some(
            (item) => item.key == "kdsbz" && item.value == "Y"
          );
          if (flag && item.click) {
            this.dialog.title = "跨区域涉税事项报验管理编号信息";
            this.dialog.width = "1300px";
            this.dialog.buttonArray = [];
            this.dialog.data = [
              {
                type: "taxIssueCode",
                value: "",
                data: this.value,
              },
            ];
            this.dialog.flag = true;
          }
          break;
        default:
          break;
      }
    },
    /**
     * 修改征收方式
     */
    changeCollect(e) {
      switch (e) {
        case "0":
          // 普通征税
          this.value.includeTaxFlag = 1;
          this.value.includeTaxDisable = false;
          break;
        case "2":
          // 差额征税-全额开票
          this.value.includeTaxFlag = 0;
          this.value.includeTaxDisable = true;
          break;
        case "3":
          // 差额征税-差额开票
          this.value.includeTaxFlag = 0;
          this.value.includeTaxDisable = true;
          break;
        default:
          break;
      }
      this.changeTaxs();
    },
    /**
     * 添加差额凭证
     */
    focusInvoiceAmount() {
      if (this.value.collect == "2" || this.value.collect == "3") {
        this.balanceDialog.flag = true;
      }
    },
    /**
     * 切换差额凭证类型
     */
    changeBalanceDocument(e) {
      this.rules = {};
      this.value.balanceDocumentList.forEach((item) => {
        if (item.value == e) {
          this.rules = item;
        }
      });
    },
    /**
     * 确认添加差额凭证
     */
    addBalanceDocument() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.value.collectField = [];
          this.value.collectField.push(this.value.collectFieldEcho);
          this.balanceDialog.flag = false;
        } else {
          return false;
        }
      });
    },
    /**
     * 根据数量和单价,计算金额
     * data 每项数据
     */
    changeAmount(data) {
      this.$cutOutNum.cutOutNum(
        parseFloat(data.quantity) * parseFloat(data.unitPrice),
        this,
        (num) => {
          data.invoiceAmount = num;
          this.changeTax(data);
        }
      );
    },
    /**
     * 根据金额,税率,计算税额
     * 含税情况下:
     *      税额 = 含税金额/(1+税率)*税率
     * 不含税情况下:
     *      税额 = 不含税金额 * 税率
     */
    changeTax(data) {
      // 改变商品行税率标识
      let result = invoiceData.changeTaxType(data.taxRateValue);
      data.preferentialPolicyFlag = result.preferentialPolicyFlag;
      data.zeroTaxFlag = result.zeroTaxFlag;
      data.vatSpecialManage = result.vatSpecialManage;
      let nn = 0;
      // 保证金额不变的条件下,改变税额
      if (this.value.includeTaxFlag) {
        nn =
          (parseFloat(data.invoiceAmount) /
            (1 + parseFloat(data.taxRateValue))) *
          parseFloat(data.taxRateValue);
      } else {
        nn = parseFloat(data.invoiceAmount) * parseFloat(data.taxRateValue);
      }
      if (data.quantity && data.unitPrice == "") {
        data.unitPrice = data.invoiceAmount / data.quantity;
      }
      if (data.unitPrice && data.quantity == "") {
        data.quantity = data.invoiceAmount / data.unitPrice;
      }
      // if (data.unitPrice && data.quantity) {
      //   data.unitPrice = data.invoiceAmount / data.quantity;
      // }
      this.$cutOutNum.cutOutNum(nn, this, (num) => {
        data.taxRateAmount = num;
        this.sumAllMoney();
      });
    },
    /**
     * 是否含税改变事件
     */
    changeTaxs(e) {
      let flag = this.value.itemList.some(
        (item) => item.invoiceLineNature == "1" || item.invoiceLineNature == "2"
      );
      if (flag) {
        this.value.includeTaxFlag = e == 0 ? 1 : 0;
        this.$message.error("请先删除折扣商品再进行操作!");
        return;
      }
      this.value.itemList.forEach((element) => {
        this.changeAmountTax(element);
      });
      this.sumAllDiscountMoney();
    },
    /**
     * 固定税额,计算金额
     */
    changeAmountTax(data) {
      if (this.value.includeTaxFlag) {
        this.$cutOutNum.cutOutNum(
          data.quantity *
            (parseFloat(data.unitPrice) +
              parseFloat(data.unitPrice) * data.taxRateValue),
          this,
          (num) => {
            data.invoiceAmount = num;
          }
        ); // 含税金额 = 数量 * 含税单价

        data.unitPrice =
          parseFloat(data.unitPrice) +
          parseFloat(data.unitPrice) * parseFloat(data.taxRateValue);
      } else {
        data.unitPrice = data.unitPrice / (1 + +data.taxRateValue);
        this.$cutOutNum.cutOutNum(
          parseFloat(data.quantity) * parseFloat(data.unitPrice),
          this,
          (num) => {
            data.invoiceAmount = num;
          }
        ); // 不含税金额 = 数量 * 不含税单价
      }
    },
    /**
     * 计算价税合计总金额
     */
    sumAllMoney() {
      let money = 0;
      let tax = 0;
      this.value.itemList.forEach((element) => {
        money += parseFloat(element.invoiceAmount);
        tax += parseFloat(element.taxRateAmount);
      });
      this.$cutOutNum.cutOutNum(money, this, (num) => {
        this.value.allMoney = num; //合计金额
        this.$cutOutNum.cutOutNum(tax, this, (tax) => {
          this.value.allTax = tax; // 合计税额
          // 计算价税合计
          if (this.value.includeTaxFlag) {
            // 含税
            // 价税总额 = 总金额
            this.value.allMoney = (num - tax).toFixed(2); //合计金额
            this.value.totalAmount = num; // 价税合计
            this.value.totalAmountText = this.$money.required(
              this.value.totalAmount
            ); // 价税合计大写
          } else {
            // 不含税
            // 价税总额 = 总金额 + 总税额
            this.$cutOutNum.cutOutNum(
              parseFloat(this.value.allMoney) + parseFloat(this.value.allTax),
              this,
              (allNum) => {
                this.value.totalAmount = allNum;
                this.value.totalAmountText = this.$money.required(allNum);
              }
            );
          }
        });
      });
      this.sumAllDiscountMoney();
    },
    // 计算 折扣项 价税合计总金额
    sumAllDiscountMoney() {
      let discountMoney = 0;
      let discountTax = 0;
      // 计算 折扣行 总金额 及 总税额
      let discountList = this.value.itemList.some(
        (item) => item.invoiceLineNature == "1"
      );

      if (discountList) {
        this.value.discountFlag = true; // 显示合计折扣
        this.value.itemList.forEach((item) => {
          if (item.invoiceLineNature == "1") {
            discountMoney += parseFloat(item.invoiceAmount);
            discountTax += parseFloat(item.taxRateAmount);
          }
        });
        this.$cutOutNum.cutOutNum(discountMoney, this, (num) => {
          this.value.allDiscountMoney = num; //合计金额
          this.$cutOutNum.cutOutNum(discountTax, this, (tax) => {
            this.value.allDiscountTax = tax; // 合计税额
          });
        });
      } else {
        this.value.discountFlag = false; // 隐藏合计折扣
      }
    },
    /**
     * 添加货物
     */
    add() {
      if (this.value.collect == "2" || this.value.collect == "3") {
        this.$message.error("差额征税商品只允许为 1 条");
        return;
      }
      if (this.value.itemList.length > 0) {
        if (
          this.value.itemList[this.value.itemList.length - 1].goodsName == "" ||
          this.value.itemList[this.value.itemList.length - 1].invoiceAmount ==
            "" ||
          this.value.itemList[this.value.itemList.length - 1].taxRateValue ==
            "" ||
          this.value.itemList[this.value.itemList.length - 1].taxRateAmount ==
            ""
        ) {
          this.$message({
            type: "error",
            message: "请完善商品信息!",
            duration: 1000,
          });
          return;
        }
      }
      let dd = {
        disabled: false,
        flag: true,
        goodsType: "", // 项目类别
        goodsName: "", // 项目名称  等价于itemName
        taxClassificationCode: "", // 对应税收分类编码
        specificationModel: "", // 对应规格型号
        meteringUnit: "", // 单位
        quantity: "", // 数量
        unitPrice: "", // 单价
        invoiceAmount: "0.00", // 金额
        taxRateValue:
          this.value.sellerValueAddedTaxType == "小规模纳税人" ? 0.01 : 0.13, // 税率
        taxRateAmount: "0", // 税额 (税额 = 含税金额/(1+税率)*税率)
        deductionAmount: "", // 差额开票抵扣金额
        invoiceLineNature: "0", // 发票行性质 0 正常行 1 折扣行 2 被折扣行
        preferentialPolicyFlag: "1", // 优惠赠策标识 0 未使用 1 使用
        zeroTaxFlag: "0", // 零税率标识 0 代表正常税率 1 出口免税和其他免税优惠政策(免税）2 不征增值税（不征税）3 普通零税率 （0%）
        vatSpecialManage: "简易征收", // 增值税特殊管理 简易征收 稀土产品 免税 不征税 先征后退
      };
      console.log("dd", dd);
      this.value.itemList.push(dd);
    },
    /**
     * 移除货物
     */
    deleteItem(index) {
      this.$confirm("此操作将删除此条 商品行, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 如果是删除 折扣行 将 被折扣行 改为 可编辑
          if (this.value.itemList[index].invoiceLineNature == "1") {
            this.value.itemList[index - 1].disabled = false; // 将 被折扣行 改为 可编辑
            this.value.itemList[index - 1].invoiceLineNature = "0"; // 将 被折扣行 改为 正常行
          }
          if (this.value.itemList[index].invoiceLineNature == "2") {
            // 如果是删除 被折扣行
            this.$message.error("被折扣行不可删除, 请先删除折扣行!");
            return;
          }
          this.value.itemList.splice(index, 1);
          if (this.value.itemList.length == 0 && index == 0) {
            this.add();
          }
          this.sumAllMoney();
          this.$message.success("删除成功!");
        })
        .catch(() => {});
    },
    /**
     * 添加折扣行
     */
    settingGoods(data, index) {
      if (
        data.goodsName == "" ||
        data.invoiceAmount == "" ||
        data.taxRateValue == "" ||
        data.taxRateAmount == ""
      ) {
        this.$message.error("请完善商品信息!");
        return;
      }
      if (
        this.value.itemList[index].invoiceLineNature == "2" ||
        this.value.itemList[index].invoiceLineNature == "1"
      ) {
        return this.$message.error("已添加折扣行, 无法再次添加!");
      }
      this.tempValue = data;
      this.tempIndex = index;
      this.input = "";
      this.dialogData.discountAmount = 0;
      this.dialogData.discountTaxRateAmount = 0;
      this.dialogData.flag = true;
    },
    /**
     * 根据折扣率计算 折扣金额 折扣税额
     */
    changeDiscountAmount() {
      this.$cutOutNum.cutOutNum(
        parseFloat(this.input) *
          0.01 *
          this.value.itemList[this.tempIndex].invoiceAmount, // 原商品金额
        this,
        (num) => {
          this.dialogData.discountAmount = num; // 折扣金额
          let nn = 0;
          if (this.value.includeTaxFlag) {
            nn =
              (parseFloat(this.dialogData.discountAmount) /
                (1 +
                  parseFloat(
                    this.value.itemList[this.tempIndex].taxRateValue // 原商品税率
                  ))) *
              parseFloat(this.value.itemList[this.tempIndex].taxRateValue);
          } else {
            nn =
              parseFloat(this.dialogData.discountAmount) *
              parseFloat(this.value.itemList[this.tempIndex].taxRateValue);
          }
          this.$cutOutNum.cutOutNum(nn, this, (money) => {
            this.dialogData.discountTaxRateAmount = money;
          });
        }
      );
    },
    /**
     * 根据折扣金额计算折扣率 折扣税额
     */
    changeDiscountRate() {
      this.$cutOutNum.cutOutNum(
        (parseFloat(this.dialogData.discountAmount) /
          this.value.itemList[this.tempIndex].invoiceAmount) *
          100, // 原商品金额
        this,
        (num) => {
          this.input = num; // 折扣率
          let nn = 0;
          if (this.value.includeTaxFlag) {
            nn =
              (parseFloat(this.dialogData.discountAmount) /
                (1 +
                  parseFloat(
                    this.value.itemList[this.tempIndex].taxRateValue // 原商品税率
                  ))) *
              parseFloat(this.value.itemList[this.tempIndex].taxRateValue);
          } else {
            nn =
              parseFloat(this.dialogData.discountAmount) *
              parseFloat(this.value.itemList[this.tempIndex].taxRateValue);
          }
          this.$cutOutNum.cutOutNum(nn, this, (money) => {
            this.dialogData.discountTaxRateAmount = money;
          });
        }
      );
    },
    /**
     * 确定设置折扣行
     */
    makeSure() {
      let data = JSON.parse(
        JSON.stringify(this.value.itemList[this.tempIndex])
      );
      let dd = {
        disabled: true,
        flag: false,
        goodsType: data.goodsType, // 项目类别
        goodsName: data.goodsName, // 项目名称  等价于itemName
        taxClassificationCode: data.taxClassificationCode, // 对应税收分类编码
        specificationModel: "", // 对应规格型号
        meteringUnit: "", // 单位
        quantity: "", // 数量
        unitPrice: "", // 单价
        invoiceAmount: "0.00", // 金额
        taxRateValue: data.taxRateValue, // 税率
        taxRateAmount: "0", // 税额 (税额 = 含税金额/(1+税率)*税率)
        deductionAmount: "", // 差额开票抵扣金额
        invoiceLineNature: "1", // 发票行性质 0 正常行 1 折扣行 2 被折扣行
        preferentialPolicyFlag: "1", // 优惠赠策标识 0 未使用 1 使用
        zeroTaxFlag: "0", // 零税率标识 0 代表正常税率 1 出口免税和其他免税优惠政策(免税）2 不征增值税（不征税）3 普通零税率 （0%）
        vatSpecialManage: "简易征收", // 增值税特殊管理 简易征收 稀土产品 免税 不征税 先征后退
      };
      // 1. 禁用 被折扣行 输入框      设置 被折扣行 发票行性质
      this.value.itemList[this.tempIndex].disabled = true;
      this.value.itemList[this.tempIndex].invoiceLineNature = "2";

      // 3. 设置 折扣行 价格及税额
      dd.invoiceAmount = -this.dialogData.discountAmount; // 修改 折扣金额
      dd.taxRateAmount = -this.dialogData.discountTaxRateAmount; // 修改 折扣税额
      // 4. 添加折扣行
      console.log("dd", dd);
      this.value.itemList.splice(this.tempIndex + 1, 0, dd);
      this.sumAllMoney();
      this.dialogData.flag = false;
    },

    /**
     * 折扣行dialog关闭事件
     */
    handleClose() {
      this.dialogData.flag = false;
      this.goodsDialog.flag = false;
    },
    /**
     * 选择货物编号
     */
    changeCode(data, index) {
      this.tempGoods = data;
      // this.tempGoodsIndex = index;
      if (data.taxClassificationCode == "") {
        // 重新获取货物编号
        this.goodsDialog.data.name = data.goodsName;
        this.goodsDialog.flag = true;
      } else {
        data.flag = !data.flag;
      }
    },
    searchCode(data, index) {
      this.tempGoods = data;
      // this.tempGoodsIndex = index;
      this.goodsDialog.data.name = this.tempGoods.goodsName;
      this.goodsDialog.flag = true;
    },
    /**
     * 确认选择货物编号
     */
    checkCode(data) {
      this.tempGoods.goodsType = data.abb;
      this.tempGoods.taxClassificationCode = data.num;
      this.tempGoods.flag = !this.tempGoods.flag;
      this.goodsDialog.flag = false;
    },
    drawerOper(active) {
      let requestData = {};
      let locationArray = this.dialog.data[0].value
        .filter((item) => item)
        .join("-");
      switch (active) {
        case "confirm_location":
          // 确定选择地区
          this.tempData.value = locationArray;
          this.colseDialog();
          break;
        case "confirm_location_table":
          // 确定选择地区
          this.tempData[this.prop] = locationArray;
          this.colseDialog();
          break;
        case "cancel":
          // 取消
          this.dialog.flag = false;
          break;
        default:
          break;
      }
    },
    showArea(item) {
      this.tempData = item;
      this.dialog.width = "600px";
      this.dialog.title = "选择地区";
      this.dialog.buttonArray = [
        {
          text: "确定",
          active: "confirm_location",
        },
        {
          text: "取消",
          active: "cancel",
        },
      ];
      this.dialog.data = [
        {
          type: "diy-area-select",
          title: "请选择地区",
          value: [],
        },
      ];
      this.dialog.flag = true;
    },
    showAreaTable(row, prop) {
      this.tempData = row;
      this.prop = prop;
      this.dialog.width = "600px";
      this.dialog.title = "选择地区";
      this.dialog.buttonArray = [
        {
          text: "确定",
          active: "confirm_location_table",
        },
        {
          text: "取消",
          active: "cancel",
        },
      ];
      this.dialog.data = [
        {
          type: "diy-area-select",
          title: "请选择地区",
          value: [],
          types: "text",
          level: 2,
          placeholder: "请选择",
        },
      ];
      this.dialog.flag = true;
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialog.flag = false;
    },
    addCode(data) {
      for (const key in data) {
        if (data.hasOwnProperty.call(data, key)) {
          this.value.specialFieldEcho.list.forEach((item) => {
            if (item.key == key) {
              item.value = data[key];
            }
          });
        }
      }
      this.dialog.flag = false;
    },
  },
  created() {
    this.changeTax(this.value.itemList[0]);
  },
  mounted() {
    if (this.value.sellerValueAddedTaxType == "小规模纳税人") {
      this.taxRateValueList = this.$taxRateValueList.taxRateValueList1; // 小规模纳税人税率
    } else {
      this.taxRateValueList = this.$taxRateValueList.taxRateValueList2; // 一般纳税人税率
    }
    console.log("this.value", this.value);
    this.queryInvoiceClerk();
    this.getAllCustomersByClientId();
  },
};
</script>

<style lang="less" scoped>
#invoice-dialog {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 1px;
  }
  .invoice-line {
    width: 100%;
    height: 100%;
    min-height: 45px;
    margin: 2px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border: 1px solid @boderColor;
    font-size: 12px;
    padding: 0 8px;
    .invoice-line-affair {
      width: 280px;
      margin-right: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .invoice-line-left {
      width: 100px;
      height: 100%;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      box-sizing: border-box;
    }
    .invoice-line-text {
      font-size: 14px;
      margin-right: 100px;
      min-width: 100px;
    }
    .invoice-line-middle {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: center;
      border-left: 1px solid @boderColor;
      .invoice-line-middle-item {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 3px;
        border-bottom: 1px solid @boderColor;
        box-sizing: border-box;
        &:nth-last-child(1) {
          border: none;
        }
        .invoice-line-middle-item-it {
          display: flex;
          align-items: center;
          width: 50%;
        }
        .invoice-line-middle-item-span {
          min-width: 50px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-left: 10px;
        }
        .invoice-line-middle-item-input {
          flex: 1;
          width: 0;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0 3px;
          box-sizing: border-box;
          text-align: left;
        }
      }
    }
    .invoice-line-right {
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      box-sizing: border-box;
      border-left: 1px solid @boderColor;
    }

    .invoice-table {
      position: relative;
      width: 100%;
      height: auto;
      max-height: 200px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      padding: 0;
      &::-webkit-scrollbar {
        width: 1px;
      }
      .invoice-table-line {
        width: 100%;
        height: 40px;
        display: flex;
        border-bottom: 1px solid @boderColor;
        box-sizing: border-box;
        &:nth-last-child(1) {
          border: none;
        }
        .invoice-table-line-cell {
          width: 0;
          flex: 1;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid @boderColor;
          padding: 0 3px;
          box-sizing: border-box;
          &:nth-child(1) {
            border: none;
            flex: 3;
          }
          i {
            cursor: pointer;
            transition: 0.3s;
            margin: 0 3px;
            font-size: 16px;
            &:hover {
              color: @activeColor3;
            }
          }
          span {
            cursor: pointer;
          }
          .invoice-table-line-cell-button {
            background: #f0f9eb;
            color: #67c23a;
            &:hover {
              background-color: #67c23a;
              color: #fff;
            }
          }
        }
      }
    }
    .line-cell {
      flex: 1;
      width: 0;
      height: 100%;
      display: flex;
      align-items: center;
      span {
        margin-right: 5px;
        width: 70px;
      }
    }
    .invoice-line-special-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      color: #a15f3b;
      .invoice-line-special-info-title {
        font-size: 18px;
        font-weight: bold;
        text-align: left;
      }
      .invoice-line-special-info-value {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .invoice-line-special-info-value-item {
          display: flex;
          align-items: center;
          text-align: right;
          margin-top: 20px;
          .is_require {
            color: red;
            margin-right: 5px;
          }
          .title {
            width: 165px;
            margin-right: 12px;
          }
          .value {
            width: 200px;
          }
          .el-input__icon {
            font-size: 16px;
            color: #a15f3b;
            cursor: pointer;
          }
          /deep/ .area-select {
            box-sizing: border-box;
          }
          /deep/ .cascader-menu-option.selected {
            color: @activeColor;
          }
          /deep/ .area-selected-trigger {
            text-align: left;
          }
          /deep/ .area-select.large {
            width: 100%;
          }
        }
      }
    }
  }
  .remark {
    color: red;
  }
  .invoice-required {
    width: 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f00;
    font-size: 18px;
  }

  .space-between {
    justify-content: space-between;
  }
  .leftal {
    align-items: center;
    justify-content: flex-start !important;
  }
  .invoice-dialog-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
    .invoice-dialog-content-line {
      width: 100%;
      height: 50px;
      display: flex;
      font-size: 16px;
      .invoice-dialog-content-line-title {
        width: 120px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .invoice-dialog-content-line-value {
        flex: 1;
        height: 100%;
        width: 0;
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
      .invoice-dialog-content-line-span {
        width: 20px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .el-autocomplete {
    width: 100%;
  }
  .goods-dialog-content {
    width: 1200px;
    height: 600px;
    overflow-y: auto;
    display: flex;
    // align-items: center;
    // justify-content: flex-start;
    &::-webkit-scrollbar {
      width: 1px;
    }
  }
}
.balance-dialog {
  text-align: left;
  /deep/ .el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 200px);
    max-width: calc(100% - 30px);

    .el-dialog__header {
      border-radius: 3px;
      background-color: #f5f8fa;
      display: flex;
      padding: 0;
      padding-left: 10px;
      align-items: center;
      height: 45px;
      border-bottom: 1px solid #ced4db;
    }
    .el-dialog__body {
      padding: 20px 0 20px 10px;
      flex: 1;
      overflow: auto;
      .el-form {
        padding: 0 36px;
        .el-form-item__label {
          text-align-last: justify;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 100%;
        }
      }
    }
    .el-dialog__footer {
      padding: 0;
      padding-right: 1rem;
      background-color: #f5f8fa;
      border-radius: 0 0 5px 5px;
      border-top: 1px solid #ced4db;
      height: 45px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>
