import _ from "lodash";
export const waitDealLocation = {
    methods: {
        /**
     * 获取地址列表
     */
    waitDealLocation() {
        this.$request(
          "post",
          "/waitDealLocation/getTreeList",
          { flag: true },
          (data) => {
            data.forEach((item) => {
              item.children.forEach((ele) => {
                ele.children = "";
              });
            });
            this.list = data;
            this.isAdd = false;
          },
          (errormessage) => {
            this.$message.error(errormessage);
          }
        );
      }
    }
}

export const callNumber = {
  methods: {
      /**
   * 好销云呼叫
   */
      callNumber: _.throttle(function(phone) {
        let requestData = {
          userId: this.baseInfo.userId, // 操作人
          TelB: phone, // 被叫号码号码
        };
        console.log('requestData', requestData)
        let flag1 = this.$phoneCheck.mobilePhoneNumberCheck(requestData.TelB); // 校验
        if (!flag1) {
          this.$message.error("手机号格式不正确,请确认填入正确的手机号!");
          return;
        }
        this.$request(
          "post",
          "/haoXiaoYun/bind",
          requestData,
          (data2) => {
            this.$message.success("绑定成功, 请耐心等待呼叫!");
          },
          (errormessage2) => {
            this.$message.error(errormessage2);
          }
        );
      }, 2000)
  }
}
