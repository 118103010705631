import Vue from 'vue'
import * as phoneCheck from "../../utils/mobilePhoneNumberCheck.js"
/**
 * 获取模块
 */
export function getModelList() {
    let modelList = [{
            name: "商机",
            active: "thread",
            model: "/thread",
            flag: 1,
        }, {
            name: "合同",
            active: "contract",
            model: "/contract",
            flag: 1,
        }, {
            name: "客户",
            active: "client",
            model: "/client",
            flag: 1,
        }, 
        {
            name: "派工",
            active: "dispatch",
            model: "/dispatch",
            flag: 1,
        },
        {
            name: "发票",
            active: "invoice",
            model: "/invoice",
            flag: 1,
        }, 
        {
            name: "税务",
            active: "tax",
            model: "/tax",
            flag: 1,
        },
        {
            name: "产品",
            active: "product",
            model: "/product",
            flag: 1,
        }, 
        {
            name: "报销",
            active: "reimbursement",
            model: "/reimbursement",
            flag: 1,
        },
        {
            name: "报表",
            active: "report",
            model: "/report",
            flag: 1,
        },
        {
            name: "知识",
            active: "knowledge",
            model: "/knowledge",
            flag: 1,
        },
        {
            name: "档案",
            active: "archives",
            model: "/archives",
            flag: 1,
        },
        {
            name: "快递",
            active: "expressage",
            model: "/expressage",
            flag: 1,
        },  
        {
            name: "人资",
            active: "human",
            model: "/human",
            flag: 1,
        },
        {
            name: "日程",
            active: "task",
            model: "/task",
            flag: 1,
        },
    ]
    return modelList
}

/**
 * 标签配置文件
 */
export function getTagList(type, model) {
    let tagList = [{
        id: "",
        name: "客户片区",
        model: ["thread", "client"],
        type: "片区",
        value: []
    }, {
        id: "",
        name: "客户来源",
        model: ["thread", "client"],
        type: "客户来源",
        value: []
    }, {
        id: "",
        name: "跟进阶段",
        model: ["thread"],
        type: "跟进阶段",
        value: []
    }, {
        id: "",
        name: "增值税类型",
        model: ["thread"],
        type: "增值税类型",
        value: []
    }, {
        id: "",
        name: "代账情况",
        model: ["thread"],
        type: "代账情况",
        value: []
    },
    {
        id: "",
        name: "意向等级",
        model: ["thread"],
        type: "意向等级",
        value: []
    },
    {
        id: "",
        name: "共享商机类型",
        model: ["thread"],
        type: "共享商机类型",
        value: []
    },
    {
        id: "",
        name: "出访事由",
        model: ["thread"],
        type: "出访事由",
        value: []
    },
    {
        id: "",
        name: "所属行业",
        model: ["thread"],
        type: "所属行业",
        value: []
    },
    {
        id: "",
        name: "客户需求",
        model: ["thread"],
        type: "客户需求",
        value: []
    },
    {
        id: "",
        name: "成交产品",
        model: ["thread"],
        type: "成交产品",
        value: []
    },
    {
        id: "",
        name: "线索来源",
        model: ["thread"],
        type: "线索来源",
        value: []
    },
    {
        id: "",
        name: "客户标签",
        model: ["client"],
        type: "客户标签",
        value: []
    }, {
        id: "",
        name: "客户等级",
        model: ["client"],
        type: "客户等级",
        value: []
    }, {
        id: "",
        name: "流失原因",
        model: ["client"],
        type: "流失原因",
        value: []
    }, {
        id: "",
        name: "合约主体",
        model: ["client"],
        type: "合约主体",
        value: []
    },{
        id: "",
        name: "收费定额",
        model: ["client"],
        type: "收费定额",
        value: []
    },{
        id: "",
        name: "收付款账户",
        model: ["charge"], // 收费模块
        type: "收付款账户",
        value: []
    }, {
        id: "",
        name: "支出类型",
        model: ["charge"], // 收费模块
        type: "支出类型",
        value: []
    }, {
        id: "",
        name: "票据类型",
        model: ["invoice"], // 发票模块
        type: "票据类型",
        value: []
    }, {
        id: "",
        name: "知识库分类",
        model: ["knowledgeBase"], // 发票模块
        type: "知识库分类",
        value: []
    }, {
        id: "",
        name: "资质证书",
        model: ["human"], // 发票模块
        type: "资质证书类型",
        value: []
    }, {
        id: "",
        name: "报销类型",
        model: ["reimbursement"], // 报销模块
        type: "报销类型",
        value: []
    }, {
        id: "",
        name: "付款方式",
        model: ["reimbursement"], // 报销模块
        type: "付款方式",
        value: []
    }, {
        id: "",
        name: "资质类型",
        model: ["client"], // 客户模块
        type: "资质类型",
        value: []
    },{
        id: "",
        name: "联系人权限",
        model: ["client"], // 客户模块
        type: "联系人权限",
        value: []
    },{
        id: "",
        name: "应聘职位",
        model: ["human"], // 人资模块
        type: "应聘职位",
        value: []
    },{
        id: "",
        name: "简历来源",
        model: ["human"], // 人资模块
        type: "简历来源",
        value: []
    },{
        id: "",
        name: "入库原因",
        model: ["human"], // 人资模块
        type: "入库原因",
        value: []
    },{
        id: "",
        name: "在职状态",
        model: ["human"], // 人资模块
        type: "在职状态",
        value: []
    },{
        id: "",
        name: "学历",
        model: ["human"], // 人资模块
        type: "学历",
        value: []
    },
    {
        id: "",
        name: "合同主体",
        model: ["human"], // 人资模块
        type: "合同主体",
        value: []
    }]
    let dd = {}
    tagList.forEach(element => {
        if (element.type == type && element.model.indexOf(model) > -1) {
            dd = element
        }
    });
    return dd
}

/**
 * 格式化部分标签
 * typeArray长度一定比returnArray长度长
 * @param {*} returnArray  从数据库获取到的部分标签数组
 * @param {*} typeArray 所有该页面要显示的标签数组
 */
export function initTagListPart(returnArray, typeArray) {
    let array = []
    typeArray.forEach((type, i) => {
        returnArray.forEach(element => {
            if (element.type == type) {
                typeArray[i] = ""
            }
        });
    });
    typeArray.forEach(type => {
        if (type != "") {
            array.push(type)
        }
    });
    return array
}

/**
 * 格式化标签
 */
export function initTagList(tagList, optionValueList) {
    tagList.forEach(element => {
        let dd = {
            id: element.id,
            name: element.name,
            value: element.value,
            type: element.type,

        }
        optionValueList.push(dd)
    });
    return optionValueList
}

/**
 * 模块转下拉框的选项
 */
export function modelListToModelOptions() {
    let options = []
    let array = getModelList()
    let d1 = {
        name: "系统设置",
        active: "system"
    }
    array.push(d1)
    let d2 = {
        name: "角色设置",
        active: "businessRole"
    }
    array.push(d2)
    for (let index = 0; index < array.length; index++) {
        let element = array[index];
        let dd = {
            label: element.name,
            value: element.active
        }
        options.push(dd)
    }
    return options
}

/**
 * 获取表头数据
 */
export function getMenuData(model, pageModel, _this, deal) {
    let requestData = {
        userId: _this.baseInfo.userId,
        companyId: _this.baseInfo.company.companyId,
        model: model,
        pageModel: pageModel,
    };
    _this.$request(
        "post",
        "/getMenuData",
        requestData,
        (data) => {
            deal(data)
        }, (errorMessage) => {
            _this.loading = false;
            _this.$message.error(errorMessage);
        }
    );
}

/**
 * 判断系统权限
 */
export function judgeAuthority(pageModel, model, _this, deal) {
    let requestData = {
        userId: _this.baseInfo.userId,
        companyId: _this.baseInfo.company.companyId,
        model: model,
        pageModel: pageModel,
    };
    _this.$request(
        "post",
        "/judgeAuthority",
        requestData,
        (data) => {
            deal(data)
        }, (errorMessage) => {
            _this.loading = false;
            _this.$message.error(errorMessage);
        }
    );
}

/**
 * 筛选框重置
 */
export function popoReset(array) {
    array.forEach(element => {
        if (element.type == "tip") {
            if (element.hasSearch) {
                element.value = []
            } else {
                element.value.forEach((e) => {
                    e.is_active = false;
                });
            }
        } else {
            element.value = "";
        }
    });
    return array
}

/**
 * 保存表头数据
 */
export function saveTableMenuData(data, model, part, _this) {
    let requestData = {
        userId: _this.baseInfo.userId,
        model: model,
        part: part,
        menuData: data,
    };
    _this.$request(
        "post",
        "/saveMenuData",
        requestData,
        (data) => {},
        (errorMessage) => {
            _this.loading = false;
            _this.$message.error(errorMessage);
        }
    );
}

/**
 * 判断按钮权限
 */
export function judgeButtonAuthority(string, array) {
    let authority = 3
    array.forEach(element => {
        if (element.title == string) {
            authority = (element.isShow == true || element.isShow == "true") ?
                (element.isDisable == true || element.isDisable == "true") ?
                2 :
                1 :
                3;
        }
    });
    return JSON.parse(sessionStorage.getItem("userData")).userId == "super" ? 1 : authority
}

/**
 * 格式化确认筛选提交数据
 */
export function makesureSearchData(data) {
    console.log(data);
    let list = []
    data.forEach(element => {
        let dd = {}
        switch (element.type) {
            case "tip":
                // 数组类型
                dd = { 
                    name: element.title,
                    type: "array",
                    key: element.flag == "0" ? element.key : element.title,
                    value: [],
                }
                element.value.forEach(el => {
                    if (el.is_active) {
                        dd.value.push(el)
                    }
                });
                break;
            case "select":
                // 数值类型
                dd = {
                    name: element.title,
                    type: "value",
                    key: element.flag == "0" ? element.key : element.title,
                    value: element.value,
                }
                break;
            case "times":
                // 范围类型
                dd = {
                    name: element.title,
                    type: "range",
                    key: element.flag == "0" ? element.key : element.title,
                    value1: element.value[0] ? element.value[0] : "",
                    value2: element.value[1] ? element.value[1] : "",
                }
                break;
            case "role":
                // 业务角色
                dd = {
                    name: "业务角色",
                    type: "role",
                    key: element.flag == "0" ? element.key : element.title,
                    value: element.value,
                    role: element.searchRole == {} ? "" : element.searchRole.value,
                }
                break;

            default:
                break;
        }
        list.push(dd)
    });
    return list
}

/**
 * 格式化确认筛选提交数据
 */
export function remakesureSearchData(data) {
    console.log(data);
    let list = []
    data.forEach(element => {
        let dd = {}
        switch (element.type) {
            case "tip":
                // 数组类型
                dd = {
                    name: element.title,
                    type: "array",
                    key: element.flag == "0" ? element.key : element.title,
                    value: [],
                }
                element.value.forEach(el => {
                    if (el.is_active) {
                        dd.value.push(el)
                    }
                });
                if (dd.value.length > 0) {
                    list.push(dd)
                }
                break;
            case "select":
            case "input":
            case "radio":
                // 数值类型
                if (element.value != '') {
                    dd = {
                        name: element.title,
                        type: "value",
                        key: element.flag == "0" ? element.key : element.title,
                        value: element.value,
                    }
                    list.push(dd)
                }
                break;
            case "tree":
                if (element.value != "" && element.value.length > 0) {
                    dd = {
                        name: element.title,
                        type: "value",
                        key: element.flag == "0" ? element.key : element.title,
                        value: element.value[element.value.length - 1],
                    }
                    list.push(dd)
                }
                break
            case "cascader":
                if (element.value != "" && element.value.length > 0) {
                    dd = {
                        name: element.title,
                        type: "value",
                        key: element.flag == "0" ? element.key : element.title,
                        value: element.value,
                    }
                    list.push(dd)
                }
                break
            case "phone":
                // 数值类型
                if (element.value != '') {
                    dd = {
                        name: element.title,
                        type: "phone",
                        key: element.flag == "0" ? element.key : element.title,
                        value: element.value,
                    }
                    list.push(dd)
                }
                break;
            case "times":
                // 范围类型
                if (element.value.length == 2 && element.value[0] != "" && element.value[1] != "") {
                    dd = {
                        name: element.title,
                        type: "range",
                        key: element.flag == "0" ? element.key : element.title,
                        value1: element.value[0] ? element.value[0] : "",
                        value2: element.value[1] ? element.value[1] : "",
                    }
                    list.push(dd)
                }
                break;
            case "role":
                // 业务角色
                if (element.value != "") {
                    dd = {
                        name: "业务角色",
                        type: "role",
                        key: element.flag == "0" ? element.key : element.title,
                        value: element.value,
                        role: element.searchRole == {} ? "" : element.searchRole.value,
                    }
                    list.push(dd)
                }
                break;

            default:
                break;
        }
    });
    return list
}

/**
 * 获取任务派工类型
 */
export function getWorkOrderType() {
    let data = [{
            label: "代账任务",
            value: "代账任务"
        },
        {
            label: "指派会计",
            value: "指派会计"
        },
        {
            label: "其他周期任务",
            value: "其他周期任务"
        },
        {
            label: "分配服务负责人",
            value: "分配服务负责人"
        },
    ]
    return data
}

/**
 * 一 到 十二 月份
 */
export function getMonthList() {
    return [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
    ]
}

export function getMonthOptions() {
    return [
        {
            label: "一月",
            value: "january"
        },
        {
            label: "二月",
            value: "february"
        },
        {
            label: "三月",
            value: "march"
        },
        {
            label: "四月",
            value: "april"
        },
        {
            label: "五月",
            value: "may"
        },
        {
            label: "六月",
            value: "june"
        },
        {
            label: "七月",
            value: "july"
        },
        {
            label: "八月",
            value: "august"
        },
        {
            label: "九月",
            value: "september"
        },
        {
            label: "十月",
            value: "october"
        },
        {
            label: "十一月",
            value: "november"
        },
        {
            label: "十二月",
            value: "december"
        }
    ]
}