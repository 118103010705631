<template>
  <div id="exam">
    <div class="exam-title">{{ examInfo.title || "--" }}</div>
    <div class="exam-content">
      <div class="exam-content-left">
        <div class="left-line">
          <div class="left-line-icon"><i class="el-icon-notebook-2"></i></div>
          <div class="left-line-title">考试分类:</div>
          <div class="left-line-value">
            {{ examInfo.enterpriseCollegeCategoryName }}
          </div>
        </div>
        <div class="left-line">
          <div class="left-line-icon"><i class="el-icon-user"></i></div>
          <div class="left-line-title">考试姓名:</div>
          <div class="left-line-value">
            {{ examInfo.userName || baseInfo.userName }}
          </div>
        </div>
        <div class="left-line">
          <div class="left-line-icon"><i class="el-icon-time"></i></div>
          <div class="left-line-title">考试时间:</div>
          <div class="left-line-value">{{ examInfo.duration }}分钟</div>
        </div>
        <div class="left-line">
          <div class="left-line-icon"><i class="el-icon-edit-outline"></i></div>
          <div class="left-line-title">试卷总分:</div>
          <div class="left-line-value grade">{{ examInfo.totalScore }} 分</div>
        </div>
        <div class="left-line" v-if="examInfo.status == '2'">
          <div class="left-line-icon"><i class="el-icon-edit-outline"></i></div>
          <div class="left-line-title">考试成绩:</div>
          <div class="left-line-value grade">{{ examInfo.userScore }} 分</div>
        </div>
        <div class="left-line" v-if="examInfo.status == '1'">
          <div class="left-line-icon"><i class="el-icon-time"></i></div>
          <div class="left-line-title">剩余时间:</div>
          <div class="left-line-value">{{ formattedRemainingTime }}</div>
        </div>
        <div class="left-line" v-if="examInfo.status == '2'">
          <div class="left-line-icon"><i class="el-icon-time"></i></div>
          <div class="left-line-title">考试用时:</div>
          <div class="left-line-value">{{ examInfo.takeDuration }}</div>
        </div>
        <div class="left-line">
          <div class="number-list" v-if="examInfo.status == '1'">
            <div
              v-for="(item, index) in examInfo.examQuestions"
              :key="index"
              :class="{
                'number-item': true,
                wrong: item.selected.length == 0,
              }"
              @click="scrollToQuestion(index)"
            >
              <span>{{ index + 1 }}</span>
            </div>
          </div>
          <div class="number-list" v-if="examInfo.status == '2'">
            <div
              v-for="(item, index) in examInfo.examQuestions"
              :key="index"
              :class="{
                'number-item': true,
                wrong: item.answer != item.selected,
              }"
              @click="scrollToQuestion(index)"
            >
              <span>{{ index + 1 }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="exam-content-right">
        <div class="exam-list">
          <div
            class="exam-list-item"
            v-for="(item, index) in examInfo.examQuestions"
            :key="index"
          >
            <div class="exam-list-item-title">
              {{ index + 1 }}、
              <span class="title-type"
                >【{{ item.type }} / {{ item.score }} 分】</span
              >
              <span class="title-question">{{ item.title }}</span>
              <div class="title-answer">
                <div>(</div>
                <div>{{ item.selected.join(", ") }}</div>
                <div>)</div>
              </div>
            </div>
            <div class="exam-list-item-answer">
              <div
                :class="{
                  'answer-item': true,
                  selected: item.selected.includes(it.label),
                }"
                v-for="(it, ind) in item.options"
                :key="ind"
                @click="selectAnswer(item, it, ind)"
              >
                <span>{{ it.label }}、</span>{{ it.content }}
              </div>
            </div>
            <div class="exam-list-item-line" v-if="examInfo.status == '2'">
              <div class="line-title">【学员答案】:</div>
              <div
                :class="{
                  'line-value': true,
                  wrong: item.answer != item.selected,
                }"
              >
                {{ item.selected.join(", ") }}
              </div>
            </div>
            <div class="exam-list-item-line" v-if="examInfo.status == '2'">
              <div class="line-title">【正确答案】:</div>
              <div class="line-value2">{{ item.answer }}</div>
            </div>
            <div class="exam-list-item-line" v-if="examInfo.status == '2'">
              <div class="line-title">【得分】:</div>
              <div class="line-value2">{{ item.userScore }}分</div>
            </div>
            <div class="exam-list-item-line" v-if="examInfo.status == '2'">
              <div class="line-title">【试题解析】:</div>
              <div class="line-value2">{{ item.analyse }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="exam-button" @click="submitExam" v-if="examInfo.status == '1'">
      交卷
    </div>
    <div class="exam-button" @click="returnExam" v-if="examInfo.status == '2'">
      返回
    </div>
  </div>
</template>

<script>
export default {
  props: {
    examInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      baseInfo: {},
      loading: false,
      remainingTime: this.examInfo.duration * 60, // 将 remainingTime 初始化为总秒数
      countdownInterval: null,
      formattedRemainingTime: "00:00:00", // 新增 formattedRemainingTime 用于显示格式化的时间
      elapsedTime: 0, // 新增 elapsedTime 用于记录考试用时
    };
  },
  methods: {
    // 点击选项, 添加选项值
    selectAnswer(item, it, ind) {
      if (this.examInfo.status == "2") {
        return;
      }
      switch (item.type) {
        case "单选题":
          this.$set(item.selected, 0, it.label);
          break;
        case "多选题":
          if (item.selected.includes(it.label)) {
            item.selected.splice(item.selected.indexOf(it.label), 1);
          } else {
            item.selected.push(it.label);
          }
          // 字母编码排序
          item.selected.sort();
          break;
        case "判断题":
          this.$set(item.selected, 0, it.label);
          break;
        default:
          break;
      }
      console.log("item", item);
      console.log("it", it);
      console.log("ind", ind);
    },
    submitExam() {
      this.$emit("submitExam", this.elapsedTime);
    },
    submit() {
      this.$emit("submit", this.elapsedTime);
    },
    returnExam() {
      this.$emit("returnExam");
    },
    scrollToQuestion(index) {
      const targetElement = document.querySelector(
        `.exam-list-item:nth-child(${index + 1})`
      );
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    },
    startCountdown() {
      if (this.remainingTime > 0) {
        this.countdownInterval = setInterval(() => {
          this.remainingTime--;
          this.elapsedTime++; // 每秒增加 elapsedTime
          this.updateFormattedTime(); // 更新格式化时间
          if (this.remainingTime == 180) {
            this.$notify({
              title: "提示",
              message: "考试时间还剩三分钟, 请尽快完成答题交卷!",
              type: "warning",
              offset: 200,
            });
          }
          if (this.remainingTime == 170) {
            clearInterval(this.countdownInterval);
            this.submit();
          }
        }, 1000); // 每秒更新一次
      }
    },
    updateFormattedTime() {
      const hours = Math.floor(this.remainingTime / 3600);
      const minutes = Math.floor((this.remainingTime % 3600) / 60);
      const seconds = this.remainingTime % 60;
      this.formattedRemainingTime = `${String(hours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    if (this.examInfo.status == "1") {
      // 开始考试倒计时
      this.startCountdown(); // 确保 countdown 方法在组件挂载时被调用
    }
  },
  beforeDestroy() {
    if (this.examInfo.status == "1") {
      // 结束考试倒计时
      clearInterval(this.countdownInterval); // 确保倒计时在组件销毁前被清除
    }
  },
};
</script>

<style lang="less" scoped>
#exam {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  .exam-title {
    font-size: 25px;
    font-weight: 700;
    padding: 20px 0;
    border-bottom: 1px solid #ccc;
  }
  .exam-content {
    display: flex;
    height: 90%;
    overflow: hidden;
    .exam-content-left {
      width: 20%;
      height: 100%;
      border-right: 1px solid #ccc;
      padding: 20px;
      box-sizing: border-box;
      font-size: 14px;
      overflow: hidden;
      .left-line {
        width: 100%;
        display: flex;
        align-items: baseline;
        margin-bottom: 20px;
        .left-line-icon {
        }
        .left-line-title {
          min-width: 60px;
          margin-left: 3px;
          margin-right: 5px;
        }
        .left-line-value {
        }
        .grade {
          color: #ee6110;
        }
        .number-list {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          height: 100%;
          margin-top: 20px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 3px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.2);
          }
          .number-item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            border: 1px solid #ccc;
            margin: 0 8px 8px 0;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
    }
    .exam-content-right {
      width: 80%;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;
      overflow-y: auto;
      .exam-list {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: rgba(0, 0, 0, 0.2);
        }
        .exam-list-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 25px;

          .exam-list-item-title {
            display: flex;
            align-items: center;
            font-size: 16px;
            margin-bottom: 15px;
            .title-answer {
              min-width: 50px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              margin: 0 5px;
            }
          }
          .exam-list-item-answer {
            display: flex;
            flex-direction: column;
            .answer-item {
              width: fit-content;
              display: flex;
              align-items: center;
              margin-bottom: 15px;
              font-size: 16px;
              color: #9d9fa3;
              cursor: pointer;
              span {
                font-weight: 700;
              }
            }
            .selected {
              color: #409eff;
            }
          }
          .exam-list-item-line {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            text-align: left;

            .line-title {
              white-space: nowrap;
            }
            .line-value {
              display: flex;
              align-items: center;
              flex: 1;
              height: 30px;
              padding: 0 8px;
              background-color: #f4f9fc;
              border-radius: 5px;
              font-size: 16px;
            }
            .line-value {
              line-height: 1.5;
            }
          }
        }
      }
    }
    .wrong {
      background-color: #fbcccc !important;
    }
  }
  .exam-button {
    position: absolute;
    bottom: 20px;
    right: 80px;
    width: 140px;
    min-width: 160px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
    cursor: pointer;
  }
}
</style>
