<template>
  <div id="my_resources">
    <!-- 我的资源 -->
    <div class="thread-screen">
      <div class="thread-screen-line">
        <div class="thread-screen-fiftrate">
          <el-popover
            placement="bottom-start"
            width="250"
            trigger="manual"
            :visible-arrow="false"
            v-model="popoData.popoVisible"
          >
            <div class="popover-search">
              <popo_search
                :list="popoData.list"
                @cancel="popoCanael"
                @reset="popoReset"
                @makeSure="popoMakeSure"
              ></popo_search>
            </div>
            <div slot="reference" @click="popoverOpen">
              筛选 <i class="el-icon-s-operation"></i>
            </div>
          </el-popover>
        </div>
        <div class="thread-screen-search">
          <el-input
            placeholder="商机名称/联系人/手机号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="thread-screen-search">
          <el-select
            v-model="searchData.userId"
            placeholder="请选择商机跟进人"
            @change="seleteInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="thread-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="thread-screen-active"></div>
        <div class="thread-screen-search">
          <el-input placeholder="请输入被叫号码" v-model="searchData.phone">
            <el-button
              slot="append"
              icon="el-icon-phone-outline phone"
              @click.native.stop="callNumber(searchData.phone)"
            ></el-button>
          </el-input>
        </div>
        <div
          class="thread-screen-but-border"
          style="width: 70px;"
          v-showFunction="{ active: 'import', array: allFunctionButtons }"
          @click="importThread(0)"
          v-loading="false"
          element-loading-background="rgba(0, 0, 0, 0.2)"
          element-loading-spinner="el-icon-loading"
        >
          导入
        </div>
        <div
          v-if="whetherAgencyHead"
          class="thread-screen-but-border"
          @click="importThread(1)"
        >
          导出
        </div>
        <div>
          <el-dropdown>
            <el-button type="small" class="drop-button">
              批量操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click.native="importThread(3)"
                v-showFunction="{
                  active: 'allocation',
                  array: allFunctionButtons,
                }"
                >批量分配</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="importThread(4)"
                v-showFunction="{
                  active: 'deleteBatch',
                  array: allFunctionButtons,
                }"
                >批量删除</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- <div
          class="thread-screen-but-border"
          @click="importThread(3)"
          v-showFunction="{ active: 'allocation', array: allFunctionButtons }"
        >
          批量分配
        </div>
        <div
          class="thread-screen-but-border"
          @click="importThread(4)"
          v-showFunction="{ active: 'deleteBatch', array: allFunctionButtons }"
        >
          批量删除
        </div> -->
        <div
          class="thread-screen-but-border"
          v-showFunction="{ active: 'create', array: allFunctionButtons }"
          @click="importThread(2)"
        >
          新建
        </div>
      </div>
      <div class="thread-screen-line" v-if="searchData.screenList.length > 0">
        <div class="title">筛选条件:</div>
        <el-tag
          v-for="(tag, index) in searchData.screenList"
          :key="index"
          closable
          size="small"
          @close="closeScreen(index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>
    <div class="thread-table">
      <div class="thread-table-card">
        <div class="thread-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            height="98%"
            @selection-change="handleSelectionChange"
            :row-style="{ height: '65px' }"
            :default-sort="defaultSort"
            @sort-change="sortChange"
            v-loading="loading"
          >
            <el-table-column
              type="selection"
              width="35"
              fixed
            ></el-table-column>
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <template v-for="(item, index) in tabMenu">
              <el-table-column
                :key="index"
                :property="item.key"
                :fixed="index == 0"
                :label="item.name"
                v-if="item.authority == 1"
                :sortable="
                  item.key == 'lastContactTime' ||
                  item.key == 'createTime' ||
                  item.key == 'protectTime'
                    ? 'custom'
                    : false
                "
                :min-width="item.width"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <div
                    class="hri"
                    v-if="item.key == 'threadName' && scope.row.clientFlag"
                  >
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="已签约"
                      placement="top"
                    >
                      <i class="el-icon-star-on"></i> </el-tooltip
                    >{{ scope.row[item.key] }}
                  </div>
                  <div v-else>
                    {{ scope.row[item.key] }}
                    <span
                      v-if="item.key == 'phone'"
                      @click="handleClick(scope.row, 3)"
                    >
                      <i class="el-icon-phone-outline phone"></i>
                    </span>
                  </div>
                </template>
              </el-table-column>
            </template>
            <el-table-column label="操作" width="250">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row, 0)"
                  type="primary"
                  size="mini"
                  icon="el-icon-edit-outline"
                  plain
                  v-showFunction="{
                    active: 'followUp',
                    array: allFunctionButtons,
                  }"
                  >跟进</el-button
                >
                <el-button
                  @click="handleClick(scope.row, 1)"
                  type="success"
                  plain
                  icon="el-icon-edit"
                  size="mini"
                  v-showFunction="{
                    active: 'edit',
                    array: allFunctionButtons,
                  }"
                  >编辑</el-button
                >
                <el-button
                  @click="handleClick(scope.row, 2)"
                  type="danger"
                  plain
                  icon="el-icon-delete"
                  size="mini"
                  v-showFunction="{
                    active: 'delete',
                    array: allFunctionButtons,
                  }"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="thread-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-sizes="[100, 200, 300, 400, 500]"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @buttonOper="drawerOper"
        @choseProductTipOne="choseProductTipOne"
        @saveDrawerData="saveDrawerData"
        @addFollowRecord="followRecord"
      ></my_dialog>
    </div>

    <!-- 右边抽屉 -->
    <div id="my-drawer" v-if="threadDrawerData.flag">
      <my_drawer
        :drawerData="threadDrawerData"
        :allFunctionButtons="allFunctionButtons"
        :model="'myResources'"
        @show="showDrawer"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @saveDrawerData="saveDrawerData"
        @tagsHandleClose="tagsHandleClose"
        @addTags="addTags"
        @drawerOper="drawerOper"
        @changeOrCreateResource="changeOrCreateResource"
      ></my_drawer>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import my_drawer from "@/components/my-drawer.vue";
import my_dialog from "@/components/dialog.vue";
import popo_search from "@/components/popo-search.vue";
import * as myThreadData from "@/assets/js/myThread.js";
import { v4 as uuidv4 } from "uuid";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { callNumber } from "@/minixs.js";
export default {
  components: {
    my_drawer,
    popo_search,
    my_dialog,
  },
  inject: ["reload"],
  data() {
    return {
      whetherAgencyHead: false,
      baseInfo: {},
      loading: true,
      searchData: {
        input: "",
        checked: false,
        screenButton_improt: 1, // 导入 1->读写;2->只读;3->不可见
        screenButton_new: 1, // 新建
        screenButton_export: 1, // 导出客户商机按钮的权限 1->读写;2->只读;3->不可见
        userId: "", //
        userList: [],
        tempUserId: "",
        departTreeData: [],
        department: "",
        screenList: [],
        phone: "",
      },
      popoData: {
        popoVisible: false,
        list: myThreadData.remyThreadSearchData(),
      },
      tabindex: "0",
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 100, // 每页条数
      },
      tabMenu: [],
      tableData: [],
      multipleSelection: [],
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: myThreadData.getMyThreadsGenjinjiluData(),
      },
      threadDrawerData: myThreadData.myThreadDrawerData("商机", {
        buttonAuth: [],
        data: [],
        customer: { name: "", id: "" },
      }),
      tempTags: [],
      tempThread: {
        id: "",
        name: "",
        clientFlag: false,
      },
      arrayList: {
        employeeList: [], // 所有员工列表
        departmentList: [], // 所有部门列表
        areaList: [], // 所有片区列表
        sourceList: [], // 所有客户来源列表
        followStageList: [], // 跟进阶段列表
        addedValueTaxTypeList: [], // 增值税类型列表
        intentionalProductList: [], // 所有意向产品列表
      },
      buttonAuth: [],
      tempAllEmpolyeeList: [],
      productList: [],
      clientList: [],

      tempContract: { id: "", name: "" }, // 临时合同
      tempClient: {},
      tempServices: [], // 该合同所包含的所有服务
      professionList: [], // 所有合同执行模板
      serviceItemList: [], // 所有服务项数据
      employeeList: [], // 所有员工
      departmentList: [], // 所有员工

      contractSettingButtonList: [],

      defaultSort: { prop: "", order: "" },
      contractDrawerData: {}, // 合同预览数据
      accountList: [], // 收款账号
      contractId: "", // 预览的合同ID

      ttempClient: {},
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "myResources"
      ),
      webSocketObject: {},
      uuid: "",
      pictLoading: false,
    };
  },
  mixins: [callNumber],
  methods: {
    webSocketInit() {
      let lastIndex = this.$baseurl.lastIndexOf("/");
      // 使用 substring 方法截取字符串
      let result = this.$baseurl.substring(lastIndex + 1);
      this.uuid = uuidv4();
      const webSocketUrl = `${this.$webSocket}://${result}/rate/${this.uuid}`;
      console.log("webSocketUrl", webSocketUrl);
      this.webSocketObject = new WebSocket(webSocketUrl);
      this.webSocketObject.onopen = this.webSocketOnOpen;
      this.webSocketObject.onmessage = this.webSocketOnMessage;
      this.webSocketObject.onerror = this.webSocketOnError;
      this.webSocketObject.onclose = this.webSocketOnClose;
    },
    webSocketOnOpen(e) {
      // 与服务端连接打开
      this.uploadFail();
    },
    webSocketOnMessage(e) {
      console.log("来自服务端的消息->", e);
      if (document.querySelector("#my_resources .thread-table")) {
        if (e.data == "1") {
          NProgress.done();
        } else {
          let number = Number(Number(e.data).toFixed(2)) || 0.01;
          console.log("number", number);
          NProgress.set(number);
        }
      }
    },
    webSocketOnError(e) {
      // 与服务端连接异常

      NProgress.done();
    },
    webSocketOnClose(e) {
      // 与服务端连接关闭

      NProgress.done();
    },
    uploadFail() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        url: this.dialogData.data[0].value.url,
        rateEndpointId: this.uuid,
      };
      NProgress.start();

      this.dialogData.flag = false;
      this.$request(
        "get",
        "/reThread/importResource?data=" + JSON.stringify(requestData),
        {},
        (data) => {
          this.$message({
            message: data,
            type: "success",
          });
          this.dialogData.flag = false;
          this.initTableData();
        },
        (err) => {
          this.$message({
            message: err,
            type: "error",
          });

          this.dialogData.flag = false;
          NProgress.done();
          this.initTableData();
        }
      );
    },
    getModelFeatureAuthority() {
      this.$authority.getModelFeatureAuthority(
        this,
        "myResources",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
        }
      );
    },
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.initMenu();
      });
    },
    initMenu() {
      this.$featureData.getAttributeData("myResources", true, [], (data) => {
        this.tabMenu = data;
        console.log(this.tabMenu);
        this.getEmployeeList();
        myThreadData.getTagList(
          this.arrayList,
          ["跟进阶段", "片区", "客户来源", "增值税类型"],
          this.baseInfo.company.companyId,
          this
        );
      });
    },
    /**
     * 获取所有员工列表
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.employeeList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.employeeList.push(dd);
          });
          this.getServicesItem();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有服务项列表
     */
    getServicesItem() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        status: "1",
        name: "", // 服务产品名称 模糊查询
        type: "",
      };
      this.$request(
        "post",
        "/servicesItem/getServicesItem",
        requestData,
        (data) => {
          this.serviceItemList = data;
          this.getAllProductList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有合同模板/产品列表
     */
    getAllProductList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        name: "", // 服务产品名称 模糊查询
        type: "",
        currentPage: 1, // 当前页数
        pageSize: 1000, // 每页条数
      };
      this.$request(
        "post",
        "/product/getAllProduct",
        requestData,
        (data) => {
          this.productList = data.tableData;
          this.getEmployeeListByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeListByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = data;
          this.tempAllEmpolyeeList = [];
          data.forEach((element) => {
            this.tempAllEmpolyeeList.push(element.userId);
          });
          this.searchData.userList.splice(0, 0, {
            userId: "all",
            userName: "全部",
          });
          this.getDepartmentTreeByUserId();
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        // "/setting/getDepartmentTreeByUserId",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 表格排序事件
     */
    sortChange(data) {
      this.defaultSort.prop = data.prop;
      this.defaultSort.order = data.order == null ? "" : data.order;
      this.initTableData();
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        userId: this.searchData.tempUserId,
        companyId: this.baseInfo.company.companyId, // 当前公司ID
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
        search: this.searchData.input,
        sortProp: this.defaultSort.prop, // 手动排序项
        sortOrder: this.defaultSort.order, // 手动排序规则 ascending descending ""
        screenList: this.searchData.screenList,
      };
      this.$request(
        "post",
        "/reThread/getMyResources",
        requestData,
        (data) => {
          this.pageData.total = data.total; // 获取总条目数
          this.tableData = myThreadData.regetInitTableData(
            data.tableData,
            this.tabMenu
          );
          this.$nextTick(() => {
            this.$refs.singleTable.doLayout();
          });

          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    seleteInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.tempAllEmpolyeeList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },

    /**
     * 表格单行点击事件
     */
    rowClick(row, column, event) {
      if (!this.popoData.popoVisible) {
        if (!this.threadDrawerData.flag) {
          //防止重复点击
          this.tempThread.id = row.id;
          this.tempThread.name = row.threadName;
          this.tempThread.clientFlag = row.clientFlag;
          this.getMyResourcesDetailsByThreadId();
        } else {
          //   this.closeUpdateTableDrawer();
        }
      } else {
        this.popoCanael();
      }
    },
    getMyResourcesDetailsByThreadId() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        threadId: this.tempThread.id,
      };
      this.$request(
        "post",
        "/reThread/getMyResourcesDetailsByThreadId",
        requestData,
        (data) => {
          this.threadDrawerData = myThreadData.getMyResourcesDrawerData(data);
          this.threadDrawerData.flag = true;
        }
      );
    },
    /**
     * 多选框事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 商机的批量导出
     */
    importThread(active) {
      switch (active) {
        case 0:
          // 导入资源
          if (this.searchData.screenButton_improt == 2) {
            this.$message.error(
              this.$errorMessage.getErrorMessageInfo("authority")
            );
            return;
          }
          if (this.webSocketObject.readyState == 1) {
            this.$message.error("正在导入资源,请耐心等待");
            return;
          }
          this.dialogData.title = "导入资源";
          this.dialogData.data = myThreadData.improtResourcesDialogData(this);
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "makesure_improt_resources",
            },
          ];
          this.dialogData.flag = true;
          break;
        case 1:
          // 导出资源
          if (this.searchData.screenButton_export == 2) {
            this.$message.error(
              this.$errorMessage.getErrorMessageInfo("authority")
            );
            return;
          }
          if (this.multipleSelection.length == 0) {
            this.$message.error("请先选择想要导出的资源!");
            return;
          }
          let requestData = "";
          this.multipleSelection.forEach((element) => {
            if (requestData == "") {
              requestData = element.id;
            } else {
              requestData = requestData + "," + element.id;
            }
          });
          window.location.href =
            this.$baseurl + "/reThread/exportResource?threadId=" + requestData;
          break;
        case 2:
          // 新建资源
          if (this.searchData.screenButton_new == 2) {
            this.$message.error(
              this.$errorMessage.getErrorMessageInfo("authority")
            );
            return;
          }
          this.threadDrawerData = {};
          this.threadDrawerData = myThreadData.getNewResourceDrawerData();
          this.threadDrawerData.flag = true;

          break;
        case 3:
          // 批量分配
          if (this.multipleSelection.length == 0) {
            this.$message({
              message: "请先选择要分配的资源!",
              type: "error",
            });
            return;
          }
          this.dialogData.title = "选择分配人";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "提交",
            active: "submit_allocation",
          });
          this.dialogData.data = [];

          let d2 = {
            type: "select",
            value: "",
            title: "请选择分配人员",
            placeholder: "请选择分配人员",
            options: this.employeeList,
          };
          this.dialogData.data.push(d2);
          this.dialogData.flag = true;
          break;
        case 4:
          // 批量删除
          if (this.multipleSelection.length == 0) {
            this.$message.error("请先选择要删除的资源!");
          } else {
            let requestData = {
              threadId: this.multipleSelection.map((item) => item.id), // 资源id
            };
            this.$confirm("确定要撤销该派工吗?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$request(
                  "post",
                  "/reThread/shiftDeleteThread",
                  requestData,
                  (data) => {
                    this.$message.success("删除成功!");
                    this.initTableData();
                  },
                  (errormessage) => {}
                );
              })
              .catch(() => {});
          }
          break;
        default:
          break;
      }
    },
    /**
     * 新建资源
     */
    changeOrCreateResource(value) {
      let requestData = {
        threadName: value, // 资源名称
        userId: this.baseInfo.userId, // 资源跟进人、资源创建人
        companyId: this.baseInfo.company.companyId, // 哪家的资源
      };
      this.$request(
        "post",
        "/reThread/createResource",
        requestData,
        (data) => {
          this.$message({
            message: "资源创建成功!",
            type: "success",
          });
          // this.threadDrawerData.flag = false;
          this.initTableData();
          this.tempThread.id = data.id;
          this.tempThread.name = requestData.threadName;
          this.getMyResourcesDetailsByThreadId();
        },
        (errormessage) => {
          this.$message.error(errormessage);
        }
      );
    },
    /**
     *
     */
    drawerOper(active) {
      let dd = {};
      let requestData = {};
      switch (active) {
        case "makesure_improt_resources":
          // 确定导入资源模板
          if (this.dialogData.data[0].value.url == "") {
            this.$message.error("请先选择要导入的文件!");
            return;
          }
          this.webSocketInit();
          break;
        case "operating_record":
          // 查看操作记录
          this.getOperationRecordsList();
          break;
        case "collect_resources":
          // 领取资源
          requestData = {
            userId: this.baseInfo.userId,
            compantId: this.baseInfo.company.companyId,
            threadId: this.tempThread.id,
          };
          this.$request(
            "post",
            "/reThread/collectResources",
            requestData,
            (data) => {
              this.$operationRecords.saveOperationRecords(
                this.tempThread.id,
                "领取商机【" + this.tempThread.name + "】!",
                this.baseInfo,
                "thread",
                this,
                (data) => {
                  this.$message({
                    message: "资源领取成功!",
                    type: "success",
                  });
                  this.threadDrawerData.flag = false;
                  this.initTableData();
                }
              );
            }
          );
          break;
        case "get_companyInfo":
          // 获取工商信息
          this.$request(
            "get",
            "/qicc/search?keyword=" + this.threadDrawerData.data.title,
            {},
            (data) => {
              this.threadDrawerData.data.title = data.name;
              this.threadDrawerData.data.modelList[1].line[4].value =
                data.taxNum == ""
                  ? this.threadDrawerData.data.modelList[1].line[4].value
                  : data.taxNum; // 企业税号
              // this.threadDrawerData.data.modelList[1].line[5].value =
              //   data.tel == ""
              //     ? this.threadDrawerData.data.modelList[1].line[5].value
              //     : data.tel; // 联系方式
              // this.threadDrawerData.data.modelList[1].line[6].value =
              //   data.bank == ""
              //     ? this.threadDrawerData.data.modelList[1].line[6].value
              //     : data.bank; // 开户行
              // this.threadDrawerData.data.modelList[1].line[7].value =
              //   data.bankAccount == ""
              //     ? this.threadDrawerData.data.modelList[1].line[7].value
              //     : data.bankAccount; // 银行账号
              this.threadDrawerData.data.modelList[1].line[3].value =
                data.address == ""
                  ? this.threadDrawerData.data.modelList[1].line[3].value
                  : data.address; //详细地址

              this.threadDrawerData.data.modelList[1].line[2].value = [];
              this.threadDrawerData.data.modelList[1].line[2].value = this.$addressAutomaticallyMatch.addressAutomaticallyMatch(
                data.address
              );

              if (
                this.threadDrawerData.data.modelList[1].line[2].value == null ||
                this.threadDrawerData.data.modelList[1].line[2].value.length < 3
              ) {
                this.threadDrawerData.data.modelList[1].line[2].value = [];
              }
              this.saveDrawerData("工商信息");
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "delete_resource":
          // 删除资源
          requestData = {
            threadId: [this.tempThread.id],
          };
          this.$confirm("此操作将永久删除该资源, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/reThread/shiftDeleteThread",
                requestData,
                (data) => {
                  this.$message({
                    message: "资源删除成功!",
                    type: "success",
                  });
                  this.threadDrawerData.flag = false;
                  this.initTableData();
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;
        case "makesure_addPurposeThread":
          this.addPurposeThread();
          // 添加意向产品
          break;
        case "submit_allocation":
          // 批量分配资源
          this.allocationThread(
            this.multipleSelection,
            this.dialogData.data[0].value
          );
          break;
        default:
          break;
      }
    },

    /**
     * 批量分配商机
     */
    allocationThread(array, userId) {
      let ids = array.map((item) => item.id);
      let requestData = {};
      requestData = {
        userId: this.baseInfo.userId,
        followUser: userId, // 被分配人 id
        threadIds: ids, // 分配的商机 ids
      };
      // 1. 将我的资源转成目标账户我的资源
      this.$request(
        "post",
        "/reThread/fromThreadPoolToMyResource",
        requestData,
        (data) => {
          // 2. 每一条资源添加操作记录
          this.initTableData();
          array.forEach((element) => {
            console.log("🚀 ~ array.forEach ~ element:", element);

            this.$operationRecords.saveOperationRecords(
              element.id,
              "资源【" + element.threadName + "】分配成功!",
              this.baseInfo,
              "thread",
              this,
              (data) => {}
            );
          });
        }
      );

      this.dialogData.flag = false;
    },
    /**
     * 表格行操作
     */
    handleClick(row, active) {
      let requestData = {};
      this.tempThread = row;
      this.tempThread.id = row.id;
      this.tempThread.name = row.threadName;
      this.tempThread.clientFlag = row.clientFlag;
      switch (active) {
        case 0:
          // 跟进
          this.showGenjin(row);
          break;
        case 1:
          // 编辑
          this.getMyResourcesDetailsByThreadId();
          break;
        case 2:
          // 删除
          this.$confirm("此操作将永久删除该资源, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                threadId: [this.tempThread.id],
              };
              this.$request(
                "post",
                "/reThread/shiftDeleteThread",
                requestData,
                (data) => {
                  this.$message({
                    message: "资源删除成功!",
                    type: "success",
                  });
                  this.threadDrawerData.flag = false;
                  this.initTableData();
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;
        case 3:
          // 拨打电话
          this.callNumber(this.tempThread.phone);
          break;
        default:
          break;
      }
    },
    /**
     * 查看跟进记录
     */
    showGenjin(clientData) {
      this.ttempClient = clientData;
      this.dialogData.title = "跟进记录-" + clientData.threadName;
      this.dialogData.buttonArray = [];
      this.dialogData.data = [
        {
          type: "genjinjilu",
          value: {
            clientId: clientData.id,
            threadId: "",
            hasContent: true,
            content: "",
            time: "",
            list: [],
            hasTarget: true,
            target: "",
            hasPhase: true,
            phase: "",
            addWeChatFriend: false,
          },
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 确定添加跟进记录
     */
    followRecord(recordData) {
      let requestData = {
        clientId: "", // 商机ID
        threadId: this.tempThread.id, // 商机ID
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId, // 操作人ID
        content: recordData.content, // 当前操作
        time: recordData.time, // 下次跟进时间
        stage: recordData.phase, // 跟进阶段
        giveUpReason: recordData.target, // 领取目标 '我的商机'单独判断
        addWeChatFriend: recordData.weChatFriend, // 是否为微信待加
      };
      if (requestData.content == "") {
        this.$message.error("跟进内容不能为空!");
        return;
      }
      if (requestData.target == "") {
        this.$message.error("领取目标不能为空!");
        return;
      }
      this.$request(
        "post",
        "/reThread/addResourcesFollow",
        requestData,
        (data) => {
          this.$message({
            message: "跟进记录添加成功!",
            type: "success",
            duration: 1000,
          });
          this.$operationRecords.saveOperationRecords(
            this.tempThread.id,
            "添加跟进记录:" + recordData.content + "!",
            this.baseInfo,
            "thread",
            this,
            (data2) => {
              this.$message({
                message: "资源跟进成功!",
                type: "success",
              });
              this.dialogData.flag = false;
              this.initTableData();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 选择产品创建客户
     */
    choseProductTipOne(data) {},

    /**
     * 保存抽屉数据
     */
    saveDrawerData(drawerTitle) {
      if (
        !myThreadData.getDrawerSubmitData(this.threadDrawerData.data.modelList)
          .flag
      ) {
        this.$message({
          message: "联系人手机号格式不正确,请确认填入正确的手机号!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      let requestData = {
        threadId: this.tempThread.id,
        userId: this.baseInfo.userId,
        company: this.baseInfo.company.companyId,
        data: myThreadData.getDrawerSubmitData(
          this.threadDrawerData.data.modelList
        ).list,
        threadName: this.threadDrawerData.data.title,
      };
      if (requestData.threadName == "") {
        this.$message({
          message: "资源名称不能为空!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      this.$request(
        "post",
        "/reThread/updateResources",
        requestData,
        (data) => {
          this.$message({
            message: "修改成功!",
            type: "success",
            duration: 1000,
          });
          this.$operationRecords.saveOperationRecords(
            this.tempThread.id,
            "修改" + drawerTitle + "信息!",
            this.baseInfo,
            "thread",
            this,
            (data) => {
              this.getMyResourcesDetailsByThreadId();
              this.initTableData();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
      this.searchData.screenList = [];
    },
    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      console.log("所有数组数据:", this.arrayList);
      // this.popoData.list[0].value = this.arrayList.employeeList// 商机状态
      this.popoData.list[1].options = []; // 客户来源
      this.popoData.list[7].options = []; // 商机创建人
      this.popoData.list[6].options = []; // 商机创建人
      this.popoData.list[8].options = []; // 意向产品
      this.arrayList.sourceList.forEach((element) => {
        if (element.active) {
          let dd = {
            label: element.name,
            value: element.value[0],
          };
          this.popoData.list[1].options.push(dd);
        }
      });

      this.popoData.list[6].options = this.employeeList;
      this.popoData.list[7].options = this.employeeList;
      this.productList.forEach((element) => {
        let dd = {
          label: element.name,
          value: element.id,
        };
        this.popoData.list[8].options.push(dd);
      });

      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选弹出框确认事件
     */
    popoMakeSure() {
      this.searchData.screenList = this.$systemBaseData.remakesureSearchData(
        this.popoData.list
      );
      // console.log(this.popoData.list);
      this.popoData.popoVisible = false;
      this.initTableData();
    },
    /**
     * 关闭筛选标签事件
     */
    closeScreen(index) {
      this.searchData.screenList.splice(index, 1);
      this.initTableData();
    },
    /**
     * 弹出框关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.dialogData.data.forEach((element) => {
        if (element.value) {
          element.value = "";
        }
      });
    },
    /**
     * 抽屉中添加tag事件
     * data(i,j):i为第几个model,j为第几line
     * 首先获取所有产品allProductList,
     * 其次拿到当前商机中以选中的意向产品
     */

    addTags(data) {
      this.tempTags = this.threadDrawerData.data.modelList[data.i].line[
        data.j
      ].value;
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.dialogData.title = "选择产品";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "确定",
        active: "makesure_addPurposeThread",
      });
      this.dialogData.buttonArray.push({
        text: "取消",
        active: "cancel",
      });
      let tempProductList = [];
      this.productList.forEach((element) => {
        let dd = {
          id: element.id,
          title: element.name,
          serve: element.servicesItem,
        };
        tempProductList.push(dd);
      });
      this.dialogData.data = [
        {
          type: "intention_the_product",
          title: "",
          value: {
            clientName: this.tempThread.name,
            chooseProduct: JSON.parse(JSON.stringify(this.tempTags)),
          },
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 抽屉中tag移除事件
     * data(tag,i,j):tag->移除的tag,i为第几个model,j为第几line
     */
    tagsHandleClose(data) {
      this.threadDrawerData.data.modelList[data.i].line[data.j].value.splice(
        this.threadDrawerData.data.modelList[data.i].line[data.j].value.indexOf(
          data.tag
        ),
        1
      );
      this.saveDrawerData(
        this.threadDrawerData.data.modelList[data.i].line[data.j].title
      );
    },
    /**
     * 确认添加意向产品
     */
    addPurposeThread() {
      let requestData = {
        threadId: this.tempThread.id, // 商机ID
        value: this.dialogData.data[0].value.chooseProduct,
      };
      this.$request(
        "post",
        // "/thread/addPurposeThread",
        "/reThread/addPurposeThread",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.$operationRecords.saveOperationRecords(
            this.tempThread.id,
            "将资源【" + this.tempThread.name + "】添加意向产品!",
            this.baseInfo,
            "thread",
            this,
            (data) => {
              this.initTableData();
              this.getMyResourcesDetailsByThreadId();
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.threadDrawerData.colseanimationflag = true;
      this.threadDrawerData.flag = false;
      // this.initTableData();
      // this.saveDrawerData();
    },
    /**
     * 右边抽屉父子组件连锁事件
     */
    showDrawer(value) {
      this.threadDrawerData = value;
    },
    /**
     * 查看操作记录
     */
    getOperationRecordsList() {
      this.$operationRecords.showOperationRecords(
        this.tempThread.id,
        "thread",
        this
      );
    },
    // /**
    //  * 修改客户抽屉公司名称
    //  */
    // updateDrawerTitle(title) {
    //   console.log("contractDrawerData", this.contractDrawerData);
    //   let requestData = {
    //     clientId: this.ttempClient.clientId, // 客户id
    //     companyId: this.baseInfo.company.companyId, // 公司 id
    //     clientName: this.contractDrawerData.data.title, // 修改后的客户名称
    //   };
    //   this.$request(
    //     "post",
    //     "/client/updateClientNameForContract",
    //     requestData,
    //     (data) => {
    //       this.$message.success("修改成功!");
    //       this.initTableData();
    //     },
    //     (errorMessage) => {
    //       this.$message.error(errorMessage);
    //     }
    //   );
    // },
  },
  mounted() {
    NProgress.configure({
      showSpinner: false,
      trickle: false,
      easing: "linear",
      speed: 100,
      minimum: 0.0,
      parent: "#my_resources .thread-table",
    });
    this.loading = true;
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.searchData.userId = this.baseInfo.userId;
    this.searchData.tempUserId = [this.baseInfo.userId];
    this.getModelFeatureAuthority();
  },
  beforeDestroy() {
    // 在组件销毁前取消防抖，确保callNumber存在
    if (this.callNumber && typeof this.callNumber.cancel === "function") {
      this.callNumber.cancel();
    }
  },
};
</script>

<style lang="less" scoped>
#my_resources {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .thread-screen {
    width: 100%;
    height: auto;
    border-bottom: 1px solid @boderColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    .thread-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .thread-screen-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .thread-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .thread-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-right: 5px;
      }
      .drop-button {
        background-color: #ecf5ff;
        border: 1px solid #40a3ff;
        color: #40a3ff;
        transition: 0.5s;
        &:hover {
          background-color: #abbed3;
        }
      }
      .thread-screen-checked {
        width: 140px;
        height: 30px;
        min-width: 140px;
        font-size: @contentFontSize;
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .thread-screen-active {
        flex: 1;
      }
      .thread-screen-but-select {
        width: 140px;
        min-width: 160px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
        .thread-screen-but-select_but {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
        }
        .thread-screen-but-select_but:hover {
          background-color: @activeColor2;
        }
        .thread-screen-but-select_select {
          width: 30px;
          height: 100%;
          border-left: 1px solid @activeColor3;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .thread-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .thread-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .thread-screen-but-border {
        width: auto;
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
        padding: 0 10px;
        box-sizing: border-box;
        /deep/ .el-loading-spinner {
          top: 97%;
          left: -23px;
          margin-top: -21px;
          width: 100%;
          text-align: center;
          position: absolute;
        }
      }
      .thread-screen-but-border:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
    .hri {
      display: flex;
      i {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 3px;
        font-size: 14px;
        color: @activeColor;
      }
    }
  }
  .thread-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    position: relative;
    .thread-table-card-content-tip {
      width: 98%;
      height: 4vh;
      position: absolute;
      top: 2vh;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .thread-table-card-active {
        flex: 1;
      }
      .thread-table-card-edit {
        width: 30px;
        min-width: 30px;
        margin-right: 10px;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @editColor;
        cursor: pointer;
      }
    }
    .thread-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .thread-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        .thread-table-card-tab {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .thread-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .thread-table-card-active {
          flex: 1;
        }
        .thread-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .thread-table-card-content {
        flex: 1;
        width: 98%;
        height: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: @contentFontSize;
        color: @closeColor;
        .phone {
          color: red;
          transform: rotateY(180deg);
          cursor: pointer;
        }
      }
      .thread-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
  .hri {
    display: flex;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
}
.popover-search {
  // 筛选的pop弹出框
  max-height: 800px;
}
</style>
